import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiEventStatus, ApiEventType } from 'src/app/shared/models/api-event';
import { AgenciesSchema } from 'src/app/shared/models/dto/agency.dto';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { ApiEventService } from 'src/app/shared/services/api-event.service';
import * as _ from 'lodash';  
import { RecruiterDto } from 'src/app/shared/models/dto/recruiter.dto';

@UntilDestroy()
@Component({
  selector: 'app-recuiters-dropdown',
  templateUrl: './recuiters-dropdown.component.html',
  styleUrls: ['./recuiters-dropdown.component.scss']
})
export class RecuitersDropdownComponent implements OnInit {
  @Input() ngControl: AbstractControl;
  @Input() name: string;
  @Input() optionValue: string;
  @Input() placeholder: string = 'Select'
  @Input() readonly: boolean = false;


  disabled: boolean = false;
  overlayVisible: boolean = false;
  focused: boolean = false;
  showClear: boolean = false;
  disableInfiniteScroll: boolean = false;
  haltInfiniteScroll: boolean = false;


  items: Array<RecruiterDto> = [];
  total: number = 0;

  constructor(private agencyService: AgencyService,
    private apiEventService: ApiEventService) { 
      this.onKeypressEvent = _.debounce(this.onKeypressEvent, 500);
      
      //disableInifiniteScroll because api does not support lazy loading
      this.disableInfiniteScroll = true;
    }

    ngOnInit(): void {
      this.getSubcribers();
      this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event=>{
        if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_AGENCIES) {
          this.haltInfiniteScroll = false;
        }
      })
    }

    getSubcribers() {
      this.agencyService.recruiters$
      .pipe(untilDestroyed(this))
      .subscribe((response: Array<RecruiterDto>)=>{
        this.items = response;
      })
    }

    onScrolledBottom() {
      // if(this.items.length >= this.total) {
      //   this.disableInfiniteScroll = true;
      // }

      // if((this.items.length <= this.total) && !this.haltInfiniteScroll) {
      //   const lastItemRecruiter = this.items[this.items.length -1];
      //   this.agencyService.getAgencyRecruiters(lastItemRecruiter.accountContactId, null, 20);
      //   this.haltInfiniteScroll = true;
      // } 
    }

    onKeypressEvent(ev) {
      // this.agencyService.getAgencies(null, ev.target.value, 20);
      // this.items = [];
    }
}

