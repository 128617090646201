import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AccountStatus } from '../models/dto/account.dto';
import { ReadonlyMapBuilder } from '../readyonly-map-builder';
  
@Directive({
    selector: '[regStatusTextColor]'
})
export class RegStatusTextColorDirective implements OnInit, OnChanges{
    @Input() accountStatus: string;

    private readonly statusColorMap = ReadonlyMapBuilder.create<AccountStatus, string>()
    .set(AccountStatus.IN_REVIEW ,'#ff8848')
    .set(AccountStatus.NOT_VERIFIED ,'#ff3251')
    .set(AccountStatus.PENDING ,'#E8AF16')
    .set(AccountStatus.VERIFIED ,'#51AF33')
    .build();


    constructor(private eleRef: ElementRef) {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.eleRef.nativeElement.style.color = this.statusColorMap.get(AccountStatus[this.accountStatus]);
    }

    ngOnInit(): void {
    }
}