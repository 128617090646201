<div  class="grid grid-nogutter">
    <div class="col-12 lg:col-6 pr-2">
        <div class="field w-100">
            <label class="w-full">{{startDateLabel}}</label>
            <p-calendar  [dateFormat]="CONST.DATE_FORMAT"
                         [showIcon] = "true"
                         (onSelect)="onSelectedStartDate.emit($event)"
                          [(ngModel)]="startDate"
                          placeholder="Please Select"
                          class="full-width-calendar-input"></p-calendar>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="field">
            <label class="w-full">{{endDateLabel}}</label>
            <p-calendar  [dateFormat]="CONST.DATE_FORMAT"
                         [showIcon] = "true"
                         (onSelect)="onSelectedEndDate.emit($event)"
                          [disabled]="!startDate"
                          [minDate]="startDate"
                          [(ngModel)]="endDate"
                          placeholder="Please Select"
                          class="full-width-calendar-input"></p-calendar>
        </div>
    </div>
</div>
