import { Component, Input, OnInit } from '@angular/core';
import { ContactDto } from '../../models/dto/contact.dto';

@Component({
  selector: 'app-get-contact-by-account-id',
  templateUrl: './get-contact-by-account-id.component.html',
  styleUrls: ['./get-contact-by-account-id.component.scss']
})
export class GetContactByAccountIdComponent implements OnInit {
  @Input() contacts: Array<ContactDto> = [];
  @Input() accountContactId: number;
  constructor() { }

  ngOnInit(): void {
  }

  get contact(): ContactDto {
    return this.contacts.find((v)=>{
      return v.id === this.accountContactId;
    });
  }
}
