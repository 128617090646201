<div  class="grid grid-nogutter">
    <div class="col-9">
        <p-calendar [(ngModel)]="currentDate" [inline]="true" appendTo="body" [minDate]="minDate"
                    (onSelect)="selectDate()" styleClass="book-interview-calendar"
                    [disabledDates]="unAvailableDates" [disabledDays]="!dayoff.availableOnWeekend && [0,6]">
        </p-calendar>
    </div>
    <div class="col-3"></div>
    <div class="col-9 grid grid-nogutter mt-2">
        <div class="col-4 flex justify-content-start align-items-center">
            <div class="unAvailable-box"></div>
            <p>UnAvailable</p>
        </div>
        <div class="col-4 flex justify-content-start align-items-center">
            <div class="selected-box"></div>
            <p>Selected Date</p>
        </div>
        <div class="col-4 flex justify-content-start align-items-center">
            <div class="today-box"></div>
            <p>Today's Date</p>
        </div>
    </div>
    <div class="col-3"></div>
    <div class="col-9 grid grid-nogutter justify-content-start align-items-center available-from-col">
        <div class="col-1 flex justify-content-center align-items-center icon-col">
            <i class="pi pi-user"></i>
        </div>
        <div class="11">
            <p class="text-rb text-primary">Available from {{currentDate | date: 'MMM d, y'}}</p>
        </div>
    </div>
</div>