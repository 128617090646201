<div  class="grid grid-nogutter" *ngIf="educationSchema; else noEducationTemplate">
    <div class="col-12">
          <p-table [value]="educationSchema.educations"
                   styleClass="p-datatable-striped"
                   [paginator]="true"
                   [rows]="10"
                   [showCurrentPageReport]="true"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                   editMode="row"
                   [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr>
                    <th>NAME OF SCHOOL</th>
                    <th>LOCATION</th>
                    <th>AREA OF STUDY</th>
                    <th>DESCRIPTION</th>
                    <th>DATES ATTENDED</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-education let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="education">
                    <td>
                        <p class="text-ssb">{{education.schoolName}}</p>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <p class="text-ssb">{{education.location}}</p>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td >
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <p class="text-ssb">{{education.studyArea}}</p>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                  <td >
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                      </ng-template>
                      <ng-template pTemplate="output">
                        <p class="text-ssb">{{education.description}}</p>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <p class="text-ssb">{{education.from | date: 'shortDate'}} - {{education.to | date: 'shortDate'}}</p>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<ng-template #noEducationTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                           (click)="onClick.emit()"
                           class="primary" label="Education"
                           icon="pi pi-plus" [disabled]="!enabledButton"></p-button>
            </div>
        </div>
    </div>
</ng-template>
