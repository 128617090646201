<div  class="grid grid-nogutter" *ngIf="certificationSchema; else noCertificatesTemplate">
    <div class="col-12">
          <p-table [value]="certificationSchema.certifications"
                   styleClass="p-datatable-striped"
                   [paginator]="true"
                   [rows]="10"
                   [showCurrentPageReport]="true"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                   editMode="row"
                   [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr>
                    <th>NAME</th>
                    <th>ISSUING ORGANIZATION</th>
                    <th>ISSUE DATE</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-certification let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="certification">
                    <td>
                        <p class="text-ssb">{{certification.name}}</p>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <p class="text-ssb">{{certification.issuingOrganization}}</p>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <p class="text-ssb">{{certification.issueDate | date: 'shortDate'}}</p>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<ng-template #noCertificatesTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                           (click)="onClick.emit()"
                           class="primary" label="Certificate"
                           icon="pi pi-plus" [disabled]="!enabledButton"></p-button>
            </div>
        </div>
    </div>
</ng-template>
