import { Injectable } from "@angular/core";
import { LeavingReasonEnum } from "../models/dto/leaving-reason.dto";

export class ReasonForLeaving {
    constructor(public reasonForLeaving: LeavingReasonEnum, 
                public title: string) {}
  }

@Injectable({
    providedIn: 'root',
})
export class ReasonForLeavingService {
    private experienceLevels: ReasonForLeaving[] = [
        new ReasonForLeaving(LeavingReasonEnum.SALARY, 'Salary'),
        new ReasonForLeaving(LeavingReasonEnum.WORK_ENVIRONMENT, 'Work Environment'),
        new ReasonForLeaving(LeavingReasonEnum.CAREER_GROWTH, 'Career Growth'),
        new ReasonForLeaving(LeavingReasonEnum.BETTER_OPPORTUNITY, 'Better Opportunity'),
        new ReasonForLeaving(LeavingReasonEnum.BETTER_COMPENSATION, 'Better Compensation'),
        new ReasonForLeaving(LeavingReasonEnum.BAD_MANAGEMENT, 'Bad Management'),
        new ReasonForLeaving(LeavingReasonEnum.OTHER, 'Others'),
    ];

    getReasonsForLeaving(): Array<ReasonForLeaving> {
        return this.experienceLevels;
    }
}
