import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiEventStatus, ApiEventType } from '../../models/api-event';
import { IdVerificationDisplayItemDto, IdVerificationDto, IdVerificationItemType, IdVerificationStatus, IdVerificationType } from '../../models/dto/id-verification.dto';
import { RejectionReasonDto, RejectionReasonInputDto } from '../../models/dto/rejection-reason.dto';
import { ApiEventService } from '../../services/api-event.service';
import { IdCard, IdVerificationService } from '../../services/id-verification.service';
import { RejectionReasonsService } from '../../services/rejection-reasons.service';

@UntilDestroy()
@Component({
  selector: 'app-id-verification',
  templateUrl: './id-verification.component.html',
  styleUrls: ['./id-verification.component.scss']
})
export class IdVerificationComponent implements OnInit {
  @Input() contactId: number;

  readonly IdVerificationItemType = IdVerificationItemType;
  readonly IdVerificationStatus = IdVerificationStatus;

  idVerificationDto: IdVerificationDto = null;
  loading: boolean = false;
  total: number = 0;
  showVerificationDialog = false;
  showRejectIdVerificationDialog = false;

  idCards: Array<IdCard> = [];

  idVerificationForm = this.formBuilder.group({
    type: this.formBuilder.control(null, [Validators.required]),
    idFront: this.formBuilder.control(null, [Validators.required]),
    idBack: this.formBuilder.control(null, [Validators.required]),
    idSelfie: this.formBuilder.control(null, [Validators.required]),
  })


  rejectionReasonForm = this.formBuilder.group({
    rejectionReasonId: this.formBuilder.control(null, [Validators.required]),
    memo: this.formBuilder.control(''),
  })

  rejectionReasons: Array<RejectionReasonDto> = [];
  currentIdVerificationItem: IdVerificationDisplayItemDto;

  constructor(private idVerificationService: IdVerificationService,
              private apiEventService: ApiEventService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private rejectionReasonService: RejectionReasonsService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.idVerificationService.getIdVerification(this.contactId);
    this.idCards = this.idVerificationService.getIdCards();
    this.rejectionReasonService.getRejectionReasons();

    this.apiEvents();
    this.getSubscribers();
  }

  apiEvents() {
    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe((event)=>{

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.UPSERT_ID_VERIFICATION_TYPE) {
        this.messageService.add({severity:'success', summary:'ID Verification Card Type Update', detail:'ID Verification Card Type is successfully updated'});
        
        this.idVerificationDto = this.idVerificationService.idVerificationDto$.value;
      }

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.UPSERT_ID_VERIFICATIONS_BY_IDCARD_TYPE) {
        this.messageService.add({severity:'success', summary:'Upload ID card', detail:'Id Card is successfully uploaded'});
       
        this.idVerificationService.getIdVerification(this.contactId);
      }

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_ID_VERIFICATIONS) {
        this.loading = false;
        this.idVerificationDto = this.idVerificationService.idVerificationDto$.value;
        this.total = this.idVerificationDto.idVerificationItems.length;


        this.idVerificationForm.patchValue({
          type: this.idVerificationDto.type || null
        })

        for(const idVerificationItem of this.idVerificationDto.idVerificationItems) {
          this.idVerificationService.getIdVerificationImage(this.contactId, idVerificationItem.type);
        }
      } else if(event && event.status === ApiEventStatus.IN_PROGRESS && event.type === ApiEventType.GET_ID_VERIFICATIONS) {
        this.loading = true;
      }

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_ID_VERIFICATIONS_BY_IDCARD_TYPE) {
        const response = this.idVerificationService.idVerificationImageItem$.value;
        if(response) {
          const idVerificationItem = this.idVerificationDto.idVerificationItems.find((v)=>{
            return v.type === response.idVerificationItemType;
          });
          idVerificationItem.imageString = response.imageString;
        }
      }

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.UPDATE_VERIFICATIONS_BY_IDCARD_TYPE_APPROVE) {
        this.messageService.add({severity:'success', summary:'ID Card Approved', detail:'Id Card is successfully approved'});
        this.idVerificationService.getIdVerification(this.contactId);
      }

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.UPDATE_ID_VERIFICATIONS_BY_IDCARD_TYPE_REJECT) {
        this.messageService.add({severity:'success', summary:'ID Card Rejected', detail:'Id Card is successfully rejected'});
        this.showRejectIdVerificationDialog = false;
        this.currentIdVerificationItem = null;
        this.idVerificationService.getIdVerification(this.contactId);
      }

      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.UPDATE_ID_VERIFICATIONS_BY_IDCARD_TYPE_REMOVE) {
        this.messageService.add({severity:'success', summary:'ID Card Removed', detail:'Id Card is successfully removed'});
        this.idVerificationService.getIdVerification(this.contactId);
      }
    })
  }

  getSubscribers() {
    this.idVerificationForm.controls['type'].valueChanges
    .pipe(untilDestroyed(this))
    .subscribe((v)=>{
      if(v) {
      }
    });

    this.rejectionReasonService.rejectionReasonSchema$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      if(response) {
        this.rejectionReasons = response.rejectionReasons;
      }
    })
  }


  onSelectedIdVerificationType(ev: any) {
    this.idVerificationService.upsertIdVerificationType(this.contactId, ev.value);
  }


  onFileSelected(file: File, idVerificationItemType: IdVerificationItemType) {
    const formData = new FormData();
    formData.append('idVerificationFile', file);
    this.idVerificationService.upsertIdVerificationImage(this.contactId, 
                                                         idVerificationItemType,
                                                         this.idVerificationDto.type,
                                                         formData);
  }

  approve(item: IdVerificationDisplayItemDto) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to approve ID card?',
      header: 'Approve  ID card',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Approve',      
      accept: () => {
          // this.accountApproveService.accountEnablement(this.accountId);
          this.idVerificationService.approveIdVerification(this.contactId, item.type)
      },
    });
  }
  

  reject(item: IdVerificationDisplayItemDto) {
    // this.confirmationService.confirm({
    //   message: 'Are you sure that you want to reject ID card?',
    //   header: 'Reject  ID card',
    //   icon: 'pi pi-exclamation-triangle',
    //   dismissableMask: true,
    //   rejectVisible: false,
    //   rejectButtonStyleClass: 'velvet',
    //   acceptLabel: 'Reject',      
    //   accept: () => {
    //       // this.accountApproveService.accountEnablement(this.accountId);
    //       this.idVerificationService.rejectIdVerification(this.contactId, item.type)
    //   },
    // });
    this.currentIdVerificationItem = item;
    this.showRejectIdVerificationDialog = true;

  }

  onRejectingIdVerificationImage() {
    const rejectionReasonInputDto: RejectionReasonInputDto = {
      rejectionReasonId: this.rejectionReasonForm.value.rejectionReasonId,
      memo: this.rejectionReasonForm.value.memo 
    }
    this.idVerificationService.rejectIdVerification(this.contactId, this.currentIdVerificationItem.type, rejectionReasonInputDto)
  }

  remove(item: IdVerificationDisplayItemDto) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove ID card?',
      header: 'Remove  ID card',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Remove',      
      accept: () => {
          // this.accountApproveService.accountEnablement(this.accountId);
          this.idVerificationService.removeIdVerification(this.contactId, item.type)
      },
    });
  }

  getIdVerificationItem(type: IdVerificationItemType): IdVerificationDisplayItemDto {
    return this.idVerificationDto.idVerificationItems.find((v)=>{
      return v.type === type;
    })
  }


  addNewId() {
    this.showVerificationDialog = true;

    console.log(this.idVerificationDto)
  }

  onSubmitAddEditVerification() {

  }
}
