import { Pipe, PipeTransform } from "@angular/core";
import { BookingType } from "../models/dto/booking.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";


@Pipe({
    name: 'employmentTypeFlag'
})
export class EmploymentTypePipe implements PipeTransform {

    private readonly employmentTypeMap = ReadonlyMapBuilder.create<BookingType, string>()
    .set(BookingType.FULL_TIME ,'Full Time')
    .set(BookingType.PART_TIME, 'Part Time')
    .set(BookingType.ONE_TIME, 'One Time')
    .build();


    transform(value: string): string {
        return this.employmentTypeMap.get(BookingType[value]);
    }
}