import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substractDate'
})
export class SubstractDatePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value && args) {
            const date1 = new Date(value);
            const date2 = new Date(args);
            const diffTime = Math.abs(date2.getTime() - date1.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays < 7) {
              return `${diffDays} days`;
            } else if (diffDays < 30) {
              const diffWeeks = Math.floor(diffDays / 7);
              return `${diffWeeks} weeks`;
            } else if (diffDays < 365) {
              const diffMonths = Math.floor(diffDays / 30);
              return `${diffMonths} months`;
            } else {
              const diffYears = Math.floor(diffDays / 365);
              return `${diffYears} years`;
            }
          }
          return null;
    }

}