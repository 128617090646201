import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobSkillExperienceDto } from '../../models/dto/opentimer-job-profile.dto';
import { SkillService } from '../../services/skill.service';

@Component({
  selector: 'app-jobskill-experience-item',
  templateUrl: './jobskill-experience-item.component.html',
  styleUrls: ['./jobskill-experience-item.component.scss']
})
export class JobskillExperienceItemComponent implements OnInit {
  @Input() primarySkillExperience: JobSkillExperienceDto;
  @Input() secondarySkillExperience: Array<JobSkillExperienceDto>;


  @Output() onEditJobSkillExperience = new EventEmitter<void>();
  
  readonly chipColor = SkillService.chipColor;
  constructor(private skillService: SkillService) { }

  ngOnInit(): void {
  }

}
