import { Pipe, PipeTransform } from "@angular/core";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";
import { BookingCancelRequestReason } from "../models/dto/booking.dto";

@Pipe({
    name: 'bookingRequestReasonPipe'
  })
  export class BookingCancellationRequestReasonPipe implements PipeTransform {

    private readonly bookingRequestReasonMap = ReadonlyMapBuilder.create<BookingCancelRequestReason, string>()
    .set(BookingCancelRequestReason.DAMAGING_COMPANY_PROPERTY, 'Damaging company property')
    .set(BookingCancelRequestReason.DRUG_OR_ALCOHOL_POSSESSION_AT_WORK, 'Drug or alcohol possession at work')
    .set(BookingCancelRequestReason.FALSIFY_COMPANY_RECORDS, 'Falsify company records')
    .set(BookingCancelRequestReason.INSUBORDINATION, 'Insubordination')
    .set(BookingCancelRequestReason.MISCONDUCT, 'Misconduct')
    .set(BookingCancelRequestReason.POOR_PERFORMANCE, 'Poor performance')
    .set(BookingCancelRequestReason.STEALING, 'Stealing')
    .set(BookingCancelRequestReason.USING_COMPANY_PROPERTY_FOR_PERSONAL_BUSINESS, 'Using company property for personal business')
    .set(BookingCancelRequestReason.TAKING_TOO_MUCH_TIME_OFF, 'Taking too much time off')
    .set(BookingCancelRequestReason.VIOLATING_COMPANY_POLICY, 'Violating company policy')
    .set(BookingCancelRequestReason.RECEIVED_ANOTHER_JOB_OFFER, 'Received another job offer')
    .set(BookingCancelRequestReason.COMMUTE_IS_MANAGEABLE, 'Commute is manageable')
    .set(BookingCancelRequestReason.PAY_IS_BELOW_MARKET_RATE, 'Pay is below market rate')
    .set(BookingCancelRequestReason.COMPANY_CULTURE_WAS_NOT_THE_RIGHT, 'Company culture was not the right')
    .set(BookingCancelRequestReason.LACK_OF_FLEXIBILITY, 'Lack of flexibility')
    .set(BookingCancelRequestReason.TEAM_WAS_NOT_THE_RIGHT_FIT, 'Team was not the right fit')
    .set(BookingCancelRequestReason.UNRELIABLE_BOSS, 'Unreliable boss')
    .set(BookingCancelRequestReason.NO_GROWTH_POTENTIAL, 'No growth potential')
    .set(BookingCancelRequestReason.UNABLE_TO_MEET_COMPANY_EXPECTATIONS, 'Unable to meet company expectations')
    .set(BookingCancelRequestReason.FRAUD, 'Fraud')
    .set(BookingCancelRequestReason.OTHER, 'Other')
    .build();

    transform(value: BookingCancelRequestReason): string {
      return this.bookingRequestReasonMap.get(value);
    }
  }