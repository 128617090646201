<app-edit-dialog [visible]="showServiceFeeDialog" [title]="'Add/Edit Service Fee'" (onSubmit)="onSubmitPrice()"
                 (onHide)="onHide.emit()" [disabled]="!priceForm.valid" [dialogHeight]="'30'">
  <ng-container [formGroup]="priceForm">
    <div class="mt-3 grid">
      <div class="col-12  p-1 pr-5">
        <div class="grid grid-nogutter">
          <label for="dateOfBirth" class="col-2 pr-2 text-rb">Price Item</label>
          <div class="col-12 mt-1">
            <p-dropdown
              [options]="adminPriceItems"
              formControlName="item"
              placeholder="Please Select"
              appendTo="body"
              [(ngModel)]="priceForm.value.item"
              optionValue="item" [style]="{'width':'100%'}">
              <ng-template pTemplate="selectedItem" let-item>
                <div class="country-item country-item-value">
                  <p class="text-ssb">{{ item.name }}</p>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="country-item">
                  <p class="text-ssb">{{ item.name }}</p>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="col-6  p-1 pr-5">
        <div class="grid grid-nogutter">
          <label for="dateOfBirth" class="col-2 pr-2 text-rb">Currency</label>
          <div class="col-12 mt-1">
            <p-dropdown
              [options]="currencyItems"
              formControlName="currency"
              placeholder="Please Select"
              appendTo="body"
              [(ngModel)]="priceForm.value.currency"
              optionValue="currency" [style]="{'width':'100%'}">
              <ng-template pTemplate="selectedItem" let-item>
                <div class="country-item country-item-value">
                  <p class="text-ssb">{{ item.name }} ({{ item.symbol }})</p>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="country-item">
                  <p class="text-ssb">{{ item.name }} ({{ item.symbol }})</p>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="col-6  p-1 pr-5">
        <div class="grid grid-nogutter">
          <label for="dateOfBirth" class="col-2 pr-2 text-rb">Price</label>
          <div class="col-12 mt-1">
            <input id="phone" type="tel" formControlName="price"
                   appOnlyInteger
                   pInputText placeholder="Number" style="width: 100%"/>
          </div>
        </div>
      </div>

      <div class="col-6  p-1 pr-5">
        <div class="grid grid-nogutter block">
          <label class="col-12 pr-2 text-rb">Execution Date</label>
          <div class="col-12 mt-1">
            <p-calendar formControlName="executionDateTime"
                        class="full-width-calendar-input"
                        [minDate]="minDate"
                        appendTo="body"
                        [showTime]="true"
                        [showButtonBar]="true"
                        [dateFormat]="CONST.DATE_FORMAT"
                        [showIcon]="true"
                        placeholder="Select Date">
            </p-calendar>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-edit-dialog>
