<main class="overflow-x-hidden w-100 v-100">
    <div class="sidebar-container">
        <div class="sidebar-content">
           <img class="login-bg" src='assets/media/svg/opentime-login-bg.svg'/>
           <img class="mt-1" src='assets/media/svg/opentime-logo-text.svg'/>
           <p class="welcome-text">Welcome to OpenTime.</p>
           <p class="description-text">Here you can hire, get hired or simply join a job interview.</p>
        </div>
    </div>
    <div class="main-container">
        <ng-container *ngIf="isSigninActive() else forgotPassword">
            <app-sign-in></app-sign-in>
        </ng-container>

        <ng-template #forgotPassword>
            <app-forgot-password></app-forgot-password>
        </ng-template>
    </div>
</main>