<form [formGroup]="form">
    <div class="grid  w-100" >
        <div class="col-8" [class.col-12]="colFull">
            <div class="grid grid-nogutter w-100"> 
                <label for="dateOfBirth" class="col-3 pr-2 text-right">Search Places</label>
                <div class="col-9" >

                    <ng-container *ngIf="addressLocation; else noSelectedPlace">
                        <div class="grid grid-nogutter info-panel-shadow" [@slideLeft]>
                            <div class="col-10 text-left flex align-items-center justify-content-start">
                                <p class="text-rb">{{addressLocation.formattedAddress}}</p>
                            </div>
                            <div class="col-2 flex justify-content-end align-items-start">
                                <button pButton pRipple type="button" icon="pi pi-pencil" 
                                        (click)="addressLocation = null; form.reset();"
                                        class="p-button-rounded p-button-text">
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noSelectedPlace>
                        <p-autoComplete #pAutoComplete [@slideLeft]
                            [suggestions]="results" (completeMethod)="search($event)"
                            [dropdownIcon]="'pi pi-search'" (onDropdownClick)="onDropdownClick($event)"
                            [delay]="400" field="description" placeholder="Type" (onSelect)="onSelected($event)"
                            appendTo="body"
                            [showClear]="true" [style]="{'width':'100%'}" 
                            inputStyleClass="autocomplete-input">
                        </p-autoComplete>
                 
                    </ng-template>
           
                </div>
            </div>
        </div>
        <div class="col-8" [class.col-12]="colFull">
            <app-labelled-input [ngControl]="form" [type]="'text'"
                    [displayBlock]="displayBlock"
                    [placeholder]="'Location'" [label]="'Location Name'" [name]="'name'">
            </app-labelled-input>
        </div>
        <div class="col-8" [class.col-12]="colFull">
            <app-labelled-input [ngControl]="form" [type]="'text'"
                    [displayBlock]="displayBlock"
                    [placeholder]="'Room Number'" [label]="'Room Number'" [name]="'roomNumber'">
            </app-labelled-input>
        </div>
        <div class="col-8" [class.col-12]="colFull">
            <app-labelled-input [ngControl]="form" [type]="'text'"
                    [displayBlock]="displayBlock"
                    [placeholder]="'Building Name'" [label]="'Building Name'" [name]="'buildingName'">
            </app-labelled-input>
        </div>
    </div>
</form>
