import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { AccountType } from "../models/account";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AccountStatus } from "../models/dto/account.dto";
import { CustomerRatingResponseSchema } from "../models/dto/customer-rating.dto";
import {
  CompanyDescriptionDto,
  CompanyDetailsDto,
  CustomerBusinessProfileDto,
  CustomerCountryDto,
  CustomerDto,
  CustomerProfileDto,
  CustomerSchema,
  CustomersSchema
} from "../models/dto/customer.dto";
import { InvoiceDtoSchema, InvoiceStatus } from "../models/dto/invoices.dto";
import { LocationAddressDto } from "../models/dto/location-address.dto";
import { PhonesAndEmailsDto } from "../models/dto/phones-emails.dto";
import { WalletDtoSchema } from "../models/dto/wallet.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";

@Injectable({
    providedIn: 'root',
})
export class CustomerService extends AbstractHttpHandler {

    customerBusinessProfile$ = new BehaviorSubject<CustomerBusinessProfileDto>(undefined);
    customerDto$ = new BehaviorSubject<CustomerDto>(undefined);
    customersSchema$ = new BehaviorSubject<CustomersSchema>(undefined);
    customerSchema$ = new BehaviorSubject<CustomerSchema>(undefined);
    customerProfile$ = new BehaviorSubject<CustomerProfileDto>(undefined);
    customerRatingResponseSchema$ = new BehaviorSubject<CustomerRatingResponseSchema>(null);
    walletSchema$ = new BehaviorSubject<WalletDtoSchema>(null);

    selectedCustomer$ = new BehaviorSubject<CustomerDto>(undefined);
    invoiceDtoSchema$ = new BehaviorSubject<InvoiceDtoSchema>(null);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getCustomers(page:number = 1,
                 limit:number = 10,
                 search: string = null,
                 accounType: AccountType = null,
                 accountStatuses: AccountStatus[] = [],
                 enabled: boolean = null,
                 email: string = null) {
        const url = `v2/customers`;
        const eventType = ApiEventType.GET_CUSTOMERS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        if(email !== null && email.length > 0) {
            params = params.append('email', `${email}`);
        }

        accountStatuses.forEach((accountStatus)=>{
            params = params.append('statuses', accountStatus);
        });

        if(accounType) {
            let type = accounType === AccountType.BUSINESS_CUSTOMER ?  'BUSINESS' : 'INDIVIDUAL'
            params = params.append('customerType', type);
        }

        if(enabled !== null) {
            params = params.append('enabled', enabled);
        }

        if(search !== null && search.length > 0) {
            params = params.append('nameLike', `%${search}%`);
        }
        params = params.append('include', ['TOTAL'].toString());

        this.http.get<CustomersSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CustomersSchema)=>{
            this.customersSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getCustomerById(accountId: number) {
        const url = `v2/customers/${accountId}`;
        const eventType = ApiEventType.GET_CUSTOMER_BY_ID;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.get<CustomerSchema>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CustomerSchema)=>{
            this.customerSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

  getCustomerProfileById(customerId: number) {
    const url = `v2/admins/customers/${customerId}`;
    const eventType = ApiEventType.GET_CUSTOMER_PROFILE_BY_ID;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
    this.http.get<CustomerProfileDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: CustomerProfileDto)=>{
        this.customerProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

    getCustomerRatings(customerId: number){
        const url = `v2/customers/${customerId}/ratings`;
        const eventType = ApiEventType.GET_CUSTOMER_RATINGS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.get<CustomerRatingResponseSchema>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CustomerRatingResponseSchema)=>{
            this.customerRatingResponseSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getCustomerWallets(customerId: number, startDate: string = null, endDate: string = null) {
        const url = `v2/customers/${customerId}/wallets`;
        const eventType = ApiEventType.GET_CUSTOMER_WALLET;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();

        if(startDate !== null && startDate.length > 0) {
            params = params.append('startDate', startDate);
        }

        if(endDate !== null && endDate.length > 0) {
            params = params.append('endDate', endDate);
        }

        this.http.get<WalletDtoSchema>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: WalletDtoSchema)=>{
            this.walletSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getCustomerInvoices(customerId: number, page: number = null,
                        limit: number = null, statuses: Array<InvoiceStatus> = []) {
        const url = `v2/customers/${customerId}/invoices`;

        const eventType = ApiEventType.GET_CUSTOMER_INVOICES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();


        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        statuses.forEach((status)=>{
            if(status) {
                params = params.append('statuses', status);
              }
        });

        this.http.get<InvoiceDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: InvoiceDtoSchema)=>{
            this.invoiceDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    upsertBusinessCustomerDetails(accountId: number, companyDetails: CompanyDetailsDto) {
        const url = `v2/businessCustomers/${accountId}/companyDetails`;
        const eventType = ApiEventType.UPSERT_CUSTOMER_DETAILS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<CustomerBusinessProfileDto>(url, companyDetails)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.customerBusinessProfile$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })
    }

    upsertCustomerCountry(accountId: number, customerCountryDto: CustomerCountryDto) {
        const url = `v2/businessCustomers/${accountId}/countries/default`;
        const eventType = ApiEventType.UPSERT_CUSTOMER_COUNTRY;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<CustomerBusinessProfileDto>(url, customerCountryDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.customerBusinessProfile$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })
    }

    public upsertCustomerPhonesAndEmails(accountId: number, phonesAndEmails: PhonesAndEmailsDto) {
        const url = `v2/customers/${accountId}/phonesAndEmails`;
        const eventType = ApiEventType.UPSERT_CUSTOMER_PHONES_EMAILS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<CustomerDto>(url, phonesAndEmails)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CustomerDto)=> {
            this.customerDto$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    upsertCustomerLocations(accountId: number, locationAddress: LocationAddressDto) {
        const url = `v2/customers/${accountId}/locations`;
        const eventType = ApiEventType.UPSERT_CUSTOMER_LOCATIONS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<CustomerBusinessProfileDto>(url, locationAddress)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CustomerBusinessProfileDto)=> {
            this.customerBusinessProfile$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    upsertBusinessCustomerAddresses(accountId: number, locationAddress: LocationAddressDto) {
        const url = `v2/businessCustomers/${accountId}/addresses`;
        const eventType = ApiEventType.UPSERT_CUSTOMER_ADDRESS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<CustomerBusinessProfileDto>(url, locationAddress)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CustomerBusinessProfileDto)=> {
            this.customerBusinessProfile$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }
}
