import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OpentimeFormatDate } from '../../date-format';
import { OpentimerAvailabilityResponseDto } from '../../models/dto/opentimer-job-profile.dto';
import { Time } from '../../time/time';

@Component({
  selector: 'app-opentimer-my-availability',
  templateUrl: './opentimer-my-availability.component.html',
  styleUrls: ['./opentimer-my-availability.component.scss']
})
export class OpentimerMyAvailabilityComponent implements OnInit {
  @Input() availability: OpentimerAvailabilityResponseDto;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.availability);
  }

  get workingUTCstartTime(): string {
    return Time.fromString(OpentimeFormatDate.fromSecondsToHhMMString(this.availability.workingTime.startTime)).convertToHhMmAString();
  }

  get workingUTCendTime(): string {
    return Time.fromString(OpentimeFormatDate.fromSecondsToHhMMString(this.availability.workingTime.endTime)).convertToHhMmAString();
  }

}
