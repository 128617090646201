import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AutoCompletePredictionDto } from "../models/dto/google-places.dto";
import { LocationAddressDto } from "../models/dto/location-address.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { AuthService } from "./auth-service";

@Injectable({
    providedIn: 'root',
})
export class GooglePlaceService extends AbstractHttpHandler{
    
    googlePredictionPlaces$ = new BehaviorSubject<Array<AutoCompletePredictionDto>>([]);
    googlePlace$ = new BehaviorSubject<LocationAddressDto>(undefined);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router,
        private authService: AuthService
      ) {
        super(http, apiEventsService);
    }

    googlePlaces(searchString: string){
        const url = `v2/placePredictions`;
        const eventType = ApiEventType.GET_GOOGLE_PREDICTIONS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: false });
        this.http.get<Array<AutoCompletePredictionDto>>(url, {
            params: {
                search: searchString,
              }
        })
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.googlePredictionPlaces$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    googlePlaceByPlaceId(placeId:string) {
        const url = `v2/places/${placeId}`;
        const eventType = ApiEventType.GET_GOOGLE_PLACE;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.get<Array<LocationAddressDto>>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.googlePlace$.next(response)
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        });
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }

            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
    
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });

          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }
}