import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appOnlyInteger]',
})
export class OnlyIntegerDirective {
  constructor() { }

  @HostListener('keypress', ['$event']) onkeypress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    return /[0-9.]/.test(inputChar);
  }
}
