import { Pipe, PipeTransform } from "@angular/core";
import { IdVerificationType } from "../models/dto/id-verification.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";
import { WorkingTimeReason, WorkingTimeStatus } from "../models/dto/working-time.dto";

@Pipe({
    name: 'workingTimeStatusPipe'
})
export class WorkingTimeStatusPipe implements PipeTransform {
  
    private readonly workingTimeReasonMap = ReadonlyMapBuilder.create<WorkingTimeStatus, string>()
    .set(WorkingTimeStatus.ADJUSTED_BY_AGENCY,'Adjusted by agency')
    .set(WorkingTimeStatus.ADJUSTED_BY_CUSTOMER,'Adjusted by customer')
    .set(WorkingTimeStatus.AGENCY_AUTOMATIC_APPROVAL,'Agency automatic approval')
    .set(WorkingTimeStatus.APPROVED_BY_AGENCY,'Approved by agency')
    .set(WorkingTimeStatus.APPROVED_BY_CUSTOMER,'Approved by customer')
    .set(WorkingTimeStatus.CANCELED_BY_AGENCY,'Cancelled by agency')
    .set(WorkingTimeStatus.CANCELED_BY_CUSTOMER,'Cancelled by customer')
    .set(WorkingTimeStatus.CUSTOMER_AUTOMATIC_APPROVAL,'Customer automatic approval')
    .set(WorkingTimeStatus.INCLUDED_IN_INVOICE,'Included in invoice')
    .set(WorkingTimeStatus.IN_REVIEW,'In review')
    .build();

    transform(value: WorkingTimeStatus): string {
      return this.workingTimeReasonMap.get(value);
    }
  
} 