import {Component, Input, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CustomerRatingResponseDto, RecommendationLevelEnum} from '../../models/dto/customer-rating.dto';
import {CustomerService} from '../../services/customer.service';

enum RatingStarBreakdownEnum {
  START_1 = 'START_1',
  START_2 = 'START_2',
  START_3 = 'START_3',
  START_4 = 'START_4',
  START_5 = 'START_5',
}

interface RatingStar {
  ratingStarBreakdown: RatingStarBreakdownEnum;
  label: string;
  ratingCount: number;
  rating: number;
  percentage: number;
}

interface RecommendationLevelItem {
  recommendationLevel: RecommendationLevelEnum;
  label: string;
  count: number;
}


class RatingStarBreakdown {

  public ratingStars: Array<RatingStar> = [
    {
      ratingStarBreakdown: RatingStarBreakdownEnum.START_1,
      label: '1 star',
      ratingCount: 0,
      rating: 1,
      percentage: 0
    },
    {
      ratingStarBreakdown: RatingStarBreakdownEnum.START_2,
      label: '2 stars',
      ratingCount: 0,
      rating: 2,
      percentage: 0
    },
    {
      ratingStarBreakdown: RatingStarBreakdownEnum.START_3,
      label: '3 stars',
      ratingCount: 0,
      rating: 3,
      percentage: 0
    },
    {
      ratingStarBreakdown: RatingStarBreakdownEnum.START_4,
      label: '4 stars',
      ratingCount: 0,
      rating: 4,
      percentage: 0
    },
    {
      ratingStarBreakdown: RatingStarBreakdownEnum.START_5,
      label: '5 stars',
      ratingCount: 0,
      rating: 5,
      percentage: 0
    },
  ];

  public recommendationLevelItemsBreakDown: Array<RecommendationLevelItem> = [
    {
      recommendationLevel: RecommendationLevelEnum.MOST_LIKELY,
      label: 'Most likely',
      count: 0
    },
    {
      recommendationLevel: RecommendationLevelEnum.EXTREMELY_LIKELY,
      label: 'Extremely likely',
      count: 0
    },
    {
      recommendationLevel: RecommendationLevelEnum.NOT_AT_ALL_LIKELY,
      label: 'Not at all likely',
      count: 0
    },
  ]

  constructor(customerRatings: Array<CustomerRatingResponseDto> = []) {
    this.ratingStars.reverse();

    for(const customerRating of customerRatings) {
      const rating = Math.floor(customerRating.totalRating) > 5 ? 5 : Math.floor(customerRating.totalRating);

      const ratingStar = this.ratingStars.find((v)=>{
        return v.rating === rating;
      });

      ratingStar.ratingCount = ratingStar.ratingCount + 1;
      ratingStar.percentage = (ratingStar.ratingCount/5) * 100;


      const recommendationLevelItem = this.recommendationLevelItemsBreakDown.find((v)=>{
        return v.recommendationLevel === customerRating.recommendationLevel;
      });

      recommendationLevelItem.count++;

    }
  }
}

@UntilDestroy()
@Component({
  selector: 'app-customer-ratings',
  templateUrl: './customer-ratings.component.html',
  styleUrls: ['./customer-ratings.component.scss']
})
export class CustomerRatingsComponent implements OnInit {
  @Input() customerId: number;

  averageRating: number = 0;
  customerRatings: Array<CustomerRatingResponseDto> = [];

  // ratingStarBreakdowns: Array<RatingStarBreakdown> = [];

  ratingStarBreakdown: RatingStarBreakdown;

  constructor(private customerService: CustomerService) { }


  ngOnInit(): void {
    this.customerService.getCustomerRatings(this.customerId);
    this.getSubscribers();
  }

  getSubscribers() {
    this.customerService.customerRatingResponseSchema$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      if(response) {
        this.averageRating = response.averageRating;
        this.customerRatings = response.customerRatings;
        this.ratingStarBreakdown = new RatingStarBreakdown(this.customerRatings);
      }
    })
  }

}
