import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CONST } from 'src/app/shared/constant';
import { ServiceSubCategoryCreationDto, ServiceSubCategoryDto } from 'src/app/shared/models/dto/service-category.dto';
import { TranslationDto } from 'src/app/shared/models/dto/translation.dto';
import { ServiceCategoryService } from 'src/app/shared/services/service-caregory.service';

@Component({
  selector: 'app-upsert-service-sub-category',
  templateUrl: './upsert-service-sub-category.component.html',
  styleUrls: ['./upsert-service-sub-category.component.scss']
})
export class UpsertServiceSubCategoryComponent implements OnInit {
  @Input() showServiceSubCategoryDialog: boolean;
  @Input() serviceSubCategory: ServiceSubCategoryDto;

  @Output() onHide = new EventEmitter();

  serviceSubCategoryForm = this.formBuilder.group({
    serviceCategoryId: this.formBuilder.control('', [Validators.required]),
    subCategoryName: this.formBuilder.control('', [Validators.required]), 
    subCategoryNativeName: this.formBuilder.control('', [Validators.required]), 
    code:  this.formBuilder.control('', [Validators.required, Validators.minLength(3),  Validators.pattern(CONST.UPPER_CASE_REGEX)]), 
    active:  this.formBuilder.control(false, [Validators.required, Validators.minLength(3)]),
    avatarFile: this.formBuilder.control(''),
  });

  avatarUri: string = null;

  constructor(private formBuilder: FormBuilder,
              private cd: ChangeDetectorRef,
              private serviceCategoryService: ServiceCategoryService) { }

  ngOnInit(): void {

    this.serviceCategoryService.getServiceCategories(null, 20);

    if(this.serviceSubCategory) {
      const serviceSubCategoryName = this.getTranslation('en', this.serviceSubCategory.translations);
      const serviceSubCategoryNativeName = this.getTranslation('ja', this.serviceSubCategory.translations);
      this.serviceSubCategoryForm.patchValue({
        serviceCategoryId: this.serviceSubCategory.serviceCategory.id.toString(),
        subCategoryName: serviceSubCategoryName,
        subCategoryNativeName: serviceSubCategoryNativeName,
        code: this.serviceSubCategory.code,
        active: this.serviceSubCategory.active

      })
    }
  }

  getTranslation(language: string, translations: Array<TranslationDto>): string {
    return translations.find(v=> v.language === language) ? 
           translations.find(v=> v.language === language).content : '';
  }

  markAllAsDirty(control: AbstractControl) {
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(key => {
        this.markAllAsDirty(control.get(key));
      });
    } else if (control instanceof FormArray) {
      for (const groupOrArray of control.controls) {
        this.markAllAsDirty(groupOrArray);
      }
    } else {
      control.markAsDirty();
    }
  }


  onSubmitServiceSubCategory() {
    this.markAllAsDirty(this.serviceSubCategoryForm);
    this.cd.detectChanges();
    

    if(this.serviceSubCategoryForm.valid) {
      if(this.serviceSubCategory) {
        const serviceSubCategoryFormValue = this.serviceSubCategoryForm.value;
        const serviceSubCategoryCreation: ServiceSubCategoryCreationDto = {
          active: serviceSubCategoryFormValue.active,
          categoryId: Number(serviceSubCategoryFormValue.serviceCategoryId),
          code: serviceSubCategoryFormValue.code.trim(),
          translations: [
            {
              content: serviceSubCategoryFormValue.subCategoryName,
              language: 'en'
            },
            {
              content: serviceSubCategoryFormValue.subCategoryNativeName,
              language: 'ja'
            }
          ]
        }
        this.serviceCategoryService.updateServiceSubCategory(this.serviceSubCategory.id ,serviceSubCategoryCreation);
      }
    }


  }

}
