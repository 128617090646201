import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AccountStatus } from '../../models/dto/account.dto';
import { AccountApproveService } from '../../services/account-approve.service';

@Component({
  selector: 'app-job-profile-activate-deactivate',
  templateUrl: './job-profile-activate-deactivate.component.html',
  styleUrls: ['./job-profile-activate-deactivate.component.scss']
})
export class JobProfileActivateDeactivateComponent implements OnInit {
  @Input() opentimerJobProfileId: number;
  @Input() status: AccountStatus;

  constructor(private confirmationService: ConfirmationService,
              private accountApproveService: AccountApproveService) { }

  ngOnInit(): void {
  }

  activateDeactivate() {
    if(this.status === AccountStatus.ACTIVE) {
      this.deactivateAccount();
    } else {
      this.activateAccount();
    }
  }

  get buttonLabel(): string {
    return this.status === AccountStatus.ACTIVE ? 'Deactivate Job Profile' : 'Activate Job Profile'
  }

  deactivateAccount() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deactivate job profile?',
      header: 'Deactivate Job Profile',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Deactivate',
      accept: () => {
          // this.accountApproveService.accountEnablement(this.accountId);
          this.accountApproveService.deActivateJobProfile(this.opentimerJobProfileId)
      },
    });
  }

  activateAccount() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to activate job profile?',
      header: 'Activate Job Profile',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Activate',
      accept: () => {
        this.accountApproveService.activateJobProfile(this.opentimerJobProfileId)
          // this.accountApproveService.accountEnablement(this.accountId);
      },
    });
  }


}
