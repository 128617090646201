import { Injectable } from "@angular/core";
import { ServiceLength } from "../components/opentimer-service-length/opentimer-service-length.component";
import { BehaviorSubject } from "rxjs";
import { CONST } from "../constant";
import { SpecialInstructionDto } from "../models/dto/special-instructions.dto";

export class BookOpentimerProps {
    selectedDate: Date;
    serviceLength: ServiceLength;
    specialInstruction: SpecialInstructionDto;

}

@Injectable({
    providedIn: 'root',
})
export class BookOpentimerPropsService {
    private _bookOpentimerProps$ = new BehaviorSubject<BookOpentimerProps>(null);

    private _isOnBookingOpentimerStep: boolean = false;
  

    public get bookOpentimerProps$() {
        return this._bookOpentimerProps$.asObservable();
    }

    public setBookOpentimerProps(value: BookOpentimerProps) {
        localStorage.setItem(CONST.BOOK_OPENTIMER_PROPS_KEY, JSON.stringify(value));
        this._bookOpentimerProps$.next(value)
    }

  
    public get isOnBookingOpentimerStep(): boolean {
        this._isOnBookingOpentimerStep = JSON.parse(localStorage.getItem(CONST.IS_ON_BOOKING_OPENTIMER_STEPS));
        return this._isOnBookingOpentimerStep;
    }

    public setIsOnBookingOpentimerStep(value: boolean) {
        localStorage.setItem(CONST.IS_ON_BOOKING_OPENTIMER_STEPS, JSON.stringify(value));
        this._isOnBookingOpentimerStep = value;
    }



}