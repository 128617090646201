import { JobLocationType } from "../job-location";
import { AssociateDto } from "./associate.dto";
import { CustomerDto } from "./customer.dto";
import { LocationAddressDto } from "./location-address.dto";
import { OpentimerProfileCreationDto, OpentimerProfileDto } from "./opentimer-job-profile.dto";
import { SearchHourlyRateDto } from "./search.dto";
import { ServiceSubCategoryDto } from "./service-category.dto";
import { CustomerShiftDto, PresetShiftDto } from "./shift.dto";
import { SpecialInstructionDto } from "./special-instructions.dto";

export enum AdminCancelResonEnum {
    FRAUD = 'FRAUD',
    OTHER = 'OTHER'
}

export interface AdminCancelBookingRequestDto {
    reason: AdminCancelResonEnum;
    message: string;
}

export interface BookingOrderDisplayDtoSchema {
    bookingOrders: Array<BookingOrderDisplayDto>;
    totalsByStatus: Array<BookingOrderTotalByStatusDto>;
}




export enum BookingType {
    FULL_TIME = 'FULL_TIME',
    PART_TIME = 'PART_TIME',
    ONE_TIME  = 'ONE_TIME' 
}



export enum BookingStatus {
    UPCOMING = 'UPCOMING',
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSED = 'PAUSED',
    COMPLETED = 'COMPLETED',
    CANCELED  ='CANCELED',
    ARCHIVED = 'ARCHIVED'  
}

export enum BookingCancelRequestStatus  {
    IN_PROGRESS = 'IN_PROGRESS',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export enum BookingCancelRequestReason {
    DAMAGING_COMPANY_PROPERTY = "DAMAGING_COMPANY_PROPERTY",
    DRUG_OR_ALCOHOL_POSSESSION_AT_WORK = "DRUG_OR_ALCOHOL_POSSESSION_AT_WORK",
    FALSIFY_COMPANY_RECORDS = "FALSIFY_COMPANY_RECORDS",
    INSUBORDINATION = "INSUBORDINATION",
    MISCONDUCT = "MISCONDUCT",
    POOR_PERFORMANCE = "POOR_PERFORMANCE",
    STEALING = "STEALING",
    USING_COMPANY_PROPERTY_FOR_PERSONAL_BUSINESS = "USING_COMPANY_PROPERTY_FOR_PERSONAL_BUSINESS",
    TAKING_TOO_MUCH_TIME_OFF = "TAKING_TOO_MUCH_TIME_OFF",
    VIOLATING_COMPANY_POLICY = "VIOLATING_COMPANY_POLICY",
    RECEIVED_ANOTHER_JOB_OFFER = "RECEIVED_ANOTHER_JOB_OFFER",
    COMMUTE_IS_MANAGEABLE = "COMMUTE_IS_MANAGEABLE",
    PAY_IS_BELOW_MARKET_RATE = "PAY_IS_BELOW_MARKET_RATE",
    COMPANY_CULTURE_WAS_NOT_THE_RIGHT = "COMPANY_CULTURE_WAS_NOT_THE_RIGHT",
    LACK_OF_FLEXIBILITY = "LACK_OF_FLEXIBILITY",
    TEAM_WAS_NOT_THE_RIGHT_FIT = "TEAM_WAS_NOT_THE_RIGHT_FIT",
    UNRELIABLE_BOSS = "UNRELIABLE_BOSS",
    NO_GROWTH_POTENTIAL = "NO_GROWTH_POTENTIAL",
    UNABLE_TO_MEET_COMPANY_EXPECTATIONS = "UNABLE_TO_MEET_COMPANY_EXPECTATIONS",
    FRAUD = "FRAUD",
    OTHER = "OTHER"
}

export interface BookingOrderDisplayDto {
    id: number;
    profile: OpentimerProfileCreationDto;
    customer: CustomerDto;
    createdBy: AssociateDto;
    subCategory: ServiceSubCategoryDto;
    shift: CustomerShiftDto;
    presetShift: PresetShiftDto;
    location: LocationAddressDto;
    jobSiteType: JobLocationType;
    employmentType: BookingType;
    creationDate: string;
    startDate: string;
    endDate: string;
    status: BookingStatus;
    hourlyRate: SearchHourlyRateDto;
    specialInstruction: SpecialInstructionDto;
    hasCustomerRating: boolean;
    hasProfileRating: boolean;
    searchId: number;
}

export interface BookingOrderTotalByStatusDto {
    total: number;
    bookingOrderStatus: BookingStatus;
}

export interface BookingOrderCreationDto {
    profileId: number;
    startDate: string;
    endDate: string;
    specialInstruction: SpecialInstructionDto;
}


export interface BookingCancellationRequestApproveRejectItem {
    requestId: number;
    status: BookingCancelRequestStatus;
}

export interface BookingCancellationResponseDto {
    id: number;
    bookingOrder: BookingOrderDisplayDto;
    createdDateTime: string;
    status: BookingCancelRequestStatus;
    reason: BookingCancelRequestReason;
    message: string;
    opentimerProfile?: OpentimerProfileDto
    associate?: AssociateDto;
}