import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExperienceLevel, SkillService} from "../../../services/skill.service";
import {ServiceSubCategoryDto} from "../../../models/dto/service-category.dto";
import {ExperienceLevelEnum} from "../../../models/dto/experience-level.dto";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-level',
  templateUrl: './edit-profile-level.component.html',
  styleUrls: ['./edit-profile-level.component.scss']
})
export class EditProfileLevelComponent implements OnInit {
  @Input() serviceSubCategory: ServiceSubCategoryDto;
  @Input() experienceLevel: ExperienceLevelEnum;
  @Input() showProfileLevelDialog: boolean;
  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  readonly chipColor = SkillService.chipColor;

  experienceLevels: Array<ExperienceLevel> = [];
  experienceLevelForm: FormGroup;


  constructor(private formBuilder: FormBuilder,
              private skillService: SkillService) {
  }

  ngOnInit(): void {
    this.experienceLevels = this.skillService.getExperienceLevels();
    this.experienceLevelForm = this.formBuilder.group({
      experienceLevel: this.formBuilder.control('', [Validators.required]),
    });
    if (this.experienceLevel) {
      this.experienceLevelForm.patchValue({
        experienceLevel: this.experienceLevel
      })
    }
  }

  onUpdateLevel() {
    this.onSubmit.emit(this.experienceLevelForm.value.experienceLevel);
    this.showProfileLevelDialog = false;
  }

}
