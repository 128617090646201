import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CertificationDtoSchema } from '../../models/dto/certification.dto';

@Component({
  selector: 'app-opentimer-license-certificates',
  templateUrl: './opentimer-license-certificates.component.html',
  styleUrls: ['./opentimer-license-certificates.component.scss']
})
export class OpentimerLicenseCertificatesComponent implements OnInit {
  @Input() certificationSchema: CertificationDtoSchema;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
