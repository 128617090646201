import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AccountStatus } from '../../models/dto/account.dto';
import { AccountApproveService } from '../../services/account-approve.service';
import { MoneyDto } from '../../models/dto/money.dto';
import { CurrencyEnum, CurrencyItem } from '../../models/dto/currency.dto';

@Component({
  selector: 'app-approve-account-buttons',
  templateUrl: './approve-account-buttons.component.html',
  styleUrls: ['./approve-account-buttons.component.scss']
})
export class ApproveAccountButtonsComponent implements OnInit {
  @Input() status: AccountStatus;
  @Input() accountId: number;
  @Input() isCustomer: boolean = false;

  readonly AccountStatus = AccountStatus;

  currencyItems: Array<CurrencyItem> = [
    {
      name: 'Currency',
      currency: null
    },
    {
      name: 'Dollars ($)',
      currency: CurrencyEnum.USD  
    },
    {
      name: 'Yen (¥)',
      currency: CurrencyEnum.JPY  
    }
  ];
  currencyItem: CurrencyEnum;


  display: boolean = false;
  creditBalance: number;
  creditBalanceDto: MoneyDto = null;
  constructor(private accountApproveService: AccountApproveService,
              private confirmationService: ConfirmationService,
              ) { }

  ngOnInit(): void {
  }

  reevaluate() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to reevaluate account?',
      header: 'Reevaluate Account',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Reevaluate',      
      accept: () => {
          this.accountApproveService.reEvaluateAccount(this.accountId);
      },
    });
  }

  approve() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to approve account?',
      header: 'Approve Account',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Approve',      
      accept: () => {
        // if(!this.isCustomer){
        //   this.accountApproveService.accountApprove(this.accountId);
        // } else {
        //   this.display = true;
        //   this.currencyItem = null;
        //   this.creditBalance = 0;
        // }
        this.accountApproveService.accountApprove(this.accountId);
      },
    });
  }

  onApproveCustomer() {
    this.creditBalanceDto = {
      amount: this.creditBalance,
      currency: this.currencyItem,
      description: ''
    }
    this.accountApproveService.accountApprove(this.accountId, this.creditBalanceDto);
    this.display = false;
  }

  deny() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deny account?',
      header: 'Deny Account',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      acceptButtonStyleClass: 'velvet',
      acceptLabel: 'Deny',      
      accept: () => {
        this.accountApproveService.accountDenial(this.accountId);
      },
    });
  }

}
