import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-account-image-upload',
  templateUrl: './account-image-upload.component.html',
  styleUrls: ['./account-image-upload.component.scss']
})
export class AccountImageUploadComponent implements OnInit {
  @Input() ngControl: AbstractControl;
  @Input() placeholder: string;  
  @Input() label: string;
  @Input() name: string;
  @Input() type: string = 'text'
  @Input() isDisplayBlock: boolean = false;
  @Input() avatarUri: string = null;
  @Input() showRequired: boolean = false;
  @Input() disabled: boolean = false;
  

  @Output() onFileSelected = new EventEmitter<File>(undefined);

  title = 'dropzone';
  
  files: File[] = [];
  constructor() { }

  ngOnInit(): void {
      if(this.ngControl.get(this.name).value !== null && 
       this.ngControl.get(this.name).value.length > 0) {
      this.files.push(this.ngControl.get(this.name).value)
    }

    this.ngControl.get(this.name).valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(v=>{
      if(!v) {
        this.files = [];
      }
    })
  }

  onSelect(event) {
    this.files = [];
    this.files.push(...event.addedFiles);
    this.ngControl.get(this.name).patchValue(this.files[0]);
    this.onFileSelected.emit(this.files[0]);
  }

onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
}

}
