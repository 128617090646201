<app-bread-crumbs [items]="menuItems"></app-bread-crumbs>
<app-back-header [title]="'Booking Cancellation Requests'" [showBackButton]="true" [showSearchBox]="false"
                  (onDisplayAdvanceSearch)="onDisplayAdvanceSearch($event)">
</app-back-header>
<div class="p-2">
    <p-tabMenu #tab [model]="tabItems" class="mb-3"></p-tabMenu>
</div>


<app-job-profile-cancel-request *ngIf="isActive('job-profiles')" 
    [displayAdvanceSearchPanel]="displayAdvanceSearchPanel"></app-job-profile-cancel-request>
<app-customer-cancel-request *ngIf="isActive('customers')"
    [displayAdvanceSearchPanel]="displayAdvanceSearchPanel"></app-customer-cancel-request>