<ng-container *ngIf="isForm; else notFormSkillDropdown">

        <opentime-p-dropdown *ngIf="items.length > 0"
                [options]="items" 
                [formControl]="ngControl"
                [style]="{'width':'100%'}"  optionValue="id"
                (onFilter)="onKeypressEvent($event)"
                (onScrolledBottom)="onScrolledBottom()"
                filterPlaceholder="Search Skills"
                [placeholder]="placeholder"
                [haltInfiniteScroll]="haltInfiniteScroll"
                optionDisabled="inactive"
                appendTo="body"
                [(ngModel)]="skillId"
                [readonly]="readonly"
                [disabled]="disabled"
                [disableInfiniteScroll]="disableInfiniteScroll">
                <ng-template let-item pTemplate="item">
                    <div class="agency-item flex align-items-center" >
                        <div class="ml-2">{{item.skill}}</div>
                    </div>
                </ng-template>

                <ng-template let-selectedItem  pTemplate="selectedItem">
                    <div class="flex align-items-center" *ngIf="selectedItem">
                        <div class="ml-2" >{{selectedItem.skill}}</div>
                    </div>
                </ng-template>
        </opentime-p-dropdown>
</ng-container>

<ng-template #notFormSkillDropdown>
        <opentime-p-dropdown 
                [options]="items" 
                [style]="{'width':'100%'}"  optionValue="id"
                (onScrolledBottom)="onScrolledBottom()"
                filterPlaceholder="Search Skills"
                [placeholder]="placeholder"
                [haltInfiniteScroll]="haltInfiniteScroll"
                optionDisabled="inactive"
                appendTo="body"
                [(ngModel)]="skillId"
                [readonly]="readonly"
                [disabled]="disabled"
                [filter]="true" 
                filterBy="skill" 
                (onChange)="onSelectedJobSkill.emit(skillId)"
                [disableInfiniteScroll]="disableInfiniteScroll">
                <ng-template let-item pTemplate="item">
                    <div class="agency-item flex align-items-center" >
                        <div class="ml-2">{{item.skill}}</div>
                    </div>
                </ng-template>

                <ng-template let-selectedItem  pTemplate="selectedItem">
                    <div class="flex align-items-center" *ngIf="selectedItem">
                        <div class="ml-2" >{{selectedItem.skill}}</div>
                    </div>
                </ng-template>
        </opentime-p-dropdown>
</ng-template>