import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { ProficiencyDto, ProficiencyLanguageDtoSchema, ProficiencyLevelEnum } from "../models/dto/language.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import {ReadonlyMapBuilder} from "../readyonly-map-builder";
import {ExperienceLevelEnum} from "../models/dto/experience-level.dto";
import {ExperienceLevel} from "./skill.service";

export class ProficiencyLevel {
  constructor(public proficiencyLevel: ProficiencyLevelEnum,
              public title: string,
              public description: string) {}
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService extends AbstractHttpHandler {
    proficiencyLanguagesSchema$ = new BehaviorSubject<ProficiencyLanguageDtoSchema>(undefined);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getProficiencyLanguages() {
        const url = `v2/proficiencyLanguages`;
        const eventType = ApiEventType.GET_PROFICIENCY_LANGUAGES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();
        params = params.append('limit', 100);

        this.http.get<ProficiencyLanguageDtoSchema>(url,  {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(response=>{
            this.proficiencyLanguagesSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

  public static readonly chipColor = ReadonlyMapBuilder.create<ProficiencyLevelEnum, string>()
    .set(ProficiencyLevelEnum.BEGINNER, 'beginner-level')
    .set(ProficiencyLevelEnum.CONVERSATIONAL, 'intermediate-level')
    .set(ProficiencyLevelEnum.BUSINESS, 'advanced-level')
    .set(ProficiencyLevelEnum.ADVANCED_BUSINESS, 'expert-level')
    .set(ProficiencyLevelEnum.NATIVE, 'master-level')
    .build();


  private preferenceLevels: ProficiencyLevel[] = [
    new ProficiencyLevel(ProficiencyLevelEnum.BEGINNER, 'Beginner', 'I know how to structure basic sentences, which may include common questions and answers typically used by tourists but cannot read or write.'),
    new ProficiencyLevel(ProficiencyLevelEnum.CONVERSATIONAL, 'Conversational', 'I am able to have limited social conversations, understand basic commands and read and write elementary level texts.'),
    new ProficiencyLevel(ProficiencyLevelEnum.BUSINESS, 'Business', 'I am able to draft simple business documents and emails and communicate with my colleagues.'),
    new ProficiencyLevel(ProficiencyLevelEnum.ADVANCED_BUSINESS, 'Advanced', 'I am able to draft complex business documents, engage in meetings with upper management and communicate with clients without problems.'),
    new ProficiencyLevel(ProficiencyLevelEnum.NATIVE, 'Native', 'I am entirely fluent and am able to read and write at the highest level.')
  ];

    getProficiencies(): Array<ProficiencyLevel>{
        return this.preferenceLevels;
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         if(error.status === 500) {
            title = Messages.HEADER_ACCOUNT_EXIST;
            message = Messages.MESSAGE_ACCOUNT_EMAIL_EXIST;
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }
}
