import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { RejectionReasonSchema } from "../models/dto/rejection-reason.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";

@Injectable({
    providedIn: 'root',
})
export class RejectionReasonsService extends AbstractHttpHandler {

    rejectionReasonSchema$ =  new BehaviorSubject<RejectionReasonSchema>(null)

    constructor(public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router) {
        super(http, apiEventsService);
    }
    
    getRejectionReasons() {
        const url = `v2/rejectionReasons`;
        const eventType = ApiEventType.GET_REJECTION_REASONS;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS });

        this.http.get<RejectionReasonSchema>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.rejectionReasonSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;
         
          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         if(error.status === 500) {
            title = Messages.HEADER_ACCOUNT_EXIST;
            message = Messages.MESSAGE_ACCOUNT_EMAIL_EXIST;
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
    
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }
}