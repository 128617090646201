import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CONST } from '../../constant';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit {
  @Input() emails: Array<string>;

  emailForm= this.formBuilder.group({
    email: this.formBuilder.control('', [Validators.required, Validators.pattern(CONST.EMAIL_REGEX)]),
    type: this.formBuilder.control('PERSONAL', [Validators.required]),
    index: this.formBuilder.control(null, Validators.required)
  })
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  editEmail(email: string, index: number) {
    this.emailForm.patchValue({
      email: email,
      index: index
    });
  }

  removeAt(index: number) {
    this.emails.splice(index, 1);
  }

  update() {
    const emailFormValue = this.emailForm.value;
    this.emails[emailFormValue.index] = emailFormValue.email;

    this.emailForm.reset();
    this.emailForm.patchValue({
      type: 'PERSONAL'
    })
  }
}
