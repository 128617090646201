import { Component, OnInit, Input } from '@angular/core';
import { BookingType } from '../../models/dto/booking.dto';
import { HourlyRateDto, HourlyRateDtoSchema } from '../../models/dto/opentimer-job-profile.dto';

@Component({
  selector: 'app-opentimer-hourly-rate',
  templateUrl: './opentimer-hourly-rate.component.html',
  styleUrls: ['./opentimer-hourly-rate.component.scss']
})
export class OpentimerHourlyRateComponent implements OnInit {
  @Input() hourlyRateSchema: HourlyRateDtoSchema;

  readonly BookingType = BookingType;

  constructor() { }

  ngOnInit(): void {
  }

  getHourlyRate(employmentType:  BookingType) {
    let hourlyRate: HourlyRateDto = null;
    if(this.hourlyRateSchema) {
      hourlyRate = this.hourlyRateSchema.hourlyRates.find((v)=>{
        return v.employmentType === employmentType;
      })
    }
    return hourlyRate;
  }

}
