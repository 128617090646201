import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { CityDto, CityInputDto, CitySChema } from "../models/dto/city.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { AuthService } from "./auth-service";
import { BehaviorSubject, catchError } from "rxjs";


@Injectable({
    providedIn: 'root',
})
export class CityService extends AbstractHttpHandler{

    citySchema$ = new BehaviorSubject<CitySChema>(undefined);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router,
        private authService: AuthService) {
        super(http, apiEventsService);
    }

    getCities(page:number = 1, 
              limit:number = 10,
              search: string = null,
              active: boolean = null,
              displayable: boolean = null,
              countryId: number = null,
              lastId: number = null) {
        const url = `v2/cities`;
        const eventType = ApiEventType.GET_CITIES;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        
        let params = new HttpParams();

           
        if(limit) {
          params = params.append('limit', limit);
        }            

        if(page) {
            params = params.append('page', page);
        }

        if(search !== null && search.length > 0) {
            params = params.append('name.like', search);
        }

        if(active !== null) {
            params = params.append('active', active);
        }

        if(displayable !== null) {
            params = params.append('displayable', displayable);
        }

        if(countryId) {
            params = params.append('countryId', countryId);
        }

        if(lastId) {
            params = params.append('id.gt', lastId);
        }

        params = params.append('include', ['TOTAL'].toString());


        this.http.get<CitySChema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.citySchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })
    }


    updateCity(cityId: number, cityInputDto: CityInputDto) {
        const url = `v2/cities/${cityId}`;
        const eventType = ApiEventType.UPDATE_CITY;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put(url, cityInputDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })

    }

    updateCities(cities: Array<CityDto> = [], 
                 active: boolean = false, 
                 displayed: boolean = false) {
        const url = `v2/cities`;
        const eventType = ApiEventType.UPDATE_CITIES;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        const ids = cities.map((v:CityDto)=>{
            return v.id
        });
        this.http.put(url, {
            ids: ids,
            active,
            displayed
        })
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })
    }

    createCity(cityInputDto: CityInputDto) {
        const url = `v2/cities`;
        const eventType = ApiEventType.CREATE_CITY;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.post(url, cityInputDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }

            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
    
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });

          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }

}