<app-edit-dialog [visible]="showHourlyDialog" [title]="'Edit Hourly rates'" (onSubmit)="onSaveHourlyRate()"
                 (onHide)="onHide.emit()" [disabled]="!hourlyRatesForm.valid">
  <div class="grid grid-nogutter p-1 mt-2">
    <label class="col-6 text-rb p-1">Local</label>
    <label class="col-6 text-rb p-1">International</label>
    <ng-container *ngFor="let control of hourlyRatesForm.controls; let i=index">
      <ng-container [formGroup]="control | cast">
        <div class="col-6 pr-5">
          <div class="grid grid-nogutter p-1 block" controlErrorContainer>
            <label class="col-12 pr-2 text-rb">{{control.value.employmentType | employmentTypeFlag}} booking</label>
            <div class="col-12 mt-1">
              <div class="grid grid-nogutter opentime-input-group">
                <div class="col-1 hidden lg:inline">
                  <span class="p-inputgroup-addon">¥</span>
                </div>
                <div class="col-11">
                  <input  id="hourlyRate" formControlName="hourlyRate"
                          appOnlyInteger
                          pInputText  placeholder="Number" style="width: 100%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6  pr-5">
          <div class="grid grid-nogutter p-1 block">
            <label class="col-12 pr-2 text-rb">{{control.value.employmentType | employmentTypeFlag}} booking</label>
            <div class="col-12 mt-1">
              <div class="grid grid-nogutter opentime-input-group">
                <div class="col-1 hidden lg:inline">
                  <span class="p-inputgroup-addon">$</span>
                </div>
                <div class="col-11">
                  <input  id="hourlyRateInter"
                          formControlName="internationalHourlyRate"
                          appOnlyInteger  pInputText
                          placeholder="Number" style="width: 100%"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</app-edit-dialog>
