<opentime-p-dropdown 
            [options]="items" 
            (onChange)="selectedServiceSubCategory($event)"
            [style]="{'width':'100%'}"  
            (onFilter)="onKeypressEvent($event)"
            (onScrolledBottom)="onScrolledBottom()"
            [haltInfiniteScroll]="haltInfiniteScroll"
            appendTo="body"
            [readonly]="readonly"
            [group]="true"
            optionValue="value"
            [(ngModel)]="selectedGroupItem"
            [disableInfiniteScroll]="disableInfiniteScroll">
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <img [src]="group.label.avatarUri | avatarUriTransform" class="mr-2" style="width: 40px"
                         onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
                    <span class="text-rb text-primary">{{group.label | translateBackend}}</span>
                </div>
            </ng-template>
            <ng-template let-serviceCategory pTemplate="item">
                <div class="agency-item flex align-items-center ml-5" >
                    <span class="">{{serviceCategory.label | translateBackend}}</span>
                </div>
            </ng-template>
            <ng-template let-selectedItem  pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="selectedItem" >
                    <div class="ml-2 text-rb" >{{selectedItem.label  | translateBackend}}</div>
                </div>
            </ng-template>
</opentime-p-dropdown>