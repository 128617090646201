<app-edit-dialog [visible]="showAddressDialog" [title]="'Add User Location Information'" 
                 (onSubmit)="onSaveUserLocationInformation()"
                 (onHide)="onHide.emit()" [disabled]="!locationFormStatusValid">
    <div  class="mt-3 grid">
        <div class="col-12">
            <app-location-form  #addLocationForm
                                [colFull]="true"
                                (onLocationFormStatusChanges)="onLocationFormStatusChanges($event)">
            </app-location-form>
        </div>
    </div>
</app-edit-dialog>
