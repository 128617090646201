<app-edit-dialog [visible]="showCustomerDetailsDialog" [title]="'Edit Customer Details'"
                 (onSubmit)="onSaveCustomerDetails()" [dialogHeight]="'20'"
                 (onHide)="onHide.emit()" [disabled]="!customerDetailsForm.valid">
    <div class="mt-3 grid">
      <ng-container [formGroup]="customerDetailsForm">
        <div class="col-12 lg:col-6">
          <app-labelled-input [ngControl]="customerDetailsForm"
                              [placeholder]="'Company Name'" [label]="'Company Name'" [name]="'companyName'">
          </app-labelled-input>
        </div>
        <div class="col-12 lg:col-6">
          <div class="grid grid-nogutter w-100">
            <label for="dateEstablished" class="col-3 pr-2 text-right text-bold">Date Established</label>
            <div class="col-9" >
              <p-calendar id="dateEstablished"
                          formControlName="dateEstablished"
                          [showClear]="true"
                          [maxDate]="maxDate"
                          [dateFormat]="CONST.DATE_FORMAT"
                          [showIcon] = "true"
                          placeholder="Date Estabished"
                          [style]="{'width':'100%'}" ></p-calendar>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6">
          <app-labelled-input [ngControl]="customerDetailsForm"
                              [placeholder]="'Representative First Name'" [label]="'Rep First Name'" [name]="'representativeFirstName'">
          </app-labelled-input>
        </div>
        <div class="col-12 lg:col-6">
          <app-labelled-input [ngControl]="customerDetailsForm"
                              [placeholder]="'Representative Last Name'" [label]="'Rep Last Name'" [name]="'representativeLastName'">
          </app-labelled-input>
        </div>
      </ng-container>
    </div>
</app-edit-dialog>
