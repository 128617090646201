<app-edit-dialog [visible]="showTaxRateDialog" [title]="'Add/Edit Tax Rate'" (onSubmit)="onSubmitTaxRate()"
                 (onHide)="onHide.emit()" [disabled]="!taxRateForm.valid" [dialogHeight]="'25'">
  <ng-container [formGroup]="taxRateForm">
    <div class="mt-3 grid">
      <div class="col-6  p-1 pr-5">
        <div class="grid grid-nogutter w-100">
          <label for="dateOfBirth" class="col-2 pr-2 text-rb">Country</label>
          <div class="col-12 mt-1">
            <app-country-dropdown [ngControl]="taxRateForm"
                                  [name]="'countryId'"
                                  [items]="countries">
            </app-country-dropdown>
          </div>
        </div>
      </div>

      <div class="col-6  p-1 pr-5">
        <div class="grid grid-nogutter">
          <label for="dateOfBirth" class="col-2 pr-2 text-rb">Rate</label>
          <div class="col-12 mt-1">
            <input id="phone" type="tel" formControlName="rate"
                   appOnlyInteger
                   pInputText placeholder="Number" style="width: 100%"/>
          </div>
        </div>
      </div>

      <div class="col-6  p-1 pr-5">
        <div class="grid grid-nogutter block">
          <label class="col-12 pr-2 text-rb">Execution Date</label>
          <div class="col-12 mt-1">
            <p-calendar formControlName="executionDateTime" class="full-width-calendar-input"
                        [minDate]="minDate"
                        appendTo="body"
                        [showTime]="true"
                        [showButtonBar]="true"
                        [dateFormat]="CONST.DATE_FORMAT"
                        [showIcon]="true"
                        placeholder="Select Date">
            </p-calendar>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-edit-dialog>
