import { Pipe, PipeTransform } from "@angular/core";
import { IdVerificationType } from "../models/dto/id-verification.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";
import { WorkingTimeReason } from "../models/dto/working-time.dto";

@Pipe({
    name: 'workingTimeReasonPipe'
})
export class WorkingTimeReasonPipe implements PipeTransform {
  
    private readonly workingTimeReasonMap = ReadonlyMapBuilder.create<WorkingTimeReason, string>()
    .set(WorkingTimeReason.ARRIVED_LATE,'Arrived late')
    .set(WorkingTimeReason.CALLED_IN_SICK,'Called in sick')
    .set(WorkingTimeReason.DIDNT_SHOW_UP,`Didn't show up`)
    .set(WorkingTimeReason.REQUESTED_DAY_OFF,`Requested day off`)
    .build();

    transform(value: WorkingTimeReason): string {
      return this.workingTimeReasonMap.get(value);
    }
  
} 