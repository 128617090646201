import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgencyDto } from 'src/app/shared/models/dto/agency.dto';
import { ContactDto } from 'src/app/shared/models/dto/contact.dto';
import {OpentimerDto, OpentimerListDto} from 'src/app/shared/models/dto/opentimer.dto';
import { OpentimerService } from 'src/app/shared/services/opentimer-service';


@UntilDestroy()
@Component({
  selector: 'app-opentimers-dropdown',
  templateUrl: './opentimers-dropdown.component.html',
  styleUrls: ['./opentimers-dropdown.component.scss']
})
export class OpentimersDropdownComponent implements OnInit {
  @Output() onSelectedOpentimer = new EventEmitter<OpentimerListDto>(null);

  results: Array<OpentimerListDto> = [];
  agencies: Array<AgencyDto> = [];
  contacts: Array<ContactDto> = [];

  constructor(private opentimerService: OpentimerService) { }

  ngOnInit(): void {
    this.getSubscribers();
  }

  getSubscribers(): void {
    this.opentimerService.opentimersSchema$
    .pipe(untilDestroyed(this))
    .subscribe(response=>{
      if(response) {
        this.results = response.opentimers;
      }
    });

  }

  search(ev: any) {
    this.opentimerService.getOpentimers(null, 10, ev.query);
  }

  onDropdownClick(ev: any) {
    console.log(ev)
  }

  onSelected(ev: OpentimerListDto) {
    this.onSelectedOpentimer.emit(ev);
  }
}
