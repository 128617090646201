import { Pipe, PipeTransform } from "@angular/core";
import { IdVerificationItemType, IdVerificationType } from "../models/dto/id-verification.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'idVerificationItemTypeTitle'
})
export class IdVerificationItemTypeTitlePipe implements PipeTransform {
  
    private readonly idVerificationItemTypeTitleMap = ReadonlyMapBuilder.create<IdVerificationItemType, string>()
    .set(IdVerificationItemType.ID_BACK,'ID Back')
    .set(IdVerificationItemType.ID_FRONT,'ID Front')
    .set(IdVerificationItemType.ID_SELFIE,`ID Selfie`)
    .build();

    transform(value: IdVerificationItemType): string {
      return this.idVerificationItemTypeTitleMap.get(value);
    }
  
} 