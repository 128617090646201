<div  class="grid grid-nogutter info-panel-shadow search-opentimer-result-item p-4">
    <div class="col-1 text-center">
        <img [src]="opentimerProfileSearchResult.avatarUri | avatarUriTransform" class="account-avatar" 
        onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
    </div>
    <div class="col-2">
        <p class="text-rb">
            {{opentimerProfileSearchResult.contactPersonalInfoDto.firstName}} 
            {{opentimerProfileSearchResult.contactPersonalInfoDto.lastName}}
        </p>
        <p >
            <span class="text-rb text-primary">
                {{opentimerProfileSearchResult.hourlyRate.currency | currencySymbol}}
                {{opentimerProfileSearchResult.hourlyRate.hourlyRate | number}}/ 
            </span>
            <span class="text-rm">
                hour
            </span>
        </p>

    </div>
    <div class="col-6">
        <p class="text-rb mb-2">All skills tested and verified</p>
        <ng-container *ngFor="let jobSkillExperienceLevel of jobSkillExperienceLevels">
            <div class="grid grid-nogutter job-skill-container w-50" >
                <div class="col-6 text-left">
                    <p-chip class="mb-1" [label]="jobSkillExperienceLevel.skill">
                    </p-chip>
                </div>
                <div class="col-6 text-left">
                    <p-chip class="mb-1" [label]="jobSkillExperienceLevel.experienceLevel"
                    [class]="chipColor.get(jobSkillExperienceLevel.experienceLevel)">
                    </p-chip>
                </div>
            </div>
        </ng-container>
       
    </div>
    <div class="col-2"></div>
    <div class="col-1 text-center mt-2">
        <button pButton  label="View"
                (click)="onClick.emit()"
        class="primary w-100" pRipple> 
</button>
    </div>
</div>