import { Directive, ElementRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
 selector: 'form'
})
export class FormSubmitDirective {
submit$ = fromEvent(this.element, 'submit').pipe(shareReplay(1))

 constructor(private host: ElementRef<HTMLFormElement>) { }

 get element() {
   return this.host.nativeElement;
 }
}