import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { shareReplay } from 'rxjs';
import { AdminRole, AdminRoleSchema } from 'src/app/shared/models/dto/admin.dto';
import { AdminCreationService } from 'src/app/shared/services/admin-creation.service';

@UntilDestroy()
@Component({
  selector: 'app-edit-admin-roles',
  templateUrl: './edit-admin-roles.component.html',
  styleUrls: ['./edit-admin-roles.component.scss']
})
export class EditAdminRolesComponent implements OnInit {

  @Input() showRolesDialog: boolean;
  @Input() roles: Array<AdminRole>
  @Output() onHide = new EventEmitter();

  adminRoles: AdminRoleSchema

  rolesForm = this.formBuilder.group({
    roles: this.formBuilder.control(null, [Validators.required])
  })

  constructor(
    private formBuilder: FormBuilder,
    private adminCreationService: AdminCreationService
  ) { }

  ngOnInit(): void {
    this.adminCreationService.getAdminRoles()
    this.subscribeToAdminRoles()

    this.rolesForm.patchValue({
      roles: this.roles
    })
  }

  private subscribeToAdminRoles(): void {
    this.adminCreationService.adminRolesSchema$.pipe(untilDestroyed(this), shareReplay(1)).subscribe(
      (adminRoles: AdminRoleSchema) => {
        this.adminRoles = adminRoles;
      }
    );
  }

  onSaveUserInformation() { 
    const adminSchema = this.adminCreationService.adminSchema$.value;
    adminSchema.admin.roles = this.rolesForm.value.roles.map((role: { role: any; }) => role.role);
    this.adminCreationService.updateAdmin(adminSchema.admin.id, adminSchema.admin)
  }

}
