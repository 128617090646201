import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { LanguageProficiencyDto, ProficiencyDto, ProficiencyLanguageDto, ProficiencyLevelEnum } from 'src/app/shared/models/dto/language.dto';
import { OpentimerCreationDto, OpentimerSignUpDto } from 'src/app/shared/models/dto/opentimer.dto';
import {LanguageService, ProficiencyLevel} from 'src/app/shared/services/language.service';
import { OpentimerService } from 'src/app/shared/services/opentimer-service';
import {ApiEventStatus, ApiEventType} from "../../../models/api-event";
import {ApiEventService} from "../../../services/api-event.service";

@UntilDestroy()
@Component({
  selector: 'app-edit-opentimer-languages',
  templateUrl: './edit-opentimer-languages.component.html',
  styleUrls: ['./edit-opentimer-languages.component.scss']
})
export class EditOpentimerLanguagesComponent implements OnInit {
  @Input() showPersonalDialog: boolean;
  @Input() showOpentimerLanguageDialog: boolean;
  @Input() languageProficiencyDtos: Array<LanguageProficiencyDto> = [];
  @Input() opentimerSignUpProfile: OpentimerSignUpDto;

  @Output() onClose = new EventEmitter();

  opentimerForm = this.formBuilder.group({
    languageCode: this.formBuilder.control('', [Validators.required]),
    proficiency: this.formBuilder.control('', [Validators.required])
  });

  proficiencyLanguages: Array<ProficiencyLanguageDto>= [];
  proficiencies: Array<ProficiencyLevel> = [];

  currentLanguageProficiencyDtos: Array<LanguageProficiencyDto> = [];
  readonly chipLanguageColor = LanguageService.chipColor;

  constructor(private languageService: LanguageService,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private opentimerService: OpentimerService,
              private apiEventService: ApiEventService) { }

  ngOnInit(): void {
    this.currentLanguageProficiencyDtos = JSON.parse(JSON.stringify(this.languageProficiencyDtos));
    this.proficiencies = this.languageService.getProficiencies();
    this.getSubscribers();
    this.getApiEvents();
  }

  getSubscribers() {
    this.languageService.proficiencyLanguagesSchema$
    .pipe(untilDestroyed(this))
    .subscribe(v=>{
      if(v) {
        this.proficiencyLanguages = v.proficiencyLanguages;
      }
    });
  }

  addLanguageProficiency() {
    const languageProficiencyDto: LanguageProficiencyDto = {
      proficiencyLanguage: this.opentimerForm.value.languageCode,
      proficiencyLevel: ProficiencyLevelEnum[this.opentimerForm.value.proficiency]
    }
    this.opentimerService.addOpentimerLanguageProficiency(this.opentimerSignUpProfile.id, languageProficiencyDto);
  }

  removeLanguageProficiency(language: string) {
    this.opentimerService.deleteOpentimerLanguageProficiency(this.opentimerSignUpProfile.id, language);
  }

  getProficiency(proficiency: ProficiencyLevelEnum): ProficiencyLevel {
    return this.proficiencies.find(s => s.proficiencyLevel === proficiency);
  }

  protected getApiEvents(): void {
    if (this.apiEventService.event) {
      this.apiEventService.event
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          if (event && event.type === ApiEventType.ADD_OPENTIMER_LANGUAGE_PROFICIENCIES && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerService.getOpentimerById(this.opentimerSignUpProfile.id);
            this.opentimerForm.reset();
            this.messageService.add({severity:'success', summary:'Language proficiency added', detail:'Language proficiency is added to this profile'});
          }

          if (event && event.type === ApiEventType.DELETE_OPENTIMER_LANGUAGE_PROFICIENCIES && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerService.getOpentimerById(this.opentimerSignUpProfile.id);
            this.messageService.add({severity:'success', summary:'Language proficiency deleted', detail:'Language proficiency is deleted from this profile'});
          }
          });
    } else {
      console.error('ApiEventService event is null');
    }
  }

}
