import {Pipe, PipeTransform} from '@angular/core';
import {Time} from './time';
import {addLeadingZero} from './time-formatting';

@Pipe({
  name: 'timeHhMm',
})
export class TimeHhMmPipe implements PipeTransform {
  transform(time: Time): string {
    if (!time) {
      return null;
    }
    return addLeadingZero(time.getHours()) + ':' + addLeadingZero(time.getMinutes());
  }
}
