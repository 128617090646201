import { AfterViewInit, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AdminCreationService } from '../services/admin-creation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {AdminPermissionEnum, AdminRole} from '../models/dto/admin.dto';

@UntilDestroy()
@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective  implements AfterViewInit{
  @Input() appHasPermission: AdminPermissionEnum[] = [];

  private currentUserPermissions: AdminPermissionEnum[] = []; // Array to hold the user's roles
  private currentUserRoles: Array<string> =[];

  constructor(
    private adminCreationService: AdminCreationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    const currentLoggedInAdmin = this.adminCreationService.currentLoggedInAdmin$.value;
    if(!currentLoggedInAdmin) {
      return;
    }

    if(currentLoggedInAdmin) {
      this.currentUserPermissions = currentLoggedInAdmin.admin.permissions;
      this.currentUserRoles = currentLoggedInAdmin.admin.roles.map(v=>v.role);
    }

    this.init();
  }

  init() {
      const allowedPermissions = this.appHasPermission;

      if (!allowedPermissions || !allowedPermissions.length) {
        this.viewContainer.clear();
        return;
      }
      const isSuperAdmin = this.currentUserRoles.includes('SUPER_ADMIN_ROLE');
      // Check if the user permissions overlap with the allowed permissions
      const hasPermission = isSuperAdmin || this.currentUserPermissions.some(permission => allowedPermissions.includes(permission));
      if (hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
  }
}
