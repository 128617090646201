

<ng-container *ngIf="isForm; else notFormServiceCategoryDropdown">
    <ng-container [formGroup]="ngControl | cast">
        <ng-container *ngIf="items.length > 0;">
            <opentime-p-dropdown
                    [options]="items" 
                    [formControlName]="name"
                    [style]="{'width':'100%'}"
                    (onFilter)="onKeypressEvent($event)"
                    (onScrolledBottom)="onScrolledBottom()"
                    filterPlaceholder="Search Agency"
                    [placeholder]="placeholder"
                    [haltInfiniteScroll]="haltInfiniteScroll"
                    appendTo="body"
                    optionValue="id"
                    [(ngModel)]="serviceCategoryId"
                    [readonly]="readonly"
                    [disabled]="disabled"
                    [disableInfiniteScroll]="disableInfiniteScroll">
                    <ng-template let-serviceCategory pTemplate="item">
                        <div class="agency-item flex align-items-center" >
                            <div class="ml-2">{{serviceCategory | translateBackend}}</div>
                    
                        </div>
                    </ng-template>

                    <ng-template let-selectedItem  pTemplate="selectedItem">
                        <div class="flex align-items-center" *ngIf="selectedItem">
                            <div class="ml-2" >{{selectedItem | translateBackend}}</div>
                        </div>
                    </ng-template>
            </opentime-p-dropdown>
        </ng-container>

        <ng-container *ngIf="items.length < 1;">
            <opentime-p-dropdown
                    [options]="items" 
                    [formControlName]="name"
                    [style]="{'width':'100%'}"
                    (onFilter)="onKeypressEvent($event)"
                    (onScrolledBottom)="onScrolledBottom()"
                    filterPlaceholder="Search Agency"
                    [placeholder]="placeholder"
                    [haltInfiniteScroll]="haltInfiniteScroll"
                    appendTo="body"
                    optionValue="id"
                    [(ngModel)]="serviceCategoryId"
                    [readonly]="readonly"
                    [disabled]="disabled"
                    [disableInfiniteScroll]="disableInfiniteScroll">
                    <ng-template let-serviceCategory pTemplate="item">
                        <div class="agency-item flex align-items-center" >
                            <div class="ml-2">{{serviceCategory | translateBackend}}</div>
                    
                        </div>
                    </ng-template>

                    <ng-template let-selectedItem  pTemplate="selectedItem">
                        <div class="flex align-items-center" *ngIf="selectedItem">
                            <div class="ml-2" >{{selectedItem | translateBackend}}</div>
                        </div>
                    </ng-template>
            </opentime-p-dropdown>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #notFormServiceCategoryDropdown>
    <opentime-p-dropdown 
                [options]="items" 
                [style]="{'width':'100%'}"  optionValue="id"
                (onChange)="onSelectedServiceCategory.emit($event.value)"
                (onFilter)="onKeypressEvent($event)"
                (onScrolledBottom)="onScrolledBottom()"
                filterPlaceholder="Search Agency"
                [haltInfiniteScroll]="haltInfiniteScroll"
                appendTo="body"
                [(ngModel)]="serviceCategoryId"
                [readonly]="readonly"
                [disableInfiniteScroll]="disableInfiniteScroll">
                <ng-template let-serviceCategory pTemplate="item">
                    <div class="agency-item flex align-items-center" >
                        <div class="ml-2">{{serviceCategory | translateBackend}}</div>
                
                    </div>
                </ng-template>

                <ng-template let-selectedItem  pTemplate="selectedItem">
                    <div class="flex align-items-center" *ngIf="selectedItem">
                        <div class="ml-2" >{{selectedItem | translateBackend}}</div>
                    </div>
                </ng-template>
    </opentime-p-dropdown>
</ng-template>