import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CONST } from 'src/app/shared/constant';
import { ApiEventStatus, ApiEventType } from 'src/app/shared/models/api-event';
import { ApiEventService } from 'src/app/shared/services/api-event.service';
import { AuthService } from 'src/app/shared/services/auth-service';
import { EncodingService } from 'src/app/shared/services/encode.service';

@UntilDestroy()
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  signinForm: FormGroup = this.formBuilder.group({
    username: this.formBuilder.control('', [Validators.required, Validators.pattern(CONST.EMAIL_REGEX)]),
    password: this.formBuilder.control('', [Validators.required, Validators.minLength(8)])
  });

  constructor(public formBuilder: FormBuilder,
              private router: Router,
              private encodingService: EncodingService,
              private authService: AuthService,
              private apiEventService: ApiEventService) { }

  ngOnInit(): void {

    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe((event) => {
      if (event) {
        if (
          event.type === ApiEventType.GET_AUTHENTICATED &&
          event.status === ApiEventStatus.COMPLETED
        ) {
          this.router.navigate(['/admin-portal'])
        }
      }
    });
  }

  signIn() {
    // this.router.navigate(['/admin-portal'])
    if(this.signinForm.valid) {
      const hashedPassword = this.encodingService.encodePassword(this.signinForm.value.password);
      this.authService.authenticate(
        this.signinForm.value.username,
        hashedPassword
      )
    }
  }

}
