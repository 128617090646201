import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ContactDto } from 'src/app/shared/models/dto/contact.dto';
import { LocationAddressDto } from 'src/app/shared/models/dto/location-address.dto';
import { ContactService } from 'src/app/shared/services/contact.service';
import { LocationFormComponent } from '../../location-form/location-form.component';


@Component({
  selector: 'app-edit-user-location-information',
  templateUrl: './edit-user-location-information.component.html',
  styleUrls: ['./edit-user-location-information.component.scss']
})
export class EditUserLocationInfomationComponent implements OnInit {
  @ViewChild(LocationFormComponent) addLocationForm: LocationFormComponent;

  @Input() contactId: number;
  @Input() showAddressDialog: boolean;
  @Input() contactLocation: boolean = true;

  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter<LocationAddressDto>(undefined);

  locationFormStatusValid: boolean = false;

  constructor(private formBuilder: FormBuilder,
             private contactService: ContactService) { }


  ngOnInit(): void {
    console.log('ngOnInit-EditUserLocationInformationComponent')
  }

  onLocationFormStatusChanges(status: string) {
    this.locationFormStatusValid = status === 'VALID';
  }

  onSaveUserLocationInformation() {
    const locationAddress = this.addLocationForm.saveForm();
    if(this.contactLocation) {
      this.contactService.upsertContactAddress(this.contactId, locationAddress);
    } else {
      this.onSubmit.emit(locationAddress);
    }
  }

}
