import { CountryDto } from "./country.dto";

export enum HolidayRepeatMode {
    NO_REPEAT = 'NO_REPEAT',
    ANNUALLY = 'ANNUALLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY'
}

export interface HolidayDtoSchema {
    holidays: Array<HolidayDto>;
    total: number;
}

export interface HolidayDto {
    id:number;
    holidayName: string;
    startDate: string;
    endDate: string;
    holidaySurcharge: number;
    holidayRepeatMode: HolidayRepeatMode;
    countries: Array<CountryDto>;
    allCountries: boolean;
}

export interface HolidayCreationDto {
    holidayName: string;
    startDate: string;
    endDate: string;
    holidaySurcharge: number;
    holidayRepeatMode: HolidayRepeatMode;
    countries: Array<number>;
    allCountries: boolean;
}