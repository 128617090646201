import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BankAccountDto } from '../../models/dto/bank.dto';

@Component({
  selector: 'app-opentimer-bank-account',
  templateUrl: './opentimer-bank-account.component.html',
  styleUrls: ['./opentimer-bank-account.component.scss']
})
export class OpentimerBankAccountComponent implements OnInit {
  @Input() defaultBankAccount: BankAccountDto; 
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;
  
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
