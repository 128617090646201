import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PhoneNumberDto } from '../../models/dto/contact.dto';
import { CountryDto } from '../../models/dto/country.dto';
import { CountryService } from '../../services/country.service';


@UntilDestroy()
@Component({
  selector: 'app-phone-number-list',
  templateUrl: './phone-number-list.component.html',
  styleUrls: ['./phone-number-list.component.scss']
})
export class PhoneNumberListComponent implements OnInit {
  @Input() phoneNumbers: Array<PhoneNumberDto>
  @Output() selectedPhoneNumber = new EventEmitter<PhoneNumberDto>();

  phoneForm = this.formBuilder.group({
    countryCode: this.formBuilder.control(''),
    countryId: this.formBuilder.control('', Validators.required),
    phone: this.formBuilder.control('', [Validators.required, Validators.minLength(10),
      Validators.pattern('^[0-9]*$')]),
    type: this.formBuilder.control('MOBILE', [Validators.required]),
    index: this.formBuilder.control(null, Validators.required)
  });

  countries: Array<CountryDto> = [];
  displayPhoneNumber: boolean = true;
  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService) { }

  ngOnInit(): void {
    this.getSubcribers();
  }

  getSubcribers() {
    this.countryService.countries$
    .pipe(untilDestroyed(this))
    .subscribe((countries: Array<CountryDto>)=>{
      this.countries = countries;
    })
  }

  editPhoneNumber(phoneNumber: PhoneNumberDto, index: number) {
    this.displayPhoneNumber = !this.displayPhoneNumber;

    this.phoneForm.patchValue({
      phone: phoneNumber.number,
      countryId: phoneNumber.countryId.toString(),
      index: index
    });
  }
  
  removeAt(index: number) {
    this.phoneNumbers.splice(index, 1);
  }

  update() {
    const phoneNumberValue = this.phoneForm.value;
    const selectedCountry = this.countries.find(v=> v.id === Number(phoneNumberValue.countryId));

    this.phoneNumbers[phoneNumberValue.index] = {
      countryId: Number(phoneNumberValue.countryId),
      number: phoneNumberValue.phone,
      type: phoneNumberValue.type,
      extension: selectedCountry.phoneCode
    };
    this.phoneForm.reset();
    this.phoneForm.patchValue({
      type: 'MOBILE'
    })
  }

}
