import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CONST} from "../../constant";

@Component({
  selector: 'app-start-end-date-filter',
  templateUrl: './start-end-date-filter.component.html',
  styleUrls: ['./start-end-date-filter.component.scss']
})
export class StartEndDateFilterComponent implements OnInit {
  @Input() startDateLabel: string = 'Start Date';
  @Input() endDateLabel: string = 'End Date';

  @Output() onSelectedStartDate = new EventEmitter<Date>();
  @Output() onSelectedEndDate = new EventEmitter<Date>();

  // [(ngModel)]="selectedDate"

  startDate: Date;
  endDate: Date;

  constructor() { }

  ngOnInit(): void {
  }

  clear() {
    this.startDate = null;
    this.endDate = null;
  }

  protected readonly CONST = CONST;
}
