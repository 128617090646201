import { Component, Input, OnInit } from '@angular/core';
import { CustomerShiftDto, PresetShiftDto, ShiftDetail, ShiftEntryItem } from '../../models/dto/shift.dto';

@Component({
  selector: 'app-shift-item-detail',
  templateUrl: './shift-item-detail.component.html',
  styleUrls: ['./shift-item-detail.component.scss']
})
export class ShiftItemDetailComponent implements OnInit {
  @Input() shift: CustomerShiftDto;
  @Input() presetShift: PresetShiftDto = null;
   
  shiftDetail: ShiftDetail;
  constructor() { }

  ngOnInit(): void {
    this.shiftDetail = new ShiftDetail(this.shift);
  }

}
