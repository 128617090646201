import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ApiEventService } from 'src/app/shared/services/api-event.service';
import { ServiceCategoryService } from 'src/app/shared/services/service-caregory.service';
import * as _ from 'lodash';  
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiEventStatus, ApiEventType } from 'src/app/shared/models/api-event';
import { ServiceCategoriesSchema, ServiceCategoryDto, ServiceSubCategoryDto } from 'src/app/shared/models/dto/service-category.dto';

interface GroupServiceSubCategoryItem {
  label:ServiceSubCategoryDto;
  value: number;
}

interface GroupServiceCategoryDtoItem {
  label: ServiceCategoryDto;
  value: number;
  items: Array<GroupServiceSubCategoryItem>;
}

@UntilDestroy()
@Component({
  selector: 'app-service-subcategory-dropdown',
  templateUrl: './service-subcategory-dropdown.component.html',
  styleUrls: ['./service-subcategory-dropdown.component.scss']
})
export class ServiceSubcategoryDropdownComponent implements OnInit {
  @Input() ngControl: AbstractControl;
  @Input() name: string;
  @Input() optionValue: string;
  @Input() placeholder: string = 'Select'
  @Input() isForm: boolean = true;

  @Input() readonly: boolean = false;
  @Output() onSelectedServiceCategory = new EventEmitter<number>();



  disabled: boolean = false;
  overlayVisible: boolean = false;
  focused: boolean = false;
  showClear: boolean = false;
  disableInfiniteScroll: boolean = false;
  haltInfiniteScroll: boolean = false;

  items: GroupServiceCategoryDtoItem[] = [];
  total: number = 0;
  selectedGroupItem: any;
  constructor(private serviceCategoryService: ServiceCategoryService,
    private apiEventService: ApiEventService) { 
    this.onKeypressEvent = _.debounce(this.onKeypressEvent, 500)
  }

  ngOnInit(): void {
    this.getSubcribers();
    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe(event=>{
      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_SERVICE_CATEGORIES) {
        this.haltInfiniteScroll = false;
      }
    })
  }

  getSubcribers() {
    this.serviceCategoryService.serviceCaregorySchema$
    .pipe(untilDestroyed(this))
    .subscribe((response: ServiceCategoriesSchema)=>{
      if(response) {
        response.serviceCategories.forEach(v=>{

          const subItems: GroupServiceSubCategoryItem[] = v.subCategories.map(v=> {
            return {
              label: v,
              value: v.id
            }
          });

          this.items.push({
            label: v,
            value: v.id,
            items: subItems
          })
        });
        this.total = response.total;
      }
    })
  }


  onScrolledBottom() {
    if(this.items.length >= this.total) {
      this.disableInfiniteScroll = true;
    }

    if((this.items.length <= this.total) && !this.haltInfiniteScroll) {
      const lastItemServiceCategory = this.items[this.items.length -1];
      this.serviceCategoryService.getServiceCategories(lastItemServiceCategory.value, 20);
      this.haltInfiniteScroll = true;
    } 
  }

  onKeypressEvent(ev) {
    // this.serviceCategoryService.getServiceCategories(null, null, 20, ev.target.value as string);
    this.items = [];
  }

  selectedServiceSubCategory(ev) {
    this.onSelectedServiceCategory.emit(this.selectedGroupItem);
  }
}
