<section class="page-container p-2 v-100 w-100 grid grid-nogutter justify-content-center align-items-center">
    <div class="grid grid-nogutter justify-content-center">
        <div class="col-12 text-center not-found">
            <p class="text-404">404</p>
            <p class="text-not-found">PAGE NOT FOUND</p>
            <p >
                The page you are looking for is temporarily not available   
            </p>
        </div>
        <div class="col-4">
            <button pButton  label="GO TO HOME PAGE" class="primary mt-3 w-100" pRipple 
                    (click)="gotoHomePage()"> 
            </button>
        </div>
    </div>
</section>