import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AccountStatus } from '../models/dto/account.dto';
import { ReadonlyMapBuilder } from '../readyonly-map-builder';
  
@Directive({
    selector: '[accountStatusTextColor]'
})
export class AccountStatusTextColorDirective implements OnInit, OnChanges{
    @Input() accountStatus: boolean;

    private readonly statusColorMap = ReadonlyMapBuilder.create<boolean, string>()
    .set(false ,'#ff3251')
    .set(true,'#51AF33')
    .build();


    constructor(private eleRef: ElementRef) {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.eleRef.nativeElement.style.color = this.statusColorMap.get(this.accountStatus);
    }

    ngOnInit(): void {
    }
}