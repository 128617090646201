import { AccountType } from "../account";
import { AccountContactDto, AccountStatus } from "./account.dto";
import {AgencyBasicDto, AgencyDto} from "./agency.dto";
import { AvailabilityDto } from "./availability.dto";
import { BankAccountDto } from "./bank.dto";
import { ContactDto, ContactsInfoDto } from "./contact.dto";
import { CountryDto } from "./country.dto";
import { IdVerificationDto } from "./id-verification.dto";
import {LanguageProficiencyDto} from "./language.dto";
import { MoneyDto } from "./money.dto";
import { OpentimerProfileCreationDto } from "./opentimer-job-profile.dto";

export interface OpentimersSchema {
    opentimers: Array<OpentimerDto>;
    total: number;
    agencies: Array<AgencyDto>;
    contacts: Array<ContactDto>;
    countries: Array<CountryDto>;
}

export interface OpentimersListSchema {
  opentimers: Array<OpentimerListDto>;
  total: number;
}

export interface OpentimersViewSchema {
  opentimers: Array<OpentimerViewDto>;
  total: number;
}

export interface OpentimerDto {
  id: number;
  enabled: boolean;
  type: AccountType;
  status: AccountStatus;
  countryId: number;
  avatarUri: string;
  createdDate: string;
  description: string;
  name: string;
  contacts: Array<AccountContactDto>;
  contactsInfo: ContactsInfoDto;
  agencyId: number;
  availability: AvailabilityDto;
  bookedRevenue: number;
  salesAmounts: Array<MoneyDto>;
  interviewAmounts: Array<MoneyDto>;
}

export interface OpentimerListDto {
  id: number;
  enabled: boolean;
  signInEmail: string;
  type: AccountType;
  status: AccountStatus;
  country: CountryDto;
  avatarUri: string;
  name: string;
  agency: AgencyBasicDto;
  salesAmounts: Array<MoneyDto>;
  interviewAmounts: Array<MoneyDto>;
}

export interface OpentimerDto {
    id: number;
    enabled: boolean;
    type: AccountType;
    status: AccountStatus;
    countryId: number;
    avatarUri: string;
    createdDate: string;
    description: string;
    name: string;
    contacts: Array<AccountContactDto>;
    contactsInfo: ContactsInfoDto;
    agencyId: number;
    availability: AvailabilityDto;
    bookedRevenue: number;
    salesAmounts: Array<MoneyDto>;
    interviewAmounts: Array<MoneyDto>;
}

export interface OpentimerMinimalDto {
    id: number;
    fullName: string;
    avatarUri: string;
}

export interface OpentimerCreationDto {
    languageProficiencyDtos: Array<LanguageProficiencyDto>
    japanWorkPermit: boolean;
}

export interface OpentimerSignUpDto {
    id: number;
    japanWorkPermit: boolean;
    languageProficiencyLevels: Array<LanguageProficiencyDto>;
    profiles: Array<OpentimerProfileCreationDto>;
    bankAccountDtos: Array<BankAccountDto>;
    idVerificationDto: IdVerificationDto;
    contact: ContactDto;
    status: AccountStatus;
    locked: boolean;
    avatarUri: string;
    enabled: boolean;
}

export interface OpentimerDisplayRecruiterDtoSchema {
    opentimerDisplayRecruiterDtos: Array<OpentimerDisplayRecruiterDto>;
}

export interface OpentimerDisplayRecruiterDto {
    id: number;
    recruiterContact: ContactDto;
    primary: boolean;
}

export interface OpentimerViewDto {
    id:number;
    agencyId: number;
    agencyName: string;
    fullName: string;
    status: AccountStatus;
    creationDate: string;
    bookingsCount: number;
    hoursWorked: number;
    totalEarned: MoneyDto;
    country: CountryDto;
}

