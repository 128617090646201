<div  class="grid grid-nogutter" *ngIf="availability; else noAvailabilityTemplate">
    <div class="col-6">
        <div  class="grid grid-nogutter">
            <div class="col-12">
                <p class="text-ssb">Earliest start time</p>
                <p class="text-primary text-rb">{{workingUTCstartTime}}</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Minimum hours per day</p>
                <p class="text-primary text-rb">{{availability.workingTime.minWorkingHour}} hours</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Minimum days per week</p>
                <p class="text-primary text-rb">{{availability.workingTime.minWorkingDay}} days</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Minimum period</p>
                <p class="text-primary text-rb">{{availability.workingTime.minAssignmentDuration}} {{availability.workingTime.minAssignmentDurationType | titlecase}}/s</p>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div  class="grid grid-nogutter">
            <div class="col-12">
                <p class="text-ssb">Latest end time</p>
                <p class="text-primary text-rb">{{workingUTCendTime}}</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Maximum hours per day</p>
                <p class="text-primary text-rb">{{availability.workingTime.maxWorkingHour}} hours</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Maximum days per week</p>
                <p class="text-primary text-rb">{{availability.workingTime.maxWorkingDay}} days</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Maximum period</p>
                <p class="text-primary text-rb">{{availability.workingTime.maxAssignmentDuration}} {{availability.workingTime.maxAssignmentDurationType | titlecase}}/s</p>
            </div>
        </div>
    </div>
</div>

<ng-template #noAvailabilityTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                            (click)="onClick.emit()"
                           class="primary" label="Availability" 
                           icon="pi pi-plus"  [disabled]="!enabledButton">
                </p-button>
            </div>
        </div>
    </div>
</ng-template>