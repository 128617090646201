<section class="main-content">
    <p class="welcome-text">Welcome to <br/>&nbsp;Opentimer</p>
    <p class="admin-panel-text">Admin Panel</p>
    <div id="signin-container" class="mt-5">
        <form [formGroup]="signinForm" class="p-fluid text-center" >
            <div class="p-field p-input-filled">
                <input id="username" type="email" formControlName="username" pInputText
                    placeholder="Email">
            </div>
            <div class="p-field p-input-filled mt-3">
                <input id="password" type="password" formControlName="password" pInputText
                    placeholder="Password">
            </div>
            <button pButton type="submit" label="Sign in" (click)="signIn()"
                    class="primary mt-3 w-100" pRipple [disabled]='!signinForm.valid'>
            </button>
        </form>
    </div>
</section>