import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LanguageProficiencyDto } from '../../models/dto/language.dto';
import {LanguageService, ProficiencyLevel} from '../../services/language.service';

@Component({
  selector: 'app-opentimer-language-proficiencies',
  templateUrl: './opentimer-language-proficiencies.component.html',
  styleUrls: ['./opentimer-language-proficiencies.component.scss']
})
export class OpentimerLanguageProficienciesComponent implements OnInit{
  @Input() languageProficiencies: Array<LanguageProficiencyDto> = [];
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;

  @Output() onClick = new EventEmitter();

  proficiencies: Array<ProficiencyLevel> = [];

  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.proficiencies = this.languageService.getProficiencies();
  }


}
