import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { delay } from "../delay";
import { getErrorMessage, getErrorTitle, Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AccountStatus } from "../models/dto/account.dto";
import { AgenciesSchema, AgencyCreationDto, AgencyDto, AgencyInformationDto, AgencyProfileDto } from "../models/dto/agency.dto";
import { BookingOrderDisplayDtoSchema, BookingStatus } from "../models/dto/booking.dto";
import { CommissionPercentageDto } from "../models/dto/commission-percentage.dto";
import { LocationAddressDto } from "../models/dto/location-address.dto";
import { PhonesAndEmailsDto } from "../models/dto/phones-emails.dto";
import { RecruiterDto } from "../models/dto/recruiter.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { AuthService } from "./auth-service";
import { OpentimersSchema, OpentimersViewSchema} from "../models/dto/opentimer.dto";

@Injectable({
    providedIn: 'root',
})
export class AgencyService extends AbstractHttpHandler {
    agenciesSchema$ = new BehaviorSubject<AgenciesSchema>(undefined);
    agency$ = new BehaviorSubject<AgencyDto>(undefined);

    agencyProfile$ = new BehaviorSubject<AgencyProfileDto>(undefined);

    bookingOrderSchema$ = new BehaviorSubject<BookingOrderDisplayDtoSchema>(undefined);

    recruiters$ = new BehaviorSubject<Array<RecruiterDto>>([]);
    opentimersViewSchema$ = new BehaviorSubject<OpentimersViewSchema>(undefined);


    commissionPercentage$ = new BehaviorSubject<CommissionPercentageDto>(null);

    constructor(public override http: HttpClient,
                public override apiEventsService: ApiEventService,
                private router: Router) {
        super(http, apiEventsService);
    }

    getAgencies(lastId: number = null,
                page:number = 1,
                limit:number = 10,
                search: string = null,
                accountStatuses: AccountStatus[] = [],
                enabled: boolean = null,
                showSpinner: boolean = true,
                email: string = null) {
        const url = `v2/agencies`;
        const eventType = ApiEventType.GET_AGENCIES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: showSpinner });

        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('pageNumber', page);
        }

        if(email !== null && email.length > 0) {
            params = params.append('email', `${email}`);
        }
        if(search !== null && search.length > 0) {
            params = params.append('name.like', `${search}%`);
        }

        if(enabled !== null) {
            params = params.append('enabled', enabled);
        }


        accountStatuses.forEach((accountStatus)=>{
            if(accountStatus) {
                params = params.append('statuses', accountStatus);
              }
        });

        params = params.append('include', ['TOTAL'].toString());

        if (lastId) {
            params = params.append('id.gt', lastId.toString());
        }

        this.http.get<AgenciesSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.agenciesSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getAgencyOpentimers(agencyId: number,
                        page:number = 1,
                        limit:number = 10,
                        search: string = null,
                        opentimerAliasLike: string = null,
                        accountStatuses: AccountStatus[] = [],
                        enabled: boolean = null,
                        countryId: number = null,
                        cityId: number = null,
                        serviceCategoryId: number = null,
                        serviceSubCategoryId: number = null,
                        email: string = null) {
            const url = `v2/admin/agencies/${agencyId}/opentimers`;
            const eventType = ApiEventType.GET_OPENTIMERS;
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

            let params = new HttpParams();


            if(limit) {
            params = params.append('limit', limit);
            }

            if(page) {
                params = params.append('page', page);
            }



            if(countryId) {
                params = params.append('countryId', countryId);
            }

            if(cityId) {
                params = params.append('cityId', cityId);
            }

            if(serviceCategoryId) {
                params = params.append('serviceCategoryId', serviceCategoryId);
            }

            if(serviceCategoryId) {
                params = params.append('serviceSubCategoryId', serviceSubCategoryId);
            }

            if(email !== null && email.length > 0) {
                params = params.append('email', `${email}`);
            }

            if(search !== null && search.length > 0) {
                params = params.append('fullName.like', `%${search}%`);
            }

            if(opentimerAliasLike !== null && opentimerAliasLike.length > 0) {
                params = params.append('opentimerAliasLike', `${opentimerAliasLike}%`);
            }


            accountStatuses.forEach((accountStatus)=>{
            if(accountStatus) {
                params = params.append('statuses', accountStatus);
            }
            });

            if(enabled !== null) {
                params = params.append('enabled', enabled);
            }

            params = params.append('include', ['TOTAL'].toString());

            this.http.get<OpentimersSchema>(url, {params})
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(async response=>{
                this.opentimersViewSchema$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })
    }

    getAgencyById(accountId: number) {
        const url = `v2/agencies/${accountId}`;
        const eventType = ApiEventType.GET_AGENCY_BY_ID;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.get<AgencyProfileDto>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: AgencyProfileDto)=>{
            this.agencyProfile$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    getAgencyRecruiters(accountId: number) {
        const url = `v2/agencies/${accountId}/recruiters`;
        const eventType = ApiEventType.GET_RECRUITERS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.get<Array<RecruiterDto>>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.recruiters$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    getAgencyCommissionPercentage(agencyId:number) {
        const url = `v2/agencies/${agencyId}/commissionPercentages`;
        const eventType = ApiEventType.GET_AGENCY_COMMISSION_PERCENTAGES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.get<CommissionPercentageDto>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.commissionPercentage$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getBookingOrders(accountId: number,
                     page:number = 1,
                     limit:number = 10,
                     status: BookingStatus[] = [],
                     startDate: string = null,
                     endDate: string = null,
                     opentimerNameLike: string = null) {
        const url = `v2/agencies/${accountId}/bookingOrders`;
        const eventType = ApiEventType.GET_AGENCY_BOOKING_ORDERS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });


        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        if(startDate) {
            params = params.append('startDate', page);
        }

        if(endDate) {
            params = params.append('endDate', page);
        }

        status.forEach((bookingStatus)=>{
            if(bookingStatus) {
                params = params.append('status', bookingStatus);
              }
        });


        if(opentimerNameLike !== null && opentimerNameLike.length > 0) {
            params = params.append('opentimerNameLike', `${opentimerNameLike}%`);
        }

        this.http.get<BookingOrderDisplayDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: BookingOrderDisplayDtoSchema)=>{
            this.bookingOrderSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    upsertAgencyName(accountId: number, agencyName: AgencyCreationDto) {
        const url = `v2/agencies/${accountId}/agencyNames`;
        const eventType = ApiEventType.UPSERT_AGENCY_NAME;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<AgencyProfileDto>(url, agencyName)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: AgencyProfileDto)=> {
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    public upsertAgencyInformation(accountId: number, agencyInfomation: AgencyInformationDto) {
        const url = `v2/agencies/${accountId}/agencyInfomation`;
        const eventType = ApiEventType.UPSERT_AGENCY_INFORMATION;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<AgencyProfileDto>(url, agencyInfomation)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: any)=> {
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    public upsertAgencyPhonesAndEmails(accountId: number, phonesAndEmails: PhonesAndEmailsDto) {
        const url = `v2/agencies/${accountId}/phonesAndEmails`;
        const eventType = ApiEventType.UPSERT_PHONES_EMAILS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<AgencyProfileDto>(url, phonesAndEmails)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: AgencyProfileDto)=> {
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    upsertAgencyAddresses(accountId: number, locationAddress: LocationAddressDto) {
        const url = `v2/agencies/${accountId}/addresses`;
        const eventType = ApiEventType.UPSERT_AGENCY_ADDRESS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<AgencyProfileDto>(url, locationAddress)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: AgencyProfileDto)=> {
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    updateAgencyCommissionPercentage(agencyId: number, commissionPercentageDto: CommissionPercentageDto) {
        const url = `v2/agencies/${agencyId}/commissionPercentages`;
        const eventType = ApiEventType.UPDATE_AGENCY_COMMISSION;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.put<CommissionPercentageDto>(url, commissionPercentageDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: CommissionPercentageDto)=> {
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }


    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = eventType === ApiEventType.GET_AGENCY_COMMISSION_PERCENTAGES ? false : true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
              case 'DUPLICATE_VALUE': {
                title = getErrorTitle(errorResponse);
                message = getErrorMessage(errorResponse);
                break;
              }
              case 'INVALID_VALUE': {
                title = getErrorTitle(errorResponse);
                message = getErrorMessage(errorResponse);
                break;
              }



            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;


         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }
}
