<div  class="grid grid-nogutter ">
    <div class="col-9 ">
        <div class="grid grid-nogutter service-length-container pl-3 pr-3 pt-3">
            <div class="col-3 flex align-items-center justify-content-start">
                <p-checkbox class="mr-3" [(ngModel)]="serviceLength.isSelectedWeek"  [binary]="true"></p-checkbox>
                <p class="text-ssb">Weeks</p>
            </div>
            <div class="col-9 pl-4">
                <p-inputNumber [showButtons]="true" buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal" [step]="1"
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-primary" 
                                [allowEmpty]="false" [disabled]="!serviceLength.isSelectedWeek"
                                [inputStyle]="{'width': '100%'}"
                                [min]="0" [max]="52" [(ngModel)]="serviceLength.numberOfWeeks"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                </p-inputNumber>
            </div>
            <div class="col-3 flex align-items-center justify-content-start mt-3">
                <p-checkbox class="mr-3" [(ngModel)]="serviceLength.isSelectedMonth" [binary]="true"></p-checkbox>
                <p class="text-ssb">Months</p>
            </div>
            <div class="col-9 pl-4 mt-3">
                <p-inputNumber [showButtons]="true" buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal" [step]="1"
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-primary" 
                                [allowEmpty]="false" [disabled]="!serviceLength.isSelectedMonth"
                                [inputStyle]="{'width': '100%'}"
                                [min]="0" [max]="12" [(ngModel)]="serviceLength.numberOfMonths"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                </p-inputNumber>
            </div>
            <div class="col-4 mt-3">
                <p class="text-rb">Starting On:</p>
                <p class="text-ssb text-primary">{{serviceLength.selectedDate | date: 'MMM d, y'}}</p>
            </div>
            <div class="col-4 mt-3">
                <p class="text-rb">Ending On:</p>
                <p class="text-ssb text-velvet">{{serviceLength.nextDate | date: 'MMM d, y'}}</p>
            </div>
   
        </div>
    </div>
    <div class="col-3"></div>
    <div class="col-9 grid grid-nogutter justify-content-start align-items-center available-from-col">
        <div class="col-12 pl-3">
            <p class="text-rb">Duration: {{serviceLength.calculateTotalNumberOfWeeks()}} <span class="text-primary">Weeks</span></p>
        </div>
    </div>
    <div class="col-3"></div>

</div>
