<div  class="grid grid-nogutter info-panel-shadow jobskill-experience-item p-2 align-items-center">
    <div class="col-3 lg:col-2">
        <p class="text-l-gray">Primary Skill</p>
        <div  class="chip-container">
            <p-chip  [label]="primarySkillExperience.skill | titlecase"
                [class]="chipColor.get(primarySkillExperience.experienceLevel)">
            </p-chip>
        </div>
    </div>
    <div class="col-7 lg:col-8">
        <p class="text-l-gray">Secondary Skills</p>
        <ng-container *ngFor="let jobSkillExperience of secondarySkillExperience">
            <div  class="chip-container">
                <p-chip  [label]="jobSkillExperience.skill | titlecase"
                    [class]="chipColor.get(jobSkillExperience.experienceLevel)">
                </p-chip>
            </div>
        </ng-container>
    </div>
    <div class="col-2 lg:col-2  flex justify-content-end align-items-start">
        <button pButton pRipple type="button" icon="pi pi-pencil" 
                (click)="onEditJobSkillExperience.emit()"
                class="p-button-rounded p-button-text">
        </button>
    </div>
</div>