import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { ExperienceLevelEnum } from "../models/dto/experience-level.dto";
import { JobSkillAddressesSchema, JobSkillCreationDto, JobSkillDto } from "../models/dto/skill.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";

export class ExperienceLevel {
  constructor(public experienceLevel: ExperienceLevelEnum,
              public title: string,
              public description: string) {}
}

@Injectable({
    providedIn: 'root',
})
export class SkillService extends AbstractHttpHandler {
    jobSkill$ = new BehaviorSubject<JobSkillDto>(undefined);
    jobSkillsSchema$ = new BehaviorSubject<JobSkillAddressesSchema>(undefined);


    public static readonly chipColor = ReadonlyMapBuilder.create<ExperienceLevelEnum, string>()
        .set(ExperienceLevelEnum.BEGINNER, 'beginner-level')
        .set(ExperienceLevelEnum.INTERMEDIATE, 'intermediate-level')
        .set(ExperienceLevelEnum.ADVANCED, 'advanced-level')
        .set(ExperienceLevelEnum.EXPERT, 'expert-level')
        .set(ExperienceLevelEnum.MASTER, 'master-level')
        .build();


    private experienceLevels: ExperienceLevel[] = [
      new ExperienceLevel(ExperienceLevelEnum.BEGINNER, 'Beginner', 'Level 1: Below average'),
      new ExperienceLevel(ExperienceLevelEnum.INTERMEDIATE, 'Intermediate', 'Level 2: Average'),
      new ExperienceLevel(ExperienceLevelEnum.ADVANCED, 'Advanced', 'Level 3: Above average'),
      new ExperienceLevel(ExperienceLevelEnum.EXPERT, 'Expert', 'Level 4: Very good'),
      new ExperienceLevel(ExperienceLevelEnum.MASTER, 'Master', 'Level 5: Excellent')
    ];

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getExperienceLevels(): Array<ExperienceLevel> {
      return this.experienceLevels;
    }

    getSkills(page:number = 1,
              limit:number = 10,
              search: string = null,
              valid: boolean = null) {
      const url = `v2/jobSkills`;
      const eventType = ApiEventType.GET_SKILLS;


      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      let params = new HttpParams();

      if(limit) {
        params = params.append('limit', limit);
      }

      if(page) {
          params = params.append('page', page);
      }

      if(search !== null && search.length > 0) {
          params = params.append('nameLike', `${search}%`);
      }

      if(valid !== null) {
        params = params.append('valid', valid);
      }

      params = params.append('include', ['TOTAL'].toString());

      this.http.get<JobSkillAddressesSchema>(url, {params})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response=>{
          this.jobSkillsSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    createSkill(skillCreationDto: JobSkillCreationDto) {
        const url = `v2/jobSkills`;
        const eventType = ApiEventType.CREATE_SKILLS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.post<JobSkillDto>(url, skillCreationDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.jobSkill$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    updateJobSKill(skillId: number , skillCreationDto: JobSkillCreationDto) {
      const url = `v2/jobSkills/${skillId}`;
      const eventType = ApiEventType.UPDATE_SKILL;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.put<JobSkillDto>(url, skillCreationDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response=>{
          this.jobSkill$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }


    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });


          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }
}
