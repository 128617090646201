import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError, filter } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { ServiceCategoriesSchema, ServiceCategoryCreationDto, ServiceCategoryDto, ServiceSubCategoriesSchema, ServiceSubCategoryCreationDto, ServiceSubCategoryDto } from "../models/dto/service-category.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
 
@Injectable({
    providedIn: 'root',
})
export class ServiceCategoryService extends AbstractHttpHandler {

    serviceCaregorySchema$ = new BehaviorSubject<ServiceCategoriesSchema>(undefined);
    serviceSubCaregorySchema$ = new BehaviorSubject<ServiceSubCategoriesSchema>(undefined);

    serviceCategory$ = new BehaviorSubject<ServiceCategoryDto>(undefined);
    serviceSubCategory$ = new BehaviorSubject<ServiceSubCategoryDto>(undefined);


    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getServiceCategories(lastId: number = null, 
                         limit: number = 10,
                         page: number = null,
                         search: string= null,
                         active: boolean = null,) {
        const url = `v2/serviceCategories`;
        const eventType = ApiEventType.GET_SERVICE_CATEGORIES;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();

        if(lastId) {
            params = params.append('id.gt', lastId);
        }

        if(page) {
            params = params.append('page', page);
        }

        if(search !== null && search.length > 0) {
            params = params.append('name.like', `${search}%`);
        }

        if(active !== null) {
            params = params.append('active', active);
        }
    
        params = params.append('limit', limit);

        this.http.get<ServiceCategoriesSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.serviceCaregorySchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    getServiceSubCategories(page: number = null,
                            limit: number = 10,
                            search: string = null,
                            lastId: number = null,
                            active: boolean = null
                             ) {
        const url = `v2/serviceSubCategories`;
        const eventType = ApiEventType.GET_SERVICE_SUB_CATEGORIES;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();

        if(lastId) {
            params = params.append('id.gt', lastId);
        }

        if(page) {
            params = params.append('page', page);
        }

        if(search !== null && search.length > 0) {
            params = params.append('name.like', search);
        }

        if(active !== null) {
            params = params.append('active', active);
        }

        params = params.append('limit', limit);

        this.http.get<ServiceSubCategoriesSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: ServiceSubCategoriesSchema)=>{
            this.serviceSubCaregorySchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    getServiceSubCategoryByCategoryId(serviceCategoryId: number, active: boolean = null) {
        const url = `v2/serviceCategories/${serviceCategoryId}/subCategories`;
        const eventType = ApiEventType.GET_SERVICE_SUB_CATEGORIES_BY_CATEGORY_ID;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        let params = new HttpParams();

        if(active !== null) {
            params = params.append('active', active);
        }

        this.http.get<ServiceSubCategoriesSchema>(url, {params})
        .pipe(
            catchError(this.handleErrors(eventType, [])),
        )
        .subscribe((response: ServiceSubCategoriesSchema)=>{

            if(active !== null) {
                response.serviceSubCategories = response.serviceSubCategories.filter((v)=>{
                    return v.active === active;
                })
            }

            this.serviceSubCaregorySchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    createServiceCategory(serviceCategoryCreationDto: ServiceCategoryCreationDto) {
        const url = `v2/serviceCategories`;
        const eventType = ApiEventType.CREATE_SERVICE_CATEGORY;  
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.post<ServiceCategoryDto>(url, serviceCategoryCreationDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.serviceCategory$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    createServiceSubCategory(serviceSubCategoryCreationDto: ServiceSubCategoryCreationDto) {
        const url = `v2/serviceSubCategories`;
        const eventType = ApiEventType.CREATE_SUB_SERVICE_CATEGORY;  
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.post<ServiceSubCategoryDto>(url, serviceSubCategoryCreationDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: ServiceSubCategoryDto)=>{
            this.serviceSubCategory$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    updateServiceCategories(serviceCategories: Array<ServiceCategoryDto> = [], 
                            active: boolean = false) {
        const url = `v2/serviceCategories`;
        const eventType = ApiEventType.UPDATE_SERVICE_CATEGORIES;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        const ids = serviceCategories.map((v:ServiceCategoryDto)=>{
            return v.id
        });
        this.http.put(url, {
            ids: ids,
            active,
        })
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: true });
        })
    }

    updateServiceCategory(serviceCategoryId: number ,serviceCategoryCreationDto: ServiceCategoryCreationDto) {
        const url = `v2/serviceCategories/${serviceCategoryId}`;
        const eventType = ApiEventType.UPDATE_SERVICE_CATEGORY;  
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<ServiceCategoryDto>(url, serviceCategoryCreationDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.serviceCategory$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    updateServiceSubCategory(serviceSubCategoryId: number, serviceSubCategoryCreationDto: ServiceSubCategoryCreationDto) {
        const url = `v2/serviceSubCategories/${serviceSubCategoryId}`;
        const eventType = ApiEventType.UPDATE_SERVICE_SUB_CATEGORY;  
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.put<ServiceSubCategoryDto>(url, serviceSubCategoryCreationDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: ServiceSubCategoryDto)=>{
            this.serviceSubCategory$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;
         
          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }  

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
         
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });


          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }
}