<div class="grid grid-nogutter justify-content-center">
    <div class="col-12 p-0 m-0">
        <img [src]="avatarUri | avatarUriTransform" class="avatar-uri" 
        onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
    </div>
    <div class="col-2 text-center camera-col" *ngIf="!hideUploadButton">
        <i class="pi  pi-camera text-primary cursor-pointer" 
        style="font-size: 2rem" (click)="inputFile.click()" ></i>
        <input #inputFile id="input-file"  
        class="form-control" 
        style="display:none;" 
        type="file"
        (change)="onFileChange($event)"/>
    </div>
</div>
