import { HttpClient } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError, Observable } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { ContactDto, ContactResponseBody, PersonalInfoDto } from "../models/dto/contact.dto";
import { LocationAddressDto } from "../models/dto/location-address.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";

@Injectable({
    providedIn: 'root',
})
export class ContactService extends AbstractHttpHandler{

    private loggedInUser = new BehaviorSubject<ContactResponseBody>(undefined);

    contactResponse$ = new BehaviorSubject<ContactResponseBody>(undefined);
    contactUser$ = new BehaviorSubject<ContactDto>(undefined);

    constructor(
      public override http: HttpClient,
      @Inject(forwardRef(() => ApiEventService))
      public override apiEventsService: ApiEventService,
      private router: Router,
    ) {
      super(http, apiEventsService);
  }

      getContactById(contactId: number) {
      const url = `v2/contacts/${contactId}`;
      const eventType = ApiEventType.GET_CONTACT_BY_ID;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
      this.http.get<ContactResponseBody>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response=> {
          this.contactResponse$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });

    }

    upsertContactPersonal(contactId: number, personal: PersonalInfoDto) {
      const url = `v2/contacts/${contactId}`;
      const eventType = ApiEventType.UPSERT_CONTACT_PERSONAL_INFO;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
      this.http.put<ContactDto>(url, {
        personal: personal,
      })
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response=> {
          this.contactUser$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    upsertContactAddress(contactId: number, locationAddress: LocationAddressDto) {
      const url = `v2/contacts/${contactId}`;
      const eventType = ApiEventType.UPSERT_CONTACT_ADDRESS;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
      this.http.put<ContactDto>(url, {
        address: locationAddress
      })
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response=> {
          this.contactUser$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    get currentUser(): Observable<ContactResponseBody> {
      return this.loggedInUser.asObservable();
    }


    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }

            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });

          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
      if(error.status === 401) {
        this.clearLocalStorage();
        this.router.navigate(['']);
      }
    }


}
