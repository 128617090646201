<div  class="grid grid-nogutter" *ngIf="profileLocationDto; else noLocationTemplate">
    <div class="col-2">
        <p class="text-ssb">Name: </p>
    </div>
    <div class="col-10">    
        <p class="text-rb text-primary">{{profileLocationDto.addressLocation.formattedAddress}}</p>
    </div>
    <div class="col-2">
        <p class="text-ssb">Country: </p>
    </div>
    <div class="col-10">
        <p class="text-rb text-primary">{{profileLocationDto.addressLocation.country}}</p>
    </div>
    <div class="col-2">
        <p class="text-ssb">Area: </p>
    </div>
    <div class="col-10">
        <p class="text-rb text-primary">{{profileLocationDto.addressLocation.cityDto | translateBackend}}</p>

    </div>
    <div class="col-2">
        <p class="text-ssb">Building name: </p>
    </div>
    <div class="col-10">
        <p class="text-rb text-primary">{{profileLocationDto.addressLocation.buildingName}}</p>
    </div>
    <div class="col-2">
        <p class="text-ssb">Room number: </p>
    </div>
    <div class="col-10">
        <p class="text-rb text-primary">{{profileLocationDto.addressLocation.roomNumber}}</p>
    </div>
</div>

<ng-template #noLocationTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                            (click)="onClick.emit()"
                           class="primary" label="Location" 
                           icon="pi pi-plus" [disabled]="!enabledButton"></p-button>
            </div>
        </div>
    </div>
</ng-template>