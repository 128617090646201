import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CountryDto } from 'src/app/shared/models/dto/country.dto';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss']
})
export class CountryDropdownComponent implements OnInit {
  @Input() ngControl: AbstractControl;
  @Input() name: string;
  @Input() items: Array<CountryDto> = []
  @Input() isForm: boolean = true;

  @Output() onSelectedCountry = new EventEmitter<number>();



  countryId: number = null;
  constructor() { }

  ngOnInit(): void {
    if(this.isForm && this.ngControl.get(this.name).value) {
      this.countryId = Number(this.ngControl.get(this.name).value);
    }

  }



}
