import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { BehaviorSubject, catchError } from "rxjs";
import { IdVerificationDto, IdVerificationItemType, IdVerificationType } from "../models/dto/id-verification.dto";
import { convertBlobToString } from "./utils/io-utils";
import { RejectionReasonInputDto } from "../models/dto/rejection-reason.dto";

export interface IdVerificationImageItem {
    idVerificationItemType: IdVerificationItemType;
    imageString: string;
}

export class IdCard {
    

    constructor(public idVerificationType: IdVerificationType, public name: string) {}
}

@Injectable({
    providedIn: 'root',
})
export class IdVerificationService extends AbstractHttpHandler {

    idVerificationDto$ = new BehaviorSubject<IdVerificationDto>(null);
    idVerificationImageItem$ = new BehaviorSubject<IdVerificationImageItem>(undefined);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getIdCards(): Array<IdCard> {
        return [
            new IdCard(IdVerificationType.BASIC_RESIDENCE_REGISTRATION_CARD, 'Basis Residence Resignation Card'),
            new IdCard(IdVerificationType.COPY_OF_RESIDENCE_CARD, 'Copy of Residence Card'),
            new IdCard(IdVerificationType.DRIVERS_LICENSE, 'Driver License'),
            new IdCard(IdVerificationType.INSURANCE_CARD, 'Insurance Card'),
            new IdCard(IdVerificationType.MY_NUMBER_CARD, 'My Number Card'),
        ]
    }

    getIdVerification(contactId: number) {
        const url = `v2/idVerifications/${contactId}`;
        const eventType = ApiEventType.GET_ID_VERIFICATIONS;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.get<IdVerificationDto>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: IdVerificationDto)=>{
            this.idVerificationDto$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    getIdVerificationImage(contactId: number, idVerificationType: IdVerificationItemType){
        const url = `v2/images/contacts/${contactId}/idVerifications/current/${idVerificationType}/default`;
        const eventType = ApiEventType.GET_ID_VERIFICATIONS_BY_IDCARD_TYPE;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.get<Blob>(url, {responseType: 'blob' as 'json'})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
          const imageString = await convertBlobToString(response)
          this.idVerificationImageItem$.next({
            idVerificationItemType: idVerificationType,
            imageString: imageString
          })
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    upsertIdVerificationImage(contactId: number, 
                              idVerificationItemType: IdVerificationItemType, 
                              idVerificationType: IdVerificationType,
                              formData: FormData) {
        const url = `v2/images/contacts/${contactId}/idVerifications/current/${idVerificationItemType}/default`;
        const eventType = ApiEventType.UPSERT_ID_VERIFICATIONS_BY_IDCARD_TYPE;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
        let params = new HttpParams();

        if(idVerificationType) {
            params = params.append('idVerificationType', idVerificationType);
        }      

        this.http.put<Blob>(url,
            formData,
            {responseType: 'blob' as 'json', params: params},
        )
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
          const imageString = await convertBlobToString(response)
          
        //   this.idVerificationImageItem$.next({
        //     idVerificationItemType: idVerificationType,
        //     imageString: imageString
        //   })
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
        

    }

    upsertIdVerificationType(contactId: number, idVerificationType: IdVerificationType) {
        const url = `v2/idVerifications/${contactId}`;
        const eventType = ApiEventType.UPSERT_ID_VERIFICATION_TYPE;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        let params = new HttpParams();

        if(idVerificationType) {
            params = params.append('idVerificationType', idVerificationType);
        }        

        this.http.put<IdVerificationDto>(url, {}, {
            params: params
        })
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: IdVerificationDto)=>{
            this.idVerificationDto$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    approveIdVerification(contactId: number, idVerificationItemType: IdVerificationItemType){
        const url = `v2/contacts/${contactId}/idVerifications/current/items/${idVerificationItemType}/approvals`;
        const eventType = ApiEventType.UPDATE_VERIFICATIONS_BY_IDCARD_TYPE_APPROVE;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
        
        this.http.put(url, {})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            // this.adminPriceDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    rejectIdVerification(contactId: number, idVerificationItemType: IdVerificationItemType, 
                         rejectionReasonInputDto: RejectionReasonInputDto){
        const url = `v2/contacts/${contactId}/idVerifications/current/items/${idVerificationItemType}/rejections`;
        const eventType = ApiEventType.UPDATE_ID_VERIFICATIONS_BY_IDCARD_TYPE_REJECT;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
        
        this.http.put(url, rejectionReasonInputDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            // this.adminPriceDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    removeIdVerification(contactId: number, idVerificationType: IdVerificationItemType) {
        const url = `v2/images/contacts/${contactId}/idVerifications/current/${idVerificationType}/default`;
        const eventType = ApiEventType.UPDATE_ID_VERIFICATIONS_BY_IDCARD_TYPE_REMOVE;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
        this.http.delete(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response)=>{
            // this.adminPriceDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
        
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;
         
          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         console.log(eventType, error)
         if(!error.ok  && eventType === ApiEventType.GET_ID_VERIFICATIONS_BY_IDCARD_TYPE) {
            showDialog = false;
         }


         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
    
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }
}