<app-edit-dialog [visible]="showEditDialog" [title]="'Edit Location (REQUIRED)'"
                 (onSubmit)="saveLocation()"
                 [dialogHeight]="'25'"
                 (onHide)="onHide.emit()" [disabled]="!locationForm.valid">
  <div class="grid grid-nogutter mt-5 justify-content-center col-12">
    <ng-container [formGroup]="locationForm">
      <div id="location" class="grid grid-nogutter p-1">
        <div class="col-12 mb-3">
          <p class="text-rm"><i>Where will you be commuting from for this Job?</i></p>
        </div>
      </div>


      <div class="col-12 grid grid-nogutter flex align-items-center mt-1">
        <div class="col-12  p-1 pr-5" >
          <app-location-form #addLocationForm [addressLocation]="addressLocation"
                             (onLocationFormStatusChanges)="onLocationFormStatusChanges($event)">
          </app-location-form>
        </div>
        <ng-container *ngIf="locationForm.dirty && !locationFormStatusValid">
          <p class="text-velvet">Location is required!</p>
        </ng-container>

        <div class="col-12  p-1 pr-5">
          <p class="text-rm">Can you work from this location?</p>
          <div class="grid grid-nogutter w-100 flex justify-content-start align-items-center p-1">
            <p-checkbox  class="pr-2" formControlName="serviceLocation"
                         [binary]="true">
            </p-checkbox>
            <p class="p-0 text-rb">I can work from this location</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</app-edit-dialog>
