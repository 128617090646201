import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JobSkillExperienceDto} from '../../models/dto/opentimer-job-profile.dto';
import {SkillService} from "../../services/skill.service";
import {JobSkillDto} from "../../models/dto/skill.dto";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventStatus, ApiEventType} from "../../models/api-event";
import {ApiEventService} from "../../services/api-event.service";

@UntilDestroy()
@Component({
  selector: 'app-opentimer-job-skills-table',
  templateUrl: './opentimer-job-skills-table.component.html',
  styleUrls: ['./opentimer-job-skills-table.component.scss']
})
export class OpentimerJobSkillsTableComponent implements OnInit {
  @Input() jobSkillLevels: Array<JobSkillExperienceDto> = [];
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;
  @Output() onClick = new EventEmitter();

  readonly chipColor = SkillService.chipColor;
  skills: Array<JobSkillDto> =[];

  constructor(private skillService: SkillService,
              private apiEventService: ApiEventService) { }

  ngOnInit(): void {
    this.skillService.getSkills(null, -1, null, true);
    this.getApiEvents();
  }

  getSkill(skill: string): JobSkillDto {
    return this.skills.find(s => s.skill === skill);
  }

  protected getApiEvents(): void {
    if (this.apiEventService.event) {
      this.apiEventService.event
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          if (event && event.type === ApiEventType.GET_SKILLS && event.status === ApiEventStatus.COMPLETED) {
            const jobSkillSchema = this.skillService.jobSkillsSchema$.value;
            if (jobSkillSchema && jobSkillSchema.jobSkills) {
              this.skills = jobSkillSchema.jobSkills;
            }
          }
        });
    } else {
      console.error('ApiEventService event is null');
    }
  }
}
