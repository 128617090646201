<ng-container [formGroup]="ngControl | cast">
    <p-dropdown [options]="items" [formControlName]="name"
                [style]="{'width':'100%'}"  optionValue="value"
                [readonly]="readonly"
                placeholder="Select Gender" appendTo="body">
            <ng-template let-gender pTemplate="item">
                <div class="country-item flex align-items-center">
                    <div class="ml-2">{{gender.name}}</div>
                </div>
            </ng-template>

            <ng-template let-selectedItem  pTemplate="selectedItem">
                <div class="country-item flex align-items-center" *ngIf="selectedItem">
                    <div class="ml-2">{{selectedItem.name}}</div>
                </div>
            </ng-template>
    </p-dropdown>
</ng-container>
