import { Pipe, PipeTransform } from "@angular/core";
import { AccountType } from "../models/account";
import { CurrencyEnum } from "../models/dto/currency.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'accountTypeMap'
  })
  export class AccountTypeMapPipe implements PipeTransform {
  
    private readonly accountTypeMap = ReadonlyMapBuilder.create<AccountType, string>()
    .set(AccountType.AGENCY ,'Agency')
    .set(AccountType.BUSINESS_CUSTOMER ,'Business Customer')
    .set(AccountType.INDIVIDUAL_CUSTOMER ,'Individual Customer')
    .set(AccountType.OPENTIMER ,'Opentimer')
    .set(AccountType.INTERVIEWER ,'Interviewer')
    .build();

    transform(value: AccountType): string {
      return this.accountTypeMap.get(value);
    }
  
}