import {ContactDto, ContactInputDto} from "./contact.dto";
import { TranslationDto } from "./translation.dto";

export interface AdminSchema {
    admin: AdminDto;
}


export interface AdminsSchema {
    admins: Array<AdminDto>;
    total: number;
}

export interface AdminDto {
    id: number;
    contact: ContactDto;
    role: AdminRoleEnum;
    roles?: Array<AdminRole>;
  permissions?: Array<AdminPermissionEnum>;
    enabled: boolean;
}

export interface AdminSubmitDto {
    id: number;
    contact: ContactInputDto;
    role: AdminRoleEnum;
    roles?: Array<string>;
    enabled: boolean;
}

export interface AdminRole {
    role: string;
    translations: Array<TranslationDto>
}

export interface AdminRoleSchema {
    roles?: Array<AdminRole>;
    total: number;

}

export enum AdminRoleEnum {
    SUPER_ADMIN_ROLE = 'SUPER_ADMIN_ROLE',
    ACCOUNT_ADMIN_ROLE = 'ACCOUNT_ADMIN_ROLE',
    AGENCY_ADMIN_ROLE = 'AGENCY_ADMIN_ROLE',
    CUSTOMER_ADMIN_ROLE = 'CUSTOMER_ADMIN_ROLE',
    OPENTIMER_ADMIN_ROLE = 'OPENTIMER_ADMIN_ROLE',
    CONFIGURATION_ADMIN_ROLE = 'CONFIGURATION_ADMIN_ROLE',
    ACCOUNTANT_ADMIN_ROLE = 'ACCOUNTANT_ADMIN_ROLE',
    BOOKING_ADMIN_ROLE = 'BOOKING_ADMIN_ROLE',
    INTERVIEW_ADMIN_ROLE = 'INTERVIEW_ADMIN_ROLE',
    ADMIN = 'ADMIN'
}

export enum AdminPermissionEnum {
  ENABLE_AGENCY = 'ENABLE_AGENCY',
  DISABLE_AGENCY = 'DISABLE_AGENCY',
  REEVALUATE_AGENCY = 'REEVALUATE_AGENCY',
  APPROVE_AGENCY = 'APPROVE_AGENCY',
  ENABLE_CUSTOMER = 'ENABLE_CUSTOMER',
  DISABLE_CUSTOMER = 'DISABLE_CUSTOMER',
  REEVALUATE_CUSTOMER = 'REEVALUATE_CUSTOMER',
  APPROVE_CUSTOMER = 'APPROVE_CUSTOMER',
  ENABLE_OPENTIMER = 'ENABLE_OPENTIMER',
  DISABLE_OPENTIMER = 'DISABLE_OPENTIMER',
  REEVALUATE_OPENTIMER = 'REEVALUATE_OPENTIMER',
  APPROVE_OPENTIMER = 'APPROVE_OPENTIMER',
  REEVALUATE_OPENTIMER_PROFILE = 'REEVALUATE_OPENTIMER_PROFILE',
  APPROVE_OPENTIMER_PROFILE = 'APPROVE_OPENTIMER_PROFILE',
  DENY_AGENCY = 'DENY_AGENCY',
  DENY_CUSTOMER = 'DENY_CUSTOMER',
  DENY_OPENTIMER = 'DENY_OPENTIMER',
  DENY_OPENTIMER_PROFILE = 'DENY_OPENTIMER_PROFILE',
  ACTIVATE_OPENTIMER_PROFILES = 'ACTIVATE_OPENTIMER_PROFILES',
  DEACTIVATE_OPENTIMER_PROFILES = 'DEACTIVATE_OPENTIMER_PROFILES',
  CREATE_ACCOUNTS = 'CREATE_ACCOUNTS',
  CREATE_ADMINS = 'CREATE_ADMINS',
  UPDATE_ADMINS = 'UPDATE_ADMINS',
  READ_ADMINS = 'READ_ADMINS',
  READ_CURRENT_ADMINS = 'READ_CURRENT_ADMINS',
  READ_AGENCY = 'READ_AGENCY',
  LIST_AGENCIES = 'LIST_AGENCIES',
  READ_AGENCY_RECRUITERS = 'READ_AGENCY_RECRUITERS',
  READ_AGENCY_COMMISSIONS = 'READ_AGENCY_COMMISSIONS',
  UPDATE_AGENCY_NAMES = 'UPDATE_AGENCY_NAMES',
  UPDATE_AGENCY_INFORMATIONS = 'UPDATE_AGENCY_INFORMATIONS',
  UPDATE_AGENCY_PHONES_EMAILS = 'UPDATE_AGENCY_PHONES_EMAILS',
  UPDATE_AGENCY_ADDRESSES = 'UPDATE_AGENCY_ADDRESSES',
  UPDATE_AGENCY_COMMISSIONS = 'UPDATE_AGENCY_COMMISSIONS',
  READ_BOOKING_ORDER = 'READ_BOOKING_ORDER',
  LIST_BOOKING_ORDERS = 'LIST_BOOKING_ORDERS',
  READ_CUSTOMER_BOOKING_ORDERS  = 'READ_CUSTOMER_BOOKING_ORDERS',
  READ_AGENCY_BOOKING_ORDERS = 'READ_AGENCY_BOOKING_ORDERS',
  READ_OPENTIMER_BOOKING_ORDERS = 'READ_OPENTIMER_BOOKING_ORDERS',
  READ_OPENTIMER_BOOKING_CANCELLATION_REQUESTS = 'READ_OPENTIMER_BOOKING_CANCELLATION_REQUESTS',
  READ_CUSTOMER_BOOKING_CANCELLATION_REQUESTS = 'READ_CUSTOMER_BOOKING_CANCELLATION_REQUESTS',
  READ_BOOKING_CANCELLATION_REQUESTS = 'READ_BOOKING_CANCELLATION_REQUESTS',
  READ_BOOKING_ORDER_STATISTICS = 'READ_BOOKING_ORDER_STATISTICS',
  READ_BOOKING_ORDER_CUSTOMER_RATINGS = 'READ_BOOKING_ORDER_CUSTOMER_RATINGS',
  APPROVE_BOOKING_CANCELLATION_REQUESTS = 'APPROVE_BOOKING_CANCELLATION_REQUESTS',
  REJECT_BOOKING_CANCELLATION_REQUESTS = 'REJECT_BOOKING_CANCELLATION_REQUESTS',
  READ_CITIES = 'READ_CITIES',
  UPDATE_CITIES = 'UPDATE_CITIES',
  CREATE_CITIES = 'CREATE_CITIES',
  READ_CONTACTS = 'READ_CONTACTS',
  READ_CURRENT_CONTACTS = 'READ_CURRENT_CONTACTS',
  UPDATE_CONTACTS = 'UPDATE_CONTACTS',
  READ_COUNTRIES = 'READ_COUNTRIES',
  READ_COUNTRY_TIME_ZONES = 'READ_COUNTRY_TIME_ZONES',
  UPDATE_COUNTRY_TIME_ZONES = 'UPDATE_COUNTRY_TIME_ZONES',
  READ_CUSTOMER = 'READ_CUSTOMER',
  LIST_CUSTOMERS = 'LIST_CUSTOMERS',
  READ_CUSTOMER_RATINGS = 'READ_CUSTOMER_RATINGS',
  READ_CUSTOMER_WALLETS = 'READ_CUSTOMER_WALLETS',
  READ_CUSTOMER_INVOICES = 'READ_CUSTOMER_INVOICES',
  UPDATE_CUSTOMER_INVOICES = 'UPDATE_CUSTOMER_INVOICES',
  UPDATE_BUSINESS_CUSTOMER_DETAILS = 'UPDATE_BUSINESS_CUSTOMER_DETAILS',
  UPDATE_BUSINESS_CUSTOMER_COUNTRIES = 'UPDATE_BUSINESS_CUSTOMER_COUNTRIES',
  UPDATE_BUSINESS_CUSTOMER_ADDRESSES = 'UPDATE_BUSINESS_CUSTOMER_ADDRESSES',
  UPDATE_CUSTOMER_ADDRESSES = 'UPDATE_CUSTOMER_ADDRESSES',
  READ_HOLIDAYS = 'READ_HOLIDAYS',
  CREATE_HOLIDAYS = 'CREATE_HOLIDAYS',
  UPDATE_HOLIDAYS = 'UPDATE_HOLIDAYS',
  DELETE_HOLIDAYS = 'DELETE_HOLIDAYS',
  READ_ID_VERIFICATIONS = 'READ_ID_VERIFICATIONS',
  READ_ID_VERIFICATION_ITEMS = 'READ_ID_VERIFICATION_ITEMS',
  UPDATE_ID_VERIFICATIONS = 'UPDATE_ID_VERIFICATIONS',
  UPDATE_ID_VERIFICATION_ITEMS = 'UPDATE_ID_VERIFICATION_ITEMS',
  DELETE_ID_VERIFICATION_ITEMS = 'DELETE_ID_VERIFICATION_ITEMS',
  READ_ACCOUNT_AVATARS = 'READ_ACCOUNT_AVATARS',
  UPDATE_ACCOUNT_AVATARS = 'UPDATE_ACCOUNT_AVATARS',
  UPDATE_CONTACT_AVATARS = 'UPDATE_CONTACT_AVATARS',
  UPDATE_JOB_CATEGORY_AVATARS = 'UPDATE_JOB_CATEGORY_AVATARS',
  READ_INTERVIEW = 'READ_INTERVIEW',
  LIST_INTERVIEWS = 'LIST_INTERVIEWS',
  READ_INTERVIEW_VIDEOS = 'READ_INTERVIEW_VIDEOS',
  UPDATE_INTERVIEW_CANCELLATIONS = 'UPDATE_INTERVIEW_CANCELLATIONS',
  READ_OPENTIMER_PROFILE = 'READ_OPENTIMER_PROFILE',
  LIST_OPENTIMER_PROFILES = 'LIST_OPENTIMER_PROFILES',
  READ_PROFICIENCY_LANGUAGES = 'READ_PROFICIENCY_LANGUAGES',
  UPDATE_PROFICIENCY_LANGUAGES = 'UPDATE_PROFICIENCY_LANGUAGES',
  CREATE_OPENTIMER_PROFILE_CATEGORIES = 'CREATE_OPENTIMER_PROFILE_CATEGORIES',
  UPDATE_OPENTIMER_PROFILE_CATEGORIES = 'UPDATE_OPENTIMER_PROFILE_CATEGORIES',
  READ_OPENTIMER_PROFILE_CATEGORIES = 'READ_OPENTIMER_PROFILE_CATEGORIES',
  UPDATE_OPENTIMER_PROFILE_RATES = 'UPDATE_OPENTIMER_PROFILE_RATES',
  UPDATE_OPENTIMER_PROFILE_SKILLS = 'UPDATE_OPENTIMER_PROFILE_SKILLS',
  UPDATE_OPENTIMER_PROFILE_EDUCATIONS = 'UPDATE_OPENTIMER_PROFILE_EDUCATIONS',
  UPDATE_OPENTIMER_PROFILE_EXPERIENCES = 'UPDATE_OPENTIMER_PROFILE_EXPERIENCES',
  UPDATE_OPENTIMER_PROFILE_CERTIFICATIONS = 'UPDATE_OPENTIMER_PROFILE_CERTIFICATIONS',
  UPDATE_OPENTIMER_PROFILE_COVER_LETTERS = 'UPDATE_OPENTIMER_PROFILE_COVER_LETTERS',
  UPDATE_OPENTIMER_PROFILE_BANK_ACCOUNTS = 'UPDATE_OPENTIMER_PROFILE_BANK_ACCOUNTS',
  UPDATE_OPENTIMER_PROFILE_ADDRESSES = 'UPDATE_OPENTIMER_PROFILE_ADDRESSES',
  READ_OPENTIMER_PROFILE_ADDRESSES = 'READ_OPENTIMER_PROFILE_ADDRESSES',
  CREATE_OPENTIMER_PROFILE_AVAILABILITIES = 'CREATE_OPENTIMER_PROFILE_AVAILABILITIES',
  CREATE_OPENTIMER_PROFILE_TRAVEL_RESTRICTIONS = 'CREATE_OPENTIMER_PROFILE_TRAVEL_RESTRICTIONS',
  UPDATE_OPENTIMER_PROFILE_AVATARS = 'UPDATE_OPENTIMER_PROFILE_AVATARS',
  CREATE_PROFILE_SEARCHES = 'CREATE_PROFILE_SEARCHES',
  READ_OPENTIMER = 'READ_OPENTIMER',
  LIST_OPENTIMERS = 'LIST_OPENTIMERS',
  READ_OPENTIMER_LANGUAGES = 'READ_OPENTIMER_LANGUAGES',
  READ_OPENTIMER_RECRUITERS = 'READ_OPENTIMER_RECRUITERS',
  READ_OPENTIMER_SUMMARIES = 'READ_OPENTIMER_SUMMARIES',
  LIST_WORKING_TIMES = 'LIST_WORKING_TIMES',
  READ_WORKING_TIME = 'READ_WORKING_TIME',
  UPDATE_WORKING_TIMES = 'UPDATE_WORKING_TIMES',
  UPDATE_OPENTIMER_BANK_ACCOUNTS = 'UPDATE_OPENTIMER_BANK_ACCOUNTS',
  UPDATE_OPENTIMERS = 'UPDATE_OPENTIMERS',
  READ_OVERTIMES = 'READ_OVERTIMES',
  UPDATE_OVERTIMES = 'UPDATE_OVERTIMES',
  READ_PRICES = 'READ_PRICES',
  UPDATE_PRICES = 'UPDATE_PRICES',
  READ_REJECTION_REASONS = 'READ_REJECTION_REASONS',
  READ_AGENCY_REPORTED_PROBLEMS = 'READ_AGENCY_REPORTED_PROBLEMS',
  READ_CUSTOMER_REPORTED_PROBLEMS = 'READ_CUSTOMER_REPORTED_PROBLEMS',
  READ_OPENTIMER_REPORTED_PROBLEMS = 'READ_OPENTIMER_REPORTED_PROBLEMS',
  READ_CATEGORIES = 'READ_CATEGORIES',
  READ_SUB_CATEGORIES = 'READ_SUB_CATEGORIES',
  CREATE_CATEGORIES = 'CREATE_CATEGORIES',
  CREATE_SUB_CATEGORIES = 'CREATE_SUB_CATEGORIES',
  UPDATE_CATEGORIES = 'UPDATE_CATEGORIES',
  UPDATE_SUB_CATEGORIES = 'UPDATE_SUB_CATEGORIES',
  READ_CUSTOMER_SHIFTS = 'READ_CUSTOMER_SHIFTS',
  LIST_PRESET_SHIFTS = 'LIST_PRESET_SHIFTS',
  READ_PRESET_SHIFT = 'READ_PRESET_SHIFT',
  CREATE_PRESET_SHIFTS = 'CREATE_PRESET_SHIFTS',
  UPDATE_PRESET_SHIFT_NAMES = 'UPDATE_PRESET_SHIFT_NAMES',
  ACTIVATE_PRESET_SHIFTS = 'ACTIVATE_PRESET_SHIFTS',
  READ_SKILLS = 'READ_SKILLS',
  CREATE_SKILLS = 'CREATE_SKILLS',
  UPDATE_SKILLS = 'UPDATE_SKILLS',
  READ_TAX_RATES = 'READ_TAX_RATES',
  CREATE_TAX_RATES = 'CREATE_TAX_RATES',
  READ_ACCOUNTS = 'READ_ACCOUNTS',
  READ_WALLETS = 'READ_WALLETS',
  UPDATE_AGENCY_COUNTRIES = 'UPDATE_AGENCY_COUNTRIES',
  UPDATE_AGENCY_DESCRIPTIONS = 'UPDATE_AGENCY_DESCRIPTIONS',
  READ_CUSTOMER_ADDRESSES = 'READ_CUSTOMER_ADDRESSES',
  UPDATE_CUSTOMER_PHONES_EMAILS = 'UPDATE_CUSTOMER_PHONES_EMAILS',
  UPDATE_ACCOUNT_PHONES_EMAILS = 'UPDATE_ACCOUNT_PHONES_EMAILS',
  READ_RECRUITERS = 'READ_RECRUITERS',
  UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS',
  READ_OPENTIMER_UNAVAILABILITY = 'READ_OPENTIMER_UNAVAILABILITY',
  UPDATE_COUNTRY_CURRENCIES = 'UPDATE_COUNTRY_CURRENCIES',
  READ_AGENTS_TIME_OFF_REQUESTS = 'READ_AGENTS_TIME_OFF_REQUESTS',
  CREATE_INTERVIEWS = 'CREATE_INTERVIEWS',
  READ_AGENCY_INTERVIEWS = 'READ_AGENCY_INTERVIEWS',
  READ_CUSTOMER_INTERVIEWS = 'READ_CUSTOMER_INTERVIEWS',
  READ_OPENTIMER_INTERVIEWS = 'READ_OPENTIMER_INTERVIEWS',
  CREATE_AGENCIES = 'CREATE_AGENCIES',
  CREATE_CUSTOMERS = 'CREATE_CUSTOMERS',
  CREATE_INTERVIEWERS = 'CREATE_INTERVIEWERS',
  CREATE_OPENTIMERS = 'CREATE_OPENTIMERS',
  CREATE_OPENTIMER_PROFILES = 'CREATE_OPENTIMER_PROFILES',
  CREATE_OVERTIMES = 'CREATE_OVERTIMES',
  CREATE_BOOKING_ORDERS = 'CREATE_BOOKING_ORDERS',
  READ_ADMIN_REPORTED_PROBLEMS = 'READ_ADMIN_REPORTED_PROBLEMS',
  CREATE_ADMIN_REPORTED_PROBLEMS = 'CREATE_ADMIN_REPORTED_PROBLEMS',
  UPDATE_AGENCY_REPORTED_PROBLEMS = 'UPDATE_AGENCY_REPORTED_PROBLEMS',
  UPDATE_CUSTOMER_REPORTED_PROBLEMS = 'UPDATE_CUSTOMER_REPORTED_PROBLEMS',
  UPDATE_OPENTIMER_REPORTED_PROBLEMS = 'UPDATE_OPENTIMER_REPORTED_PROBLEMS',
  UPDATE_ADMIN_REPORTED_PROBLEMS = 'UPDATE_ADMIN_REPORTED_PROBLEMS',
  UPDATE_TAX_RATES = 'UPDATE_TAX_RATES',
  READ_AGENCY_OPENTIMERS = 'READ_AGENCY_OPENTIMERS',
  READ_OPENTIMER_PROFILE_RATES = 'READ_OPENTIMER_PROFILE_RATES',
  READ_OPENTIMER_PROFILE_SKILLS = 'READ_OPENTIMER_PROFILE_SKILLS',
  READ_OPENTIMER_PROFILE_EDUCATIONS = 'READ_OPENTIMER_PROFILE_EDUCATIONS',
  READ_OPENTIMER_PROFILE_EXPERIENCES = 'READ_OPENTIMER_PROFILE_EXPERIENCES',
  READ_OPENTIMER_PROFILE_CERTIFICATIONS = 'READ_OPENTIMER_PROFILE_CERTIFICATIONS',
  READ_OPENTIMER_PROFILE_COVER_LETTERS = 'READ_OPENTIMER_PROFILE_COVER_LETTERS',
  READ_OPENTIMER_PROFILE_AVAILABILITIES = 'READ_OPENTIMER_PROFILE_AVAILABILITIES',
  READ_OPENTIMER_PROFILE_TRAVEL_RESTRICTIONS = 'READ_OPENTIMER_PROFILE_TRAVEL_RESTRICTIONS',
}

export const AddPermissions: AdminPermissionEnum[] = [AdminPermissionEnum.CREATE_CUSTOMERS,
  AdminPermissionEnum.CREATE_OVERTIMES,
  AdminPermissionEnum.CREATE_CATEGORIES,
  AdminPermissionEnum.CREATE_SUB_CATEGORIES,
  AdminPermissionEnum.CREATE_SKILLS,
  AdminPermissionEnum.CREATE_AGENCIES,
  AdminPermissionEnum.CREATE_OPENTIMERS,
  AdminPermissionEnum.CREATE_ADMINS,
  AdminPermissionEnum.CREATE_HOLIDAYS,
  AdminPermissionEnum.CREATE_OPENTIMER_PROFILES];


export const CategoriesPermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_CATEGORIES,
  AdminPermissionEnum.CREATE_CATEGORIES,
  AdminPermissionEnum.UPDATE_CATEGORIES];

export const SubCategoriesPermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_SUB_CATEGORIES,
  AdminPermissionEnum.CREATE_SUB_CATEGORIES,
  AdminPermissionEnum.UPDATE_SUB_CATEGORIES];

export const SkillPermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_SKILLS,
  AdminPermissionEnum.CREATE_SKILLS,
  AdminPermissionEnum.UPDATE_SKILLS];

export const CategoriesSkillsPermissions: AdminPermissionEnum[] = [...CategoriesPermissions,
  ...SkillPermissions,
  ...SubCategoriesPermissions];

export const CustomerApprovalPermissions: AdminPermissionEnum[] = [AdminPermissionEnum.REEVALUATE_CUSTOMER,
  AdminPermissionEnum.APPROVE_CUSTOMER,
  AdminPermissionEnum.DENY_CUSTOMER]

export const AgencyApprovalPermissions: AdminPermissionEnum[] = [AdminPermissionEnum.REEVALUATE_AGENCY,
  AdminPermissionEnum.APPROVE_AGENCY,
  AdminPermissionEnum.DENY_AGENCY]

export const OpentimerApprovalPermissions: AdminPermissionEnum[] = [
  AdminPermissionEnum.DENY_OPENTIMER,
  AdminPermissionEnum.REEVALUATE_OPENTIMER,
  AdminPermissionEnum.APPROVE_OPENTIMER]

export const ApprovalPermissions: AdminPermissionEnum[] = [...CustomerApprovalPermissions,
  ...AgencyApprovalPermissions,
  ...OpentimerApprovalPermissions];

export const AdminCasePermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_ADMIN_REPORTED_PROBLEMS,
  AdminPermissionEnum.CREATE_ADMIN_REPORTED_PROBLEMS]

export const AgencyCasePermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_AGENCY_REPORTED_PROBLEMS,
  AdminPermissionEnum.UPDATE_AGENCY_REPORTED_PROBLEMS]

export const CustomerCasePermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_CUSTOMER_REPORTED_PROBLEMS,
  AdminPermissionEnum.UPDATE_CUSTOMER_REPORTED_PROBLEMS]

export const OpentimerCasePermissions: AdminPermissionEnum[] = [AdminPermissionEnum.READ_OPENTIMER_REPORTED_PROBLEMS,
  AdminPermissionEnum.UPDATE_OPENTIMER_REPORTED_PROBLEMS]

export const CasePermissions: AdminPermissionEnum[] = [...AdminCasePermissions,
  ...AgencyCasePermissions,
  ...CustomerCasePermissions,
  ...OpentimerCasePermissions]
