import {Pipe, PipeTransform} from "@angular/core";
import {CustomerFeedBackCategoryEnum} from "../models/dto/customer-rating.dto";
import {ReadonlyMapBuilder} from "../readyonly-map-builder";


@Pipe({
    name: 'customerFeedBackCategoryTitlePipe'
})
export class CustomerFeedBackCategoryTitlePipe implements PipeTransform {

    private readonly idVerificationTypeTitleMap = ReadonlyMapBuilder.create<CustomerFeedBackCategoryEnum, string>()
    .set(CustomerFeedBackCategoryEnum.CAREER_OPPORTUNITIES,'Career Opportunities')
    .set(CustomerFeedBackCategoryEnum.COMP_AND_BENEFITS,'Benefits')
    .set(CustomerFeedBackCategoryEnum.CULTURE_AND_VALUES,`Culture and Values`)
    .set(CustomerFeedBackCategoryEnum.QUALITY_OF_WORK,`Quality of Work`)
    .set(CustomerFeedBackCategoryEnum.SENIOR_MANAGEMENT,`Senior Management`)
    .set(CustomerFeedBackCategoryEnum.WORK_LIFE_BALANCE,`Work Life Balance`)
    .build();

    transform(value: CustomerFeedBackCategoryEnum): string {
      return this.idVerificationTypeTitleMap.get(value);
    }

}
