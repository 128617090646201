import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDateDuration]'
})
export class DateDurationDirective implements OnChanges {
  @Input() startDate: string;
  @Input() endDate: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    if (this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);

      const duration = this.calculateDuration(start, end);
      this.renderer.setProperty(this.el.nativeElement, 'innerText', duration);
    }
  }

  private calculateDuration(start: Date, end: Date): string {
    const daysDiff = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
    const weeks = Math.floor(daysDiff / 7);
    let remainingDays = daysDiff % 7;
    let months = Math.floor(weeks / 4);
    let remainingWeeks = weeks % 4;

    if (remainingDays > 0 && weeks < 4) {
      remainingWeeks++;
      remainingDays = 0;
    }
    if (weeks < 4) {
      return `${remainingWeeks} week(s)`;
    } else {
      return `${months} month(s) and ${remainingWeeks} week(s)`;
    }
  }
}