import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CONST } from 'src/app/shared/constant';
import { OpentimeFormatDate } from 'src/app/shared/date-format';
import { ContactDto, PersonalInfoDto } from 'src/app/shared/models/dto/contact.dto';
import { ContactService } from 'src/app/shared/services/contact.service';

@Component({
  selector: 'app-edit-user-infomation',
  templateUrl: './edit-user-infomation.component.html',
  styleUrls: ['./edit-user-infomation.component.scss']
})
export class EditUserInfomationComponent implements OnInit {
  @Input() contact: ContactDto;
  @Input() showPersonalDialog: boolean;

  @Output() onHide = new EventEmitter();

  contactForm = this.formBuilder.group({
    firstName: this.formBuilder.control('', [Validators.required]),
    lastName: this.formBuilder.control('', [Validators.required]),
    nativeFirstName: this.formBuilder.control('', [Validators.required]),
    nativeLastName: this.formBuilder.control('', [Validators.required]),
    email: this.formBuilder.control('', [Validators.required, Validators.pattern(CONST.EMAIL_REGEX)]),
    dateOfBirth: this.formBuilder.control(undefined, [Validators.required]),
    gender: this.formBuilder.control('', [Validators.required]),
  });

  invalidDates: Array<Date> = OpentimeFormatDate.getDaysArray(new Date());
  maxDate = new Date();

  constructor(private formBuilder: FormBuilder,
              private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.contactForm.patchValue({
      ...this.contact,
      email: this.contact.signInEmail,
      dateOfBirth: this.contact.dateOfBirth ? new Date(this.contact.dateOfBirth) : null
    });
  }

  onSaveUserInformation() {
    const contact:PersonalInfoDto = this.contactForm.value as PersonalInfoDto;
    this.contactService.upsertContactPersonal(this.contact.id, contact);
  }

  protected readonly CONST = CONST;
}
