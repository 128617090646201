import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';  

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  @Input() displaySearchInput: boolean = true;
  @Input() displayAdvancedButton: boolean = true;
  @Input() searchPlaceHolder: string = 'Search';
  @Output() onKeyPress = new EventEmitter<string>();
  @Output() onDisplayAdvanceSearch = new EventEmitter<boolean>();

  @ViewChild('searchInput') searchInput: ElementRef;

  displayAdvanceSearch: boolean = false;
  constructor() { 
    this.onKeypressEvent = _.debounce(this.onKeypressEvent, 500)
    this.clear = _.debounce(this.clear, 200)
  }

  ngOnInit(): void {
  }

  onKeypressEvent(ev) {
    this.onKeyPress.emit(ev.target.value);
  }

  clear() {
    this.searchInput.nativeElement.value = '';
    this.onKeyPress.emit(null);
  }

  onDisplayingAdvanceSearch() {
    this.displayAdvanceSearch = !this.displayAdvanceSearch;
    this.onDisplayAdvanceSearch.emit(this.displayAdvanceSearch);
  }

  get advanceSearchlabel(): string {
    return this.displayAdvanceSearch ? 'CLOSE' : 'ADVANCED'
  }

}
