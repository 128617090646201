import {Injectable} from '@angular/core';
import SHA3 from 'sha3';

@Injectable({
  providedIn: 'root',
})
export class EncodingService {
  private sha3 = new SHA3(512);

  encodePassword(inputText: string): string {
    this.sha3.update(inputText);
    const encoded = this.sha3.digest('hex');
    this.sha3.reset();
    return encoded;
  }
}