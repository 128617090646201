import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CONST } from 'src/app/shared/constant';
import { CityDto, CityInputDto } from 'src/app/shared/models/dto/city.dto';
import { CountryDto } from 'src/app/shared/models/dto/country.dto';
import { LanguageEnum } from 'src/app/shared/models/dto/language.dto';
import { CityService } from 'src/app/shared/services/city-service';
import { CountryService } from 'src/app/shared/services/country.service';

@UntilDestroy()
@Component({
  selector: 'app-upsert-city-dialog',
  templateUrl: './upsert-city-dialog.component.html',
  styleUrls: ['./upsert-city-dialog.component.scss']
})
export class UpsertCityDialogComponent implements OnInit {
  @Input() showCityDialog: boolean;
  @Input() city: CityDto;

  @Output() onHide = new EventEmitter();

  cityForm = this.formBuilder.group({
    avatarFile: this.formBuilder.control(''),
    countryId: this.formBuilder.control('', [Validators.required]),
    active: this.formBuilder.control(false, [Validators.required]),
    displayed: this.formBuilder.control(false, [Validators.required]),
    cityName: this.formBuilder.control('', [Validators.required]),
    cityNativeName: this.formBuilder.control('', [Validators.required]),
  });

  countries: Array<CountryDto> = [];
  avatarUri: string = null;
  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService,
              private cityService: CityService) { }

  ngOnInit(): void {
    if(this.city) {
      const englishName = this.city.translations.find(v=>v.language.toUpperCase() === LanguageEnum.EN);
      const katakanaName = this.city.translations.find(v=>v.language.toUpperCase() === LanguageEnum.JA);
      this.cityForm.patchValue({
        countryId: this.city.country.id.toString(),
        active: this.city.active,
        displayed: this.city.displayed,
        cityName: englishName? englishName.content : '',
        cityNativeName: katakanaName? katakanaName.content : ''
      });
      this.avatarUri = this.city.avatarUri;
    }
    this.getSubcribers();
  }

  getSubcribers() {
    this.countryService.countries$
    .pipe(untilDestroyed(this))
    .subscribe(response=>{
      this.countries = response;
    });

    this.cityForm.controls['countryId'].valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(countryId=>{
      if(countryId) {
        console.log(countryId);
      }
    })
  }

  onSubmitCity() {
    const cityFormValue = this.cityForm.value;

    const country = this.countries.find(v=> v.id === Number(cityFormValue.countryId));
    const cityInputDto: CityInputDto = {
      active: cityFormValue.active,
      countryCode: country.code,
      displayed: cityFormValue.displayed,
      name: cityFormValue.cityName,
      translations: [
        {
          language: LanguageEnum.EN.toLowerCase(),
          content: cityFormValue.cityName
        },
        {
          language: LanguageEnum.JA.toLowerCase(),
          content: cityFormValue.cityNativeName
        }
      ]
    }
    if(this.city) {
      this.cityService.updateCity(this.city.id,cityInputDto)
    } else {
      this.cityService.createCity(cityInputDto);
    }
  }
}
