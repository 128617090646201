import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { PreAuthGuard } from "./shared/guards/pre-auth.guard";

const routes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'signin'
    },
    {
      path: 'signin',
      component: HomeComponent,
      canActivate: [PreAuthGuard]
    },
    {
      path: 'forgot-password',
      component: HomeComponent,
      canActivate: [PreAuthGuard]
    },
    {
      path: 'admin-portal',
      loadChildren: () => import('./admin-portal/admin-portal.module').then(m => m.AdminPortalModule)
    },
    {
      path: 'forbidden',
      loadChildren: () => import('./forbidden/forbidden.module').then(m => m.ForbiddenModule)
    },
    // {
    //   path: 'not-found',
    //   component: NotFoundComponent
    // },
    // {
    //   path: '**',
    //   redirectTo: 'not-found'
    // }
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }