import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OpentimerTravelRestrictionResponseDto } from '../../models/dto/opentimer-job-profile.dto';

@Component({
  selector: 'app-opentimer-travel-restrictions',
  templateUrl: './opentimer-travel-restrictions.component.html',
  styleUrls: ['./opentimer-travel-restrictions.component.scss']
})
export class OpentimerTravelRestrictionsComponent implements OnInit {
  @Input() travelRestriction: OpentimerTravelRestrictionResponseDto;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;
  
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
