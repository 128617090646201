import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookOpentimerProps, BookOpentimerPropsService } from '../../services/book-opentimer-props.service';

export class ServiceLength {
  isSelectedWeek: boolean = false;
  isSelectedMonth: boolean = false;
  numberOfWeeks: number = 0;
  numberOfMonths: number = 0;
  nextDate: Date;

  constructor(public selectedDate: Date = null) {
    if(!this.selectedDate) {
      this.selectedDate = new Date();
    }
    this.calculateDate();
  }

  updateSelectedDate(selectedDate: Date) {
    this.selectedDate = selectedDate;
    this.calculateDate();
  }

  calculateDate() {
    const totalNumberOfDays = this.convertWeeksToDays() + this.convertMonthsToDays();
    this.nextDate = new Date(this.selectedDate.getTime() + totalNumberOfDays * 24 * 60 * 60 * 1000);
  }

  public calculateTotalNumberOfWeeks():number {
    return (this.convertWeeksToDays() + this.convertMonthsToDays()) > 0 ? 
          Math.floor(((this.convertWeeksToDays() + this.convertMonthsToDays()) / 7)) : 0;
  }

  private convertWeeksToDays(): number {
    return this.isSelectedWeek ? this.numberOfWeeks * 7: 0;
  }

  private convertMonthsToDays(): number {
    const currentYear = new Date().getFullYear();
    const numberOfMonths = this.isSelectedMonth ? this.numberOfMonths: 0;
    let numOfDays = 0;

    for (let i = 0; i < numberOfMonths; i++) {
      const month = (new Date(currentYear, i + 1, 0)).getDate();
      numOfDays += month;
    }

    return numOfDays;
  }

}

@UntilDestroy()
@Component({
  selector: 'app-opentimer-service-length',
  templateUrl: './opentimer-service-length.component.html',
  styleUrls: ['./opentimer-service-length.component.scss']
})
export class OpentimerServiceLengthComponent implements OnInit, OnChanges, DoCheck {
  @Input() selectedDate: Date = new Date();

  serviceLength: ServiceLength = new ServiceLength(this.selectedDate);
  constructor(private bookOpentimerPropsService: BookOpentimerPropsService) { }

  ngDoCheck(): void {
    // console.log(this.serviceLength);
    this.serviceLength.calculateDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.serviceLength.updateSelectedDate(new Date(changes['selectedDate'].currentValue));
  }

  ngOnInit(): void {
    this.bookOpentimerPropsService.bookOpentimerProps$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      if(response && response.serviceLength) {
        this.serviceLength = new ServiceLength(this.selectedDate);
        this.serviceLength.isSelectedMonth = response.serviceLength.isSelectedMonth;
        this.serviceLength.isSelectedWeek = response.serviceLength.isSelectedWeek;
        this.serviceLength.numberOfWeeks = response.serviceLength.numberOfWeeks;
        this.serviceLength.numberOfMonths = response.serviceLength.numberOfMonths;
        this.serviceLength.calculateDate();
      }
    })
  }

}
