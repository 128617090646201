<div class="grid grid-nogutter">
    <div class="col-10">
        <p class="title">{{title}}</p>
    </div>
    <div class="col-2 flex justify-content-end">
        <!-- <i class="pi  text-primary cursor-pointer" [class]="icon" (click)="onClick.emit()"
            style="font-size: 1rem" *ngIf="showEditButton" [pTooltip]="toolTip"></i> -->
            <button *ngIf="showEditButton" pButton pRipple type="button" [icon]="icon"
                    class="p-button-rounded p-button-text" (click)="onClick.emit()"
                    [pTooltip]="toolTip" [disabled]="disableEditButton">
            </button>
    </div>
    <div class="col-12 p-0 m-0">
        <ng-content></ng-content>
    </div>
</div>