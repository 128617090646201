<div class="grid grid-nogutter w-100">
    <div class="col-4" *ngIf="!displayAdvancedButton"></div>
    <div class="col-8" >
        <div class="p-inputgroup" *ngIf="!displayAdvanceSearch">
            <ng-container *ngIf="displaySearchInput">
                <input  #searchInput
                        type="text" pInputText [placeholder]="searchPlaceHolder"  
                        style="width: 100%" (input)="onKeypressEvent($event)"
                        class="search-box">   

                <ng-container *ngIf="!searchInput.value; else buttonTemplate">
                    <button type="button" pButton pRipple icon="pi pi-search" class="button-search"></button>      
                </ng-container>

                <ng-template #buttonTemplate>
                    <button type="button" pButton pRipple icon="pi pi-times" (click)="clear()"
                            class="gray button-search"></button>      
                </ng-template>
            </ng-container>
        </div>
    </div>
    <div class="col-4 text-right pr-3" *ngIf="displayAdvancedButton">
        <p-button  
                   [label]="advanceSearchlabel" 
                   styleClass="p-button-link"
                   (click)="onDisplayingAdvanceSearch()"></p-button>
    </div>
</div>