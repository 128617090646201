import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobSkillExperienceDto } from '../../models/dto/opentimer-job-profile.dto';
import { OpentimerProfileSearchResultDto } from '../../models/dto/opentimer-profile-search-result.dto';
import { SkillService } from '../../services/skill.service';

@Component({
  selector: 'app-opentimer-search-result-item',
  templateUrl: './opentimer-search-result-item.component.html',
  styleUrls: ['./opentimer-search-result-item.component.scss']
})
export class OpentimerSearchResultItemComponent implements OnInit {
  @Input() opentimerProfileSearchResult: OpentimerProfileSearchResultDto;
  @Output() onClick = new EventEmitter<void>();
  
  readonly chipColor = SkillService.chipColor;
  constructor() { }

  ngOnInit(): void {
  }


  get jobSkillExperienceLevels(): Array<JobSkillExperienceDto> {
    const jobSkillExperienceLevels  = this.opentimerProfileSearchResult.jobSkillSchema.jobSkillExperienceLevels;
    return jobSkillExperienceLevels.length > 2 ? jobSkillExperienceLevels.splice(0,1) : jobSkillExperienceLevels 
  }
}
