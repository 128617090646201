import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {BehaviorSubject, catchError} from "rxjs";
import {Messages} from "../messages";
import {ApiEventStatus, ApiEventType} from "../models/api-event";
import {AbstractHttpHandler} from "./abstract-http-handler.service";
import {ApiEventService} from "./api-event.service";
import {AccountStatus} from "../models/dto/account.dto";
import {AccountApproveItem, AccountEnablementItem, AccountEnablementStatus} from "../models/account-approve";
import {ReadonlyMapBuilder} from "../readyonly-map-builder";
import {MoneyDto} from "../models/dto/money.dto";

@Injectable({
    providedIn: 'root',
})
export class AccountApproveService extends AbstractHttpHandler {

    accountApproveItem$ = new BehaviorSubject<AccountApproveItem>(undefined);
    accountEnablementItem$ = new BehaviorSubject<AccountEnablementItem>(undefined);


    static readonly activaDeactivateteButtonLabelMap = ReadonlyMapBuilder.create<boolean, string>()
    .set(true, 'Deactivate Account')
    .set(false, 'Activate Account')
    .build();

    static readonly accountEnablementStatusMap = ReadonlyMapBuilder.create<boolean, string>()
        .set(true, 'intermediate-level')
        .set(false, 'master-level')
        .build();

    static readonly registrationStatusColorMap = ReadonlyMapBuilder.create<AccountStatus, string>()
        .set(AccountStatus.ACTIVE, 'intermediate-level')
        .set(AccountStatus.INACTIVE, 'advanced-level')
        .set(AccountStatus.IN_REVIEW, 'expert-level')
        .set(AccountStatus.NOT_VERIFIED, 'master-level')
        .set(AccountStatus.PENDING, 'advanced-level')
        .set(AccountStatus.VERIFIED, 'intermediate-level')
        .build();

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router,
      ) {
        super(http, apiEventsService);
    }

    accountEnablement(accountId: number) {
      const url = `v2/accounts/${accountId}/enablements`
      const eventType = ApiEventType.UPDATE_ACCOUNT_ENABLE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

      this.http.put(url, {})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.accountEnablementItem$.next({
           accountId: accountId,
           status: AccountEnablementStatus.ENABLED
          });
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    accountDisablement(accountId: number) {
      const url = `v2/accounts/${accountId}/disablements`
      const eventType = ApiEventType.UPDATE_ACCOUNT_DISABLE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

      this.http.put(url, {})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.accountEnablementItem$.next({
           accountId: accountId,
           status: AccountEnablementStatus.DISABLED
          });
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }


    reEvaluateAccount(accountId: number, creditBalance: MoneyDto = null) {
      const url = `v2/accounts/${accountId}/reevaluations`
      const eventType = ApiEventType.UPDATE_ACCOUNT_REEVALUATION;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

      let params = new HttpParams();

      this.http.put(url, creditBalance || {}, {params},)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.accountApproveItem$.next({
           accountId: accountId,
           status: AccountStatus.IN_REVIEW
          });
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
   }

    accountApprove(accountId: number, creditBalance: MoneyDto = null) {
       const url = `v2/accounts/${accountId}/approvals`
       const eventType = ApiEventType.UPDATE_ACCOUNT_APPROVAL;
       this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

       let params = new HttpParams();

       this.http.put(url, creditBalance || {}, {params},)
       .pipe(catchError(this.handleErrors(eventType, [])))
       .subscribe(()=> {
           this.accountApproveItem$.next({
            accountId: accountId,
            status: AccountStatus.VERIFIED
           });
           this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
       });
    }

    accountDenial(accountId: number) {
        const url = `v2/accounts/${accountId}/denials`;
        const eventType = ApiEventType.UPDATE_ACCOUNT_DENIAL;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
        this.http.put(url, {})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(()=> {
            this.accountApproveItem$.next({
              accountId: accountId,
              status: AccountStatus.NOT_VERIFIED
            });
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    creditRequestApprove(creditRequestId: number, amountAllowed: number = null) {
      const url = `v2/creditRequests/${creditRequestId}/approvals`
      const eventType = ApiEventType.UPDATE_ACCOUNT_APPROVAL;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.post(url, {
        amountAllowed: amountAllowed
      })
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    creditRequestDenial(creditRequestId: number) {
      const url = `v2/creditRequests/${creditRequestId}/denials`
      const eventType = ApiEventType.UPDATE_ACCOUNT_DENIAL;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.post(url, {})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }


    activateJobProfile(opentimerJobProfileId: number) {
      const url = `v2/opentimerProfiles/${opentimerJobProfileId}/activations`
      const eventType = ApiEventType.UPDATE_JOB_PROFILE_ACTIVATE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.put(url, {})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    deActivateJobProfile(opentimerJobProfileId: number) {
      const url = `v2/opentimerProfiles/${opentimerJobProfileId}/deactivations`
      const eventType = ApiEventType.UPDATE_JOB_PROFILE_DEACTIVATE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.put(url, {})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(()=> {
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }


    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          if (error instanceof HttpErrorResponse) {
            // Access the custom header
            const errorToken = error.headers.get('Response_token');
            console.log('Response_token:', errorToken);

          }
          const errorResponse = error.error;

          console.log(error.headers);
          const headers = error.headers;

          headers.keys().forEach(key => {
            console.log(key, headers.get(key));
          });

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            console.log('error', errorCode);
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }

              case 'INCOMPATIBLE_STATUS': {
                title = 'Cannot Deactivate Account';
                message = Messages.MESSAGE_ACCOUNT_HAS_ONGOING_BOOKINGS_OR_INTERVIEWS;
                break;
              }

              case 'INVALID_ACTION': {
                title = 'Invalid Action';
                message = 'Profile cannot be deactivated';
                break;
              }
              case 'INCOMPLETE_PROFILE': {
                title = 'Incomplete Profile';
                message = errorResponse.message
                break;
              }

              case 'INVALID_CONTACT_STATUS': {
                title = 'Invalid Contact Status!';
                message = 'Please verify the Verification ID before approval.';
                break;
              }

              case 'INVALID_ACCOUNT_STATUS': {
                title = Messages.HEADER_ACCOUNT_STATUS;
                message = `${Messages.MESSAGE_ACCOUNT_STATUS_INVALID}`;
                break;
              }

            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });


          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }
}
