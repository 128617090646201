<ng-container [formGroup]="ngControl | cast">
    <opentime-p-dropdown 
            [options]="items" [formControlName]="name"
            [style]="{'width':'100%'}"  optionValue="accountContactId"
            [filter]="true"            
            (onScrolledBottom)="onScrolledBottom()"
            filterPlaceholder="Search Recruiters"
            [haltInfiniteScroll]="haltInfiniteScroll"
            [readonly]="readonly"
            [disableInfiniteScroll]="disableInfiniteScroll">
 
            <ng-template let-recruiter pTemplate="item">
                <div class="agency-item flex align-items-center" >
                    <div class="ml-2" >{{recruiter.firstName}} {{recruiter.lastName}}</div>
                </div>
            </ng-template>

            <ng-template let-selectedItem  pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="selectedItem">
                    <div class="ml-2" >{{selectedItem.firstName}} {{selectedItem.lastName}}</div>
                </div>
            </ng-template>
    </opentime-p-dropdown>
</ng-container>