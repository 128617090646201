import { AccountType } from "../account";
import { IntegerRange } from "../integer-range";
import { RegistrationStatus } from "../registration-status";
import { AccountContactDto, AccountStatus } from "./account.dto";
import { BookingOrderDisplayDto, BookingType } from "./booking.dto";
import { CityDto } from "./city.dto";
import {AccountContactMinimalDto, ContactDto, ContactsInfoDto, PersonalInfoDto} from "./contact.dto";
import { CountryDto } from "./country.dto";
import { LocationAddressDto } from "./location-address.dto";
import { MoneyDto } from "./money.dto";
import { ShiftEntryDto } from "./shift.dto";
import {PhonesAndEmailsDto} from "./phones-emails.dto";

export interface CustomersSchema {
    customers: Array<CustomerDto>;
    total: number;
    contacts: Array<ContactDto>;
}

export interface CustomerSchema {
    customer: CustomerDto;
    contacts: Array<ContactDto>;
    countries: Array<CountryDto>;
    cities: Array<CityDto>;
}

export interface CustomerProfileDto {
  id: number;
  name: string;
  type: AccountType;
  owner: AccountContactMinimalDto;
  phonesAndEmail: PhonesAndEmailsDto;
  addresses: Array<LocationAddressDto>;
  details: CompanyDetailsDto;
  countryDto: CountryDto;
  status: AccountStatus;
  creditBalance: MoneyDto;
  avatarUri: string;
  creator: PersonalInfoDto;
  enabled: boolean;
}

export interface CustomerDto {
    id: number;
    enabled: boolean;
    type: AccountType;
    status: AccountStatus;
    countryId: number;
    avatarUri: string;
    createdDate: string;
    description: string;
    name: string;
    contacts: Array<AccountContactDto>;
    contactsInfo: ContactsInfoDto;
    registrationStatus: RegistrationStatus;
    customerLocation: CustomerLocationDto;
    lastBooking: BookingOrderDisplayDto;
    salesAmount: MoneyDto;
    salesAmounts: Array<MoneyDto>;
    creditBalance: MoneyDto;
    interviewAmounts: Array<MoneyDto>;
}

export interface CustomerStatusDto {
    country: boolean
    company: boolean
    subBusiness: boolean
    companyDetail:boolean
    contact: boolean
    location: boolean
    pending: boolean
}

export interface CustomerBusinessProfileDto {
    id: number;
    country: CustomerCountryDto;
    company: CompanyDetailsDto;
    subBusinesses: Array<SubBusinessDto>;
    companyDetail: CompanyDescriptionDto;
    contact: ContactDto;
    addresses: Array<LocationAddressDto>;
    status: CustomerStatusDto;
    accountStatus: AccountStatus
}

export interface CustomerCountryDto {
    countryId: number;
    international: boolean;
}

export interface CustomerLocationDto {
    totalActiveSites: number;
    totalSites: number;
}

export interface CompanyDetailsDto {
    companyName: string;
    dateEstablished?: string;
    representativeFirstName?: string;
    representativeLastName?: string;
    employeesCountRange?: IntegerRange;
    valid?: boolean;
}

export interface CompanyDescriptionDto {
    description: string;
}


export interface SubBusinessDto {
    id: number;
    serviceSubCategoryId: number;
    employeesCountRange: IntegerRange;
}


// export interface CustomerShiftDto {
//     shiftEntries: Array<ShiftEntryDto>;
//     name: string;
//     color: string;
//     id: number;
//     employmentType: BookingType;
// }
