<ng-container *ngFor="let email of emails; let i=index">
    <div *ngIf="i !== emailForm.value.index; else formTemplate" 
          class="grid grid-nogutter w-100 p-0 mt-3 align-items-center">
        <div class="p-card col-10 flex align-items-center">
            <div class="col-1 ">
                <i class="pi pi-envelope pt-1" style="font-size: 1rem"></i>
            </div>
            <div class="col-9 p-0 pl-2">
                <p>{{email}}</p>
            </div>
            <div class="col-2">
                <button pButton pRipple type="button" icon="pi pi-pencil"  (click)="editEmail(email, i)"
                        class="p-button-rounded p-button-primary p-button-outlined button-border-none">
                </button>
            </div>
        </div>
        <div class="col-2 text-center">
            <button pButton pRipple type="button" icon="pi pi-times-circle" (click)="removeAt(i)"
                    class="p-button-rounded p-button-danger p-button-outlined button-border-none">
            </button>
        </div>
    </div>
</ng-container>

<ng-template #formTemplate >
    <form [formGroup]="emailForm">
        <div class="grid grid-nogutter w-100 p-0 mt-3 align-items-center">
            <div class="col-10">
                <input id="phone" type="email" formControlName="email" 
                        pInputText placeholder="Email" style="width: 100%"/>
            </div>
            <div class="col-2 text-center">
                <button pButton pRipple type="button" icon="pi pi-check-circle" (click)="update()"
                        [disabled]="!emailForm.valid"   
                        class="p-button-rounded p-button-success p-button-outlined button-border-none">
                </button>
            </div>
        </div>
    </form>

</ng-template>