<div class="grid grid-nogutter  shift-item-detail" *ngIf="shiftDetail">
    <div class="col-11 info-panel-shadow  p-2 ">
        <div class="grid grid-nogutter">
            <div class="col-4">
                <p class="text-rb">{{shiftDetail.shiftName | titlecase}}</p>
            </div>
            <div class="col-8">
            </div>

            <div class="col-12">
                <div class="shift-item-container">
                    <ng-container *ngFor="let shiftEntryItem of shiftDetail.shiftEntryItems">
                        <div class="shit-item grid grid-nogutter">
                            <div class="col-2 flex align-items-center justify-content-center">
                                <div class="day-box">
                                    <p class="text-ssb">{{shiftEntryItem.day}}</p>
                                </div>
                            </div>
                            <div class="col-3 flex justify-content-start align-items-start pl-2">
                                <i class="pi pi-clock text-primary mt-1"></i>
                                <div>
                                    <p>{{shiftEntryItem.startTime | timeHhMm}}</p>
                                    <p>{{shiftEntryItem.endTime | timeHhMm}}</p>
                                </div>
                            </div>
                            <div class="col-3 block pl-2">
                                <div class="flex align-items-center justify-content-start">
                                    <i class="pi pi-briefcase text-primary"></i>
                                    <p>{{shiftEntryItem.totalShiftHours | timeDuration}}</p>
                                </div>
                                <div class="flex align-items-center justify-content-start text-velvet" 
                                     *ngIf="shiftEntryItem.overTimeHours.toTotalSeconds() > 0">
                                    <i class="pi pi-clock"></i>
                                    <p>{{shiftEntryItem.overTimeHours | timeDuration}}</p>
                                </div>
                            </div>
                            <div class="col-4 block pl-2">
                                <div class="flex align-items-center justify-content-start">
                                    <i class="pi pi-book text-primary"></i>
                                    <p>{{shiftEntryItem.breakHours | timeDuration}}</p>
                                </div>
                                <div class="flex align-items-center justify-content-start pl-2">
                                    <p class="text-primary">({{shiftEntryItem.totalHours | timeDuration}})</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid grid-nogutter flex justify-content-center align-items-center mt-3">
    <div class="col-5 shift-item-total-col">
        <div class="grid grid-no-gutter ">
            <div class="col-3"></div>
            <div class="col-3">
                <p>Working</p>
            </div>
            <div class="col-6">
                <p>{{shiftDetail.totalWorkingShiftHours.getHours()}} hours</p>
            </div>
        </div>
    </div>

    <div class="col-5 shift-item-total-col">
        <div class="grid grid-no-gutter">
            <div class="col-3"></div>
            <div class="col-3">
                <p>Overtime</p>
            </div>
            <div class="col-6">
                <p>{{shiftDetail.totalOvertimeHours.getHours()}} hours</p>
            </div>
        </div>
    </div>

      <div class="col-5 shift-item-total-col">
        <div class="grid grid-no-gutter">
            <div class="col-3"></div>
            <div class="col-3">
                <p>Break</p>
            </div>
            <div class="col-6">
                <p>{{shiftDetail.totalBreakHours.getHours()}} hours</p>
            </div>
        </div>
    </div>

    <div class="col-5 shift-item-total-col">
        <div class="grid grid-no-gutter">
            <div class="col-3"></div>
            <div class="col-3">
                <p>Total</p>
            </div>
            <div class="col-6">
                <p>{{shiftDetail.totalHours.getHours()}} hours</p>
            </div>
        </div>
    </div>
</div>