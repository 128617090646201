import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CityDto } from '../../models/dto/city.dto';
import { JobLocation } from '../../models/job-location';

@Component({
  selector: 'app-job-location-item',
  templateUrl: './job-location-item.component.html',
  styleUrls: ['./job-location-item.component.scss']
})
export class JobLocationItemComponent implements OnInit {
  @Input() jobLocation: JobLocation;
  @Input() city: CityDto;
  
  @Input() isDisplayMode: boolean = false;
  @Input() showEditButton: boolean = false;

  @Output() onEditJobLocation = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

}
