import { Pipe, PipeTransform } from "@angular/core";
import { IdVerificationType } from "../models/dto/id-verification.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'idVerificationTypeTitle'
})
export class IdVerificationTypeTitlePipe implements PipeTransform {
  
    private readonly idVerificationTypeTitleMap = ReadonlyMapBuilder.create<IdVerificationType, string>()
    .set(IdVerificationType.BASIC_RESIDENCE_REGISTRATION_CARD,'Basic Residence Resignation Card')
    .set(IdVerificationType.COPY_OF_RESIDENCE_CARD,'Copy of Residence Card')
    .set(IdVerificationType.DRIVERS_LICENSE,`Driver's License`)
    .set(IdVerificationType.INSURANCE_CARD,`Insurance Card`)
    .set(IdVerificationType.MY_NUMBER_CARD,`My Number Card`)
    .build();

    transform(value: IdVerificationType): string {
      return this.idVerificationTypeTitleMap.get(value);
    }
  
} 