export const baseUrl = 'https://app-new.sit.opentimeapp.net';

// const baseUrl = 'http://localhost:9090';

const twilioBaseUrl = 'https://opentime-twilio-backend.herokuapp.com';

export const environment = {
  production: true,
  baseUrl: baseUrl,
  apiEndPoint: `${baseUrl}/api/`,
  oauthEndPoint: `${baseUrl}/oauth/token`,
  appClient: 'Basic b3BlbnRpbWUtam9icy1wcm92aWRlci13ZWI6YXBwMTIz',
  useMockStores: false,
  twilioServerBaseUrl: twilioBaseUrl,
};
