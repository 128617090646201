import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AccountType} from 'src/app/shared/models/account';
import {PhoneNumberDto} from 'src/app/shared/models/dto/contact.dto';
import {CountryDto} from 'src/app/shared/models/dto/country.dto';
import {CountryService} from 'src/app/shared/services/country.service';

@UntilDestroy()
@Component({
  selector: 'app-edit-main-phone',
  templateUrl: './edit-main-phone.component.html',
  styleUrls: ['./edit-main-phone.component.scss']
})
export class EditMainPhoneComponent implements OnInit {
  @Input() phones: Array<PhoneNumberDto> = [];
  @Input() showSignInPhoneDialog: boolean;
  @Input() accountType: AccountType;
  @Input() accountId: number;

  @Output() onHide = new EventEmitter();

    phoneForm = this.formBuilder.group({
      countryCode: this.formBuilder.control(''),
      countryId: this.formBuilder.control('', Validators.required),
      phone: this.formBuilder.control('', [Validators.required, Validators.minLength(10),
        Validators.pattern('^[0-9]*$')]),
      type: this.formBuilder.control('MOBILE', [Validators.required]),
    });

  countries: Array<CountryDto> = [];

  phoneNumbers: Array<PhoneNumberDto> = [];

  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService) {
  }

  ngOnInit(): void {
    this.getSubscribers();
    this.phoneNumbers = this.phones;
  }

  getSubscribers() {
    this.countryService.countries$
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.countries = response;
      })
  }

  addPhoneNumber() {
    const phoneFormValue = this.phoneForm.value;
    const selectedCountry = this.countries.find(v => v.id === Number(phoneFormValue.countryId));

    this.phoneNumbers.push({
      countryId: selectedCountry.id,
      extension: selectedCountry.phoneCode,
      number: phoneFormValue.phone,
      type: phoneFormValue.type
    });
    this.phoneForm.reset()
    this.phoneForm.patchValue({
      type: 'MOBILE',
    });
    this.phoneForm.markAsPristine();
  }

  onSaveUserContactsInformation() {
    console.log('Phone', this.phoneForm.value);
  }
}
