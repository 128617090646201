<p-dialog [(visible)]="visible" [style]="{'width': dialogWidth, 'height': dialogHeight}"
          styleClass="edit-dialog" (onHide)="onClose.emit()" class="p-0 m-0"
          [modal]="true" [draggable]="false" [closable]="true">
    <ng-template pTemplate="header">
        <div class="grid grid-nogutter w-100">
            <div class="col-10 flex justify-content-start align-items-center">
                <p class="text-primary text-ssb text-2xl p-0 m-0">
                    {{title}}
                </p>
            </div>
            <div class="col-2 flex justify-content-end align-items-center">
                <button pButton pRipple type="button" icon="pi pi-times"
                        (click)="onClose.emit()" class="p-button-rounded p-button-text">
                </button>
            </div>
        </div>

    </ng-template>
    <ng-content></ng-content>
    <ng-template pTemplate="footer">
        <div class="mt-3 grid justify-content-end">
            <div class="col-4 flex">
                <button pButton  label="Close"  class="primary mt-3 w-100" pRipple (click)="visible=false"> </button>
            </div>
        </div>
    </ng-template>
</p-dialog>
