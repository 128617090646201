import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CountryDto } from 'src/app/shared/models/dto/country.dto';
import { HolidayCreationDto, HolidayDto, HolidayRepeatMode } from 'src/app/shared/models/dto/holiday.dto';
import { CountryService } from 'src/app/shared/services/country.service';
import { HolidayService } from 'src/app/shared/services/holiday.service';
import {CONST} from "../../../constant";

export interface HolidayRepeatModeItem {
  name: string;
  value: HolidayRepeatMode;
}

@UntilDestroy()
@Component({
  selector: 'app-upsert-holiday-dialog',
  templateUrl: './upsert-holiday-dialog.component.html',
  styleUrls: ['./upsert-holiday-dialog.component.scss']
})
export class UpsertHolidayDialogComponent implements OnInit {
  @Input() showHolidayDialog: boolean;
  @Input() holiday: HolidayDto;

  @Output() onHide = new EventEmitter();


  holidayForm = this.formBuilder.group({
    holidayName: this.formBuilder.control('', [Validators.required]),
    holidaySurcharge: this.formBuilder.control(null, [Validators.required]),
    holidayRepeatMode: this.formBuilder.control(null, [Validators.required]),
    startDate: this.formBuilder.control('', [Validators.required]),
    endDate: this.formBuilder.control('', [Validators.required]),
    allCountries: this.formBuilder.control(false, [Validators.required]),
    countries: this.formBuilder.control([])
  });

  holidayRepeatModeItems: Array<HolidayRepeatModeItem> = [
    {
      name: 'No Repeat',
      value: HolidayRepeatMode.NO_REPEAT
    },
    {
      name: 'Weekly',
      value: HolidayRepeatMode.WEEKLY
    },
    {
      name: 'Monthly',
      value: HolidayRepeatMode.MONTHLY
    },
    {
      name: 'Annually',
      value: HolidayRepeatMode.ANNUALLY
    }
  ]

  countries: Array<CountryDto> = [];
  minDate= new Date();
  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService,
              private holidayService: HolidayService) { }

  ngOnInit(): void {
    this.getSubcribers();

    if(this.holiday) {
      this.holidayForm.patchValue({
        allCountries: this.holiday.allCountries,
        countries: this.holiday.countries,
        endDate: this.holiday.endDate,
        holidayName: this.holiday.holidayName,
        holidayRepeatMode: HolidayRepeatMode[this.holiday.holidayRepeatMode],
        holidaySurcharge: this.holiday.holidaySurcharge,
        startDate: this.holiday.startDate
      })
    }
  }

  getSubcribers() {
    this.countryService.countries$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      this.countries = response;
    });

  }

  get startDateAsMinDate(): Date {
    return  this.holidayForm.value.startDate ? new Date(this.holidayForm.value.startDate) : new Date()
  }

  onSubmitHoliday() {

    const countryIds: Array<any> = [];
    this.holidayForm.value.countries.forEach((v: CountryDto)=>{
      countryIds.push({
        id: v.id
      })
    })

    if(!this.holiday) {
      const holidayCreationDto: HolidayCreationDto = {
        holidayName: this.holidayForm.value.holidayName,
        holidayRepeatMode: HolidayRepeatMode[this.holidayForm.value.holidayRepeatMode],
        holidaySurcharge: this.holidayForm.value.holidaySurcharge,
        startDate: this.holidayForm.value.startDate,
        endDate: this.holidayForm.value.endDate,
        allCountries: this.holidayForm.value.allCountries,
        countries: this.holidayForm.value.allCountries ? [] : countryIds
      }

      this.holidayService.createHoliday(holidayCreationDto);
    } else {

      const holidayDto: HolidayDto = {
        id: this.holiday.id,
        holidayName: this.holidayForm.value.holidayName,
        holidayRepeatMode: HolidayRepeatMode[this.holidayForm.value.holidayRepeatMode],
        holidaySurcharge: this.holidayForm.value.holidaySurcharge,
        startDate: this.holidayForm.value.startDate,
        endDate: this.holidayForm.value.endDate,
        allCountries: this.holidayForm.value.allCountries,
        countries: this.holidayForm.value.allCountries ? [] : this.holidayForm.value.countries
      }

      this.holidayService.updateHoliday(this.holiday.id, holidayDto);
    }


  }

    protected readonly CONST = CONST;
}
