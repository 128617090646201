import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, EMPTY, from, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { ApiEventService } from "./api-event.service";
import { AuthService } from "./auth-service";

@Injectable({
  providedIn: 'root',
})

export class AppInterceptorService implements HttpInterceptor {
  private readonly API_URL_REGEX = new RegExp(`^(${environment.baseUrl}/)?(oauth|api|v2).*$`);

  constructor(
    private apiEventsService: ApiEventService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matcher = this.API_URL_REGEX.exec(req.url);
    if (matcher) {
      const apiBaseUrlIsAbsent = !matcher[1];
      if (apiBaseUrlIsAbsent) {
        const newUrl = { url: `${environment.baseUrl}/${req.url}` };
        req = req.clone(newUrl);
      }
      const isApiUrl = matcher[2] === 'api' || matcher[2] === 'v2';
      if (isApiUrl) {
        const token = localStorage.getItem('access_token');
        if (token) {
          const authRequest = req.clone({
            headers: req.headers
              .set('Authorization', 'Bearer ' + token)
              .set('Accept-Language', 'en'),
          });
          return next.handle(authRequest).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 403) {
                let title: any = "Permission error"
                let message: any = "Your account can't perform this action."
                let showDialog = true

                message = `${message} <br/> Response Token: ${error.headers.get('Response_token')}`

                this.apiEventsService.sendEvent({
                  type: null,
                  status: ApiEventStatus.ERROR,
                  title,
                  message,
                  popup: showDialog
                });
                return EMPTY
              }

              return throwError(error);
            })
          )
        }
      }
    }
    return next.handle(req)
  }
}