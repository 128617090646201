import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { HolidayCreationDto, HolidayDto, HolidayDtoSchema } from "../models/dto/holiday.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";

@Injectable({
    providedIn: 'root',
})
export class HolidayService extends AbstractHttpHandler{

    holidaySchema$ = new BehaviorSubject<HolidayDtoSchema>(null);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getHolidays(page:number = 1, 
                limit:number = 10,
                search: string = null,
                year: number = null,
                startDate: string = null,
                endDate: string = null,
                lastId: number = null) {

        const url = `v2/holidays`;
        const eventType = ApiEventType.GET_HOLIDAYS;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }            

        if(page) {
            params = params.append('page', page);
        }

        if(search !== null && search.length > 0) {
            params = params.append('name.like', `${search}%`);
        }

        if(year) {
            params = params.append('year', year);
        }

        if(startDate !== null && startDate.length > 0) {
            params = params.append('startDate', startDate);
        }

        if(endDate !== null && endDate.length > 0) {
            params = params.append('endDate', endDate);
        }

        if (lastId) {
            params = params.append('id.gt', lastId.toString());
        }

        params = params.append('include', ['TOTAL'].toString());


        this.http.get<HolidayDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.holidaySchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    updateHoliday(holidayId: number, holidayDto: HolidayDto) {
        const url = `v2/holidays/${holidayId}`;
        const eventType = ApiEventType.UPDATE_HOLIDAY;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.put(url, holidayDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    createHoliday(holidayCreationDto: HolidayCreationDto) {
        const url = `v2/holidays`;
        const eventType = ApiEventType.CREATE_HOLIDAY;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.post(url, holidayCreationDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        });
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;
         
          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
    
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }

}