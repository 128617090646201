import { Time } from "./time/time";

export class OpentimeFormatDate {
  static formatDate(date: Date): string {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  static formatDateFromString(dateString: string): string {
    if (dateString) {
      const date = new Date(dateString);
      return OpentimeFormatDate.formatDate(date);
    } else {
      return '';
    }
  }

  static generateMaximumDate(): string {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return this.formatDate(d);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getSeconds(dateTime: any): number {
    const date = new Date(new Date(dateTime).getTime());
    return ((date.getHours() * 60 * 60) + (date.getMinutes() * 60));
  }

  static fromSecondsToDate(seconds: number): Date {
    return new Date((seconds * 1000) + (new Date().getTimezoneOffset() * 60 * 1000));
  }

  static  getDaysArray(endDate: Date): Array<Date> {
    let arr:Array<Date> = []
    let dt: Date;

    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear()-1);

    for(arr=[], dt=new Date(startDate); dt<=new Date(endDate); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
  };

  static getDateFromTime(time: string): Date {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date;
  }

  static fromSecondsToHhMMString(second: number) {
    const utcDate = new Date(second * 1000).toISOString();
    const utcTime = utcDate.split('T');
    return Time.fromString(utcTime[1].slice(0, -5)).convertToHhMmString();
  }
}
