import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyEnum } from "../models/dto/currency.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'currencySymbol'
  })
  export class CurrencySymbolPipe implements PipeTransform {
  
    private readonly currencySymbolMap = ReadonlyMapBuilder.create<string, string>()
    .set(CurrencyEnum.USD ,'$')
    .set(CurrencyEnum.JPY, '¥')
    .build();

    transform(value: string): string {
      return this.currencySymbolMap.get(value);
    }
  
}