import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CommissionPercentageDto} from 'src/app/shared/models/dto/commission-percentage.dto';
import {AgencyService} from 'src/app/shared/services/agency.service';
import {untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventType} from "../../../models/api-event";
import {ApiEventService} from "../../../services/api-event.service";

@Component({
  selector: 'app-upsert-agency-commission-percentage',
  templateUrl: './upsert-agency-commission-percentage.component.html',
  styleUrls: ['./upsert-agency-commission-percentage.component.scss']
})
export class UpsertAgencyCommissionPercentageComponent implements OnInit {
  @Input() accountId: number;
  @Input() showAgencyCommissionPercentageDialog: boolean;

  @Output() onHide = new EventEmitter();

  commissionPercentageForm = this.formBuilder.group({
    commissionPercentage: this.formBuilder.control(null, [Validators.required])
  })

  constructor(private formBuilder: FormBuilder,
              private agencyService: AgencyService,
              private apiEventService: ApiEventService) { }

  ngOnInit(): void {
    this.agencyService.getAgencyCommissionPercentage(this.accountId);
    this.getApiEvents()
  }

  getApiEvents() {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
          if (this.apiEventService.isEventCompleted(ApiEventType.GET_AGENCY_COMMISSION_PERCENTAGES)) {
            this.commissionPercentageForm.patchValue({
              commissionPercentage: this.agencyService.commissionPercentage$.value.commissionPercentage
            });
          }
      });
  }

  onSaveAgencyCommission() {
    const commissionPercentage: CommissionPercentageDto = {
      commissionPercentage: this.commissionPercentageForm.value.commissionPercentage
    }

    this.agencyService.updateAgencyCommissionPercentage(this.accountId, commissionPercentage);
  }
}
