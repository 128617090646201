import { Component, OnInit, Input, Output, EventEmitter,  SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { JobSkillAddressesSchema, JobSkillDto } from 'src/app/shared/models/dto/skill.dto';
import { ApiEventService } from 'src/app/shared/services/api-event.service';
import { SkillService } from 'src/app/shared/services/skill.service';
import * as _ from 'lodash';  
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiEventStatus, ApiEventType } from 'src/app/shared/models/api-event';
import { TranslationDto } from 'src/app/shared/models/dto/translation.dto';

class SelectionJobSkillDto implements JobSkillDto {
  skill: string;
  id: number;
  valid: boolean;
  translations: TranslationDto[];
  inactive: boolean = false;
}

@UntilDestroy()
@Component({
  selector: 'app-skills-dropdown',
  templateUrl: './skills-dropdown.component.html',
  styleUrls: ['./skills-dropdown.component.scss']
})
export class SkillsDropdownComponent implements OnInit, OnChanges {
  @Input() ngControl: FormControl;
  @Input() name: string;
  @Input() optionValue: string;
  @Input() placeholder: string = 'Select'
  @Input() isForm: boolean = true;
  @Input() selectedJobSkills: Array<JobSkillDto> = [];


  @Input() jobSkill: JobSkillDto = null;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @Output() onSelectedJobSkill = new EventEmitter<number>();


  overlayVisible: boolean = false;
  focused: boolean = false;
  showClear: boolean = false;
  disableInfiniteScroll: boolean = false;
  haltInfiniteScroll: boolean = false;


  items: Array<SelectionJobSkillDto> = [];
  total: number = 0;
  skillId: number;

  constructor(private skillService: SkillService,
              private apiEventService: ApiEventService) { 
              this.onKeypressEvent = _.debounce(this.onKeypressEvent, 500)
  }

  ngOnInit(): void {    
    this.initJobSkills();
    this.getSubcribers();
    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe(event=>{
      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_SERVICE_CATEGORIES) {
        this.haltInfiniteScroll = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedJobSkills'] && changes['selectedJobSkills'].currentValue) {
      const skillIds: Array<number> = [];
      this.selectedJobSkills.forEach((v)=>{
        skillIds.push(v.id)
      });

      this.items.forEach((v)=>{
        if(skillIds.includes(v.id)) {
          v.inactive = true;
        } else {
          v.inactive = false;
        }
      });

      if(this.ngControl.dirty) {
        this.ngControl.updateValueAndValidity();
      }
    }
  }

  initJobSkills() {
    if(this.jobSkill) {
      this.items.push(this.jobSkill as SelectionJobSkillDto);
    }

    if(this.isForm && this.ngControl.value) {
      this.skillId = Number(this.ngControl.value);
    }
  }

  getSubcribers() {
    this.skillService.jobSkillsSchema$
    .pipe(untilDestroyed(this))
    .subscribe((response: JobSkillAddressesSchema)=>{
      if(response) {
        response.jobSkills.forEach(v=>{
          this.items.push(v as SelectionJobSkillDto)
        });
        this.total = response.total;
      }
    })
  }

  onScrolledBottom() {
    if(this.items.length >= this.total) {
      this.disableInfiniteScroll = true;
    }

    if((this.items.length <= this.total) && !this.haltInfiniteScroll) {
      const lastItemJobSkill = this.items[this.items.length -1];
      this.skillService.getSkills(lastItemJobSkill.id, 20, null, true);
      this.haltInfiniteScroll = true;
    } 
  }

  onKeypressEvent(ev) {
    // this.serviceCategoryService.getServiceCategories(null, null, 20, ev.target.value as string);
    this.items = [];
  }

}
