import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingType } from '../../models/dto/booking.dto';
import { HourlyRateDto, OpentimerProfileCreationDto } from '../../models/dto/opentimer-job-profile.dto';
import { SkillService } from '../../services/skill.service';

@Component({
  selector: 'app-job-profile-detail-item',
  templateUrl: './job-profile-detail-item.component.html',
  styleUrls: ['./job-profile-detail-item.component.scss']
})
export class JobProfileDetailItemComponent implements OnInit {
  @Input() opentimerJobProfile: OpentimerProfileCreationDto;

  readonly chipColor = SkillService.chipColor;
  bookingType: BookingType;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.bookingType = this.route.snapshot.queryParams['bookingType'];
  }

  get hourlyRate(): HourlyRateDto {
    return this.opentimerJobProfile.hourlyRateSchema.hourlyRates.find((v)=>{
      return v.employmentType === this.bookingType
    });
  }

}
