import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CurrencyEnum } from 'src/app/shared/models/dto/currency.dto';
import { AdminPriceDto, AdminPriceItemType, PriceInputDto } from 'src/app/shared/models/dto/prices.dto';
import * as moment from 'moment';
import { PricesService } from 'src/app/shared/services/prices.servce';
import {CONST} from "../../../constant";


interface AdminPriceItem {
  item: AdminPriceItemType;
  name: string;
}

interface CurrencyItem {
  currency: CurrencyEnum;
  name: string;
  symbol: string;
}

@Component({
  selector: 'app-upsert-service-fee',
  templateUrl: './upsert-service-fee.component.html',
  styleUrls: ['./upsert-service-fee.component.scss']
})
export class UpsertServiceFeeComponent implements OnInit {
  @Input() showServiceFeeDialog: boolean;
  @Input() price: AdminPriceDto;

  @Output() onHide = new EventEmitter();

  adminPriceItems: Array<AdminPriceItem> =[
    {
      item: AdminPriceItemType.INTERVIEW,
      name: 'Interview'
    }
  ]

  currencyItems: Array<CurrencyItem> = [
    {
      currency: CurrencyEnum.JPY,
      name: 'Yen',
      symbol: '¥'
    },
    {
      currency: CurrencyEnum.USD,
      name: 'Dollars',
      symbol: '$'
    }
  ]

  priceForm = this.formBuilder.group({
    item: this.formBuilder.control('', [Validators.required]),
    currency: this.formBuilder.control('', [Validators.required]),
    price: this.formBuilder.control(null, [Validators.required]),
    executionDateTime: this.formBuilder.control(null, [Validators.required]),
  })

  minDate = new Date();
  constructor(private formBuilder: FormBuilder,
              private pricesService: PricesService) { }

  ngOnInit(): void {
    if(this.price) {
      this.priceForm.patchValue({
        item: this.price.item,
        currency: this.price.currency,
        price: this.price.price,
        executionDateTime: new Date(this.price.executionDateTime)
      })
    }
  }


  onSubmitPrice() {
    const priceInputDto: PriceInputDto = {
      currency: CurrencyEnum[this.priceForm.value.currency],
      executionDateTime:  moment(new Date(this.priceForm.value.executionDateTime).getTime() + 60*1000).format(CONST.TIME_FORMAT_MOMENT),
      item: AdminPriceItemType[this.priceForm.value.item],
      price: this.priceForm.value.price
    }


    this.pricesService.upsertPrice(priceInputDto);
  }

  protected readonly CONST = CONST;
}
