import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages, getErrorMessage, getErrorTitle } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AdminCancelBookingRequestDto, BookingCancelRequestStatus, BookingCancellationRequestApproveRejectItem, BookingCancellationResponseDto, BookingOrderCreationDto, BookingOrderDisplayDto, BookingOrderDisplayDtoSchema, BookingStatus } from "../models/dto/booking.dto";
import { CustomerRatingResponseDto } from "../models/dto/customer-rating.dto";
import { BookingOrderWorkTimeOverViewDto } from "../models/dto/working-time.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { BookingCancelRequestEntityType } from "src/app/admin-portal/bookings/booking-cancel-request/booking-cancel-request.component";

@Injectable({
    providedIn: 'root',
})
export class BookingsService extends AbstractHttpHandler {

    public bookingOrderDisplayDtoSchema$ = new BehaviorSubject<BookingOrderDisplayDtoSchema>(null);
    public bookingOrderDisplayDto$ = new BehaviorSubject<BookingOrderDisplayDto>(null);
    public bookingOrderWorkTimeOverViewDto$ = new BehaviorSubject<BookingOrderWorkTimeOverViewDto>(null)

    public customerRatingResponseDto$ = new BehaviorSubject<CustomerRatingResponseDto>(null);

    public bookingCancellationResponseDtos$ = new BehaviorSubject<Array<BookingCancellationResponseDto>>([]);

    public bookingCancellationRequestApproveRejectItem$ = new BehaviorSubject<BookingCancellationRequestApproveRejectItem>(null);

    constructor(public override http: HttpClient,
                public override apiEventsService: ApiEventService,
                private router: Router) {
        super(http, apiEventsService);
    }


    getBookingOrder(bookingOrderId: number) {
        const url = `v2/bookingOrders/${bookingOrderId}`;
        const eventType = ApiEventType.GET_BOOKINGS_BY_ID;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.get<BookingOrderDisplayDto>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.bookingOrderDisplayDto$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getBookingOrdersByAccount(
                    accountType: string,
                    accountId: number,
                    page:number = 1,
                    limit:number = 10,
                    status: Array<BookingStatus> = [],
                    startDate: string = null,
                    endDate: string = null,
                    opentimerNameLike: string = null,
                    customerNameLike: string = null,
                    agencyNameLike: string = null,
                    bookingId: number = null,
                    lastId: number = null) {

        const url = `v2/${accountType}/${accountId}/bookingOrders`;
        const eventType = ApiEventType.GET_BOOKINGS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        status.forEach((bookingStatus)=>{
            params = params.append('status', bookingStatus);
        });

        if(startDate) {
            params = params.append('startDate', startDate);
        }

        if(endDate) {
            params = params.append('endDate', endDate);
        }

        if(opentimerNameLike !== null && opentimerNameLike.length > 0) {
            params = params.append('opentimerNameLike', `${opentimerNameLike}%`);
        }

        if(customerNameLike !== null && customerNameLike.length > 0) {
            params = params.append('customerNameLike', `${customerNameLike}%`);
        }

        if(agencyNameLike !== null && agencyNameLike.length > 0) {
            params = params.append('agencyNameLike', `${agencyNameLike}%`);
        }

        if (lastId) {
            params = params.append('id.gt', lastId.toString());
        }

        if (bookingId && bookingId > 0) {
            params = params.append('bookingId', bookingId.toString());
        }

        this.http.get<BookingOrderDisplayDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.bookingOrderDisplayDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    getBookingOrders(page:number = 1,
                limit:number = 10,
                status: Array<BookingStatus> = [],
                startDate: string = null,
                endDate: string = null,
                opentimerNameLike: string = null,
                customerNameLike: string = null,
                agencyNameLike: string = null,
                opentimerAliasLike: string = null,
                bookingId: number = null,
                lastId: number = null) {
        const url = `v2/bookingOrders`;
        const eventType = ApiEventType.GET_BOOKINGS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        status.forEach((bookingStatus)=>{
            params = params.append('status', bookingStatus);
        });

        if(startDate) {
            params = params.append('startDate', startDate);
        }

        if(endDate) {
            params = params.append('endDate', endDate);
        }

        if(opentimerNameLike !== null && opentimerNameLike.length > 0) {
            params = params.append('opentimerNameLike', `${opentimerNameLike}%`);
        }

        if(customerNameLike !== null && customerNameLike.length > 0) {
            params = params.append('customerNameLike', `${customerNameLike}%`);
        }

        if(agencyNameLike !== null && agencyNameLike.length > 0) {
            params = params.append('agencyNameLike', `${agencyNameLike}%`);
        }

        if(opentimerAliasLike !== null && opentimerAliasLike.length > 0) {
            params = params.append('opentimerAliasLike', `${opentimerAliasLike}%`);
        }

        if (lastId) {
            params = params.append('id.gt', lastId.toString());
        }

        if (bookingId && bookingId > 0) {
            params = params.append('bookingId', bookingId.toString());
        }

        this.http.get<BookingOrderDisplayDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.bookingOrderDisplayDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getbookingCancellationRequests(eventType: ApiEventType, bookingCancelRequestEntityType: BookingCancelRequestEntityType,
                            page:number = 1,
                            limit:number = 10,) {
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      const bookingCancellationRequestMap = {
        [BookingCancelRequestEntityType.JOB_PROFILE]: 'v2/profileBookingCancellationRequests',
        [BookingCancelRequestEntityType.CUSTOMER]: 'v2/customerBookingCancellationRequests',
      }
      const url = bookingCancellationRequestMap[bookingCancelRequestEntityType];

      let params = new HttpParams();

      if(limit) {
        params = params.append('limit', limit);
      }

      if(page) {
          params = params.append('page', page);
      }

      this.http.get<Array<BookingCancellationResponseDto>>(url, {params})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async (response: Array<BookingCancellationResponseDto>)=>{
          this.bookingCancellationResponseDtos$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    bookOpentimer(opentimerProfileSearchId: number, bookingOrderCreationDto: BookingOrderCreationDto, accountId: number) {
        const url = `v2/opentimersProfilesSearches/${opentimerProfileSearchId}/bookingOrders`;
        const eventType = ApiEventType.CREATE_BOOK_OPENTIMER;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.post<BookingOrderDisplayDto>(url, bookingOrderCreationDto,{
            headers: new HttpHeaders({
                'Account-Id': String(accountId || '')
            }),
        })
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.bookingOrderDisplayDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getBookingWorkingTimeStatistics(bookingId: number,
                                    startDate: string = null,
                                    endDate: string = null) {
        const url = `v2/bookingOrders/${bookingId}/workingTimeStatistics`;
        const eventType = ApiEventType.GET_BOOKINGS_WORKING_TIME_STATISTICS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        let params = new HttpParams();

        if(startDate) {
            params = params.append('startDate', startDate);
        }

        if(endDate) {
            params = params.append('endDate', endDate);
        }

        this.http.get<BookingOrderWorkTimeOverViewDto>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async (response: BookingOrderWorkTimeOverViewDto)=>{
            this.bookingOrderWorkTimeOverViewDto$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getCustomerBookingRatings(bookingOrderId: number) {
        const url = `v2/bookingOrders/${bookingOrderId}/customerRatings`;
        const eventType = ApiEventType.GET_BOOKING_CUSTOMER_RATINGS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.get<CustomerRatingResponseDto>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async (response: CustomerRatingResponseDto)=>{
            this.customerRatingResponseDto$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    cancelBooking(bookingOrderId: number, adminCancelBookingRequestDto: AdminCancelBookingRequestDto) {
        const url = `v2/bookingOrders/${bookingOrderId}/cancellations`;
        const eventType = ApiEventType.CANCEL_BOOKING_REQUEST;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.put<BookingOrderDisplayDto>(url, adminCancelBookingRequestDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    approveBookingCancellationRequest(requestId: number) {
        const url = `v2/bookingCancellationRequests/${requestId}/approvals`;

        const eventType =  ApiEventType.UPDATE_BOOKING_CANCELLATION_REQUEST_APPROVAL;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.put(url, {})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.bookingCancellationRequestApproveRejectItem$.next({
                requestId: requestId,
                status: BookingCancelRequestStatus.APPROVED
            });
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    rejectBookingCancellationRequest(requestId: number) {
        const url = `v2/bookingCancellationRequests/${requestId}/rejections`;
        const eventType =  ApiEventType.UPDATE_BOOKING_CANCELLATION_REQUEST_REJECTION;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

        this.http.put(url, {})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.bookingCancellationRequestApproveRejectItem$.next({
                requestId: requestId,
                status: BookingCancelRequestStatus.REJECTED
            });
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
              case 'INCOMPATIBLE_STATUS': {
                title = getErrorTitle(errorResponse);
                message = getErrorMessage(errorResponse);
                break;
              }
            }
         }


         if(error.status === 404 && eventType === ApiEventType.GET_BOOKING_CUSTOMER_RATINGS) {
            title =  Messages.HEADER_NO_AVAILABLE_FEEDBACKS;
            message = Messages.MESSAGE_NO_AVAILABLE_FEEDBACKS
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }

}
