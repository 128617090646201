<p-autoComplete pAutoComplete [suggestions]="results" (completeMethod)="search($event)" [dropdown]="false"
[dropdownIcon]="'pi pi-search'" (onDropdownClick)="onDropdownClick($event)"
[delay]="400" field="name" placeholder="Type" (onSelect)="onSelected($event)"
appendTo="body" 
[showClear]="true" [style]="{'width':'100%'}" inputStyleClass="autocomplete-input">
    <ng-template let-item pTemplate="selectedItem">
        <div class="grid grid-nogutter">
            <div class="col-1 flex justify-content-center align-items-center">
                <img [src]="item.avatarUri | avatarUriTransform" class="account-avatar" 
                onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
            </div>
            <div class="col-11">
                <p class="text-rb">{{item.name}}</p>
            </div>
        </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
            <div class="grid grid-nogutter">
                <div class="col-1 flex justify-content-center align-items-center">
                    <img [src]="item.avatarUri | avatarUriTransform" class="account-avatar" 
                    onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
                </div>
                <div class="col-11">
                    <p class="text-rb">{{item.name}}</p>
                    <p class="text-rm">Opentimer ID: {{item.id}}</p>
                </div>
            </div>
    </ng-template>


</p-autoComplete>