import { ComponentFactoryResolver, ComponentRef, Directive, Host, Inject, Optional, Self, ViewContainerRef } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EMPTY, merge, Observable } from "rxjs";
import { ControlErrorComponent } from "../components/control-error/control-error.component";
import { FORM_ERRORS } from "../form-errors";
import { ControlErrorContainerDirective } from "./control-error-container.directive";
import { FormSubmitDirective } from "./form-submit.directive";

enum FORM_ERROR_TYPE {
    required = 'required',
    minLength = 'minLength',
    pattern = 'pattern'
}

@UntilDestroy()
@Directive({
    selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective {
    ref: ComponentRef<ControlErrorComponent>;

    submit$: Observable<Event>;
    container: ViewContainerRef;
    
    constructor(@Self()private control: NgControl,
                @Inject(FORM_ERRORS) private errors: any,
                private vcr: ViewContainerRef,
                private resolver: ComponentFactoryResolver,
                @Optional() controlErrorContainer: ControlErrorContainerDirective,
                @Optional() @Host() private form: FormSubmitDirective,) {
                this.container = controlErrorContainer ? controlErrorContainer.vcr : this.vcr;
                this.submit$ = this.form ? this.form.submit$ : EMPTY;
    }

    ngOnInit() {
        merge(
            this.submit$,
            this.control?.valueChanges,
        ).pipe(untilDestroyed(this))
        .subscribe(()=>{
            const controlName = this.control.name;
            const controlErrors = this.control.errors;
            if (this.control.dirty && controlErrors) {
                let firstKey = Object.keys(controlErrors)[0];
                
                if(firstKey === FORM_ERROR_TYPE.pattern) {
                   firstKey = `${controlName}_${firstKey}`;
                }
                const getError = this.errors[firstKey];
                const text = getError(controlErrors[firstKey]);
                this.setError(text);
            } else {
                this.setError(null);
            }
        })
     
    }

    setError(text: string) {
        if (!this.ref) {
          const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
          this.ref = this.container.createComponent(factory);
        }
        this.ref.instance.text = text;
      }
   
}