import { Pipe, PipeTransform } from "@angular/core";
import { LanguageEnum } from "../models/dto/language.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'languagePipe'
})
export class LanguagePipe implements PipeTransform {

    private readonly languageMap = ReadonlyMapBuilder.create<string, string>()
    .set(LanguageEnum.EN ,'English')
    .set(LanguageEnum.JA,'Japanese')
    .build();

    transform(value: string): string {
      return this.languageMap.get(value);
    }
}