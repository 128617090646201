import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LeavingReasonEnum } from '../../models/dto/leaving-reason.dto';
import { PreviousExperienceDtoSchema } from '../../models/dto/previous-work-experience.dto';
import {ReasonForLeaving} from "../../services/reason-for-leaving.service";

@Component({
  selector: 'app-opentimer-work-experience',
  templateUrl: './opentimer-work-experience.component.html',
  styleUrls: ['./opentimer-work-experience.component.scss']
})
export class OpentimerWorkExperienceComponent implements OnInit {
  @Input() previousExperienceSchema: PreviousExperienceDtoSchema;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() reasonsForLeaving: Array<ReasonForLeaving>;

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  getReason(reason: string): ReasonForLeaving {
    reason = reason ? reason : LeavingReasonEnum.OTHER;
    return this.reasonsForLeaving.find(s => s.reasonForLeaving === reason);
  }

}
