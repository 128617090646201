import {Pipe, PipeTransform} from '@angular/core';
import {environment} from 'src/environments/environment';
import {CONST} from "../constant";

@Pipe({
  name: 'avatarUriTransform',
})
export class AvatarUriTransformPipe implements PipeTransform {
  transform(value: string): string {
    console.log('AvatarUriTransformPipe', value);
    if (value && this.isBase64(value)) {
      return value;
    }
    if (value === undefined) {
      return CONST.IMAGE_PLACEHOLDER;
    }
    return `${environment.baseUrl}/v2/files/public${value}`;
  }

  private isBase64(str: string): boolean {
    return str.startsWith('data:');
  }
}
