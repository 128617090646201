import {trigger, style, transition, animate} from '@angular/animations';

export const OpentimeAnimations = {
  slideLeft: trigger('slideLeft',
      [
        transition(
            ':enter',
            [
              style({'position': 'relative', 'left': '10em', 'opacity': 0}),
              animate('0.2s ease-out',
                  style({'position': 'relative', 'left': 0, 'opacity': 0.8})),
            ],
        ),
        transition(
            ':leave',
            [
              style({position: 'relative', right: 0, opacity: 0.2}),
              animate('0s ease-in',
                  style({position: 'relative', right: '10em', opacity: 0})),
            ],
        ),
      ],
  ),
  slideDown: trigger('slideDown',
      [
        transition(
            ':enter',
            [
              style({'position': 'relative', 'bottom': '0em', 'opacity': 0}),
              animate('0.6s ease-out',
                  style({'position': 'relative', 'bottom': 0, 'opacity': 0.8})),
            ],
        ),
        transition(
            ':leave',
            [
              style({position: 'relative', bottom: 0, opacity: 1}),
              animate('0.6s ease-in',
                  style({position: 'relative', bottom: '2em', opacity: 0})),
            ],
        ),
      ],
  ),
  fadeOutFadeIn: trigger('fadeOutFadeIn',
      [
        transition(
            ':enter',
            [
              style({opacity: 0}),
              animate('0.3s ease-out',
                  style({'opacity': 1})),
            ],
        ),
        transition(
            ':leave',
            [
              style({opacity: 1}),
              animate('0.6s ease-out',
                  style({'opacity': 0})),
            ],
        ),
      ],
  ),
  slideUp: trigger('slideUp',
      [
        transition(
            ':enter',
            [
              style({'position': 'relative', 'top': '0.5em', 'opacity': 0}),
              animate('0.3s ease-out',
                  style({'position': 'relative', 'top': 0, 'opacity': 0.8})),
            ],
        ),
        transition(
            ':leave',
            [
              style({position: 'relative', bottom: 0, opacity: 0.2}),
              animate('0.2s ease-in',
                  style({position: 'relative', bottom: '0.5em', opacity: 0})),
            ],
        ),
      ],
  ),
  fadeOut: trigger('fadeOut',
      [
        transition(
            ':leave',
            [
              style({opacity: 0.5}),
              animate('0.4s ease-out',
                  style({'opacity': 0})),
            ],
        ),
      ],
  ),
};
