import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-opentime-panel',
  templateUrl: './opentime-panel.component.html',
  styleUrls: ['./opentime-panel.component.scss']
})
export class OpentimePanelComponent implements OnInit {
  @Input() opentimePanelItems = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
