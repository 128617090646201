import { OpentimerProfileDto } from "./opentimer-job-profile.dto";

export enum ContractTerminationReasonEnum {
    JOB_COMPLETED_SUCCESSFULLY = 'JOB_COMPLETED_SUCCESSFULLY',
    CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
    CANCELLED_BY_OPENTIMER = 'CANCELLED_BY_OPENTIMER'
}

export enum RecommendationLevelEnum {
    NOT_AT_ALL_LIKELY = 'NOT_AT_ALL_LIKELY',
    MOST_LIKELY = 'MOST_LIKELY',
    EXTREMELY_LIKELY = 'EXTREMELY_LIKELY'
}

export enum CustomerFeedBackCategoryEnum {
    CULTURE_AND_VALUES = 'CULTURE_AND_VALUES',
    WORK_LIFE_BALANCE = 'WORK_LIFE_BALANCE',
    SENIOR_MANAGEMENT = 'SENIOR_MANAGEMENT',
    COMP_AND_BENEFITS = 'COMP_AND_BENEFITS',
    QUALITY_OF_WORK = 'QUALITY_OF_WORK',
    CAREER_OPPORTUNITIES = 'CAREER_OPPORTUNITIES'
}

export interface CustomerRatingResponseSchema {
    averageRating: number;
    customerRatings: Array<CustomerRatingResponseDto>;
}

export interface CustomerRatingResponseDto {
    id: number;
    contractTerminationReason: ContractTerminationReasonEnum;
    recommendationLevel: RecommendationLevelEnum;
    message: string;
    totalRating: number;
    feedbacks: Array<CustomerFeedbackDto>;
    reviewedBy: OpentimerProfileDto;

}

export interface CustomerFeedbackDto {
    categoryValue: number;
    categoryName: CustomerFeedBackCategoryEnum;
}
