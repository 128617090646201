<app-edit-dialog [visible]="showCoverLetterDialog" [title]="'Edit Cover Letter'"
                 (onSubmit)="onUpdateCoverLetter()"
                 [dialogHeight]="'5'"
                 (onHide)="onHide.emit()" [disabled]="!coverLetterForm.valid">
  <div class="grid grid-nogutter mt-5 justify-content-center col-12">
    <ng-container [formGroup]="coverLetterForm">
      <div id="coverLetter" class="grid grid-nogutter col-12">
        <div class="col-12 lg:col-6 pr-5">
          <app-labelled-input [ngControl]="coverLetterForm"
                              [displayBlock]="true"
                              [textAlign]="TextAlignPosition.LEFT"
                              [placeholder]="'Ex: Alias'" [label]="'Alias'" [name]="'alias'">
          </app-labelled-input>
        </div>
        <div class="col-12 pr-5">
            <div class="grid grid-nogutter w-100 block">
              <label class="col-3 pr-2 text-left text-rb" >
                Cover Letter
              </label>
              <div class="col-12 mt-1 pr-2">
                    <textarea  formControlName="description" cols="100"
                               pInputTextarea placeholder="Cover Letter" rows="5" class="cover-letter-text-area" ></textarea>
              </div>
            </div>
        </div>
      </div>
    </ng-container>
  </div>
</app-edit-dialog>
