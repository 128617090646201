import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AdminPermissionEnum } from 'src/app/shared/models/dto/admin.dto';
import { AdminCreationService } from 'src/app/shared/services/admin-creation.service';
import { RouteService } from 'src/app/shared/services/utils/route.service';

export enum BookingCancelRequestEntityType  {
  CUSTOMER = 'CUSTOMER',
  JOB_PROFILE = 'JOB_PROFILE',
}

@Component({
  selector: 'app-booking-cancel-request',
  templateUrl: './booking-cancel-request.component.html',
  styleUrls: ['./booking-cancel-request.component.scss']
})
export class BookingCancelRequestComponent implements OnInit {

  menuItems: MenuItem[] = [
    {
      label:'Cancel Requests',
      url: '/admin-portal/bookings/bookings-cancel-request',
    },
  ];

  tabItems: MenuItem[] = [
    {
      label:'Customers',
      routerLink: `/admin-portal/bookings/bookings-cancel-request/customers`,
      styleClass: this.routeService.startsWith(`/admin-portal/bookings/bookings-cancel-request/customers`) ? 'active' : '',
      visible: this.adminCreationService.hasAccessPermission([AdminPermissionEnum.READ_CUSTOMER_BOOKING_CANCELLATION_REQUESTS,
        AdminPermissionEnum.READ_BOOKING_CANCELLATION_REQUESTS,
        AdminPermissionEnum.APPROVE_BOOKING_CANCELLATION_REQUESTS,
        AdminPermissionEnum.REJECT_BOOKING_CANCELLATION_REQUESTS]),

    },
    {
      label:'OpenTimer',
      routerLink: `/admin-portal/bookings/bookings-cancel-request/job-profiles`,
      styleClass: this.routeService.startsWith(`/admin-portal/bookings/bookings-cancel-request/job-profiles`) ? 'active' : '',
      visible: this.adminCreationService.hasAccessPermission([AdminPermissionEnum.READ_OPENTIMER_BOOKING_CANCELLATION_REQUESTS,
        AdminPermissionEnum.READ_BOOKING_CANCELLATION_REQUESTS,
        AdminPermissionEnum.APPROVE_BOOKING_CANCELLATION_REQUESTS,
        AdminPermissionEnum.REJECT_BOOKING_CANCELLATION_REQUESTS]),
    },
  ];

  displayAdvanceSearchPanel: boolean = false;
  constructor(private routeService: RouteService,
              private adminCreationService: AdminCreationService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  onDisplayAdvanceSearch(ev: boolean) {
    this.displayAdvanceSearchPanel = ev;
  }

  isActive(childRoute): boolean {
    const route = 'admin-portal/bookings/bookings-cancel-request'
    return this.routeService.startsWith(`${route}/${childRoute}`);
  }
}
