<app-edit-dialog [visible]="showAgencyCommissionPercentageDialog" [title]="'Edit Agency Commission Percentage'" 
                 (onSubmit)="onSaveAgencyCommission()" [dialogHeight]="'20'"
                 (onHide)="onHide.emit()" [disabled]="!commissionPercentageForm.valid">
                 <ng-container [formGroup]="commissionPercentageForm">
                    <div class="grid grid-nogutter">
                        <label class="col-12 pr-2 text-rb">Commission Percentage</label>
                        <div class="col-12 mt-1">
                            <input  id="phone" type="tel" 
                                    formControlName="commissionPercentage"
                                    appOnlyInteger  pInputText  
                                    placeholder="Number" style="width: 100%"/>
                        </div>
                    </div>
                 </ng-container>
</app-edit-dialog>
