import { ExperienceLevelEnum } from "./experience-level.dto";
import { TranslationDto } from "./translation.dto";

export interface ServiceCategoriesSchema {
  serviceCategories: Array<ServiceCategoryDto>;
  total: number;
}

export interface ServiceSubCategoriesSchema {
  serviceSubCategories: Array<ServiceSubCategoryDto>;
  total: number;
}

export interface ServiceCategoryDto {
  id?: number;
  code: string;
  translations: Array<TranslationDto>;
  subCategories: Array<ServiceSubCategoryDto>;
  avatarUri?: string;
  active?: boolean;
}
  
export interface ServiceSubCategoryDto {
  code: string;
  id: number;
  active: boolean;
  translations: Array<TranslationDto>;
  serviceCategory?: ServiceCategoryDto
}


export interface ServiceCategoryCreationDto {
  code: string;
  active: boolean;
  translations: Array<TranslationDto>;
}

export interface ServiceSubCategoryCreationDto {
  categoryId: number;
  code: string;
  active: boolean;
  translations: Array<TranslationDto>;
}

export interface JobSubCategoryLevelDto {
  id: number;
  jobSubCategoryId: number;
  serviceSubCategory: ServiceSubCategoryDto;
  experienceLevel: ExperienceLevelEnum;
  locked: boolean;
}