import { Pipe, PipeTransform } from "@angular/core";
import { AccountType } from "../models/account";
import { AccountStatus } from "../models/dto/account.dto";
import { CurrencyEnum } from "../models/dto/currency.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'registrationStatusFlag'
  })
  export class RegistrationStatusFlagPipe implements PipeTransform {

    private readonly accountTypeMap = ReadonlyMapBuilder.create<AccountStatus, string>()
    .set(AccountStatus.IN_REVIEW ,'In Review')
    .set(AccountStatus.NOT_VERIFIED,'Not Verified')
    .set(AccountStatus.PENDING, 'Pending')
    .set(AccountStatus.VERIFIED ,'Verified')
    .set(AccountStatus.ACTIVE ,'Active')
    .set(AccountStatus.INACTIVE ,'Inactive')


    .build();

    transform(value: AccountStatus): string {
      return this.accountTypeMap.get(value);
    }

}
