<div  class="grid grid-nogutter" *ngIf="defaultBankAccount; else noBankAccountTemplate">
    <div class="col-1 img-col">
        <div>
            <img src="/assets/media/img/bank.png" height="30" width="30"/>
        </div>
    </div>
    <div class="col-11">

    </div>
</div>

<ng-template #noBankAccountTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                (click)="onClick.emit()"
                class="primary" label="Bank Account" 
                icon="pi pi-plus"  [disabled]="!enabledButton"></p-button>
            </div>
        </div>
    </div>
</ng-template>