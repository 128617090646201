export const Messages = {
    HEADER_GENERIC_ERROR: 'Server error',
    HEADER_EXPIRED_SESSION_ERROR: 'Session expired',
    HEADER_ACCESS_RESTRICTED: 'Access restricted',
    HEADER_AUTHENTICATION_FAILED_ERROR: 'Authentication failed',
    HEADER_INVALID_INPUT: 'Invalid Input',
    HEADER_ACCOUNT_EXIST: 'Account already exist',
    HEADER_ACCOUNT_STATUS: 'Account status invalid',
    HEADER_IMAGE_UPLOAD_ISSUE: 'Problem uploading image',
    HEADER_COMPANY_NAME_EXIST: 'Company name already exist',
    HEADER_ACCOUNT_HAS_ONGOING_BOOKINGS_OR_INTERVIEWS: 'ACCOUNT_HAS_ONGOING_BOOKINGS_OR_INTERVIEWS',
    HEADER_INSUFFICIENT_PERMISSION: 'Insufficient Permission',
    HEADER_PROFILE_ALREADY_CREATED_FOR_SUB_CATEGORY: 'Duplicate Job Profile',
    HEADER_NO_AVAILABLE_FEEDBACKS: 'No Available Feedbacks',
    // HEADER_DUPLICATE_VALUE: '',


    MESSAGE_FIELD_IS_REQUIRED: 'This field is required',
    MESSAGE_ACCOUNT_EMAIL_EXIST: 'Account email is already exist, please try another one',
    MESSAGE_ACCOUNT_HAS_ONGOING_BOOKINGS_OR_INTERVIEWS: 'Account has ongoing bookings or interviews!',
    MESSAGE_ACTION_NOT_ALLOWED: 'Action not allowed. Please contact Support for assistance.',
    MESSAGE_COMPANY_NAME_EXIST: 'Company name is already exist, please try another one',
    MESSAGE_CODE_IS_INVALID: 'Code should be in uppercase',
    MESSAGE_DUPLICATE_AGENCY_NAME: 'Duplicate Agency Name',
    MESSAGE_USERNAME_IS_REQUIRED: 'Email is required',
    MESSAGE_USERNAME_IS_INVALID: 'Email is invalid',
    MESSAGE_DATE_IS_PRESENT_ERROR: 'Date inputted must be a past date.',
    MESSAGE_IMAGE_UPLOAD_ISSUE: 'Problem uploading image',
    MESSAGE_GENERIC_ERROR: 'We were unable to process your request right now. Please try again at a later time. If the error persists, please contact Support for assistance.',
    MESSAGE_INVALID_BUSINESS_ERROR: 'We could not identify the business intended for this request. Please try refreshing your browser and try again.',
    MESSAGE_STAFF_NOT_FOUND_ERROR: 'We were unable to find a corresponding staff profile for this user in this business.',
    MESSAGE_EXPIRED_SESSION_ERROR: 'Your session has expired. For your own security, please sign in again.',
    MESSAGE_ACCESS_RESTRICTED_ERROR: 'You do not have sufficient access privileges to access this content.',
    MESSAGE_DOMAIN_ACCESS_DENIED_ERROR: 'You are unauthorized to access this domain.',
    MESSAGE_INVITE_STAFF_INVALID_BUSINESS_ERROR: 'Unable to send invitation email(s) due to invalid business information. Please refresh the page and try again.',
    MESSAGE_INVITE_STAFF_CANNOT_INVITE_SELF_ERROR: 'Unable to send staff invitation to your own email.',
    MESSAGE_INVITE_STAFF_EXISTING_STAFF_ERROR: 'Unable to send staff invitation email(s) to existing staff member(s).',
    MESSAGE_INVITE_STAFF_ALREADY_SENT_ERROR: 'You have already sent an invitation to one or more staff member(s). Please wait at least 3 minutes if you want to re-send another one.',
    MESSAGE_PRINCIPAL_BUSINESS_NOT_FOUND_ERROR: 'No principal business was found for this domain.',
    MESSAGE_GROUP_ALREADY_EXISTS_ERROR: 'Group with a similar name already exists in this business.',
    MESSAGE_EMAIL_UNAVAILABLE_ERROR: 'This email is no longer available. Please choose another email and try again.',
    MESSAGE_EMAIL_PASSWORD_INCORRECT_ERROR: 'Email/password is incorrect',
    MESSAGE_PROFILE_ALREADY_CREATED_FOR_SUB_CATEGORY: 'Duplicate job profile for opentimer!',
    MESSAGE_USER_NOT_FOUND_ERROR: 'We were unable to find the user associated with this request. If you need further help in resolving the issue, please contact our Support Team for further assistance.',
    MESSAGE_STAFF_INVITATION_NOT_FOUND_ERROR: 'The invitation link associated with your request is either invalid or has already expired.',
    MESSAGE_NO_AVAILABLE_FEEDBACKS: 'This booking has no available feedbacks!',
    MESSAGE_MINIMUM_SHOULD_LESS_THAN_MAXIMUM: 'Minimum value should less than maximum',
    MESSAGE_ACCOUNT_STATUS_INVALID: 'You cannot activate this Account',
  };

  const SERVER_ERROR_MESSAGES: Record<string, string> = {
    AgencyName: 'Duplicate Agency name',
    CITY_NOT_FOUND: 'Selected city is not found in the server',
    BOOKING_PROBLEM_STATUS_NOT_COMPATIBLE: 'You cannot choose a status that you have already chosen before!',
    BOOKING_CANNOT_BE_CANCELLED: 'Booking cannot be cancelled!'
  };

  const SERVER_ERROR_TITLE_KEYS: Record<string, string> = {
    AgencyName: 'Agency Name',
    CITY_NOT_FOUND: 'City Not Found',
    BOOKING_PROBLEM_STATUS_NOT_COMPATIBLE: 'Incompatible Status',
    BOOKING_CANNOT_BE_CANCELLED: 'Incompatible Status'
  };

  export const errorTitleMapper: Record<string, (key: string) => string> = {
    DUPLICATE_VALUE: (key: string) => SERVER_ERROR_TITLE_KEYS[key] || 'Duplicate value error',
    INVALID_VALUE: (key: string) => SERVER_ERROR_TITLE_KEYS[key] || 'Duplicate value error',
    INCOMPATIBLE_STATUS: (key: string) => SERVER_ERROR_TITLE_KEYS[key] || 'Duplicate value error',
    default: () => 'An unknown error occurred.',
  };

  export const errorMapper: Record<string, (key: string) => string> = {
    DUPLICATE_VALUE: (key: string) => SERVER_ERROR_MESSAGES[key] || 'Duplicate value error',
    INVALID_VALUE: (key: string) => SERVER_ERROR_MESSAGES[key] || 'Duplicate value error',
    INCOMPATIBLE_STATUS: (key: string) => SERVER_ERROR_MESSAGES[key] || 'Duplicate value error',
    default: () => 'An unknown error occurred.',
  };

  export function getErrorMessage(errorResponse: any): string {
    const mapper = errorMapper[errorResponse.error] || errorMapper["default"];
    return mapper(errorResponse.key);
  }

  export function getErrorTitle(errorResponse: any): string {
    const mapper = errorTitleMapper[errorResponse.error] || errorMapper["default"];
    return mapper(errorResponse.key);
  }
