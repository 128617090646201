import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiEventStatus, ApiEventType } from 'src/app/shared/models/api-event';
import { ServiceCategoriesSchema, ServiceCategoryDto } from 'src/app/shared/models/dto/service-category.dto';
import { ApiEventService } from 'src/app/shared/services/api-event.service';
import { ServiceCategoryService } from 'src/app/shared/services/service-caregory.service';
import * as _ from 'lodash';  

@UntilDestroy()
@Component({
  selector: 'app-service-category-dropdown',
  templateUrl: './service-category-dropdown.component.html',
  styleUrls: ['./service-category-dropdown.component.scss']
})
export class ServiceCategoryDropdownComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() ngControl: AbstractControl;
  @Input() name: string;
  @Input() optionValue: string;
  @Input() placeholder: string = 'Select'
  @Input() isForm: boolean = true;
  @Input() jobCategoryStatus: boolean = null;


  @Input() serviceCategory: ServiceCategoryDto = null;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @Output() onSelectedServiceCategory = new EventEmitter<number>();


  // disabled: boolean = false;
  overlayVisible: boolean = false;
  focused: boolean = false;
  showClear: boolean = false;
  disableInfiniteScroll: boolean = false;
  haltInfiniteScroll: boolean = false;


  items: Array<ServiceCategoryDto> = [];
  total: number = 0;
  serviceCategoryId: number;
  constructor(private serviceCategoryService: ServiceCategoryService,
              private apiEventService: ApiEventService,
              private cdref: ChangeDetectorRef) { 
              this.onKeypressEvent = _.debounce(this.onKeypressEvent, 500)
  }

  ngOnInit(): void {
    this.initServiceCategory();
    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe(event=>{
      if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_SERVICE_CATEGORIES) {
        this.haltInfiniteScroll = false;
        const response = this.serviceCategoryService.serviceCaregorySchema$.value;
        response.serviceCategories.forEach(v=>{
          this.items.push(v)
        });
        this.total = response.total;
      // this.initServiceCategory();

      }
    })
  }


  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['serviceCategory'].currentValue != undefined && changes['serviceCategory'].currentValue) {
      this.initServiceCategory();
      this.cdref.detectChanges();
    }
  }

  initServiceCategory() {
    if(this.serviceCategory) {
      this.items.push(this.serviceCategory);
      this.serviceCategoryId = this.serviceCategory.id;
    }

    if(this.isForm && this.ngControl.get(this.name).value) {
      this.serviceCategoryId = Number(this.ngControl.get(this.name).value);
    }
  }



  onScrolledBottom() {
    if(this.items.length >= this.total) {
      this.disableInfiniteScroll = true;
    }

    if((this.items.length <= this.total) && !this.haltInfiniteScroll) {
      const lastItemServiceCategory = this.items[this.items.length -1];
      this.serviceCategoryService.getServiceCategories(lastItemServiceCategory.id, 20, null, null, this.jobCategoryStatus);
      this.haltInfiniteScroll = true;
    } 
  }

  onKeypressEvent(ev) {
    // this.serviceCategoryService.getServiceCategories(null, null, 20, ev.target.value as string);
    this.items = [];
  }
}
