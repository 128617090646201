import {
    Directive, EventEmitter, HostListener, Input, Output
  }
  from '@angular/core';
  
  @Directive({
    selector: '[scrollTracker]'
  })
  export class ScrollTrackerDirective {
    @Input() theBoundCallback: Function; 
    @Input() disableInfiniteScroll: boolean;
    @Input() haltInfiniteScroll: boolean;

    @HostListener('scroll', ['$event'])

    onScroll(event) {
      // do tracking
      // console.log('scrolled', event.target.scrollTop);
      // Listen to click events in the component
      let tracker = event.target;
  
      let limit = tracker.scrollHeight - tracker.clientHeight;

      let offSet: number = 5;

      if(!this.disableInfiniteScroll) {
        if ((limit > 100) && 
            !this.haltInfiniteScroll &&
            (event.target.scrollTop >= (limit - offSet) &&
             event.target.scrollTop <= (limit + 5)
            )) {
            this.theBoundCallback();
            event.target.scrollTop = (event.target.scrollTop - 100)
        }
      }
    }
    constructor() {}
  }