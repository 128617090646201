import { AccountStatus } from "./account.dto";
import { RejectionReasonDto } from "./rejection-reason.dto";

export enum IdVerificationType {
    DRIVERS_LICENSE = 'DRIVERS_LICENSE',
    INSURANCE_CARD = 'INSURANCE_CARD',
    COPY_OF_RESIDENCE_CARD = 'COPY_OF_RESIDENCE_CARD',
    BASIC_RESIDENCE_REGISTRATION_CARD = 'BASIC_RESIDENCE_REGISTRATION_CARD',
    MY_NUMBER_CARD  = 'MY_NUMBER_CARD '   
}

export enum IdVerificationStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED' 
}

export enum IdVerificationItemType {
    ID_FRONT = 'ID_FRONT',
    ID_BACK = 'ID_BACK',
    ID_SELFIE  = 'ID_SELFIE'  
}

export interface IdVerificationDto {
    type: IdVerificationType;
    status: IdVerificationStatus;
    idVerificationItems: Array<IdVerificationDisplayItemDto>
}

export interface IdVerificationDisplayItemDto {
    status: IdVerificationStatus;
    type: IdVerificationItemType;
    rejectionReasonDto: RejectionReasonDto;
    memo: string;
    imageString?: string;
}