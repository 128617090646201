import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from '../../services/image.service';

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnInit {
  @Input() avatarUri: string;
  @Input() formDataString: string;
  @Input() id: number;
  @Input() contactImage: boolean = false;
  @Input() profileImage: boolean = false;
  @Input() hideUploadButton: boolean = false;

  constructor(private accountImageService: ImageService) { }

  ngOnInit(): void {
  }

  onFileChange(event: any) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const file = event.target.files[0];
      this.upload(file);
    }
  }

  upload(file: File) {
    const formData = new FormData();
    formData.append(this.formDataString, file);
    if(this.contactImage) {
      this.accountImageService.updateContactImage(this.id, formData);
    } else if(this.profileImage) {
      this.accountImageService.updateProfileImage(this.id, formData);
    } else {
      this.accountImageService.updateAccountImage(this.id, formData);
    }
  }

}
