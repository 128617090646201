import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CONST} from 'src/app/shared/constant';
import {AccountType} from 'src/app/shared/models/account';
import {EmailAddressType, EmailDto, PhoneNumberDto} from 'src/app/shared/models/dto/contact.dto';
import {CountryDto} from 'src/app/shared/models/dto/country.dto';
import {PhonesAndEmailsDto} from 'src/app/shared/models/dto/phones-emails.dto';
import {AdminCreationService} from 'src/app/shared/services/admin-creation.service';
import {AgencyService} from 'src/app/shared/services/agency.service';
import {CountryService} from 'src/app/shared/services/country.service';
import {CustomerService} from 'src/app/shared/services/customer.service';

@UntilDestroy()
@Component({
  selector: 'app-edit-user-contacts-information',
  templateUrl: './edit-user-contacts-information.component.html',
  styleUrls: ['./edit-user-contacts-information.component.scss']
})
export class EditUserContactsInformationComponent implements OnInit {
  @Input() phones: Array<PhoneNumberDto> = [];
  @Input() emails: Array<EmailDto> = [];
  @Input() showContactsInformationDialog: boolean;
  @Input() accountType: AccountType;
  @Input() accountId: number;

  @Output() onHide = new EventEmitter();

  contactsInfoForm = this.formBuilder.group({
    phoneForm: this.formBuilder.group({
      countryCode: this.formBuilder.control(''),
      countryId: this.formBuilder.control('', Validators.required),
      phone: this.formBuilder.control('', [Validators.required, Validators.minLength(10),
        Validators.pattern('^[0-9]*$')]),
      type: this.formBuilder.control('MOBILE', [Validators.required]),
    }),
    emailForm: this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.required, Validators.pattern(CONST.EMAIL_REGEX)]),
      type: this.formBuilder.control('PERSONAL', [Validators.required]),
    }),
  });

  countries: Array<CountryDto> = [];

  phoneNumbers: Array<PhoneNumberDto> = [];
  emailAddresses: Array<string> = [];

  constructor(private formBuilder: FormBuilder,
              private agencyService: AgencyService,
              private customerService: CustomerService,
              private countryService: CountryService,
              private adminCreationService: AdminCreationService) {
  }

  ngOnInit(): void {
    this.getSubcribers();

    this.emails.forEach(v => {
      this.emailAddresses.push(v.address);
    });
    this.phoneNumbers = this.phones;
  }

  getSubcribers() {
    this.countryService.countries$
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.countries = response;
      })
  }

  addPhoneNumber() {
    const phoneFormValue = this.contactsInfoForm.controls['phoneForm'].value;
    const selectedCountry = this.countries.find(v => v.id === Number(phoneFormValue.countryId));

    this.phoneNumbers.push({
      countryId: selectedCountry.id,
      extension: selectedCountry.phoneCode,
      number: phoneFormValue.phone,
      type: phoneFormValue.type
    });
    this.contactsInfoForm.controls['phoneForm'].reset()
    this.contactsInfoForm.controls['phoneForm'].patchValue({
      type: 'MOBILE',
    });
    this.contactsInfoForm.controls['phoneForm'].markAsPristine();
  }

  addEmail() {
    const emailValue = this.contactsInfoForm.controls['emailForm'].value;
    this.emailAddresses.push(emailValue.email);
    this.contactsInfoForm.controls['emailForm'].reset()
    this.contactsInfoForm.controls['emailForm'].patchValue({
      type: 'PERSONAL',
    });
    this.contactsInfoForm.controls['emailForm'].markAsPristine();
  }

  onSaveUserContactsInformation() {
    const emailsDto: Array<EmailDto> = this.emailAddresses.map((v: string) => {
      return {type: EmailAddressType.PERSONAL, address: v}
    })
    const phonesAndEmails: PhonesAndEmailsDto = {
      phoneNumbers: this.phoneNumbers,
      emails: emailsDto
    }


    if (this.accountType === AccountType.AGENCY) {
      this.agencyService.upsertAgencyPhonesAndEmails(this.accountId, phonesAndEmails);
    } else if (this.accountType === AccountType.BUSINESS_CUSTOMER || this.accountType === AccountType.INDIVIDUAL_CUSTOMER) {
      this.customerService.upsertCustomerPhonesAndEmails(this.accountId, phonesAndEmails)
    } else {
      const adminSchema = this.adminCreationService.adminSchema$.value;

      adminSchema.admin.contact.contactsInfo = {
        addressLocations: adminSchema.admin.contact.contactsInfo.addressLocations,
        emails: emailsDto,
        phones: this.phoneNumbers
      };
      this.adminCreationService.updateAdmin(adminSchema.admin.id, adminSchema.admin)
    }


  }
}
