import {Pipe, PipeTransform} from '@angular/core';
import {Time} from './time';
import {addLeadingZero} from './time-formatting';

@Pipe({
  name: 'timeDuration',
})
export class TimeDurationPipe implements PipeTransform {
  transform(time: Time): string {
    if (!time) {
      return null;
    }
    let hours = `${addLeadingZero(time.getHours())} hours`;
    let minutes = time.getMinutes() > 0 ? ` ${addLeadingZero(time.getMinutes())} min` : '';
    return hours + minutes;
  }
}
