import { AccountType } from "../account";
import { ContactBasicDto } from "./contact.dto";

export interface BookingOrderWorkTimeOverViewDto {
    noOfCancelledDays: number;
    noOfArrivedLateDays:  number;
    scheduledHrsInSeconds: number;
    reportedHrsInSeconds: number;
}


export enum WorkingTimeStatus {
    IN_REVIEW = 'IN_REVIEW',
    APPROVED_BY_AGENCY = 'APPROVED_BY_AGENCY',
    APPROVED_BY_CUSTOMER = 'APPROVED_BY_CUSTOMER',
    ADJUSTED_BY_AGENCY = 'ADJUSTED_BY_AGENCY',
    ADJUSTED_BY_CUSTOMER = 'ADJUSTED_BY_CUSTOMER',
    CANCELED_BY_AGENCY = 'CANCELED_BY_AGENCY',
    CANCELED_BY_CUSTOMER = 'CANCELED_BY_CUSTOMER',
    AGENCY_AUTOMATIC_APPROVAL = 'AGENCY_AUTOMATIC_APPROVAL',
    CUSTOMER_AUTOMATIC_APPROVAL = 'CUSTOMER_AUTOMATIC_APPROVAL',
    INCLUDED_IN_INVOICE = 'INCLUDED_IN_INVOICE'
}

export enum WorkingTimeReason {
    CALLED_IN_SICK = 'CALLED_IN_SICK',
    DIDNT_SHOW_UP = 'DIDNT_SHOW_UP',
    REQUESTED_DAY_OFF = 'REQUESTED_DAY_OFF',
    ARRIVED_LATE = 'ARRIVED_LATE'
}

export interface WorkingTimeDisplaySchema {
    workingTimeDisplayDtos: Array<WorkingTimeDisplayDto>;
    total: number;
}

export interface WorkingTimeDisplayDto {
    id: number;
    day: string;
    startTime: string;
    endTime: string;
    expectedStartTime: string;
    expectedEndTime: string;
    expectedDuration: string;
    effectiveDuration: string;
    normalDuration: string;
    overtimeDuration: string;
    midnightOvertimeDuration: string;
    midnightDuration: string;
    breakTimeDuration: string;
    creationDateTime: string;
    status: WorkingTimeStatus;
    historyList: Array<WorkingTimeHistoryDto>;
    bookingId: number;
}

export interface WorkingTimeHistoryDto {
    id: number;
    startTime: string;
    endTime: string;
    specificReason: string;
    creationDateTime: string;
    status: WorkingTimeStatus;
    creatorType: AccountType;
    createdBy: ContactBasicDto;
    reason: WorkingTimeReason;

}