export interface AvailabilityDto {
    availableSeconds: number;
    totalSeconds: number; 
}

/* eslint-disable no-unused-vars */
export enum AssignmentDurationTypes{
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
  }