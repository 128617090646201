<app-edit-dialog [visible]="showEditDialog" [title]="'Edit Availability'"
                 (onSubmit)="onUpdate()"
                 [dialogHeight]="'80'"
                 (onHide)="onHide.emit()" [disabled]="!availabilityForm.valid">
  <div class="grid grid-nogutter justify-content-center">
    <div class="p-card content-panel v-80 p-3 shadow-5">
      <form [formGroup]="availabilityForm">
        <div class="grid grid-nogutter mb-3">
          <div class="col-12">
            <p class="page-title-primary">ADD AVAILABILITY</p>
            <p class="text-rm"><i>Select booking type (Select up to 3 types)</i></p>
          </div>
        </div>

        <ng-container *ngFor="let jobType of jobTypeItems; let i = index">
          <div class="grid grid-nogutter checkbox-container mb-3">
            <div class="col-1 checkbox-col">
              <div class="checkbox" (click)="onSelectJobType(jobType)">
                <i *ngIf="jobType.isSelected" class="pi pi-check"></i>
              </div>
            </div>
            <div class="col-9">
              <p class="text-rb">{{jobType.name}}</p>
              <p class="text-rm">{{jobType.description}}</p>
            </div>
          </div>
        </ng-container>


        <div class="grid grid-nogutter">
          <ng-container *ngIf="availabilityForm.controls['jobTypes'].dirty && !availabilityForm.controls['jobTypes'].valid">
            <div class="col-12">
              <p class="text-velvet">This field is required</p>
            </div>
          </ng-container>
        </div>

        <div class="grid grid-nogutter mt-3">
          <div class="col-12">
            <p class="page-title-primary">Job Profile Availability</p>
          </div>
        </div>

        <ng-container [formGroup]="availabilityForm.controls['workingTime']">
          <div class="grid grid-nogutter mt-1">
            <div class="col-12 lg:col-6 pr-0 lg:pr-2">
              <p class="text-rm text-primary">Start time and end time</p>
              <div class="grid grid-nogutter p-1">
                <div class="col-12 lg:col-6 pr-0 lg:pr-2">
                  <label class="mt-1 text-rm" >Earliest start time</label>
                  <p-calendar [timeOnly]="true"
                              #startTimeCalendar
                              class="full-width-calendar-input mt-2"
                              placeholder="Select time"
                              appendTo="body"
                              formControlName="startTime"
                              timeFormat="hh:mm A"
                              [hourFormat]="'12'" inputId="timeonly">
                    <ng-template pTemplate="footer">
                      <button pButton pRipple (click)="onSetCurrentTime('startTime', startTimeCalendar)"
                              type="button" label="Set current time" class="p-button-text">
                      </button>
                    </ng-template>
                  </p-calendar>
                </div>
                <div class="col-12 lg:col-6 mt-2 lg:mt-0">
                  <label class="mt-1 text-rm" >Earliest end time</label>
                  <p-calendar [timeOnly]="true"
                              #endTimeCalendar
                              [disabled]="!availabilityForm.controls['workingTime'].get('startTime').value"
                              class="full-width-calendar-input mt-2"
                              placeholder="Select time"
                              appendTo="body"
                              timeFormat="hh:mm A"
                              formControlName="endTime"
                              [minDate]="availabilityForm.controls['workingTime'].get('startTime').value"
                              [hourFormat]="'12'" inputId="timeonly">
                    <ng-template pTemplate="footer">
                      <button pButton pRipple (click)="onSetCurrentTime('endTime', endTimeCalendar)"
                              type="button" label="Set current time" class="p-button-text">
                      </button>
                    </ng-template>
                  </p-calendar>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6">
              <p class="text-rm text-primary">Hour per day</p>
              <div class="grid grid-nogutter p-1">
                <div class="col-12 lg:col-6 grid grid-nogutter block pr-0 lg:pr-2">
                  <label class="text-rm" >Minimum</label>
                  <div class="col-12">
                    <p-dropdown [options]="hours" formControlName="minWorkingHour"
                                [style]="{'width':'100%'}" appendTo="body"
                                placeholder="Select Minimum Hour"  inputId="timeonly">
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-12 lg:col-6 mt-2 lg:mt-0 grid grid-nogutter block">
                  <label class="mt-1 text-rm" >Maximum</label>
                  <div class="col-12">
                    <p-dropdown [options]="maximumHours" formControlName="maxWorkingHour"
                                [disabled]="!availabilityForm.controls['workingTime'].get('minWorkingHour').value"
                                [style]="{'width':'100%'}" appendTo="body"
                                placeholder="Select Minimum Hour"  inputId="timeonly">
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter mt-3">
            <div class="col-12 lg:col-6 pr-0 lg:pr-2">
              <p class="text-rm text-primary">Days per week</p>
              <div class="grid grid-nogutter p-1">
                <div class="col-12 lg:col-6 grid grid-nogutter block pr- lg:pr-2">
                  <label class="text-rm" >Minimum</label>
                  <div class="col-12">
                    <p-dropdown [options]="days" formControlName="minWorkingDay"
                                [style]="{'width':'100%'}" appendTo="body"
                                placeholder="Select Minimum Day"  inputId="timeonly">
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-12 lg:col-6 grid grid-nogutter block mt-2 lg:mt-0">
                  <label class="mt-1 text-rm" >Maximum</label>
                  <div class="col-12">
                    <p-dropdown [options]="maximumDays" formControlName="maxWorkingDay"
                                [disabled]="!availabilityForm.controls['workingTime'].get('minWorkingDay').value"
                                [style]="{'width':'100%'}" appendTo="body"
                                placeholder="Select Minimum Day"  inputId="timeonly">
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 pr-0 lg:pr-2">
              <p class="text-rm text-primary">Job duration</p>
              <div class="grid grid-nogutter p-1">
                <div class="col-12 lg:col-6 grid grid-nogutter block pr-0 lg:pr-2">
                  <label class="text-rm" >Minimum</label>
                  <div class="col-12 grid grid-nogutter" controlErrorContainer>
                    <div class="col-3 pr-2">
                      <input  id="phone" type="tel" formControlName="minAssignmentDuration"
                              appOnlyInteger
                              pInputText  placeholder="Min" style="width: 100%"/>
                    </div>
                    <div class="col-9">
                      <p-dropdown [options]="assignmentDurationTypes" formControlName="minAssignmentDurationType"
                                  [style]="{'width':'100%'}" appendTo="body"
                                  placeholder="Select minimum duration"  inputId="timeonly">
                      </p-dropdown>
                    </div>
                    <ng-container *ngIf="availabilityForm.controls['workingTime'].get('minAssignmentDuration').valid &&
                                                                availabilityForm.controls['workingTime'].get('maxAssignmentDuration').valid &&
                                                                availabilityForm.controls['workingTime'].get('minAssignmentDurationType').valid &&
                                                                availabilityForm.controls['workingTime'].get('maxAssignmentDurationType').valid &&
                                                                availabilityForm.controls['workingTime'].errors && availabilityForm.controls['workingTime']?.errors['minMaxValidator']">
                      <p class="text-velvet">Minimum value should less than maximum</p>
                    </ng-container>
                  </div>

                </div>
                <div class="col-12 lg:col-6 grid grid-nogutter block mt-2 lg:mt-0">
                  <label class="mt-1 text-rm">Maximum</label>
                  <div class="col-12 grid grid-nogutter" controlErrorContainer>
                    <div class="col-3 pr-2">
                      <input  id="duration" type="tel" formControlName="maxAssignmentDuration"
                              appOnlyInteger
                              pInputText  placeholder="Max" style="width: 100%"/>
                    </div>
                    <div class="col-9">
                      <p-dropdown [options]="maximumAssignmentDurationTypes" formControlName="maxAssignmentDurationType"
                                  [style]="{'width':'100%'}" appendTo="body"
                                  placeholder="Select minimum duration"  inputId="timeonly">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-container>

        <div class="grid grid-nogutter mt-3">
          <div class="col-12">
            <p class="page-title-primary">SELECT JOB PROFILE START AND END DATE</p>
          </div>
        </div>

        <ng-container [formGroup]="availabilityForm.controls['joiningTime']">
          <div class="grid grid-nogutter mt-1">
            <div class="col-12  pr-0 lg:pr-2">
              <div class="grid grid-nogutter p-1">
                <div class="col-12 lg:col-6 pr-0 lg:pr-2">
                  <label class="mt-1 text-rm" >Earliest <i>(Select earliest start date)</i></label>
                  <p-calendar class="full-width-calendar-input mt-2"
                              placeholder="Select earliest date"
                              formControlName="startDate"
                              [dateFormat]="CONST.DATE_FORMAT"
                              [showIcon] = "true">
                  </p-calendar>
                </div>
                <div class="col-12 lg:col-6 mt-2 lg:mt-0">
                  <label class="mt-1 text-rm" >Latest <i>(Select latest end date)</i></label>
                  <p-calendar class="full-width-calendar-input mt-2"
                              placeholder="Select latest date"
                              [dateFormat]="CONST.DATE_FORMAT"
                              [showIcon] = "true"
                              formControlName="endDate">
                  </p-calendar>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter mt-1">
            <div class="col-12  pr-0 lg:pr-2">
              <div class="grid grid-nogutter p-1">
                <div class="col-12 lg:col-6 pr-0 lg:pr-2">
                  <div class="grid grid-nogutter checkbox-container mb-3">
                    <div class="col-1 checkbox-col">
                      <div class="checkbox" (click)="onClickASAPToggle()" >
                        <i *ngIf="availabilityForm.controls['joiningTime'].get('asSoonAsPossible').value"
                           class="pi pi-check"></i>
                      </div>
                    </div>
                    <div class="col-9">
                      <p class="text-rm">Available as soon as possible </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 lg:col-6 mt-2 lg:mt-0">
                  <div class="grid grid-nogutter checkbox-container mb-3">
                    <div class="col-1 checkbox-col">
                      <div class="checkbox" (click)="onClickASLONGToggle()" >
                        <i  *ngIf="availabilityForm.controls['joiningTime'].get('asLongAsPossible').value"
                            class="pi pi-check"></i>
                      </div>
                    </div>
                    <div class="col-9">
                      <p class="text-rm">Available as long as possible </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>

        <div class="grid grid-nogutter mt-3">
          <div class="col-12">
            <p class="page-title-primary">SELECT THE DATES THAT THE JOB PROFILE NOT AVAILABLE TO WORK</p>
          </div>
        </div>

        <ng-container [formGroup]="availabilityForm.controls['dayOff']">
          <div class="grid grid-nogutter mt-1">
            <div class="col-4  pr-0 lg:pr-2">
              <p class="text-rm"><i>Select unavailable dates</i></p>
              <p-calendar  [inline]="true"
                           [minDate]="today"
                           (onSelect)="onSelect($event)"
                           [disabledDates]="disableSelectedDates"
                           class="full-width-calendar-input">
              </p-calendar>
            </div>
            <div class="col-8  pr-0 lg:pr-2 dayoffs-item-container">
              <p class="text-rb">DAYS OFF</p>
              <ng-container *ngFor="let date of this.availabilityForm.controls['dayOff'].get('daysOff').value; let i = index">
                <div class="p-1 dayoff-item shadow-6 mt-2 mr-2">
                  <p>{{date}}</p>
                  <i class="pi pi-trash" (click)="onRemoveUnavailableDate(i)"></i>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="grid grid-nogutter checkbox-container mt-3 mb-3">
            <div class="col-1 checkbox-col">
              <div class="checkbox" (click)="onClickAvailableOnWeekEnd()" >
                <i  *ngIf="availabilityForm.controls['dayOff'].get('availableOnWeekend').value"
                    class="pi pi-check"></i>
              </div>
            </div>
            <div class="col-9">
              <p class="text-rm">I'm not available on weekends</p>
            </div>
          </div>
        </ng-container>

      </form>
    </div>
  </div>
</app-edit-dialog>
