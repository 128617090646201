<div  class="grid grid-nogutter info-panel-shadow customer-item p-2 align-items-center">
    <div class="col-2 lg:col-2">
        <div  class="grid grid-nogutter">
            <div class="col-12 lg:col-4">
                <img [src]="customer.avatarUri | avatarUriTransform" class="account-avatar" 
                onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
            </div>
            <div class="col-12 lg:col-8">
                <p class="text-rb">{{customer.name}}</p>
                <div class="flex align-items-center" *ngIf="location">
                    <i class="pi pi-map-marker"></i>
                    <p>{{location.city}}, {{location.country}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-8 lg:col-8">
        <div  class="grid grid-nogutter">
            <div class="col-12 lg:col-2 flex lg:block">
                <p class="text-l-gray">Account ID:</p>
                <p class="text-rb">{{customer.id}}</p>
            </div>
            <div class="col-12 lg:col-3 flex lg:block">
                <p class="text-l-gray">Account Type:</p>
                <p class="text-rb">{{customer.type | accountTypeMap}}</p>
            </div>
            <div class="col-12 lg:col-3 flex lg:block">
                <p class="text-l-gray">Phone:</p>
                <p *ngIf="phone" class="text-rb">+{{phone.extension}}{{phone.number}}</p>
                <p *ngIf="!phone" class="text-rb">N/A</p>
            </div>
            <div class="col-12 lg:col-4 flex lg:block">
                <p class="text-l-gray">Email:</p>
                <p *ngIf="contact" class="text-rb">{{contact.signInEmail}}</p>
                <p *ngIf="!contact" class="text-rb">N/A</p>
            </div>
        </div>
    </div>


    <ng-container *ngIf="!isDisplayMode && !showEditButton; else displayModetemplate">
        <div class="col-2 lg:col-2">
            <button pButton  label="Select"
                    (click)="onSelectCustomer.emit(customer)"
                    class="success  w-100" pRipple> 
            </button>
        </div>
    </ng-container>
    <ng-template #displayModetemplate>
        <div class="col-2 lg:col-2  flex justify-content-end align-items-start">
            <button pButton pRipple type="button" icon="pi pi-pencil" 
                    (click)="onEditCustomer.emit()"
                    class="p-button-rounded p-button-text">
            </button>
        </div>
    </ng-template>

</div>