import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventStatus, ApiEventType} from "../../../models/api-event";
import {ApiEventService} from "../../../services/api-event.service";
import {OpentimerJobProfileService} from "../../../services/opentimer-job-profile-service";
import {MessageService} from "primeng/api";
import * as moment from "moment/moment";
import {TextAlignPosition} from "../../labelled-input/labelled-input.component";
import {
  PreviousExperienceDtoSchema, PreviousExperienceInputDto
} from "../../../models/dto/previous-work-experience.dto";
import {LeavingReasonEnum} from "../../../models/dto/leaving-reason.dto";
import {ReasonForLeaving, ReasonForLeavingService} from "../../../services/reason-for-leaving.service";
import {CONST} from "../../../constant";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-experience',
  templateUrl: './edit-profile-experience.component.html',
  styleUrls: ['./edit-profile-experience.component.scss']
})
export class EditProfileExperienceComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() profileId: number;
  @Input() reasonsForLeaving: Array<ReasonForLeaving>;

  @Output() onClose = new EventEmitter();

  previousExperienceSchema: PreviousExperienceDtoSchema;
  maxDateValue = new Date();


  previousExperienceForm = this.formBuilder.group({
    companyName: this.formBuilder.control('', [Validators.required, Validators.minLength(2)]),
    description: this.formBuilder.control(''),
    location: this.formBuilder.control('', [Validators.required, Validators.minLength(2)]),
    title: this.formBuilder.control(''),
    from: this.formBuilder.control('', [Validators.required]),
    to: this.formBuilder.control('', [Validators.required]),
    leavingReason: this.formBuilder.control<LeavingReasonEnum>(null, [Validators.required]),
  });


  constructor(private formBuilder: FormBuilder,
              private opentimerJobProfileService: OpentimerJobProfileService,
              private messageService: MessageService,
              private apiEventService: ApiEventService) {
  }

  ngOnInit(): void {
    this.getApiEvents();
    this.opentimerJobProfileService.getJobProfileProfessionalExperiences(this.profileId);
  }

  protected getApiEvents(): void {
    if (this.apiEventService.event) {
      this.apiEventService.event
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          if (event && event.type === ApiEventType.GET_JOB_PROFILE_PROFESSIONAL_EXPERIENCES && event.status === ApiEventStatus.COMPLETED) {
            this.previousExperienceSchema = this.opentimerJobProfileService.previousExperienceSchema$.value;
          }

          if (event && event.type === ApiEventType.ADD_JOB_PROFILE_PROFESSIONAL_EXPERIENCES && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileProfessionalExperiences(this.profileId);
            this.previousExperienceForm.reset();
            this.messageService.add({
              severity: 'success',
              summary: 'Previous Experience added',
              detail: 'Previous Experience is added to this profile'
            });
          }

          if (event && event.type === ApiEventType.DELETE_JOB_PROFILE_PROFESSIONAL_EXPERIENCES && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileProfessionalExperiences(this.profileId);
            this.messageService.add({
              severity: 'success',
              summary: 'Previous Experience deleted',
              detail: 'Previous Experience is deleted from this profile'
            });
          }
        });
    } else {
      console.error('ApiEventService event is null');
    }
  }

  addProfessionalExperience() {
    const formValue = this.previousExperienceForm.value;
    const previousExperienceDto: PreviousExperienceInputDto = {
      title: formValue.title,
      companyName: formValue.companyName,
      location: formValue.location,
      from: moment(formValue.from).format(CONST.DATE_FORMAT_MOMENT),
      to: moment(formValue.to).format(CONST.DATE_FORMAT_MOMENT),
      description: formValue.description,
      leavingReason: formValue.leavingReason,
    };
    this.opentimerJobProfileService.addJobProfileProfessionalExperience(this.profileId, previousExperienceDto);
  }

  removeProfessionalExperience(experienceId: number) {
    this.opentimerJobProfileService.deleteJobProfileProfessionalExperience(this.profileId, experienceId);
  }

  getReason(reason: string): ReasonForLeaving {
    return this.reasonsForLeaving.find(s => s.reasonForLeaving === reason);
  }

  protected readonly TextAlignPosition = TextAlignPosition;
  protected readonly CONST = CONST;
}
