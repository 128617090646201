<!-- (onHide)="onHide.emit()" [disabled]="!serviceSubCategoryForm.valid" -->
<app-edit-dialog [visible]="showEditDialog" [title]="'Add/Edit Skill'" 
                 (onSubmit)="onSubmitSkill()"
                 [dialogHeight]="'28'"
                 (onHide)="onHide.emit()"
                 [disabled]="!skillForm.valid">
            <ng-container [formGroup]="skillForm">
                <div  class="mt-3 grid">
                    <div class="col-6">
                        <app-labelled-input [ngControl]="skillForm" 
                             [displayBlock] = "true"
                             [placeholder]="'Skill Name'" [label]="'Skill Name'" 
                             [name]="'skill'">
                        </app-labelled-input>
                     </div>
                     <div class="col-6">
                        <app-labelled-input [ngControl]="skillForm" 
                             [displayBlock] = "true"
                             [placeholder]="'Skill Native Name'" [label]="'Skill Native Name'" 
                             [name]="'skillNativeName'">
                        </app-labelled-input>
                     </div>
                     <div class="col-12">
                        <label class="block text-rb">Status</label>
                         <p-toggleButton onLabel='Active' offLabel="Inactive"
                              offIcon="pi pi-times" onIcon='pi pi-check' formControlName="valid"></p-toggleButton>
                      </div>
                </div>
            </ng-container>
</app-edit-dialog>