import { Time } from "../../time/time";
import { BookingType } from "./booking.dto";
import { CountryTimeZoneDto } from "./country-timezones.dto";
import { TranslationDto } from "./translation.dto";


export class ShiftEntryItem {
    private static readonly NORMAL_WORK_DAY_TIME = Time.of(8, 0, 0);
    private static readonly ONE_HOUR = Time.of(1, 0, 0);

    constructor(public day: DaysEnum, 
                public startTime: Time,
                public endTime: Time) {}

    get overTimeHours(): Time {
        const total = this.endTime.substract(this.startTime).substract(ShiftEntryItem.ONE_HOUR)
        return total.substract(ShiftEntryItem.NORMAL_WORK_DAY_TIME);
    }

    get breakHours(): Time {
        return ShiftEntryItem.ONE_HOUR;
    }

    get totalShiftHours(): Time {
        const total = this.endTime.substract(this.startTime).substract(ShiftEntryItem.ONE_HOUR)
        return total.substract(this.overTimeHours);
    }

    get totalHours(): Time {
        return this.endTime.substract(this.startTime).substract(ShiftEntryItem.ONE_HOUR)
    }
}

export class ShiftDetail {
    constructor(public shift: CustomerShiftDto,
                public presetShift: PresetShiftDto = null) {
                }

    get shiftName(): string {
        return this.shift.name;
    }

    get shiftEntryItems(): Array<ShiftEntryItem> {
        const shiftEntryItems:Array<ShiftEntryItem> = [];
        for(const shiftEntry of this.shift.shiftEntries) {
            const begin = shiftEntry.begin as any;
            const end = shiftEntry.end as any;
            const startTime = Time.fromString(begin);
            const endTime = Time.fromString(end);
            shiftEntryItems.push(
                new ShiftEntryItem(shiftEntry.day, startTime, endTime)
            )
        }
        return shiftEntryItems;
    }

    get totalWorkingShiftHours(): Time {
        let total: Time = Time.ZERO;
        for(const shiftEntryItem of this.shiftEntryItems) {
            total = total.add(shiftEntryItem.totalShiftHours)
        }
        return total;
    }

    get totalOvertimeHours(): Time {
        let total: Time = Time.ZERO;
        for(const shiftEntryItem of this.shiftEntryItems) {
            total = total.add(shiftEntryItem.overTimeHours)
        }
        return total;
    }

    get totalBreakHours(): Time {
        let total: Time = Time.ZERO;
        for(const shiftEntryItem of this.shiftEntryItems) {
            total = total.add(shiftEntryItem.breakHours)
        }
        return total;
    }

    get totalHours(): Time {
        let total: Time = Time.ZERO;
        for(const shiftEntryItem of this.shiftEntryItems) {
            total = total.add(shiftEntryItem.totalHours)
        }
        return total;
    }
} 

export enum DaysEnum {
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THU = 'THU',
    FRI = 'FRI',
    SAT = 'SAT',
    SUN = 'SUN'   
}

export enum PresetShiftTypeEnum {
    DAY = 'DAY',
    NIGHT = 'NIGHT',
    MIDNIGHT = 'MIDNIGHT'
}

export interface ShiftEntryDto {
    day: DaysEnum;
    begin: LocalTimeDto;
    end: LocalTimeDto;
}

export interface LocalTimeDto {
    hour: number;
    minute: number;
    second: number;
    nano: number;
}

export interface PresetShiftDtoSchema {
    presetShiftDtos: Array<PresetShiftDto>;
}

export interface PresetShiftDto {
    name: string;
    id?: number;
    employmentType: BookingType;
    presetShiftType:PresetShiftTypeEnum;
    executionDateTime?: string;
    days: Array<DaysEnum>;
    begin: string;
    end: string;
    translations: Array<TranslationDto>;
    active: boolean;
}


export interface PresetShiftNameDto {
    name: string;
    translations: Array<TranslationDto>;
}


export interface CustomerShiftDtoSchema {
    customerShifts: Array<CustomerShiftDto>;
}

export interface CustomerShiftDto {
    shiftEntries: Array<ShiftEntryDto>;
    name: string;
    color: string;
    id: number;
    employmentType:BookingType;
    countryTimeZone: CountryTimeZoneDto;
}