import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {CompanyDetailsDto} from "../../../models/dto/customer.dto";
import {CustomerService} from "../../../services/customer.service";
import * as moment from "moment/moment";
import {CONST} from "../../../constant";

@Component({
  selector: 'app-edit-customer-details',
  templateUrl: './edit-customer-details.component.html',
  styleUrls: ['./edit-customer-details.component.scss']
})
export class EditCustomerDetailsComponent implements OnInit {
  @Input() showCustomerDetailsDialog: boolean;
  @Input() customerDetails: CompanyDetailsDto;
  @Input() customerId: number;
  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter<CompanyDetailsDto>(undefined);

  maxDate = new Date();

  customerDetailsForm = this.formBuilder.group({
    companyName: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
    dateEstablished: this.formBuilder.control(this.maxDate, Validators.required),
    representativeFirstName: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
    representativeLastName: this.formBuilder.control('', [Validators.required, Validators.minLength(3)])
  });

  constructor(private formBuilder: FormBuilder,
              private customerService: CustomerService) { }

  ngOnInit(): void {
    if(this.customerDetails) {
      this.customerDetailsForm.patchValue({
        companyName: this.customerDetails.companyName,
        dateEstablished: new Date(this.customerDetails.dateEstablished),
        representativeFirstName: this.customerDetails.representativeFirstName,
        representativeLastName: this.customerDetails.representativeLastName
      })
    }
  }


  onSaveCustomerDetails() {
    const customerDetailsValue = this.customerDetailsForm.value;
    const companyDetailsDto: CompanyDetailsDto = {
      companyName: customerDetailsValue.companyName,
      dateEstablished: moment(customerDetailsValue.dateEstablished).format(CONST.DATE_FORMAT_MOMENT),
      representativeFirstName: customerDetailsValue.representativeFirstName,
      representativeLastName: customerDetailsValue.representativeLastName
    }
    this.onSubmit.emit(companyDetailsDto);
    this.showCustomerDetailsDialog = false;
  }

    protected readonly CONST = CONST;
}
