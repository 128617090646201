import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AgencyCreationDto, AgencyInformationDto } from 'src/app/shared/models/dto/agency.dto';
import { AgencyService } from 'src/app/shared/services/agency.service';

@Component({
  selector: 'app-edit-agency-information',
  templateUrl: './edit-agency-information.component.html',
  styleUrls: ['./edit-agency-information.component.scss']
})
export class EditAgencyInformationComponent implements OnInit {
  @Input() accountId: number;
  @Input() showAgencyInformationDialog: boolean;
  @Input() agencyInformation: AgencyInformationDto;

  @Output() onHide = new EventEmitter();  

  agencyForm = this.formBuilder.group({
    agencyName: this.formBuilder.control('',[Validators.required, Validators.minLength(2)])
  });

  constructor(private formBuilder: FormBuilder,
              private agencyService: AgencyService) { }

  ngOnInit(): void {
    if(this.agencyInformation) {
      this.agencyForm.patchValue({
        agencyName: this.agencyInformation.agencyName
      })
    }
  }


  onSaveAgencyInformation() {
    const agencyFormValue = this.agencyForm.value;
    const agencyCreation: AgencyCreationDto = {
      agencyName: agencyFormValue.agencyName
    }
    this.agencyService.upsertAgencyName(this.accountId, agencyCreation);
  }
}
