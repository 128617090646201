import {addLeadingZero} from './time-formatting';

export class Time {
  public static readonly ZERO = Time.of(0, 0, 0);
  public static readonly DAY = Time.of(24, 0, 0);

  private static readonly HH_MM_SS_REG_EXP = new RegExp('^(\\d{1,2}):(\\d{1,2})(:(\\d{1,2}))?$');

  private constructor(private hours: number,
                      private minutes: number,
                      private seconds: number,
                      private positive: boolean) {
    this.normalize();
  }

  static of(hours: number, minutes: number, seconds: number = 0, positive: boolean = true): Time {
    return new Time(hours, minutes, seconds, positive);
  }

  static fromString(text: string): Time {
    const groups = this.HH_MM_SS_REG_EXP.exec(text);
    if (!groups) {
      return null;
    }
    let seconds = 0;
    const secondsString = groups[4];
    if (secondsString) {
      seconds = parseInt(secondsString, 10);
    }
    return new Time(parseInt(groups[1], 10), parseInt(groups[2], 10), seconds, true);
  }

  static min(t1: Time, t2: Time): Time {
    return t1.compare(t2) < 0 ? t1 : t2;
  }

  static max(t1: Time, t2: Time): Time {
    return t1.compare(t2) > 0 ? t1 : t2;
  }

  getHours(): number {
    return this.hours;
  }

  getMinutes(): number {
    return this.minutes;
  }

  getSeconds(): number {
    return this.seconds;
  }

  isPositive(): boolean {
    return this.positive;
  }

  toTotalSeconds(): number {
    return (this.positive ? 1 : -1) * this.hours * 60 * 60 + this.minutes * 60 + this.seconds;
  }

  substract(other: Time): Time {
    const totalSeconds = this.toTotalSeconds() - other.toTotalSeconds();
    return Time.of(0, 0, Math.abs(totalSeconds), totalSeconds >= 0);
  }

  add(other: Time): Time {
    return Time.of(0, 0, this.toTotalSeconds() + other.toTotalSeconds());
  }

  invert(): Time {
    return new Time(this.getHours(), this.getMinutes(), this.getSeconds(), !this.isPositive());
  }

  equals(other: Time): boolean {
    return this.toTotalSeconds() === other.toTotalSeconds();
  }

  compare(other: Time): number {
    const diff = this.toTotalSeconds() - other.toTotalSeconds();
    if (diff == 0) {
      return 0;
    }
    return diff > 0 ? 1: -1;
  }

  toString(): string {
    return `Time{${this.positive ? '' : '- '}${addLeadingZero(this.hours)}:${addLeadingZero(this.minutes)}:` +
      `${addLeadingZero(this.seconds)}}`;
  }

  convertToHhMmString(): string {
    return `${this.positive ? '' : '- '}${addLeadingZero(this.hours)}:${addLeadingZero(this.minutes)}`;
  }

  convertToHhMmAString(): string {
    const hour: number = this.getHours() === 0 ? 12 : this.getHours();
    if (hour <= 12) {
      return `${addLeadingZero(hour)}:${addLeadingZero(this.getMinutes())} am`;
    } else {
      return `${addLeadingZero(hour-12)}:${addLeadingZero(this.getMinutes())} pm`;
    }
  }

  private normalize(): void {
    while (this.seconds > 59) {
      this.seconds -= 60;
      this.minutes++;
    }
    while (this.minutes > 59) {
      this.minutes -= 60;
      this.hours++;
    }
  }
}
