import { Gender } from "../gender";
import { PhoneVerificationStatus } from "../phone-verification.status";

export interface ContactResponseBody {
    contact: ContactDto;
    accounts: AccountDto[];
}

interface AccountDto {
    id: number;
    type: string;
    status: string;
    name: string;
    contacts: AccountContactDto[];
    avatarUri: string;
    contactsInfo: ContactsInfoDto;
}

interface AccountContactDto {
    contactId: number;
    role: string;
}

export interface AccountContactMinimalDto {
  accountContactId: number;
  role: string;
  avatarUri: string;
  contactPersonalInfoDto: PersonalInfoDto;
}

export interface ContactBasicDto {
    id: number;
    firstName: string;
    lastName: string;
    avatarUri: string;
}

export interface ContactDto {
    id: number;
    firstName: string;
    lastName: string;
    contactsInfo: ContactsInfoDto;
    nativeFirstName: string;
    nativeLastName: string;
    status: string;
    dateOfBirth: string;
    gender: string;
    phoneVerificationStatus: PhoneVerificationStatus;
    avatarUri?: string;
    signInEmail?: string;
}

export interface ContactInputDto {
  id: number;
  firstName: string;
  lastName: string;
  contactsInfo: ContactsInfoDto;
  nativeFirstName: string;
  nativeLastName: string;
  dateOfBirth: string;
  gender: string;
  signInEmail: string;
  signInPhone?: PhoneNumberDto;
}

export interface PersonalInfoDto {
    contactId: number;
    firstName: string;
    lastName: string;
    nativeFirstName: string;
    nativeLastName: string;
    dateOfBirth: string;
    gender: Gender;
    email: string;
    avatarUri: string;
    fullName?: string;
}

export interface ContactsInfoDto {
    emails: EmailDto[];
    addressLocations: AddressLocation[]
    phones: PhoneNumberDto[]
}

export interface PhoneNumberDto {
    id?: number;
    countryId: number;
    type: string;
    number: string;
    extension?: string;
    signIn?: boolean;
    default_?: true;
}

export class AddressLocation {
    id?: number;
    name: string;
    formattedAddress: string;
    city: string;
    country: string;
    zipCode: string;
    lat: string;
    lng: string;
    buildingName: string;
    roomNumber: string;
    locationName: string;
    _default: boolean;
    serviceLocation?: boolean;
}

/* eslint-disable no-unused-vars */
export enum EmailAddressType {
    PERSONAL,
    ALERTS,
    OFFICE,
    HOME
}

export interface EmailDto {
    type: EmailAddressType;
    address: string;
    default_?: boolean;
}
