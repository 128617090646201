import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AccountStatus } from '../models/dto/account.dto';
import { ReadonlyMapBuilder } from '../readyonly-map-builder';
import { BookingCancelRequestStatus } from '../models/dto/booking.dto';


@Directive({
    selector: '[bookingRequestStatusDirective]'
})
export class BookingCancellationRequestStatusDirective implements OnInit, OnChanges{
    @Input() bookingRequestStatus: BookingCancelRequestStatus;


    private readonly statusColorMap = ReadonlyMapBuilder.create<BookingCancelRequestStatus, string>()
    .set(BookingCancelRequestStatus.APPROVED ,'#51AF33')
    .set(BookingCancelRequestStatus.IN_PROGRESS,'#1B76F8')
    .set(BookingCancelRequestStatus.REJECTED,'#ff3251')
    .build();

    constructor(private eleRef: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.eleRef.nativeElement.style.color = this.statusColorMap.get(this.bookingRequestStatus);
    }

    ngOnInit(): void {
    }
}