<app-edit-dialog [visible]="showAgencyInformationDialog" [title]="'Edit Agency Information'" 
                 (onSubmit)="onSaveAgencyInformation()" [dialogHeight]="'20'"
                 (onHide)="onHide.emit()" [disabled]="!agencyForm.valid">
        <ng-container [formGroup]="agencyForm">
            <div  class="mt-3 grid">
                <div class="col-12">
                    <app-labelled-input [ngControl]="agencyForm" [type]="'text'"
                            [displayBlock]="true"
                            [placeholder]="'Agency Name'" [label]="'Agency Name'" [name]="'agencyName'">
                    </app-labelled-input>
                </div>
            </div>
        </ng-container>
</app-edit-dialog>