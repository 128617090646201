import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CONST } from 'src/app/shared/constant';
import { ServiceCategoryCreationDto, ServiceCategoryDto } from 'src/app/shared/models/dto/service-category.dto';
import { TranslationDto } from 'src/app/shared/models/dto/translation.dto';
import { ImageService } from 'src/app/shared/services/image.service';
import { ServiceCategoryService } from 'src/app/shared/services/service-caregory.service';

@Component({
  selector: 'app-upsert-service-category',
  templateUrl: './upsert-service-category.component.html',
  styleUrls: ['./upsert-service-category.component.scss']
})
export class UpsertServiceCategoryComponent implements OnInit {
  @Input() showServiceCategoryDialog: boolean;
  @Input() serviceCategory: ServiceCategoryDto;

  @Output() onHide = new EventEmitter();

  serviceCategoryForm = this.formBuilder.group({
    serviceCategoryName:  this.formBuilder.control('', [Validators.required, Validators.minLength(2)]), 
    serviceCategoryNativeName:  this.formBuilder.control('', [Validators.required]), 
    code:  this.formBuilder.control('', [Validators.required, Validators.minLength(3),  Validators.pattern(CONST.UPPER_CASE_REGEX)]), 
    active:  this.formBuilder.control(false, [Validators.required, Validators.minLength(3)]),
    avatarFile: this.formBuilder.control(''),
  });

  avatarUri: string = null;
  constructor(private formBuilder: FormBuilder,
              private imageService: ImageService,
              private cd: ChangeDetectorRef,
              private serviceCategoryService: ServiceCategoryService) { }

  ngOnInit(): void {
    if(this.serviceCategory) {
      this.avatarUri = this.serviceCategory.avatarUri;
      const serviceCategoryName = this.getTranslation('en', this.serviceCategory.translations);
      const serviceCategoryNativeName = this.getTranslation('ja', this.serviceCategory.translations);
      this.serviceCategoryForm.patchValue({
        active: this.serviceCategory.active,
        code: this.serviceCategory.code,
        serviceCategoryName: serviceCategoryName,
        serviceCategoryNativeName: serviceCategoryNativeName,
      });
      this.avatarUri = this.serviceCategory.avatarUri;
    }
  }

  getTranslation(language: string, translations: Array<TranslationDto >): string {
    return translations.find(v=> v.language === language) ? 
           translations.find(v=> v.language === language).content : '';
  }

  markAllAsDirty(control: AbstractControl) {
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(key => {
        this.markAllAsDirty(control.get(key));
      });
    } else if (control instanceof FormArray) {
      for (const groupOrArray of control.controls) {
        this.markAllAsDirty(groupOrArray);
      }
    } else {
      control.markAsDirty();
    }
  }


  onSubmitServiceCategory() {
    this.markAllAsDirty(this.serviceCategoryForm);
    this.cd.detectChanges();

    if(this.serviceCategoryForm.valid) {
      const serviceCategoryFormValue = this.serviceCategoryForm.value;
      if(this.serviceCategory) {
        const serviceCategoryCreationDto = this.transformServiceFormValueToServiceCreationDto();
        this.serviceCategoryService.updateServiceCategory(Number(this.serviceCategory.id), serviceCategoryCreationDto);
  
        if(serviceCategoryFormValue.avatarFile) {
          const formData = new FormData();
          formData.append('imageFile', serviceCategoryFormValue.avatarFile as any);
          this.imageService.updateJobCategoryImage(this.serviceCategory.id, formData);
        }
      }
    }

    
  }

  transformServiceFormValueToServiceCreationDto(): ServiceCategoryCreationDto {
    const serviceCategoryFormValue = this.serviceCategoryForm.value;
    return  {
      active: serviceCategoryFormValue.active,
      code: serviceCategoryFormValue.code,
      translations: [
        {
          content: serviceCategoryFormValue.serviceCategoryName,
          language: 'en'
        },
        {
          content: serviceCategoryFormValue.serviceCategoryNativeName && serviceCategoryFormValue.serviceCategoryNativeName.length ? 
                   serviceCategoryFormValue.serviceCategoryNativeName : serviceCategoryFormValue.serviceCategoryName,
          language: 'ja'
        }
      ]
    }
  }

}
