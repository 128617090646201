import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CONST } from '../shared/constant';
import { RouteService } from '../shared/services/utils/route.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor(public formBuilder: FormBuilder,
              private routeService: RouteService) { }

  ngOnInit(): void {
  }

  isSigninActive(): boolean {
    return this.routeService.endsWith('signin');
  }

}
