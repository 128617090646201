import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventStatus, ApiEventType} from "../../../models/api-event";
import {ApiEventService} from "../../../services/api-event.service";
import {OpentimerJobProfileService} from "../../../services/opentimer-job-profile-service";
import {MessageService} from "primeng/api";
import * as moment from "moment/moment";
import {TextAlignPosition} from "../../labelled-input/labelled-input.component";
import {CertificationDtoSchema, CertificationInputDto} from "../../../models/dto/certification.dto";
import {CONST} from "../../../constant";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-certification',
  templateUrl: './edit-profile-certification.component.html',
  styleUrls: ['./edit-profile-certification.component.scss']
})
export class EditProfileCertificationComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() profileId: number;

  @Output() onClose = new EventEmitter();

  certificationSchema: CertificationDtoSchema;
  maxDateValue = new Date();

  certificationForm = this.formBuilder.group({
    issueDate: this.formBuilder.control('', [Validators.required]),
    issuingOrganization: this.formBuilder.control('', [Validators.required]),
    name: this.formBuilder.control('', [Validators.required]),
  });


  constructor(private formBuilder: FormBuilder,
              private opentimerJobProfileService: OpentimerJobProfileService,
              private messageService: MessageService,
              private apiEventService: ApiEventService) {
  }

  ngOnInit(): void {
    this.getApiEvents();
    this.opentimerJobProfileService.getJobProfileCertifications(this.profileId);
  }

  protected getApiEvents(): void {
    if (this.apiEventService.event) {
      this.apiEventService.event
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          if (event && event.type === ApiEventType.GET_JOB_PROFILE_CERTIFICATIONS && event.status === ApiEventStatus.COMPLETED) {
            this.certificationSchema = this.opentimerJobProfileService.certificatesSchema$.value;
          }

          if (event && event.type === ApiEventType.ADD_JOB_PROFILE_CERTIFICATIONS && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileCertifications(this.profileId);
            this.certificationForm.reset();
            this.messageService.add({
              severity: 'success',
              summary: 'Certification added',
              detail: 'Certification is added to this profile'
            });
          }

          if (event && event.type === ApiEventType.DELETE_JOB_PROFILE_CERTIFICATIONS && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileCertifications(this.profileId);
            this.messageService.add({
              severity: 'success',
              summary: 'Certification deleted',
              detail: 'Certification is deleted from this profile'
            });
          }
        });
    } else {
      console.error('ApiEventService event is null');
    }
  }

  addCertification() {
    const formValue = this.certificationForm.value;
    const inputDto: CertificationInputDto = {
      issueDate: moment(formValue.issueDate).format(CONST.DATE_FORMAT_MOMENT),
      issuingOrganization: formValue.issuingOrganization,
      name: formValue.name,
    }
    this.opentimerJobProfileService.addJobProfileCertification(this.profileId, inputDto);
  }

  removeCertification(certificationId: number) {
    this.opentimerJobProfileService.deleteJobProfileCertification(this.profileId, certificationId);
  }

  protected readonly TextAlignPosition = TextAlignPosition;
  protected readonly CONST = CONST;
}
