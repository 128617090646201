import { Injectable } from "@angular/core";
import { ApiEventType } from "../models/api-event";
import { ApiEventService } from "./api-event.service";
import {HttpClient} from '@angular/common/http';
import { CONST } from "../constant";
@Injectable({
    providedIn: 'root',
})
export abstract class AbstractHttpHandler {
    protected abstract handleErrors<T>(
      eventType: ApiEventType,
      response?: T,
    ): (error: any) => T;
  
    protected abstract unAuthorizedHandler(error:any)

    constructor(
      public http: HttpClient,
      public apiEventsService: ApiEventService,
    ) {}

    protected clearLocalStorage(): void { 
      localStorage.removeItem(CONST.SEARCH_OPENTIMER_PROPS_KEY);
      localStorage.removeItem(CONST.BOOK_OPENTIMER_PROPS_KEY);
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_in');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('scope');
      localStorage.removeItem('token_type');
    }
    
  }
  