import {Pipe, PipeTransform} from '@angular/core';
import {environment} from 'src/environments/environment';
import {CONST} from "../constant";

@Pipe({
  name: 'countryFlag',
})
export class CountryFlagPipe implements PipeTransform {
  transform(value: string): string {
    console.log('CountryFlagPipe', value);
    if (value === undefined) {
      return CONST.IMAGE_PLACEHOLDER;
    }
    return `/assets/flags/${value}.png`;
  }
}
