import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { BookingStatus } from "../models/dto/booking.dto";
import { InterviewDto, InterviewDtoSchema, InterviewStatus } from "../models/dto/interview.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { convertBlobToString } from "./utils/io-utils";

@Injectable({
    providedIn: 'root',
})
export class InterviewsService extends AbstractHttpHandler {

    interviewDtoSchema$ = new BehaviorSubject<InterviewDtoSchema>(null);
    interviewDto$ = new BehaviorSubject<InterviewDto>(null);


    downloadedRecordedBlob$ = new BehaviorSubject<any>(null);


    constructor(public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router) {
        super(http, apiEventsService);
    }

    getInterview(interviewId: number, accountId: number = null) {
        const url = `v2/interviews/${interviewId}`;
        const eventType = ApiEventType.GET_INTERVIEWS_BY_ID;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS });

        let params = new HttpParams();

        this.http.get<InterviewDto>(url, {
            params,
            headers: new HttpHeaders({
                'Account-Id': String(accountId || '')
            }),
        })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(async response => {
                this.interviewDto$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })

    }

    getInterviews(accountId: number = null,
        page: number = 1,
        limit: number = 10,
        status: Array<InterviewStatus> = [],
        nameLike: string = null,
        customerLike: string = null,
        agencyLike: string = null,
        interviewerLike: string = null,) {
        const url = `v2/interviews`;
        const eventType = ApiEventType.GET_INTERVIEWS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS });

        let params = new HttpParams();

        if (limit) {
            params = params.append('limit', limit);
        }

        if (page) {
            params = params.append('page', page);
        }

        status.forEach((interviewStatus) => {
            params = params.append('status', interviewStatus);
        });

        if (nameLike !== null && nameLike.length > 0) {
            params = params.append('nameLike', `${nameLike}%`);
        }

        if (customerLike !== null && customerLike.length > 0) {
            params = params.append('customerNameLike', `${customerLike}%`);
        }

        if (agencyLike !== null && agencyLike.length > 0) {
            params = params.append('agencyNameLike', `${agencyLike}%`);
        }

        if (interviewerLike !== null && interviewerLike.length > 0) {
            params = params.append('interviewerNameLike', `${interviewerLike}%`);
        }

        this.http.get<InterviewDtoSchema>(url, {
            params,
            headers: new HttpHeaders({
                'Account-Id': String(accountId || '')
            }),
        })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(async response => {
                this.interviewDtoSchema$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })

    }


    // new endpoint
    getInterviewsByAccount(
        accountType: string,
        accountId: number = null,
        page: number = 1,
        limit: number = 10,
        status: Array<InterviewStatus> = [],
        nameLike: string = null,
        customerLike: string = null,
        agencyLike: string = null,
        interviewerLike: string = null) {
        const url = `v2/${accountType}/${accountId}/interviews`;
        const eventType = ApiEventType.GET_INTERVIEWS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS });

        let params = new HttpParams();

        if (limit) {
            params = params.append('limit', limit);
        }

        if (page) {
            params = params.append('page', page);
        }

        status.forEach((interviewStatus) => {
            params = params.append('status', interviewStatus);
        });

        if (nameLike !== null && nameLike.length > 0) {
            params = params.append('nameLike', `${nameLike}%`);
        }

        if (customerLike !== null && customerLike.length > 0) {
            params = params.append('customerNameLike', `${customerLike}%`);
        }

        if (agencyLike !== null && agencyLike.length > 0) {
            params = params.append('agencyNameLike', `${agencyLike}%`);
        }

        if (interviewerLike !== null && interviewerLike.length > 0) {
            params = params.append('interviewerNameLike', `${interviewerLike}%`);
        }

        this.http.get<InterviewDtoSchema>(url, {
            params,
            headers: new HttpHeaders({
                'Account-Id': String(accountId || '')
            }),
        })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(async response => {
                this.interviewDtoSchema$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })

    }


    downloadVideo(interviewId: number, compositionSid: string, accountId: number) {
        const url = `v2/interviews/${interviewId}/videos/${compositionSid}`;
        const eventType = ApiEventType.DOWNLOAD_INTERVIEW_RECORDED_VIDEO;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS });


        this.http.get<Blob>(url, {
            headers: new HttpHeaders({
                'Account-Id': `${accountId}`,
            }),
            responseType: 'blob' as 'json',
            reportProgress: true,
            observe: 'events',
        })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(async (response) => {

                if (response.type === HttpEventType.DownloadProgress) {
                    console.log(response.loaded, response.total);
                } else if (response.type === HttpEventType.Response) {
                    const base64Blob = await convertBlobToString(response.body);

                    this.downloadedRecordedBlob$.next(response.body);



                    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
                }
            });

    }

    cancelInterview(interviewId: number, accountId: number = null) {
        const url = `v2/interviews/${interviewId}/cancellations`;
        const eventType = ApiEventType.CANCEL_INTERVIEW_REQUEST;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS });

        this.http.put(url, {}, {
            headers: new HttpHeaders({
                'Account-Id': String(accountId || '')
            }),
        })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(() => {
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
            });
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
            const errorResponse = error.error;

            let title = Messages.HEADER_GENERIC_ERROR;
            let message = Messages.MESSAGE_GENERIC_ERROR;
            let showDialog = true;

            if (errorResponse) {
                const errorCode = errorResponse.error;
                switch (errorCode) {
                    case 'invalid_token': {
                        title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                        message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                        break;
                    }
                    case 'INVALID_INPUT': {
                        title = Messages.HEADER_INVALID_INPUT;
                        message = JSON.stringify(errorResponse.constraintViolations);
                        break;
                    }
                }
            }

            const errorToken = error.headers.get('Response_token');
            message = `${message} <br/> Response Token: ${errorToken}`;

            this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
            });
            this.unAuthorizedHandler(error)
            return response as T;
        };
    }

    protected unAuthorizedHandler(error: any) {
        if (error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }

}
