<div  class="grid grid-nogutter info-panel-shadow bookin-shift-item p-2 align-items-center">
    <div class="col-4 lg:col-2 block lg:block">
        <p class="text-l-gray">Booking Type:</p>
        <p class="text-rb">{{bookingType}}</p>
    </div>
    <div class="col-4 lg:col-2 block lg:block">
        <p class="text-l-gray">Shift:</p>
        <p class="text-rb">{{presetShift.name}}</p>
    </div>
    <div class="col-4 lg:col-8  flex justify-content-end align-items-start">
        <button pButton pRipple type="button" icon="pi pi-pencil" 
                (click)="onEditBookingShift.emit()"
                class="p-button-rounded p-button-text">
        </button>
    </div>
</div>