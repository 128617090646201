
export const CONST = {
    EMAIL_REGEX: new RegExp('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))' +
    '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'),
    UPPER_CASE_REGEX: new RegExp('^[A-Z\s]+'),
    SEARCH_OPENTIMER_PROPS_KEY: 'SEARCH_OPENTIMER_PROPS_KEY',
    BOOK_OPENTIMER_PROPS_KEY: 'BOOK_OPENTIMER_PROPS_KEY',
    IS_ON_BOOKING_OPENTIMER_STEPS: 'IS_ON_BOOKING_OPENTIMER_STEPS',
    SEARCH_TEMP_OPENTIMER_PROPS_KEY: 'SEARCH_TEMP_OPENTIMER_PROPS_KEY',
    CURRENT_ADMIN_USER: 'CURRENT_ADMIN_USER',
    BOOKINGS_BY_ACCOUNT_TYPE: {
        opentimers: 'opentimers'
    },
    CURRENT_LOGGED_IN_ADMIN: 'CURRENT_LOGGED_IN_ADMIN',
    IMAGE_PLACEHOLDER: '/assets/media/img/profile-placeholder.png',
    DATE_FORMAT: 'yy-mm-dd',
    DATE_FORMAT_MOMENT: 'YYYY-MM-DD',
    TIME_FORMAT_MOMENT: 'YYYY-MM-DD HH:mm',
    ZONED_TIME_FORMAT_MOMENT: 'YYYY-MM-DD HH:mm Z'
}
