import { Component, OnInit, Input } from '@angular/core';
import { DaysEnum } from '../../models/dto/shift.dto';

@Component({
  selector: 'app-days-chip',
  templateUrl: './days-chip.component.html',
  styleUrls: ['./days-chip.component.scss']
})
export class DaysChipComponent implements OnInit {
  @Input() days: Array<DaysEnum> = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
