import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TextAlignPosition} from "../../labelled-input/labelled-input.component";
import {ProfileLocationDto, ProfileLocationInputDto} from "../../../models/dto/location-address.dto";
import {AddressLocation} from "../../../models/dto/contact.dto";
import {LocationFormComponent} from "../../location-form/location-form.component";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-location',
  templateUrl: './edit-profile-location.component.html',
  styleUrls: ['./edit-profile-location.component.scss']
})
export class EditProfileLocationComponent implements OnInit {
  @Input() profileLocationDto: ProfileLocationDto;
  @Input() showEditDialog: boolean;

  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @ViewChild(LocationFormComponent) addLocationForm: LocationFormComponent;

  locationFormStatusValid = false;

  locationForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.locationForm = this.formBuilder.group({
      serviceLocation: this.formBuilder.control(false, [Validators.required])
    });

    if (this.profileLocationDto) {
      this.locationForm.patchValue({
        serviceLocation: this.profileLocationDto.serviceLocation
      })
    }
  }

  saveLocation() {
    const locationAddress = this.addLocationForm.saveForm();

    const profileLocationInputDto: ProfileLocationInputDto = {
      id: (this.profileLocationDto) ? this.profileLocationDto.id : null,
      addressLocation: locationAddress,
      serviceLocation: this.locationForm.value.serviceLocation
    }

    this.onSubmit.emit(profileLocationInputDto);
    this.showEditDialog = false;
  }

  get addressLocation(): AddressLocation {
    if(this.profileLocationDto) {
      return this.profileLocationDto.addressLocation as AddressLocation;
    }
    return null;
  }

  onLocationFormStatusChanges(status: string) {
    this.locationFormStatusValid = status === 'VALID';
  }



  protected readonly TextAlignPosition = TextAlignPosition;
}
