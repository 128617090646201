import { Pipe, PipeTransform } from "@angular/core";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";
import { BookingCancelRequestStatus } from "../models/dto/booking.dto";

@Pipe({
    name: 'bookingRequestStatusPipe'
  })
  export class BookingCancellationRequestStatusPipe implements PipeTransform {
  
    private readonly bookingRequestStatusMap = ReadonlyMapBuilder.create<BookingCancelRequestStatus, string>()
    .set(BookingCancelRequestStatus.APPROVED ,'Approved')
    .set(BookingCancelRequestStatus.IN_PROGRESS,'In-Progress')
    .set(BookingCancelRequestStatus.REJECTED, 'Rejected')


    .build();

    transform(value: BookingCancelRequestStatus): string {
      return this.bookingRequestStatusMap.get(value);
    }
  
}