import {Pipe, PipeTransform} from "@angular/core";
import {RecommendationLevelEnum} from "../models/dto/customer-rating.dto";
import {ReadonlyMapBuilder} from "../readyonly-map-builder";


@Pipe({
    name: 'recommendationLevelTitlePipe'
})
export class RecommendationLevelTitlePipePipe implements PipeTransform {

    private readonly idVerificationTypeTitleMap = ReadonlyMapBuilder.create<RecommendationLevelEnum, string>()
    .set(RecommendationLevelEnum.EXTREMELY_LIKELY,'Extremely likely')
    .set(RecommendationLevelEnum.MOST_LIKELY,'Most likely')
    .set(RecommendationLevelEnum.NOT_AT_ALL_LIKELY,`Not at all likely`)
    .build();

    transform(value: RecommendationLevelEnum): string {
      return this.idVerificationTypeTitleMap.get(value);
    }

}
