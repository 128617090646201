import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared-module";
import { SignInComponent } from './sign-in/sign-in.component';
import { HomeComponent } from './home.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    SignInComponent,
    HomeComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
})
export class HomeModule { }
  