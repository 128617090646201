import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() title: string = 'Dialog Title'
  @Input() disabled: boolean = false;
  @Input() dialogWidth: string = '50';
  @Input() dialogHeight: string = '30';
  @Input() submitButtonLabel:string = 'Submit';
  @Input() dismissableMask: boolean = false;

  @Input() showSubmitButton: boolean = true;

  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.dialogWidth = `${this.dialogWidth}vw`;
    this.dialogHeight = `${this.dialogHeight}vw`;
  }

}
