<app-edit-dialog [visible]="showRolesDialog" [title]="'Edit Admin Roles'" (onSubmit)="onSaveUserInformation()"
    (onHide)="onHide.emit()" [disabled]="!rolesForm.valid">
    <ng-container [formGroup]="rolesForm">
        <div class="col-12 lg:col-12">
            <div class="grid grid-nogutter w-100 block">
                <label for="dateOfBirth" class="col-3 pr-2 text-right">Admin roles</label>
                <div class="col-10">
                    <p-multiSelect [options]="adminRoles?.roles" appendTo="body" placeholder="Select" display="chip"
                        formControlName="roles" [style]="{'width':'100%'}" [filter]="false">
                        <ng-template let-value pTemplate="selectedItems">
                            <p *ngIf="!rolesForm.value.roles.length">Select admin roles</p>
                            <div class="multiselct-item-selected flex">
                                <p-chip *ngFor="let option of rolesForm.value.roles" [label]="option | translateBackend"
                                    [removable]="false">
                                </p-chip>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="country-item">
                                <div>
                                    <p>{{item | translateBackend}}</p>
                                </div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>
            </div>
        </div>
    </ng-container>
</app-edit-dialog>