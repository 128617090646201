import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProfileLocationDto } from '../../models/dto/location-address.dto';

@Component({
  selector: 'app-opentimer-location',
  templateUrl: './opentimer-location.component.html',
  styleUrls: ['./opentimer-location.component.scss']
})
export class OpentimerLocationComponent implements OnInit {
  @Input() profileLocationDto: ProfileLocationDto;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
