import { Pipe, PipeTransform } from "@angular/core";
import { PersonalInfoDto } from "../models/dto/contact.dto";

@Pipe({
    name: 'personalInfoDtoFullName'
})

export class PersonalInfoFullNamePipe implements PipeTransform{

    transform(value: PersonalInfoDto) {
        return value.fullName ? `${value.fullName}` : `${value.firstName} ${value.lastName}`
    }

}