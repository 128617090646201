import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CoverLetterDto } from '../../models/dto/cover-letter.dto';

@Component({
  selector: 'app-opentimer-cover-letter',
  templateUrl: './opentimer-cover-letter.component.html',
  styleUrls: ['./opentimer-cover-letter.component.scss']
})
export class OpentimerCoverLetterComponent implements OnInit {
  @Input() coverLetter: CoverLetterDto;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;
  
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
