import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormControlName } from '@angular/forms';

export enum TextAlignPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

@Component({
  selector: 'app-labelled-input',
  templateUrl: './labelled-input.component.html',
  styleUrls: ['./labelled-input.component.scss']
})
export class LabelledInputComponent implements OnInit, AfterViewInit {
  @Input() ngControl: AbstractControl;
  @Input() placeholder: string;  
  @Input() label: string;
  @Input() name: string;
  @Input() type: string = 'text'
  @Input() readonly: boolean = false;
  @Input() textAlign: TextAlignPosition = TextAlignPosition.RIGHT;

  @Input() maxLength:number = null;

  @Input() displayBlock: boolean = false

  @ViewChild('myInputField') myInputField: ElementRef;


  readonly TextAlignPosition = TextAlignPosition;

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    if (this.maxLength) {
      this.myInputField.nativeElement.setAttribute('maxlength', this.maxLength);
    }
  }




}
