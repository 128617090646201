import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JobSkillCreationDto, JobSkillDto } from 'src/app/shared/models/dto/skill.dto';
import { TranslationDto } from 'src/app/shared/models/dto/translation.dto';
import { SkillService } from 'src/app/shared/services/skill.service';

@Component({
  selector: 'app-edit-jobskill',
  templateUrl: './edit-jobskill.component.html',
  styleUrls: ['./edit-jobskill.component.scss']
})
export class EditJobskillComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() jobSkill: JobSkillDto

  @Output() onHide = new EventEmitter();

  skillForm = this.formBuilder.group({
    skill: this.formBuilder.control('', [Validators.required]),
    skillNativeName:  this.formBuilder.control('', [Validators.required]),
    valid:  this.formBuilder.control(false, [Validators.required]),
  });


  constructor(private formBuilder: FormBuilder,
              private skillService: SkillService) { }

  ngOnInit(): void {
    if(this.jobSkill) {
      const skillName = this.getTranslation('en', this.jobSkill.translations);
      const skillNativeName = this.getTranslation('ja', this.jobSkill.translations);
      this.skillForm.patchValue({
        valid: this.jobSkill.valid,
        skill: skillName,
        skillNativeName: skillNativeName
      });
    }
  }

  getTranslation(language: string, translations: Array<TranslationDto>): string {
    return translations.find(v=> v.language === language) ? 
           translations.find(v=> v.language === language).content : '';
  }

  onSubmitSkill() {
    const skillFormValue = this.skillForm.value;

    const skillCreationDto: JobSkillCreationDto = {
      skill: skillFormValue.skill,
      translations: [
        {
          content: skillFormValue.skill,
          language: 'en'
        },
        {
          content: skillFormValue.skillNativeName,
          language: 'ja'
        }
      ],
      serviceSubCategoryIds: [],
      valid: skillFormValue.valid
    }

    this.skillService.updateJobSKill(this.jobSkill.id, skillCreationDto);
  }
}
