<div  class="grid grid-nogutter" *ngIf="travelRestriction; else noTravelRestrictionTemplate">
    <div class="col-6">
        <div  class="grid grid-nogutter">
            <div class="col-12">
                <p class="text-ssb">Means of Transportation</p>
                <p class="text-primary text-rb">{{travelRestriction.transportationType}}</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Maximum travel time for work</p>
                <p class="text-primary text-rb">{{travelRestriction.travelTime}} mins</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Willing to work remotely <span class="text-velvet">(with local companies)</span></p>
                <p class="text-primary text-rb" *ngIf="travelRestriction.remoteWork">Yes</p>
                <p class="text-primary text-rb" *ngIf="!travelRestriction.remoteWork">No</p>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div  class="grid grid-nogutter">
            <div class="col-12">
                <p class="text-ssb">Willing to relocate</p>
                <p class="text-primary text-rb" *ngIf="travelRestriction.relocate">Yes</p>
                <p class="text-primary text-rb" *ngIf="!travelRestriction.relocate">No</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Maximum travel time for Interview</p>
                <p class="text-primary text-rb">{{travelRestriction.travelTimeInterview}} mins</p>
            </div>
            <div class="col-12">
                <p class="text-ssb">Willing to work remotely <span class="text-velvet">(with international companies)</span></p>
                <p class="text-primary text-rb" *ngIf="travelRestriction.internationalWork">Yes</p>
                <p class="text-primary text-rb" *ngIf="!travelRestriction.internationalWork">No</p>
            </div>
        </div>
    </div>
</div>

<ng-template #noTravelRestrictionTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                            (click)="onClick.emit()"
                           class="primary" label="Commute Preferences" 
                           icon="pi pi-plus" [disabled]="!enabledButton">
                </p-button>
            </div>
        </div>
    </div>
</ng-template>