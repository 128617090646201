<ng-container *ngFor="let phoneNumber of phoneNumbers; let i=index">
    <div *ngIf="i !== phoneForm.value.index; else formTemplate" 
          class="grid grid-nogutter w-100 p-0 mt-3 align-items-center">
        <div class="p-card col-10 flex align-items-center">
            <div class="col-1 ">
                <i class="pi pi-phone pt-1" style="font-size: 1rem"></i>
            </div>
            <div class="col-9 p-0 pl-2">
                <p>+{{phoneNumber.extension}}{{phoneNumber.number}}</p>
            </div>
            <div class="col-2">
                <button pButton pRipple type="button" icon="pi pi-pencil" (click)="editPhoneNumber(phoneNumber, i)"
                        class="p-button-rounded p-button-primary p-button-outlined button-border-none">
                </button>
            </div>
        </div>
        <div class="col-2 text-center">
            <button pButton pRipple type="button" icon="pi pi-times-circle" (click)="removeAt(i)"
                    class="p-button-rounded p-button-danger p-button-outlined button-border-none">
            </button>
        </div>
    </div>
</ng-container>

<ng-template #formTemplate >
    <form [formGroup]="phoneForm">
        <div class="grid grid-nogutter w-100 p-0 mt-3 align-items-center">
            <div class="col-5">
                <app-country-dropdown [ngControl]="phoneForm"
                                      [name]="'countryId'"
                                      [items]="countries">
                </app-country-dropdown>
            </div>
            <div class="col-5">
                <input id="phone" type="tel" formControlName="phone" maxlength="10"
                        pInputText placeholder="Number" style="width: 100%"/>
            </div>
            <div class="col-2 text-center">
                <button pButton pRipple type="button" icon="pi pi-check-circle" (click)="update()"
                        [disabled]="!phoneForm.valid"   
                        class="p-button-rounded p-button-success p-button-outlined button-border-none">
                </button>
            </div>
        </div>
    </form>

</ng-template>