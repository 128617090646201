<div  class="grid grid-nogutter info-panel-shadow service-category-item p-2 align-items-center">
    <div class="col-4 lg:col-2 block lg:block">
        <p class="text-l-gray">Service Category:</p>
        <p class="text-rb">{{serviceSubCategory.serviceCategory | translateBackend}}</p>
    </div>
    <div class="col-4 lg:col-2 block lg:block">
        <p class="text-l-gray">Service SubCategory:</p>
        <p class="text-rb">{{serviceSubCategory | translateBackend}}</p>
    </div>
    <div class="col-4 lg:col-8  flex justify-content-end align-items-start">
        <button pButton pRipple type="button" icon="pi pi-pencil" 
                (click)="onEditServiceCategory.emit()"
                class="p-button-rounded p-button-text">
        </button>
    </div>
</div>