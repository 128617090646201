<app-edit-list-dialog [visible]="showOpentimerLanguageDialog" [title]="'Add Opentimer Language'"
                 (onClose)="onClose.emit()" [disabled]="currentLanguageProficiencyDtos.length < 1"
                 [dialogHeight]="'40'">
    <div  class="mt-3 grid">
        <ng-container [formGroup]="opentimerForm">
            <div class="col-12 lg:col-6">
                <div class="grid grid-nogutter w-100 block">
                    <label  class="col-3 pr-2 text-right">Select Language</label>
                    <div class="col-12" >
                        <p-dropdown  [options]="proficiencyLanguages"
                                     formControlName="languageCode"
                                     optionValue="code"
                                     placeholder="Please select a language"
                                     [style]="{'width':'100%'}" appendTo="body">
                            <ng-template pTemplate="selectedItem" let-selectedItem  >
                                <div class="country-item country-item-value" >
                                    <div><p>{{selectedItem | translateBackend}}</p></div>
                                </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <div class="country-item">
                                    <div><p>{{item | translateBackend}}</p></div>
                                </div>
                            </ng-template>
                        </p-dropdown >
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6">
                <div class="grid grid-nogutter w-100 block">
                    <label  class="col-3 pr-2 text-right">Select Proficiency</label>
                    <div class="col-12" >
                      <p-dropdown
                        [options]="proficiencies"
                        formControlName="proficiency"
                        placeholder="Please select proficiency level"
                        appendTo="body"
                        optionValue="proficiencyLevel" [style]="{'width':'100%'}">
                        <ng-template pTemplate="selectedItem" let-selectedItem  >
                          <div class="country-item country-item-value" >
                            <p-chip class="mb-1" [label]="selectedItem.title"
                                    [class]="chipLanguageColor.get(selectedItem.proficiencyLevel)">
                            </p-chip>
                          </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                          <div class="country-item">
                            <p-chip class="mb-1" [label]="item.title"
                                    [class]="chipLanguageColor.get(item.proficiencyLevel)">
                            </p-chip>
                            <p>{{item.description}}</p>
                          </div>
                        </ng-template>
                      </p-dropdown >
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 text-right ">
                <button pButton type="button" label="Add Language" icon="pi pi-plus-circle"
                        class="p-button-text p-button-primary p-0 ml-2" iconPos="left"
                        [disabled]="!opentimerForm.valid"
                        (click)="addLanguageProficiency()">
                </button>
            </div>
          <div class="col-12">
            <p-table [value]="opentimerSignUpProfile.languageProficiencyLevels" editMode="row"
                     styleClass="p-datatable-striped" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                <tr>
                  <th>LANGUAGE</th>
                  <th>PROFICIENCY</th>
                  <th>ACTIONS</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-language let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="language">
                  <td>
                    {{ language.proficiencyLanguage | languagePipe }}
                  </td>
                  <td class="text-primary">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown [options]="proficiencies" [(ngModel)]="language.proficiencyLevel"
                                    [style]="{'width':'100%'}" appendTo="body" optionLabel="label"
                                    optionValue="code">
                        </p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <p-chip [label]="getProficiency(language.proficiencyLevel).title" class="mb-1"
                                [class]="chipLanguageColor.get(language.proficiencyLevel)">
                        </p-chip>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <button pButton pRipple type="button" icon="pi pi-times-circle" (click)="removeLanguageProficiency(language.proficiencyLanguage)"
                            class="p-button-rounded p-button-danger p-button-outlined button-border-none">
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-container>
    </div>
</app-edit-list-dialog>
