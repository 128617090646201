import { AssociateDto } from "./associate.dto";
import { BookingOrderDisplayDto } from "./booking.dto";
import { OpentimerProfileDto } from "./opentimer-job-profile.dto";
import { RecruiterDisplayDto } from "./recruiter.dto";

export enum ReportProblemEntityType  {
    AGENCY = 'AGENCY',
    CUSTOMER = 'CUSTOMER',
    JOB_PROFILE = 'JOB_PROFILE',
    BOOKING = 'BOOKING'
}

export enum ReportProblemStatus {
    NEW = 'NEW',
    PENDING = 'PENDING',
    RESOLVED = 'RESOLVED'
}

export interface BookingOrderProblemResponseDtoSchema {
    reportedProblems: Array<BookingOrderProblemResponseDto>;
    total: number;
}

export interface BookingOrderProblemResponseDto {
    id: number;
    problemDate: string;
    createdDate: string;
    bookingOrder: BookingOrderDisplayDto;
    message: string;
    status: ReportProblemStatus;
    recruiter?: RecruiterDisplayDto;
    profile?: OpentimerProfileDto;
    associate?: AssociateDto;
    comment: string;
}

export interface BookingOrderProblemInputDto {
    status: string;
    comment: string;
}