import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceCategoryDto, ServiceSubCategoryDto } from '../../models/dto/service-category.dto';

@Component({
  selector: 'app-service-category-item',
  templateUrl: './service-category-item.component.html',
  styleUrls: ['./service-category-item.component.scss']
})
export class ServiceCategoryItemComponent implements OnInit {
  @Input() serviceSubCategory: ServiceSubCategoryDto;
  @Input() isDisplayMode: boolean = false;
  @Input() showEditButton: boolean = false;

  @Output() onEditServiceCategory = new EventEmitter<void>();



  ngOnInit(): void {
  }

}
