import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MenuItem, MessageService} from "primeng/api";
import {OpentimerAvailabilityDto, OpentimerProfileCreationDto} from "../../../models/dto/opentimer-job-profile.dto";
import {ApiEventService} from "../../../services/api-event.service";
import {OpentimerJobProfileService} from "../../../services/opentimer-job-profile-service";
import {ApiEvent, ApiEventStatus, ApiEventType} from "../../../models/api-event";
import {BookingType} from "../../../models/dto/booking.dto";
import {AssignmentDurationTypes} from "../../../models/dto/availability.dto";
import {ActivatedRoute} from "@angular/router";
import {OpentimeFormatDate} from "../../../date-format";
import {Calendar} from "primeng/calendar";
import * as moment from "moment/moment";
import {Time} from "../../../time/time";
import {CONST} from "../../../constant";

interface JobTypeItem {
  name: string;
  description: string;
  value: BookingType;
  isSelected: boolean
}

interface HourItem {
  label: string;
  value: number;
}

interface DayItem {
  label: string;
  value: number;
}

interface AssignmentDurationTypesItem {
  label: string;
  value: AssignmentDurationTypes;
  index: number;
}


@UntilDestroy()
@Component({
  selector: 'app-edit-profile-availability',
  templateUrl: './edit-profile-availability.component.html',
  styleUrls: ['./edit-profile-availability.component.scss']
})
export class EditProfileAvailabilityComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() profileId: number;
  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  items: MenuItem[] = []

  opentimerJobProfile: OpentimerProfileCreationDto;

  availabilityForm = this.formBuilder.group({
    id: this.formBuilder.control(null),
    jobTypes: this.formBuilder.control([], [Validators.required]),
    workingTime: this.formBuilder.group({
      startTime: this.formBuilder.control(null, [Validators.required]),
      endTime: this.formBuilder.control(null, [Validators.required]),
      minWorkingHour: this.formBuilder.control(null, [Validators.required]),
      maxWorkingHour: this.formBuilder.control(null, [Validators.required]),
      minWorkingDay: this.formBuilder.control(null, [Validators.required]),
      maxWorkingDay: this.formBuilder.control(null, [Validators.required]),
      minAssignmentDuration: this.formBuilder.control(null, [Validators.required]),
      minAssignmentDurationType: this.formBuilder.control(null),
      maxAssignmentDuration: this.formBuilder.control(null, [Validators.required]),
      maxAssignmentDurationType: this.formBuilder.control(null),
    },{ validator: this.minMaxValidator.bind(this) }),
    joiningTime: this.formBuilder.group({
      startDate: this.formBuilder.control(null, [Validators.required]),
      asSoonAsPossible: this.formBuilder.control(false, [Validators.required]),
      endDate: this.formBuilder.control(null, [Validators.required]),
      asLongAsPossible: this.formBuilder.control(false, [Validators.required]),
    }),
    dayOff: this.formBuilder.group({
      availableOnWeekend: this.formBuilder.control(false, [Validators.required]),
      daysOff: this.formBuilder.control([]),
    })
  });


  jobTypeItems: Array<JobTypeItem> = [
    {
      name: 'FULL-TIME BOOKINGS',
      description: '40 hours a week',
      value: BookingType.FULL_TIME,
      isSelected: false
    },
    {
      name: 'PART-TIME BOOKINGS',
      description: 'Up to 28 hours a week',
      value: BookingType.PART_TIME,
      isSelected: false
    },
    {
      name: 'ONE-TIME BOOKINGS',
      description: 'Project based',
      value: BookingType.ONE_TIME,
      isSelected: false
    }
  ]

  assignmentDurationTypes: Array<AssignmentDurationTypesItem> =[
    {
      label: 'Day',
      value: AssignmentDurationTypes.DAY,
      index: 0,
    },
    {
      label: 'Week',
      value: AssignmentDurationTypes.WEEK,
      index: 1,
    },
    {
      label: 'Month',
      value: AssignmentDurationTypes.MONTH,
      index: 2,
    },
    {
      label: 'Year',
      value: AssignmentDurationTypes.YEAR,
      index: 3,
    },
  ]

  hours: Array<HourItem> = [];
  days: Array<DayItem> = [];

  today = new Date();
  disableSelectedDates: Array<Date>  = [];
  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private apiEventService: ApiEventService,
              private cd: ChangeDetectorRef,
              private messageService: MessageService,
              private opentimerJobProfileService: OpentimerJobProfileService) { }

  ngOnInit(): void {
    this.today.setDate(this.today.getDate() + 1);
    this.opentimerJobProfileService.getOpentimerJobProfile(this.profileId);

    this.items = [
      {
        label:'Opentimer',
        url: '/admin-portal/opentimer/',
      },
      {
        label:'Job Profile',
        url: `/admin-portal/opentimer-job-profile/${this.profileId}`,
      },
      {
        label:'Availability',
        url: `/admin-portal/opentimer-job-profile/${this.profileId}`,
      },
    ];

    for (let i = 1; i <= 24; i++) {
      this.hours.push({label: (i < 10 ? '0' : '') + i + ':00', value: i});
    }

    for(let i = 1; i<=7; i++) {
      const dayText = i > 1 ? 'Days' : 'Day'
      this.days.push({label: `${i} ${dayText}`, value: i})
    }

    this.getApiEvents();

  }

  getApiEvents() {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event=>{
        if(!event) {
          return;
        }

        const EVENT_STATUS_FACTORY = this.eventStatusHandleMapFunction(event)[event.status];
        EVENT_STATUS_FACTORY();
      });

  }

  private eventStatusHandleMapFunction(apiEvent: ApiEvent):{ [key in ApiEventStatus]: () => void } {
    return  {
      [ApiEventStatus.COMPLETED]: (()=>{
        const eventTypeHandleMap = {
          [ApiEventType.GET_OPENTIMER_JOB_PROFILE_BY_ID]: (()=>{
            this.opentimerJobProfile = this.opentimerJobProfileService.opentimerJobProfile$.value;
            if(this.opentimerJobProfile && this.opentimerJobProfile.availability) {
              console.log(this.opentimerJobProfile);
              const startTime = OpentimeFormatDate.fromSecondsToHhMMString(this.opentimerJobProfile.availability.workingTime.startTime);
              const endTime  = OpentimeFormatDate.fromSecondsToHhMMString(this.opentimerJobProfile.availability.workingTime.endTime);
              console.log()
              this.availabilityForm.patchValue({
                id: this.opentimerJobProfile.availability.id,
                jobTypes: this.opentimerJobProfile.availability.jobType.jobTypes,
                workingTime: {
                  startTime: OpentimeFormatDate.getDateFromTime(startTime),
                  endTime: OpentimeFormatDate.getDateFromTime(endTime),
                  maxAssignmentDuration: this.opentimerJobProfile.availability.workingTime.maxAssignmentDuration,
                  maxAssignmentDurationType: this.opentimerJobProfile.availability.workingTime.maxAssignmentDurationType,
                  minAssignmentDuration: this.opentimerJobProfile.availability.workingTime.minAssignmentDuration,
                  minAssignmentDurationType: this.opentimerJobProfile.availability.workingTime.minAssignmentDurationType,
                  maxWorkingDay: this.opentimerJobProfile.availability.workingTime.maxWorkingDay,
                  minWorkingDay: this.opentimerJobProfile.availability.workingTime.minWorkingDay,
                  maxWorkingHour: this.opentimerJobProfile.availability.workingTime.maxWorkingHour,
                  minWorkingHour: this.opentimerJobProfile.availability.workingTime.minWorkingHour,
                },
                joiningTime: {
                  asLongAsPossible: this.opentimerJobProfile.availability.joiningTime.asLongAsPossible,
                  asSoonAsPossible: this.opentimerJobProfile.availability.joiningTime.asSoonAsPossible,
                  endDate: this.opentimerJobProfile.availability.joiningTime.endDate,
                  startDate: this.opentimerJobProfile.availability.joiningTime.startDate,
                },
                dayOff: {
                  availableOnWeekend: this.opentimerJobProfile.availability.dayOff.availableOnWeekend,
                  daysOff: this.opentimerJobProfile.availability.dayOff.daysOff
                }
              });
              const jobTypesSet = new Set(this.opentimerJobProfile.availability.jobType.jobTypes.map(jobProfileJobType => BookingType[jobProfileJobType]));
              for(const jobType of this.jobTypeItems) {
                jobType.isSelected = jobTypesSet.has(jobType.value);
              }

              this.setDisabledDates();
              this.updateJoiningStartDate();
              this.updateJoiningEndDate();
            }
          }),
          [ApiEventType.UPDATE_JOB_PROFILE_AVAILABILITY]: (()=>{
            this.messageService.add({severity:'success', summary:'Availability Update', detail:'Availability is successfully updated'});
          })

        }

        const EVENT_TYPE_HANDLE_FACTORY = eventTypeHandleMap[apiEvent.type] || (()=>{});
        EVENT_TYPE_HANDLE_FACTORY();

      }),
      [ApiEventStatus.ERROR]: (()=>{ }),
      [ApiEventStatus.IN_PROGRESS]: (()=>{}),
      [ApiEventStatus.DEFAULT]: (()=>{})
    }


  }

  get maximumHours() {
    const minVal = this.availabilityForm.controls['workingTime'].get('minWorkingHour') .value;
    if (minVal !== null) {
      return this.hours.filter(hour => hour.value > minVal);
    }
    return this.hours;
  }

  get maximumDays() {
    const minVal = this.availabilityForm.controls['workingTime'].get('minWorkingDay').value;
    if (minVal !== null) {
      return this.days.filter(day => day.value > minVal);
    }
    return this.days;
  }

  get maximumAssignmentDurationTypes() {
    const minVal = this.availabilityForm.controls['workingTime'].get('minAssignmentDurationType').value as AssignmentDurationTypes;
    const minIndex = this.assignmentDurationTypes.findIndex(v => v.value === minVal);
    if (minIndex > -1) {
      return this.assignmentDurationTypes.filter((v, index) => index >= minIndex);
    }
    return this.assignmentDurationTypes;
  }

  minMaxValidator(group: FormGroup): { [key: string]: any } | null {

    const minAssignmentDuration = group.get('minAssignmentDuration').value ? group.get('minAssignmentDuration').value: 0;
    const minAssignmentDurationType = group.get('minAssignmentDurationType').value ? group.get('minAssignmentDurationType').value: null;
    const maxAssignmentDuration = group.get('maxAssignmentDuration').value ? group.get('maxAssignmentDuration').value: 0;
    const maxAssignmentDurationType = group.get('maxAssignmentDurationType').value ? group.get('maxAssignmentDurationType').value: null;

    const isMinDurationIsLesserThanMaxDuration = this.isFirstDurationLess(minAssignmentDuration,
      minAssignmentDurationType,
      maxAssignmentDuration,
      maxAssignmentDurationType)
    return isMinDurationIsLesserThanMaxDuration ? null : { minMaxValidator: true };
  }


  private isFirstDurationLess(firstDuration: number, firstType: AssignmentDurationTypes, secondDuration: number, secondType: AssignmentDurationTypes): boolean {
    const firstDurationInDays = this.durationToDays(firstDuration, firstType);
    const secondDurationInDays = this.durationToDays(secondDuration, secondType);
    return firstDurationInDays < secondDurationInDays;
  }

  private durationToDays(duration: number, type: AssignmentDurationTypes): number {
    switch (type) {
      case AssignmentDurationTypes.DAY:
        return duration;
      case AssignmentDurationTypes.WEEK:
        return duration * 7; // 1 week = 7 days
      case AssignmentDurationTypes.MONTH:
        return duration * 30; // Approximate, 1 month = 30 days
      case AssignmentDurationTypes.YEAR:
        return duration * 365; // 1 year = 365 days
      default:
        return 0;
    }
  }

  onSelectJobType(jobType: JobTypeItem) {
    jobType.isSelected = !jobType.isSelected;

    const selectedJobTypes = this.jobTypeItems.filter(v=>v.isSelected).map(item => item.value);
    this.availabilityForm.patchValue({
      jobTypes: selectedJobTypes
    });
    this.availabilityForm.controls['jobTypes'].markAsDirty();
  }

  onSetCurrentTime(formControlName: string, calendar: Calendar) {
    this.availabilityForm.controls['workingTime'].patchValue({
      [formControlName]: new Date()
    });
    calendar.toggle();
  }

  onClickASAPToggle() {
    this.availabilityForm.controls['joiningTime'].patchValue({
      asSoonAsPossible: !this.availabilityForm.controls['joiningTime'].get('asSoonAsPossible').value
    });
    this.updateJoiningStartDate();
  }

  updateJoiningStartDate() {
    if(this.availabilityForm.controls['joiningTime'].get('asSoonAsPossible').value) {
      this.availabilityForm.controls['joiningTime'].get('startDate').disable();
      this.availabilityForm.controls['joiningTime'].get('startDate').clearValidators();
    } else {
      this.availabilityForm.controls['joiningTime'].get('startDate').enable();
      this.availabilityForm.controls['joiningTime'].get('startDate').setValidators([Validators.required]);
    }
    this.availabilityForm.controls['joiningTime'].get('startDate').updateValueAndValidity();
  }

  onClickASLONGToggle() {
    this.availabilityForm.controls['joiningTime'].patchValue({
      asLongAsPossible: !this.availabilityForm.controls['joiningTime'].get('asLongAsPossible').value
    });
    this.updateJoiningEndDate();
  }

  updateJoiningEndDate() {
    if(this.availabilityForm.controls['joiningTime'].get('asLongAsPossible').value) {
      this.availabilityForm.controls['joiningTime'].get('endDate').disable();
      this.availabilityForm.controls['joiningTime'].get('endDate').clearValidators();
    } else {
      this.availabilityForm.controls['joiningTime'].get('endDate').enable();
      this.availabilityForm.controls['joiningTime'].get('endDate').setValidators([Validators.required]);
    }
    this.availabilityForm.controls['joiningTime'].get('endDate').updateValueAndValidity();
  }

  onClickAvailableOnWeekEnd() {
    this.availabilityForm.controls['dayOff'].patchValue({
      availableOnWeekend: !this.availabilityForm.controls['dayOff'].get('availableOnWeekend').value
    });
  }

  onSelect(value: string) {
    const daysOff =  this.availabilityForm.controls['dayOff'].get('daysOff').value;

    const date = moment(value).format(CONST.DATE_FORMAT_MOMENT);
    const index = daysOff.indexOf(date);
    if (index == -1) {
      daysOff.push(date);
    }
    this.availabilityForm.controls['dayOff'].patchValue({
      daysOff: daysOff
    });
    this.setDisabledDates();
  }

  setDisabledDates(){
    this.disableSelectedDates = this.availabilityForm.controls['dayOff'].get('daysOff').value.map((v)=>{
      return new Date(v);
    });
  }

  onRemoveUnavailableDate(index: number) {
    const daysOff =  this.availabilityForm.controls['dayOff'].get('daysOff').value;

    const newDaysOff = daysOff.slice(0, index).concat(daysOff.slice(index + 1));
    this.availabilityForm.controls['dayOff'].patchValue({
      daysOff: newDaysOff
    });
    this.setDisabledDates();
  }


  markAllAsDirty(control: AbstractControl) {
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(key => {
        this.markAllAsDirty(control.get(key));
      });
    } else if (control instanceof FormArray) {
      for (const groupOrArray of control.controls) {
        this.markAllAsDirty(groupOrArray);
      }
    } else {
      control.markAsDirty();
    }
  }

  onUpdate() {
    this.markAllAsDirty(this.availabilityForm);
    this.cd.detectChanges();

    if(this.availabilityForm.valid) {
      const availabilityFormValue = this.availabilityForm.value;
      const startTime = OpentimeFormatDate.fromSecondsToHhMMString(OpentimeFormatDate.getSeconds(availabilityFormValue.workingTime.startTime));
      const endTime = OpentimeFormatDate.fromSecondsToHhMMString(OpentimeFormatDate.getSeconds(availabilityFormValue.workingTime.endTime));

      const opentimerAvailabilityDto: OpentimerAvailabilityDto = {
        jobType: {
          jobTypes: availabilityFormValue.jobTypes,
        },
        dayOff: {
          availableOnWeekend: availabilityFormValue.dayOff.availableOnWeekend,
          daysOff: availabilityFormValue.dayOff.daysOff,
        },
        joiningTime: {
          asLongAsPossible: availabilityFormValue.joiningTime.asLongAsPossible,
          asSoonAsPossible: availabilityFormValue.joiningTime.asSoonAsPossible,
          endDate: OpentimeFormatDate.formatDateFromString(availabilityFormValue.joiningTime.endDate),
          startDate: OpentimeFormatDate.formatDateFromString(availabilityFormValue.joiningTime.startDate),
        },
        workingTime: {
          endTime: Time.fromString(`${endTime}`).toTotalSeconds(),
          startTime: Time.fromString(`${startTime}`).toTotalSeconds(),
          maxAssignmentDuration: availabilityFormValue.workingTime.maxAssignmentDuration,
          maxAssignmentDurationType: availabilityFormValue.workingTime.maxAssignmentDurationType,
          minAssignmentDuration: availabilityFormValue.workingTime.minAssignmentDuration,
          minAssignmentDurationType: availabilityFormValue.workingTime.minAssignmentDurationType,
          maxWorkingDay: availabilityFormValue.workingTime.maxWorkingDay,
          minWorkingDay: availabilityFormValue.workingTime.minWorkingDay,
          maxWorkingHour: availabilityFormValue.workingTime.maxWorkingHour,
          minWorkingHour: availabilityFormValue.workingTime.minWorkingHour,
        },
      }

      this.onSubmit.emit(opentimerAvailabilityDto)
    } else {
      this.messageService.add({severity:'warn', summary:'Please review the form, as there appears to be an error.'});
    }
  }

    protected readonly CONST = CONST;
}
