import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-list-dialog',
  templateUrl: './edit-list-dialog.component.html',
  styleUrls: ['./edit-list-dialog.component.scss']
})
export class EditListDialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() title: string = 'Dialog Title'
  @Input() disabled: boolean = false;
  @Input() dialogWidth: string = '50';
  @Input() dialogHeight: string = '30';

  @Output() onClose = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.dialogWidth = `${this.dialogWidth}vw`;
    this.dialogHeight = `${this.dialogHeight}vw`;
  }

}
