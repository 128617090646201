import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AccountStatus } from '../models/dto/account.dto';
import { ReadonlyMapBuilder } from '../readyonly-map-builder';
import { ReportProblemStatus } from '../models/dto/report.dto';
  
@Directive({
    selector: '[reportedProblemStatusTextColor]'
})
export class ReportedProblemStatusTextColorDirective implements OnInit, OnChanges{
    @Input() reportProblemStatus: ReportProblemStatus;

    private readonly statusColorMap = ReadonlyMapBuilder.create<ReportProblemStatus, string>()
    .set(ReportProblemStatus.PENDING ,'#ff3251')
    .set(ReportProblemStatus.NEW ,'#1B76F8')
    .set(ReportProblemStatus.RESOLVED ,'#51AF33')
    .build();


    constructor(private eleRef: ElementRef) {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.eleRef.nativeElement.style.color = this.statusColorMap.get(this.reportProblemStatus);
    }

    ngOnInit(): void {
    }
}