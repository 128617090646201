import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Tag } from "primeng/tag";
import { DaysEnum } from "../models/dto/shift.dto";

@Directive({
    selector: '[dayBGColor]',
})
export class DaysBGColorDirective implements OnInit, OnChanges{
    @Input() day: DaysEnum



    constructor(private eleRef: Tag) {}

    ngOnInit(): void {
        // throw new Error("Method not implemented.");
        this.eleRef.styleClass =  `days-${this.day}` //"days-SUN"
    }

    ngOnChanges(changes: SimpleChanges): void {
        // throw new Error("Method not implemented.");
    }

}