import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { convertBlobToString } from "./utils/io-utils";

@Injectable({
    providedIn: 'root',
})
export class ImageService extends AbstractHttpHandler {

    accountImageStr$ = new BehaviorSubject<string>(undefined);
    contactImageStr$ = new BehaviorSubject<string>(undefined);
    profileImageStr$ = new BehaviorSubject<string>(undefined);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router,
      ) {
        super(http, apiEventsService);
    }

    getAccountImage(accountId: number) {
      const url = `v2/images/accounts/${accountId}/avatars/default`;
      const eventType = ApiEventType.GET_ACCOUNT_IMAGE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
      this.http.get<Blob>(url, {responseType: 'blob' as 'json'})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response=>{
        const accountImageStr = await convertBlobToString(response)
        this.accountImageStr$.next(accountImageStr);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })

    }

    updateAccountImage(accountId: number, formData: FormData) {
        const url = `v2/images/accounts/${accountId}/avatars/default`;
        const eventType = ApiEventType.UPSERT_ACCOUNT_IMAGE;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.put<Blob>(url, formData, {responseType: 'blob' as 'json'})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
           const accountImageStr = await convertBlobToString(response)
           this.accountImageStr$.next(accountImageStr);
           this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

  updateProfileImage(profileId: number, formData: FormData) {
      const url = `v2/images/opentimerProfiles/${profileId}/avatars/default`;
      const eventType = ApiEventType.UPSERT_PROFILE_IMAGE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.put<Blob>(url, formData, {responseType: 'blob' as 'json'})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response=>{
         const profileImageStr = await convertBlobToString(response)
         this.profileImageStr$.next(profileImageStr);
         this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

  updateContactImage(contactId: number, formData: FormData) {
    const url = `v2/images/contacts/${contactId}/avatars/default`;
    const eventType = ApiEventType.UPSERT_CONTACT_IMAGE;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<Blob>(url, formData, {responseType: 'blob' as 'json'})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response=>{
        const contactImageStr = await convertBlobToString(response)
        this.contactImageStr$.next(contactImageStr);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
  }

    updateJobCategoryImage(serviceCategoryId: number, formData: FormData) {
      const url = `v2/images/jobCategories/${serviceCategoryId}/avatars/default`;
      const eventType = ApiEventType.UPSERT_SERVICE_CATEGORY_IMAGE;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
      this.http.put<Blob>(url, formData, {responseType: 'blob' as 'json'})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response=>{
         const imgStr = await convertBlobToString(response)
         this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      })
    }

    // updateCityImage(cityId)


    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         if(error.status === 500) {
            title = Messages.HEADER_IMAGE_UPLOAD_ISSUE;
            message = Messages.MESSAGE_IMAGE_UPLOAD_ISSUE;
         }

         if(error.status === 404) {
          showDialog = false;
         }
         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }


}
