
<ng-container *ngIf="contact; else noContactTemplate">
    <!-- <p-button [label]="opentimer.name" styleClass="p-button-link p-button-link-no-padding" 
        (click)="onSelectedOpentimer(opentimer)">
    </p-button> -->
    <p>{{contact.signInEmail}}</p>
</ng-container>

<ng-template #noContactTemplate>
    <p>Not Available</p>
</ng-template>
