<div class="grid grid-nogutter" *ngIf="idVerificationDto">
    <div class="col-12">
        <p-table #pTable
                [value]="idVerificationDto.idVerificationItems"
                [showCurrentPageReport]="false"
                dataKey="id"
                [rows]="10"
                [lazy]="false"
                [paginator]="false"
                [totalRecords]="total"
                responsiveLayout="scroll"
                [loading]="loading"
                styleClass="p-datatable-striped">

                <ng-template pTemplate="caption">
                    <div class="grid grid-nogutter align-items-center">
                        <div class="col-3">
                            <p-button label="Edit ID Verification" (click)="addNewId()"
                                      icon="pi  pi-pencil" iconPos="right">
                            </p-button>
                        </div>
                        <div class="col-11">  </div>
                    </div>
                </ng-template>


                <ng-template pTemplate="header">
                    <tr>
                        <th>TITLE</th>
                        <th>TYPE</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            <div class="flex align-items-center justify-content-start">
                                <ng-container *ngIf="item && item.imageString; else loadingImageTemplate">
                                    <p-image [src]="item.imageString | avatarUriTransform" imageClass="id-avatar"
                                        onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar" [preview]="true">
                                    </p-image>
                                </ng-container>
                                <ng-template #loadingImageTemplate>
                                    <p-image src="/assets/media/img/profile-placeholder.png" imageClass="id-avatar"
                                        onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar" [preview]="true">
                                    </p-image>
                                </ng-template>
                                <p class="text-rm ml-2">{{idVerificationDto.type | idVerificationTypeTitle}}</p>
                            </div>
                        </td>

                        <td>
                            <p class="text-rm">{{item.type | idVerificationItemTypeTitle}}</p>
                        </td>
                        <td>
                            <p class="text-rm">{{item.status}}</p>
                            <p class="text-velvet text-rm">{{item.memo}}</p>
                        </td>
                        <td >
                            <div class="block">
                                <button pButton pRipple type="button"
                                        *ngIf="item.status === IdVerificationStatus.PENDING"
                                        label="Approve"
                                        (click)="approve(item)"
                                        class="p-button-primary p-button-text">
                                </button>

                                <button pButton pRipple type="button"
                                        *ngIf="item.status === IdVerificationStatus.PENDING"
                                        label="Reject"
                                        (click)="reject(item)"
                                        class="p-button-warning p-button-text">
                                </button>

                                <button pButton pRipple type="button"
                                        label="Delete"
                                        (click)="remove(item)"
                                        class="p-button-danger p-button-text">
                                </button>

                            </div>

                        </td>
                    </tr>
                </ng-template>
        </p-table>
    </div>
</div>

<app-edit-dialog *ngIf="showVerificationDialog" [showSubmitButton]="false"
                 [dismissableMask]="true" [visible]="showVerificationDialog"
                 [title]="'Add/Edit ID Verification'" (onSubmit)="onSubmitAddEditVerification()"
                 (onHide)="showVerificationDialog = false"
                 [dialogHeight]="'45'" [disabled]="!idVerificationForm.valid">
                <form [formGroup]="idVerificationForm">
                    <div class="grid grid-nogutter p-2 mt-2 block">
                        <label for="dateOfBirth" class="col-12 text-rb" >
                            ID Verification Type<span class="text-error">*</span>
                        </label>
                        <div class="col-12 mt-2">
                            <p-dropdown
                                [options]="idCards"
                                formControlName="type"
                                placeholder="Please Select"
                                appendTo="body"
                                [disabled]="idVerificationDto.idVerificationItems.length > 0"
                                (onChange)="onSelectedIdVerificationType($event)"
                                [(ngModel)]="idVerificationForm.value.type"
                                optionValue="idVerificationType" [style]="{'width':'100%'}">
                                <ng-template pTemplate="selectedItem" let-selectedItem  >
                                    <div class="country-item country-item-value" >
                                        <p>{{selectedItem.name}}</p>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="country-item">
                                        <p>{{item.name}}</p>
                                    </div>
                                </ng-template>
                            </p-dropdown >
                        </div>
                        <label class="text-rm" style="font-size: 12px !important;">
                            <i>To upload an image, please select an ID verification card first!</i>
                        </label>
                    </div>

                    <div class="grid grid-nogutter p-2 mt-2">
                        <div class="col-12 lg:col-6">
                            <app-account-image-upload [ngControl]="idVerificationForm"
                                [disabled]="!idVerificationDto.type"
                                [avatarUri]="getIdVerificationItem(IdVerificationItemType.ID_FRONT) ?
                                             getIdVerificationItem(IdVerificationItemType.ID_FRONT).imageString : null"
                                [showRequired]="true"
                                [isDisplayBlock]="true" (onFileSelected)="onFileSelected($event, IdVerificationItemType.ID_FRONT)"
                                [placeholder]="'ID (front)'" [label]="'ID (front)'" [name]="'idFront'">
                            </app-account-image-upload>
                        </div>
                        <div class="col-12 lg:col-6">
                            <app-account-image-upload [ngControl]="idVerificationForm"
                                [disabled]="!idVerificationDto.type"
                                [avatarUri]="getIdVerificationItem(IdVerificationItemType.ID_BACK) ?
                                             getIdVerificationItem(IdVerificationItemType.ID_BACK).imageString : null"
                                [showRequired]="true"
                                [isDisplayBlock]="true"  (onFileSelected)="onFileSelected($event, IdVerificationItemType.ID_BACK)"
                                [placeholder]="'ID (back)'" [label]="'ID (back)'" [name]="'idBack'">
                            </app-account-image-upload>
                        </div>

                        <div class="col-12 lg:col-6 mt-3">
                            <app-account-image-upload [ngControl]="idVerificationForm"
                                [disabled]="idVerificationDto.type ? false : true"
                                [avatarUri]="getIdVerificationItem(IdVerificationItemType.ID_SELFIE) ?
                                             getIdVerificationItem(IdVerificationItemType.ID_SELFIE).imageString : null"
                                [showRequired]="true"
                                [isDisplayBlock]="true" (onFileSelected)="onFileSelected($event, IdVerificationItemType.ID_SELFIE)"
                                [placeholder]="'ID (selfie)'" [label]="'ID (selfie)'" [name]="'idSelfie'">
                            </app-account-image-upload>
                        </div>
                    </div>
                </form>
</app-edit-dialog>


<app-edit-dialog *ngIf="showRejectIdVerificationDialog" [disabled]="!rejectionReasonForm.valid"
                 [dismissableMask]="true" [visible]="showRejectIdVerificationDialog"
                 [title]="'Reject Id Card'" (onSubmit)="onSubmitAddEditVerification()"
                 (onHide)="showRejectIdVerificationDialog = false"
                 (onSubmit)="onRejectingIdVerificationImage()">
                <form [formGroup]="rejectionReasonForm">
                    <div class="grid grid-nogutter p-2 mt-2 block">
                        <label for="dateOfBirth" class="col-12 text-rb" >Rejection Reason<span class="text-error">*</span></label>
                        <div class="col-12 mt-2">
                            <p-dropdown
                                [options]="rejectionReasons"
                                formControlName="rejectionReasonId"
                                placeholder="Please Select"
                                appendTo="body"
                                optionValue="id" [style]="{'width':'100%'}">
                                <ng-template pTemplate="selectedItem" let-selectedItem  >
                                    <div class="country-item country-item-value" >
                                        <p>{{selectedItem | translateBackend}}</p>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="country-item">
                                        <p>{{item | translateBackend}}</p>
                                    </div>
                                </ng-template>
                            </p-dropdown >
                        </div>
                    </div>

                    <div class="grid grid-nogutter w-100 block mt-1 p-2">
                        <label for="phone" class="col-12 pr-2 text-rm" >Memo</label>
                        <div class="col-12 mt-1">
                            <textarea  #myInputField   formControlName="memo"
                            rows="5" cols="30"
                            pInputTextarea placeholder="Memo" class="cover-letter-text-area" ></textarea>
                        </div>
                    </div>

                </form>
</app-edit-dialog>
