<ng-container [formGroup]="ngControl | cast">
    <div class="grid grid-nogutter w-100" [class.block]="displayBlock">
        <label [for]="name" class="col-3 pr-2 text-right text-rb" 
            [class.text-right]="textAlign === TextAlignPosition.RIGHT"
            [class.text-left]="textAlign === TextAlignPosition.LEFT">
            {{label}}
        </label>
        <div class="col-9" [class.col-12]="displayBlock" [class.mt-1]="displayBlock">
            <input #myInputField [id]="name" [type]="type" [formControlName]="name" 
                    [readonly]="readonly" 
                 pInputText [placeholder]="placeholder" style="width: 100%"/>
        </div>
    </div>
</ng-container>