
<div class="flex w-100 flex-wrap">
    <ng-container *ngFor="let opentimePanelItem of opentimePanelItems">
        <ng-container *ngIf="opentimePanelItem.visible">
            <p-card styleClass="opentime-card-panel" [routerLink]="opentimePanelItem.url"
                    *appHasPermission="opentimePanelItem.permissions">
                <div class="grid">
                    <div class="col-1"></div>
                    <div class="col-2">
                        <img [src]="opentimePanelItem.icon"  width="35" height="35"
                            onerror="this.src='/assets/media/img/profile-placeholder.png';" />
                    </div>
                    <div class="col-9 mt-1">
                        <p class="label">{{opentimePanelItem.label}}</p>
                    </div>
                </div>
            </p-card>
        </ng-container>
    </ng-container>
</div>
