<app-edit-dialog [visible]="showSignInPhoneDialog" [title]="'Edit User Contacts Information'"
                 (onSubmit)="onSaveUserContactsInformation()"
                 (onHide)="onHide.emit()" [disabled]="this.phoneNumbers.length < 1">
        <ng-container [formGroup]="phoneForm">
            <div  class="mt-3 grid">
                <div class="col-12 lg:col-6">
                    <ng-container [formGroup]="phoneForm">
                        <div class="grid grid-nogutter w-100">
                            <label for="phone" class="col-12 pr-2 text-left">Number</label>
                            <div class="col-6">
                                <app-country-dropdown [ngControl]="phoneForm"
                                                      [name]="'countryId'"
                                                      [items]="countries">
                                </app-country-dropdown>
                            </div>
                            <div class="col-6">
                                <input id="phone" type="tel" formControlName="phone" maxlength="10"
                                       pInputText placeholder="Number" style="width: 100%"/>
                            </div>
                        </div>
                        <div class="col-12 p-0 text-right ">
                            <button pButton type="button" label="Add Phone" icon="pi pi-plus-circle"
                                    class="p-button-text p-button-primary p-0 ml-2" iconPos="left"
                                    [disabled]="!phoneForm.valid"
                                (click)="addPhoneNumber()">
                            </button>
                        </div>
                        <div class="col-12 p-0" *ngIf="phoneNumbers.length > 0">
                            <app-phone-number-list [phoneNumbers]="phoneNumbers"></app-phone-number-list>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>

</app-edit-dialog>

