import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingType } from '../../models/dto/booking.dto';
import { PresetShiftDto } from '../../models/dto/shift.dto';

@Component({
  selector: 'app-booking-shift-item',
  templateUrl: './booking-shift-item.component.html',
  styleUrls: ['./booking-shift-item.component.scss']
})
export class BookingShiftItemComponent implements OnInit {
  @Input() bookingType: BookingType;
  @Input() presetShift: PresetShiftDto;

  @Output() onEditBookingShift = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
