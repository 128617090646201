import { AfterViewInit, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AdminCreationService } from '../services/admin-creation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdminRole } from '../models/dto/admin.dto';

@UntilDestroy()
@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective  implements AfterViewInit{
  @Input() appHasRole: string[] = [];
   
  private currentUserRoles: string[] = []; // Array to hold the user's roles

  constructor(
    private adminCreationService: AdminCreationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { 
  }

  ngAfterViewInit(): void {
    const currentLoggedInAdmin = this.adminCreationService.currentLoggedInAdmin$.value;
    if(!currentLoggedInAdmin) {
      return;
    }

    if(currentLoggedInAdmin) {
      this.currentUserRoles = currentLoggedInAdmin.admin.roles.map(v=>v.role);
    }
    this.init();
  }
  
  init() {
      const allowedRoles = this.appHasRole;

      if (!allowedRoles || !allowedRoles.length) {
        this.viewContainer.clear();
        return;
      }
      // Check if the user has the SUPER_ADMIN_ROLE
      const isSuperAdmin = this.currentUserRoles.includes('SUPER_ADMIN_ROLE');
  
      // Check if the user roles overlap with the allowed roles
      const hasRole = isSuperAdmin || this.currentUserRoles.some(role => allowedRoles.includes(role));

      if (hasRole) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
  }
}
