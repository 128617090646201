import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EducationDtoSchema } from '../../models/dto/educations.dto';

@Component({
  selector: 'app-opentimer-educations',
  templateUrl: './opentimer-educations.component.html',
  styleUrls: ['./opentimer-educations.component.scss']
})
export class OpentimerEducationsComponent implements OnInit {
  @Input() educationSchema: EducationDtoSchema;
  @Input() enabledButton: boolean = true;
  @Input() showAddButton: boolean = true;
  

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
