import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';  

@Pipe({
    name: 'ellipsisPipe'
  })
  export class EllipsisPipe implements PipeTransform {
    transform(value: string, limit: number = 12): string {
      return _.truncate(value, {length: limit});
    }
  
}