import { TranslationDto } from "./translation.dto";

export enum LanguageEnum {
    'EN' = 'EN',
    'JA' = 'JA'
}

export enum ProficiencyLevelEnum {
    BEGINNER = 'BEGINNER',
    CONVERSATIONAL = 'CONVERSATIONAL',
    BUSINESS = 'BUSINESS',
    ADVANCED_BUSINESS = 'ADVANCED_BUSINESS',
    NATIVE = 'NATIVE'
}

export interface ProficiencyLanguageDtoSchema {
    proficiencyLanguages: Array<ProficiencyLanguageDto>;
    total: number;
}

export interface ProficiencyLanguageDto {
    code: string;
    active: string;
    translations: Array<TranslationDto>;
}

export interface ProficiencyDto {
    code: string;
    label: string;
}

export interface LanguageProficiencyDto {
    proficiencyLanguage: string;
    proficiencyLevel:ProficiencyLevelEnum 
}

export interface LanguageProficiencyDtoSchema {
    languageProficiencies: Array<LanguageProficiencyDto>
}