import { InjectionToken } from '@angular/core';
import { Messages } from './messages';

export const defaultErrors = {
    required: (error) => Messages.MESSAGE_FIELD_IS_REQUIRED,
    username_pattern: (error) => Messages.MESSAGE_USERNAME_IS_INVALID,
    username_required: (error) => Messages.MESSAGE_USERNAME_IS_REQUIRED,
    email_pattern: (error) => Messages.MESSAGE_USERNAME_IS_INVALID,
    email_required: (error) => Messages.MESSAGE_USERNAME_IS_REQUIRED,
    code_pattern: (error)=> Messages.MESSAGE_CODE_IS_INVALID,
    notAllowPresentDate: (error) => Messages.MESSAGE_DATE_IS_PRESENT_ERROR,
    account_already_exsist: (error) => Messages.MESSAGE_ACCOUNT_EMAIL_EXIST,
    duplicate_agency_name: (error) => Messages.MESSAGE_DUPLICATE_AGENCY_NAME,
    minlength: ({ requiredLength, actualLength }) => `Minimum ${requiredLength}; ${requiredLength - actualLength} left`,
    minMaxValidator: (error) => Messages.MESSAGE_MINIMUM_SHOULD_LESS_THAN_MAXIMUM,
  }
  
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});

