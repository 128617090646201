<ng-container [formGroup]="ngControl | cast">
    <opentime-p-dropdown 
            #pDrodown
            [options]="items" 
            [formControlName]="name"
            [style]="{'width':'100%'}"  optionValue="id"
            (onScrolledBottom)="onScrolledBottom()"
            (onShow)="onShow($event)"
            filterPlaceholder="Search Agency"
            [haltInfiniteScroll]="haltInfiniteScroll"
            [readonly]="readonly"
            [(ngModel)]="agencyId"
            placeholder="Select Agency"
            [disableInfiniteScroll]="disableInfiniteScroll">
            <ng-template pTemplate="filter" >
                <div class="flex p-3">
                    <div class="p-inputgroup" (click)="$event.stopPropagation()">
                        <input type="text" pInputText placeholder="Search" [value]="searchString"
                               (input)="onKeypressEvent($event)">
                        <span class="bg-velvet text-white p-inputgroup-addon"> 
                            <i class="pi pi-times" (click)="clearSearch()"></i>  
                        </span>
                    </div>
                    <span class="p-inputgroup-addon"> <i class="pi pi-search"></i>  </span>
                    <!-- <button pButton icon="pi pi-search"></button> -->
                </div>
            </ng-template>

            <ng-template let-agency pTemplate="item">
                <div class="agency-item flex align-items-center" >
                    <div class="ml-2" *ngIf="agency.name else noNamteTemplate">{{agency.name}}</div>
                    <ng-template #noNamteTemplate>
                        <div class="ml-2">No Name - Agency ID: {{agency.id}}</div>
                    </ng-template>
                </div>
            </ng-template>

            <ng-template let-selectedItem  pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="selectedItem">
                    <div class="ml-2" *ngIf="selectedItem.name else noNamteTemplate">{{selectedItem.name}}</div>
                    <ng-template #noNamteTemplate>
                        <div class="ml-2">No Name - Agency ID: {{selectedItem.id}}</div>
                    </ng-template>
                </div>
            </ng-template>
    </opentime-p-dropdown>
</ng-container>