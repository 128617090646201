import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-advance-search-panel',
  templateUrl: './advance-search-panel.component.html',
  styleUrls: ['./advance-search-panel.component.scss']
})
export class AdvanceSearchPanelComponent implements OnInit {
  @Output() onAdvanceSearch = new EventEmitter();
  @Output() onReset = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
