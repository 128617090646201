
export enum AccountType {
    OPENTIMER = 'OPENTIMER',
    AGENCY = 'AGENCY',
    INTERVIEWER = 'INTERVIEWER',
    INDIVIDUAL_CUSTOMER = 'INDIVIDUAL_CUSTOMER',
    BUSINESS_CUSTOMER  = 'BUSINESS_CUSTOMER',
    ADMIN = 'ADMIN',
    RECRUITER = 'RECRUITER',
    ASSOCIATE = 'ASSOCIATE',
    INTERVIEW_ASSOCIATE= 'INTERVIEW_ASSOCIATE',
    PROFILE_ASSOCIATE = 'PROFILE_ASSOCIATE'
}


export enum CustomerAccountTypeEnum {
    BUSINESS_CUSTOMER = 'BUSINESS_CUSTOMER',
    INDIVIDUAL_CUSTOMER = 'INDIVIDUAL_CUSTOMER'
}

export  interface CustomerAccountTypeItem {
    value: CustomerAccountTypeEnum,
    name: string;
}
