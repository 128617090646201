import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CountryDto } from 'src/app/shared/models/dto/country.dto';
import { AdminTaxRateDto, TaxRateInputDto } from 'src/app/shared/models/dto/taxt-rate.dto';
import { CountryService } from 'src/app/shared/services/country.service';
import { TaxRatesService } from 'src/app/shared/services/taxt-rate.service';
import * as moment from 'moment';
import {CONST} from "../../../constant";

@UntilDestroy()
@Component({
  selector: 'app-upsert-tax-rate',
  templateUrl: './upsert-tax-rate.component.html',
  styleUrls: ['./upsert-tax-rate.component.scss']
})
export class UpsertTaxRateComponent implements OnInit {
  @Input() showTaxRateDialog: boolean;
  @Input() taxRate: AdminTaxRateDto;

  @Output() onHide = new EventEmitter();

  taxRateForm = this.formBuilder.group({
    rate: this.formBuilder.control(null, [Validators.required]),
    countryId: this.formBuilder.control(null, [Validators.required]),
    executionDateTime: this.formBuilder.control(null, [Validators.required]),
  })


  countries: Array<CountryDto> = [];

  minDate = new Date();
  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService,
              private taxRateService: TaxRatesService) { }

  ngOnInit(): void {
    this.getSubcribers();

    if(this.taxRate) {
      this.taxRateForm.patchValue({
        countryId: (this.taxRate && this.taxRate.country) ? this.taxRate.country.id : null,
        rate: this.taxRate.rate,
        executionDateTime: new Date(this.taxRate.executionDateTime)
      })
    }
  }




  getSubcribers() {
    this.countryService.countries$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      this.countries = response;
    })
  }

  onSubmitTaxRate() {
    const taxRateInputDto: TaxRateInputDto = {
      countryId: this.taxRateForm.value.countryId,
      rate: this.taxRateForm.value.rate,
      executionDateTime:moment(new Date(this.taxRateForm.value.executionDateTime).getTime() + 60*1000).format(CONST.TIME_FORMAT_MOMENT)
    }

    this.taxRateService.upsertTaxRate(taxRateInputDto)
  }


  protected readonly CONST = CONST;
}
