import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { BehaviorSubject, catchError } from "rxjs";
import { AdminTaxRateDtoSchema, TaxRateDto, TaxRateInputDto } from "../models/dto/taxt-rate.dto";

@Injectable({
    providedIn: 'root',
})
export class TaxRatesService extends AbstractHttpHandler {
    adminTaxRateDtoSchema$ = new BehaviorSubject<AdminTaxRateDtoSchema>(null);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }

    getTaxRates(page:number = 1, 
                limit:number = 10,
                countryId: number = null) {

        const url = `v2/taxRates`;
        const eventType = ApiEventType.GET_TAX_RATES;    
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();
    
        if(limit) {
            params = params.append('limit', limit);
        }            
  
        if(page) {
            params = params.append('page', page);
        }

        if(countryId) {
          params = params.append('countryId', countryId);
        }

        this.http.get<AdminTaxRateDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.adminTaxRateDtoSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    upsertTaxRate(taxRateInputDto: TaxRateInputDto) {
      const url = `v2/taxRates`;
      const eventType = ApiEventType.UPSERT_TAX_RATES;    
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.post<TaxRateDto>(url, taxRateInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: TaxRateDto)=>{
          // this.adminPriceDtoSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;
         
          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

    
         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;
         
         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog 
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();  
            this.router.navigate(['']);
        }
    }
}