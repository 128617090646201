import { Component, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService } from 'primeng/api';
import { AccountType } from '../../models/account';
import { ApiEventStatus, ApiEventType } from '../../models/api-event';
import { AccountStatus } from '../../models/dto/account.dto';
import { BookingStatus } from '../../models/dto/booking.dto';
import { InterviewStatus } from '../../models/dto/interview.dto';
import { ReadonlyMapBuilder } from '../../readyonly-map-builder';
import { AccountApproveService } from '../../services/account-approve.service';
import { ApiEventService } from '../../services/api-event.service';
import { BookingsService } from '../../services/bookings.service';
import { InterviewsService } from '../../services/interviews.service';

@UntilDestroy()
@Component({
  selector: 'app-account-activate-deactivate-button',
  templateUrl: './account-activate-deactivate-button.component.html',
  styleUrls: ['./account-activate-deactivate-button.component.scss']
})
export class AccountActivateDeactivateButtonComponent implements OnInit {
  @Input() accountId: number;
  @Input() status: boolean;
  @Input() accountType: AccountType;
  @Input() checkBookings: boolean = false;


  activaDeactivateteButtonLabelMap = AccountApproveService.activaDeactivateteButtonLabelMap;

  readonly getAccountTypeRouteMap =  ReadonlyMapBuilder.create<AccountType, string>()
  .set(AccountType.OPENTIMER, 'opentimers')
  .set(AccountType.BUSINESS_CUSTOMER, 'customers')
  .set(AccountType.INDIVIDUAL_CUSTOMER, 'customers')
  .set(AccountType.AGENCY, 'agencies')
  .build()


  displayDeactivationDialog: boolean = false;

  hasBookings: boolean = false;
  hasInterviews: boolean = false;
  constructor(private accountApproveService: AccountApproveService,
              private apiEventService: ApiEventService,
              private confirmationService: ConfirmationService,
              private bookingsService: BookingsService,
              private interviewsService: InterviewsService) { }

  ngOnInit(): void {
    if(this.checkBookings) {
      this.bookingsService.getBookingOrdersByAccount(this.getAccountTypeRouteMap.get(this.accountType), this.accountId,
                           null, null, [BookingStatus.UPCOMING, BookingStatus.IN_PROGRESS]);
      this.interviewsService.getInterviewsByAccount(this.getAccountTypeRouteMap.get(this.accountType), this.accountId, null, null, [InterviewStatus.NEW, InterviewStatus.UPCOMING, InterviewStatus.IN_PROGRESS])
    }

    this.getApiEvents();
  }

  getApiEvents() {
    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe(event=>{
      if(event && event.type === ApiEventType.GET_BOOKINGS && event.status === ApiEventStatus.COMPLETED) {
        const bookingSchema = this.bookingsService.bookingOrderDisplayDtoSchema$.value;

        let total = 0;
        const totalsByStatus  = bookingSchema.totalsByStatus.filter((v)=>{
          return [BookingStatus.UPCOMING, BookingStatus.IN_PROGRESS].includes(v.bookingOrderStatus);
        });
        totalsByStatus.forEach((status)=>{
          total += status.total;
        });
        this.hasBookings = total > 0;
      }

      if(event && event.type === ApiEventType.GET_INTERVIEWS && event.status === ApiEventStatus.COMPLETED) {
        const interviewDtoSchema = this.interviewsService.interviewDtoSchema$.value;
        let total = 0;
        const totalsByStatus  = interviewDtoSchema.totalsByStatus.filter((v)=>{
          return [InterviewStatus.NEW, InterviewStatus.UPCOMING, InterviewStatus.IN_PROGRESS].includes(v.interviewStatus);
        });
        totalsByStatus.forEach((status)=>{
          total += status.total;
        });
        this.hasInterviews = total > 0;
      }

      
    })
  }

  get isCustomer(): boolean {
    return this.accountType === AccountType.BUSINESS_CUSTOMER || 
           this.accountType === AccountType.INDIVIDUAL_CUSTOMER;
  }


  activateDeactivate() {
    if(this.status) {
      this.deactivateAccount();
    } else {
      this.activateAccount();
    }
  }

  deactivateAccount() {
    this.displayDeactivationDialog = true;
  }

  accountDeactivation() {
    this.accountApproveService.accountDisablement(this.accountId);
    this.displayDeactivationDialog = false;
  }

  activateAccount() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to activate account?',
      header: 'Activate Account',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: false,
      rejectButtonStyleClass: 'velvet',
      acceptLabel: 'Activate',      
      accept: () => {
          this.accountApproveService.accountEnablement(this.accountId);
      },
    });
  }



}
