<ng-container [formGroup]="ngControl | cast">
    <div class="grid grid-nogutter w-100" [class.block]="isDisplayBlock">
        <label [for]="name" class="col-4 pr-2 text-right text-rb" >
            {{label}}<span class="text-error" *ngIf="showRequired">*</span>
        </label>
        <div class="col-6" [class.mt-1]="isDisplayBlock">
            <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [disabled]="disabled">
                <ngx-dropzone-label *ngIf="!avatarUri">
                    Drop files to upload or Browse
                </ngx-dropzone-label>
                <ng-container *ngIf="files.length > 0; else avatarTemplate">
                    <ngx-dropzone-image-preview style="height: 100% !important;"
                        ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                    </ngx-dropzone-image-preview>
                </ng-container>

                <ng-template #avatarTemplate>
                    <div *ngIf="avatarUri" class="flex align-items-center">
                        <img [src]="avatarUri | avatarUriTransform" 
                              class="avatar-uri"
                              onerror="this.src='/assets/media/img/profile-placeholder.png';"
                              alt="Avatar"/>
                    </div>
                </ng-template>
            
              </ngx-dropzone>
        </div>
    </div>
</ng-container>