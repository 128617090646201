<ng-container *ngIf="isForm; else notFormCountryDropdown">
    <ng-container [formGroup]="ngControl | cast">
        <p-dropdown [options]="items" [formControlName]="name"
                    appendTo="body"
                    [(ngModel)]="countryId"
                    [style]="{'width':'95%'}"  optionValue="id" optionLabel="name">
                <ng-template let-country pTemplate="item">
                    <div class="country-item flex align-items-center">
                        <img [src]="country.code | countryFlag" />
                        <div class="ml-2">{{country | translateBackend}}</div>
                    </div>
                </ng-template>

                <ng-template let-selectedItem  pTemplate="selectedItem">
                    <div class="country-item flex align-items-center" *ngIf="selectedItem">
                        <img [src]="selectedItem.code | countryFlag" />
                        <div class="ml-2">{{selectedItem | translateBackend}}</div>
                    </div>
                </ng-template>
        </p-dropdown>
    </ng-container>
</ng-container>

<ng-template #notFormCountryDropdown>
    <p-dropdown [options]="items"
                appendTo="body"
                (onChange)="onSelectedCountry.emit($event.value)"
                [placeholder]="'Select Country'"
                [filter]="true" filterBy="name"
                [style]="{'width':'95%'}"  optionValue="id" optionLabel="name">
            <ng-template let-country pTemplate="item">
                <div class="country-item flex align-items-center">
                    <img [src]="country.code | countryFlag" />
                    <div class="ml-2">{{country | translateBackend}}</div>
                </div>
            </ng-template>

            <ng-template let-selectedItem  pTemplate="selectedItem">
                <div class="country-item flex align-items-center" *ngIf="selectedItem">
                    <img [src]="selectedItem.code | avatarUriTransform"/>
                    <div class="ml-2">{{selectedItem | translateBackend}}</div>
                </div>
            </ng-template>
    </p-dropdown>
</ng-template>



