<div class="grid grid-nogutter">
    <div class="col-12">
        <p-table [value]="languageProficiencies" editMode="row"
                styleClass="p-datatable-striped" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>LANGUAGE</th>
                    <th>PROFICIENCY</th>
                    <th>ACTION</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-language let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="language">
                    <td>
                        {{language.proficiencyLanguage | languagePipe}}
                    </td>
                    <td class="text-primary">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-dropdown [options]="proficiencies" [(ngModel)]="language.proficiencyLevel"
                                            [style]="{'width':'100%'}" appendTo="body" optionLabel="label" optionValue="code">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{language.proficiencyLevel}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="text-align:center">
                        <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(language)" class="p-button-rounded p-button-text"></button> -->
                        <!-- <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(language)" class="p-button-rounded p-button-text p-button-success mr-2"></button> -->
                        <!-- <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(language, ri)" class="p-button-rounded p-button-text p-button-danger"></button> -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
