import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DayOffDto, OpentimerAvailabilityResponseDto } from '../../models/dto/opentimer-job-profile.dto';

@Component({
  selector: 'app-opentimer-calendar',
  templateUrl: './opentimer-calendar.component.html',
  styleUrls: ['./opentimer-calendar.component.scss']
})
export class OpentimerCalendarComponent implements OnInit {
  @Input() availability: OpentimerAvailabilityResponseDto;
  @Input() currentDate: Date = new Date();

  @Output() onSelectedDate = new EventEmitter<Date>(null);

  minDate = new Date();
  unAvailableDates: Array<Date> = [];

  constructor() { }

  ngOnInit(): void {
    this.unAvailableDates.push(new Date());
    if(this.dayoff) {
      this.dayoff.daysOff.forEach((date, i)=>{
        this.unAvailableDates[i] = new Date(date);
      })
    }
  }

  get dayoff(): DayOffDto {
    return this.availability.dayOff || null;
  }

  selectDate() {
    this.onSelectedDate.emit(this.currentDate);
  }

}
