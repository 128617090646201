<app-edit-dialog [visible]="showServiceCategoryDialog" [title]="'Add/Edit Service Category'" (onSubmit)="onSubmitServiceCategory()"
                 [dialogHeight]="'40'"
                 (onHide)="onHide.emit()"  >
                 <div  class="mt-3 grid p-2">
                    <ng-container [formGroup]="serviceCategoryForm">
                        <div class="col-6">
                            <app-labelled-input [ngControl]="serviceCategoryForm"
                                 [displayBlock] = "true"
                                 [placeholder]="'Service Category Name'" [label]="'Service Category Name'"
                                 [name]="'serviceCategoryName'">
                            </app-labelled-input>
                         </div>

                         <div class="col-6">
                            <app-labelled-input [ngControl]="serviceCategoryForm"
                                 [displayBlock] = "true"
                                 [placeholder]="'Service Category Name (Native)'" [label]="'Service Category Name (Native)'"
                                 [name]="'serviceCategoryNativeName'">
                            </app-labelled-input>
                         </div>
                         <div class="col-9">
                            <app-labelled-input [ngControl]="serviceCategoryForm"
                                 [displayBlock] = "true"
                                 [placeholder]="'CODE'" [label]="'CODE'"
                                 [name]="'code'">
                            </app-labelled-input>
                         </div>
                         <div class="col-3 p-0">
                           <div class="grid grid-nogutter">
                                 <label class="col-12 text-rb">Status</label>
                                 <div class="col-12">
                                    <p-toggleButton onLabel='Active' offLabel="Inactive"
                                    offIcon="pi pi-times" onIcon='pi pi-check' formControlName="active"></p-toggleButton>
                                 </div>
                           </div>

                        </div>
                         <div class="col-12 flex justify-content-start">
                            <app-account-image-upload [ngControl]="serviceCategoryForm" [avatarUri]="avatarUri"
                                [placeholder]="'Upload Thumbnail'" [label]="'Upload Thumbnail'" [name]="'avatarFile'">
                            </app-account-image-upload>
                         </div>

                    </ng-container>
                </div>
</app-edit-dialog>
