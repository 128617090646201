import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import {AdminCreationService} from "./admin-creation.service";



/* eslint-disable camelcase */
interface OauthTokensResponse {
  access_token: string;
  refresh_token: string;
  token_type?: string;
  expires_in?: number;
  scope?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService extends AbstractHttpHandler{

    constructor(public override http: HttpClient,
                public override apiEventsService: ApiEventService,
                private adminCreationService: AdminCreationService,
                private router: Router) {
      super(http, apiEventsService)
    }

    private static getOauthHeader(): HttpHeaders {
      return new HttpHeaders({
        'Authorization': environment.appClient,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    }

    authenticate(username: string, hashedPassword: string): void {
      console.log('eventType',  ApiEventType.SIGNIN)
      this.apiEventsService.sendEvent({ type: ApiEventType.SIGNIN, status: ApiEventStatus.IN_PROGRESS });
      this.http.post<OauthTokensResponse>('oauth/token', null, {
          headers: AuthService.getOauthHeader(),
          params: {
            username: username,
            password: hashedPassword,
            grant_type: 'password',
          }})
          .pipe(catchError(this.handleErrors(ApiEventType.SIGNIN, [])))
          .subscribe((response:OauthTokensResponse) =>{
            localStorage.setItem('access_token', response.access_token);
            localStorage.setItem('expires_in', response.expires_in.toString());
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.setItem('scope', response.scope);
            localStorage.setItem('token_type', response.token_type);
            this.apiEventsService.sendEvent({ type:  ApiEventType.SIGNIN, status: ApiEventStatus.COMPLETED });
            console.log('eventType-completed',  ApiEventType.SIGNIN)
            this.adminCreationService.getAuthenticated();
          })
    }


    public clearStorage() {
      super.clearLocalStorage()
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: HttpErrorResponse) => T {
      return (error: any): T => {



        const errorResponse = error.error;

        let title = Messages.HEADER_GENERIC_ERROR;
        let message = Messages.MESSAGE_GENERIC_ERROR;
        let showDialog = true;

        if (errorResponse) {
          const errorCode = errorResponse.error;
          switch (errorCode) {
            case 'invalid_token': {
              title = Messages.HEADER_EXPIRED_SESSION_ERROR;
              message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
              break;
            }

            case 'invalid_grant': {
              title = Messages.HEADER_AUTHENTICATION_FAILED_ERROR;
              message = Messages.MESSAGE_EMAIL_PASSWORD_INCORRECT_ERROR;
              break;
            }

          }
       }

       const errorToken = error.headers.get('Response_token');
       message = `${message} <br/> Response Token: ${errorToken}`

       this.apiEventsService.sendEvent({
              type: eventType,
              status: ApiEventStatus.ERROR,
              title,
              message,
              popup: showDialog
        });

        this.unAuthorizedHandler(error)
        return response as T;
      };
    }

    protected unAuthorizedHandler(error:any) {
      if(error.status === 401) {
          this.clearLocalStorage();
          this.router.navigate(['']);
      }
  }
}
