import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Gender } from 'src/app/shared/models/gender';

interface GenderItem {
  value: Gender;
  name: string;
}

@Component({
  selector: 'app-gender-dropdown',
  templateUrl: './gender-dropdown.component.html',
  styleUrls: ['./gender-dropdown.component.scss']
})
export class GenderDropdownComponent implements OnInit {
  @Input() ngControl: AbstractControl;
  @Input() name: string;
  @Input() readonly: boolean = false;

  items: Array<GenderItem> = [
    {
      name: 'Male',
      value: Gender.MALE
    },
    {
      name: 'Female',
      value: Gender.FEMALE
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
