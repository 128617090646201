<ng-container *ngIf="status === AccountStatus.PENDING || 
                     status === AccountStatus.IN_REVIEW ; else vettedTemplated">
    <div>
        <button pButton  label="Deny"  class="velvet w-85" pRipple (click)="deny()"> </button>
        <button pButton  label="Approve" class="primary w-85 mt-2" pRipple (click)="approve()"> </button>
    </div>
</ng-container>

<ng-template #vettedTemplated>
    <button *ngIf="status !== AccountStatus.NOT_VERIFIED"
            pButton  [label]="status | registrationStatusFlag"  
            class="gray  mr-3 w-85" pRipple disabled> </button>

    <button *ngIf="status === AccountStatus.NOT_VERIFIED"
            pButton  label="Reevaluate"  
            (click)="reevaluate()"
            class="success  mr-3 w-85" pRipple> </button>
</ng-template>

<p-dialog [(visible)]="display" [style]="{width: '30vw'}">
    <ng-template pTemplate="header">
       <label class="text-rb text-primary">Credit Balance</label> 
    </ng-template>
    <div class="grid grid-nogutter">

        <div class="col-12 lg:col-4 pr-2">
                <p-dropdown  [options]="currencyItems" optionLabel="name" 
                             [(ngModel)]="currencyItem" 
                             appendTo="body"
                             placeholder="Currency"
                             optionValue="currency" [style]="{'width':'100%'}" >
                    <ng-template pTemplate="selectedItem" let-selectedItem>
                        <div class="country-item country-item-value" >
                            <div>{{selectedItem.name}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div class="country-item">
                            <div><p>{{item.name}}</p></div>
                        </div>
                    </ng-template>
                </p-dropdown>
        </div>

        <div class="col-8">
            <input  id="phone" type="tel" [(ngModel)]="creditBalance" 
                    appOnlyInteger 
                    class="text-right"
                    pInputText  placeholder="Credit Balance" style="width: 100%"/>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div>
            <button pButton  label="Cancel"  class="velvet" pRipple (click)="display = false"> </button>
            <button pButton  label="Approve" class="primary mt-2" 
                    [disabled]="creditBalance < 1 || !currencyItem"
                    pRipple (click)="onApproveCustomer()"> </button>
        </div>
    </ng-template>
</p-dialog>

<!-- <p-confirmDialog header="Successfully Added" message="The information added successfully" 
                 icon="pi pi-check-circle" [rejectVisible]="true">
</p-confirmDialog> -->