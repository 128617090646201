<app-edit-dialog [visible]="showCityDialog" [title]="'Add/Edit City'" (onSubmit)="onSubmitCity()"
                 (onHide)="onHide.emit()" [disabled]="!cityForm.valid" [dialogHeight]="'40'">
        <ng-container [formGroup]="cityForm">
            <div  class="mt-3 grid">
                <div class="col-12 lg:col-8">
                    <app-account-image-upload [ngControl]="cityForm" [avatarUri]="avatarUri"
                        [placeholder]="'Add City Image'" [label]="'Add City Image'" [name]="'avatarFile'">
                    </app-account-image-upload>
                </div>
                <div class="col-12 lg:col-12">
                    <div class="grid grid-nogutter w-100 block">
                        <label for="phone" class="col-3 pr-2 text-right">Country</label>
                        <div class="col-12">
                            <app-country-dropdown [ngControl]="cityForm"
                                                  [name]="'countryId'"
                                                  [items]="countries">
                            </app-country-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6">
                    <app-labelled-input [ngControl]="cityForm" [displayBlock]="true"
                            [placeholder]="'City Name'" [label]="'City Name'" [name]="'cityName'">
                    </app-labelled-input>
                </div>
                <div class="col-12 lg:col-6">
                    <app-labelled-input [ngControl]="cityForm" [displayBlock]="true"
                            [placeholder]="'City Name(Katakana)'" [label]="'City Name(Katakana)'" [name]="'cityNativeName'">
                    </app-labelled-input>
                </div>
                <div class="col-12 lg:col-6">
                    <p-toggleButton onLabel='Active' offLabel="Inactive"
                         offIcon="pi pi-times" onIcon='pi pi-check' formControlName="active"></p-toggleButton>
                 </div>
            </div>
        </ng-container>
</app-edit-dialog>