import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CreditStatus } from '../../models/dto/credit-request.dto';
import { AccountApproveService } from '../../services/account-approve.service';

@Component({
  selector: 'app-approve-credit-buttons',
  templateUrl: './approve-credit-buttons.component.html',
  styleUrls: ['./approve-credit-buttons.component.scss']
})
export class ApproveCreditButtonsComponent implements OnInit {
  @Input() creditRequestId: number;
  @Input() creditStatus: CreditStatus;

  readonly CreditStatus = CreditStatus;

  display: boolean = false;
  creditBalance: number = 0;

  constructor(private confirmationService: ConfirmationService,
              private accountApproveService: AccountApproveService) { }

  ngOnInit(): void {
  }

  approve() {

  }

  onApproveCredit() {
    
  }

  deny() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deny account?',
      header: 'Deny Account',
      icon: 'pi pi-exclamation-triangle',
      dismissableMask: true,
      rejectVisible: true,
      rejectButtonStyleClass: 'velvet',
      accept: () => {
        this.accountApproveService.accountDenial(this.creditRequestId);
      },
    });
  }

}
