<app-edit-dialog [visible]="showPersonalDialog" [title]="'Edit User Information'" (onSubmit)="onSaveUserInformation()"
                 (onHide)="onHide.emit()" [disabled]="!contactForm.valid">
    <div  class="mt-3 grid">
        <ng-container [formGroup]="contactForm">
            <div class="col-12 lg:col-6">
                <app-labelled-input [ngControl]="contactForm" [displayBlock]="true"
                    [placeholder]="'First Name (English)'" [label]="'First Name'" [name]="'firstName'">
                </app-labelled-input>
            </div>
            <div class="col-12 lg:col-6">
                <app-labelled-input [ngControl]="contactForm" [displayBlock]="true"
                    [placeholder]="'Last Name (English)'" [label]="'Last Name'" [name]="'lastName'">
                </app-labelled-input>
            </div>
            <div class="col-12 lg:col-6">
                <app-labelled-input [ngControl]="contactForm" [displayBlock]="true"
                    [placeholder]="'First Name (Katakana)'" [label]="'First Name'" [name]="'nativeFirstName'">
                </app-labelled-input>
            </div>
            <div class="col-12 lg:col-6">
                <app-labelled-input [ngControl]="contactForm" [displayBlock]="true"
                    [placeholder]="'Last Name (Katakana)'" [label]="'Last Name'" [name]="'nativeLastName'">
                </app-labelled-input>
            </div>
            <div class="col-12 lg:col-6">
                <div class="grid grid-nogutter w-100 block">
                    <label for="dateOfBirth" class="col-3 pr-2 text-right">Date of Birth</label>
                    <div class="col-9" >
                        <p-calendar id="dateOfBirth"
                                    formControlName="dateOfBirth"
                                    appendTo="body"
                                    [showClear]="true"
                                    [maxDate]="maxDate"
                                    [dateFormat]="CONST.DATE_FORMAT"
                                    [showIcon] = "true"
                                    placeholder="Date of Birth"
                                    [style]="{'width':'100%'}"
                                    panelStyleClass="modal-calendar"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-5">
                <div class="grid grid-nogutter w-100 block">
                    <label for="dateOfBirth" class="col-3 pr-2 text-right">Gender</label>
                    <div class="col-10" >
                        <app-gender-dropdown [ngControl]="contactForm"
                                             [name]="'gender'">
                        </app-gender-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-edit-dialog>
