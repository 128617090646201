import { ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgenciesSchema, AgencyDto } from 'src/app/shared/models/dto/agency.dto';
import { AgencyService } from 'src/app/shared/services/agency.service';
import * as _ from 'lodash';  
import { ApiEventService } from 'src/app/shared/services/api-event.service';
import { ApiEventStatus, ApiEventType } from 'src/app/shared/models/api-event';
import { delay } from 'src/app/shared/delay';
import { Dropdown } from 'src/app/shared/modules/custom-p-dropdown/custom-p-dropdown.module';

@UntilDestroy()
@Component({
  selector: 'app-agencies-dropdown',
  templateUrl: './agencies-dropdown.component.html',
  styleUrls: ['./agencies-dropdown.component.scss']
})
export class AgenciesDropdownComponent implements OnInit {
  @Input() ngControl: AbstractControl;
  @Input() name: string;
  @Input() optionValue: string;
  @Input() placeholder: string = 'Select'
  @Input() agency: AgencyDto;
  @Input() readonly: boolean = false;

  @ViewChild(Dropdown) pDrodown: Dropdown;


  disabled: boolean = false;
  overlayVisible: boolean = false;
  focused: boolean = false;
  showClear: boolean = false;
  disableInfiniteScroll: boolean = false;
  haltInfiniteScroll: boolean = false;


  items: Array<AgencyDto> = [];
  total: number = 0;

  searchString: string = ''
  isSearch: boolean = false;

  agencyId: number;

  constructor(private agencyService: AgencyService,
              private apiEventService: ApiEventService,) { 
      this.onKeypressEvent = _.debounce(this.onKeypressEvent, 500)
    }

    ngOnInit(): void {
      this.getSubcribers();
      if(this.agency) {
        this.items.push(this.agency);
      }

      if(this.ngControl.get(this.name).value) {
        this.agencyId = Number(this.ngControl.get(this.name).value);
      }


      this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event=>{
        if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_AGENCIES) {
          this.haltInfiniteScroll = false;
        }
      })
    }

    getSubcribers() {
      this.agencyService.agenciesSchema$
      .pipe(untilDestroyed(this))
      .subscribe((response: AgenciesSchema)=>{
        if(response) {
          if(this.isSearch) {
            this.items = [];
            this.isSearch = false;
          }
          for(const agency of response.agencies) {
              this.items.push(agency);
          }
          this.total = response.total;
        }
      })
    }
    

    onScrolledBottom() {
      if(this.items.length >= this.total) {
        this.disableInfiniteScroll = true;
      }

      if((this.items.length <= this.total) && !this.haltInfiniteScroll) {
        const lastItemAgency = this.items[this.items.length -1];
        this.agencyService.getAgencies(lastItemAgency.id, null, 20);
        this.haltInfiniteScroll = true;
      } 
    }

    async onKeypressEvent(ev) {
      this.searchString = ev.target.value as string;
      this.isSearch = true;
      this.agencyService.getAgencies(null, null, 20, ev.target.value as string);
    }

    onShow(ev) {
      // this.agencyService.getAgencies(null, null, 20, null, [], null, false);
    }

    clearSearch() {
      this.isSearch = true;
      this.searchString = '';
      this.agencyService.getAgencies(null, null, 20, null);
    }
}

