import { JobLocationType } from "../job-location";
import { AssociateDto } from "./associate.dto";
import { BookingType } from "./booking.dto";
import { CommentDto } from "./comment.dto";
import { ContactBasicDto } from "./contact.dto";
import { CustomerDto } from "./customer.dto";
import { InviteDto } from "./invite.dto";
import { LocationAddressDto } from "./location-address.dto";
import { OpentimerProfileCreationDto } from "./opentimer-job-profile.dto";
import { PriceDto } from "./prices.dto";
import { RecruiterDisplayDto, RecruiterDto } from "./recruiter.dto";
import { ServiceSubCategoryDto } from "./service-category.dto";
import { SpecialInstructionDto } from "./special-instructions.dto";

export enum InterviewStatus {
    NEW = 'NEW',
    UPCOMING = 'UPCOMING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED'
}

export interface InterviewTotalByStatusDto {
    total: number;
    interviewStatus: InterviewStatus;
}

export interface InterviewDtoSchema {
    interviews: Array<InterviewDto>;
    total: number;
    totalsByStatus: Array<InterviewTotalByStatusDto>;
}

export interface InterviewDto {
    id: number;
    profile: OpentimerProfileCreationDto;
    participants: Array<AssociateDto>;
    invites: Array<InviteDto>;
    address: LocationAddressDto;
    customer: CustomerDto;
    recruiter: RecruiterDisplayDto;
    status: InterviewStatus;
    interviewType: JobLocationType;
    scheduledStartTime: string; 
    scheduledEndTime: string;
    price: number;
    priceDto: PriceDto;
    interviewContact: SpecialInstructionDto;
    employmentType: BookingType;
    searchId: number;
    agencyComment: CommentDto;
    effectiveEndTime: string;
    scheduledZonedEndTime: string;
    scheduledZonedStartTime: string;
    durationInSeconds: number;
    connectedParticipants: Array<ContactBasicDto>;
    waitingParticipants: Array<ContactBasicDto>;
    savedVideos: Array<SavedVideosDto>;
}

export interface InterviewInviteDto {
    interviewId: number;
    opentimerFullName: string;
    interviewType: JobLocationType;
    scheduledStartTime: string;
    address: string;
    serviceSubCategoryDto: ServiceSubCategoryDto;
}

export enum SavedVideoStatus {
    STARTED = 'STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED'
}

export interface SavedVideosDto {
    mediaUri: string;
    size: number;
    compositionSid: string;
    duration: number;
    secondsRemaining: number;
    percentageDone: number;
    status: SavedVideoStatus;
    startTime: string;
    endTime: string;
}