import { Pipe, PipeTransform } from "@angular/core";
import { AccountType } from "../models/account";
import { AccountStatus } from "../models/dto/account.dto";
import { CreditStatus } from "../models/dto/credit-request.dto";
import { CurrencyEnum } from "../models/dto/currency.dto";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'creditStatusMap'
  })
  export class CreditStatusMapPipe implements PipeTransform {
  
    private readonly creditStatusMap = ReadonlyMapBuilder.create<CreditStatus, string>()
    .set(CreditStatus.APPROVED ,'Approved')
    .set(CreditStatus.DENIED,'Denied')
    .set(CreditStatus.PENDING, 'Pending')
    .build();

    transform(value: CreditStatus): string {
      return this.creditStatusMap.get(value);
    }
  
}