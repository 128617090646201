import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressLocation, ContactDto, PhoneNumberDto } from '../../models/dto/contact.dto';
import { CustomerDto } from '../../models/dto/customer.dto';

@Component({
  selector: 'app-customer-item',
  templateUrl: './customer-item.component.html',
  styleUrls: ['./customer-item.component.scss']
})
export class CustomerItemComponent  {
  @Input() customer: CustomerDto;
  @Input() contacts: Array<ContactDto> = [];
  @Input() isDisplayMode: boolean = false;
  @Input() showEditButton: boolean = false;

  @Output() onSelectCustomer = new EventEmitter<CustomerDto>();
  @Output() onEditCustomer = new EventEmitter<void>();

  constructor() { }


  get contact(): ContactDto {
    let contact: ContactDto = null;
    if(this.customer) {
      contact = this.contacts.find((v=>{
        return v.id === this.customer.contacts[0].contactId
      }));
    }
    return contact;
  }

  get location(): AddressLocation {
    return this.customer.contactsInfo.addressLocations.length > 0 ? this.customer.contactsInfo.addressLocations[0] : null;
  }

  get phone(): PhoneNumberDto {
    return this.customer.contactsInfo.phones.length > 0 ? this.customer.contactsInfo.phones[0] : null;
  }
}
