import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SkillService} from "../../../services/skill.service";
import {CoverLetterDto} from "../../../models/dto/cover-letter.dto";
import {TextAlignPosition} from "../../labelled-input/labelled-input.component";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-cover-letter',
  templateUrl: './edit-profile-cover-letter.component.html',
  styleUrls: ['./edit-profile-cover-letter.component.scss']
})
export class EditProfileCoverLetterComponent implements OnInit {
  @Input() coverLetter: CoverLetterDto;
  @Input() avatarUri: string;
  @Input() showCoverLetterDialog: boolean;
  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  coverLetterForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private skillService: SkillService) {
  }

  ngOnInit(): void {
    this.coverLetterForm = this.formBuilder.group({
      id: this.formBuilder.control(null),
      alias: this.formBuilder.control('', [Validators.required]),
      description: this.formBuilder.control('', [Validators.required, Validators.minLength(150)])
    })

    if(this.coverLetter) {
      this.coverLetterForm.patchValue({
        id: this.coverLetter.id,
        alias: this.coverLetter.alias,
        description: this.coverLetter.description,
      })
    }
  }

  onUpdateCoverLetter() {
    this.onSubmit.emit(this.coverLetterForm.value);
    this.showCoverLetterDialog = false;
  }

  protected readonly TextAlignPosition = TextAlignPosition;
}
