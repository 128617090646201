
export function convertBlobToString(imageResponse: Blob) {
    return new Promise<string>((resolve)=>{
      const reader = createFileReader();
      reader.readAsDataURL(imageResponse);
      reader.onload = () => {
        const imageSrc = reader.result as string;
        resolve(imageSrc);
      };
    });
  }
  
  export function createFileReader(): FileReader {
    const fileReader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || fileReader;
  }
  