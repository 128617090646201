import {Pipe, PipeTransform} from "@angular/core";
import {ContractTerminationReasonEnum} from "../models/dto/customer-rating.dto";
import {ReadonlyMapBuilder} from "../readyonly-map-builder";


@Pipe({
    name: 'contactTerminationReasonTitlePipe'
})
export class ContactTerminationReasonTitlePipe implements PipeTransform {

    private readonly idVerificationTypeTitleMap = ReadonlyMapBuilder.create<ContractTerminationReasonEnum, string>()
    .set(ContractTerminationReasonEnum.CANCELLED_BY_CLIENT,'Cancelled by client')
    .set(ContractTerminationReasonEnum.CANCELLED_BY_OPENTIMER,'Cancelled by opentimer')
    .set(ContractTerminationReasonEnum.JOB_COMPLETED_SUCCESSFULLY,`Job completed`)
    .build();

    transform(value: ContractTerminationReasonEnum): string {
      return this.idVerificationTypeTitleMap.get(value);
    }

}
