import { NgModule } from "@angular/core";
import {CommonModule, registerLocaleData} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {PanelMenuModule} from 'primeng/panelmenu';
import { ControlErrorComponent } from "./components/control-error/control-error.component";
import { ControlErrorContainerDirective } from "./directives/control-error-container.directive";
import { ControlErrorsDirective } from "./directives/control-error.directive";
import { FormSubmitDirective } from "./directives/form-submit.directive";
import { OpentimerDialogComponent } from "./components/opentimer-dialog/opentimer-dialog.component";
import { OpentimerSplashComponent } from "./components/opentimer-splash/opentimer-splash.component";
import {DialogModule} from 'primeng/dialog';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {CardModule} from 'primeng/card';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ListboxModule} from 'primeng/listbox';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {TableModule} from 'primeng/table';
import { NgxDropzoneModule } from "ngx-dropzone";
import { CustomPDropdownModule } from "./modules/custom-p-dropdown/custom-p-dropdown.module";
import {MultiSelectModule} from 'primeng/multiselect';
import {ChipsModule} from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {PaginatorModule} from 'primeng/paginator';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TabMenuModule} from 'primeng/tabmenu';
import {InputNumberModule} from 'primeng/inputnumber';
import {TooltipModule} from 'primeng/tooltip';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ConfirmationService} from 'primeng/api';
import { OpentimePanelComponent } from "./components/opentime-panel/opentime-panel.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { LabelledInputComponent } from "./components/labelled-input/labelled-input.component";
import { CastPipe } from "./pipes/cast-pipe";
import { LocationFormComponent } from "./components/location-form/location-form.component";
import { CountryDropdownComponent } from "./components/dropdown/country-dropdown/country-dropdown.component";
import { AvatarUriTransformPipe } from "./pipes/avatar-uri-transform.pipe";
import { TranslateBackendPipe } from "./pipes/translate-backend.pipe";
import { PhoneNumberListComponent } from "./components/phone-number-list/phone-number-list.component";
import { EmailListComponent } from "./components/email-list/email-list.component";
import { MessageService } from "primeng/api";
import { GenderDropdownComponent } from "./components/dropdown/gender-dropdown/gender-dropdown.component";
import { AgenciesDropdownComponent } from "./components/dropdown/agencies-dropdown/agencies-dropdown.component";
import { IndexOfPipe } from "./pipes/index-of";
import { RecuitersDropdownComponent } from "./components/dropdown/recuiters-dropdown/recuiters-dropdown.component";
import { AccountImageUploadComponent } from "./components/account-image-upload/account-image-upload.component";
import { CurrencySymbolPipe } from "./pipes/currency.pipe";
import { SearchBoxComponent } from "./components/search-box/search-box.component";
import { AdvanceSearchPanelComponent } from "./components/advance-search-panel/advance-search-panel.component";
import { AccountTypeMapPipe } from "./pipes/account-type.pipe";
import { EllipsisPipe } from "./pipes/ellipsis.pipe";
import { RegistrationStatusFlagPipe } from "./pipes/account-status.pipe";
import { SegmentComponent } from "./components/segment/segment.component";
import { ServiceCategoryDropdownComponent } from "./components/dropdown/service-category-dropdown/service-category-dropdown.component";
import { RegStatusTextColorDirective } from "./directives/reg-status-text-color.directive";
import { AccountStatusFlagPipe } from "./pipes/account-status-flag.pipe";
import { ApproveAccountButtonsComponent } from "./components/approve-account-buttons/approve-account-buttons.component";
import { ApproveCreditButtonsComponent } from "./components/approve-credit-buttons/approve-credit-buttons.component";
import { CreditStatusMapPipe } from "./pipes/credit-status.pipe";
import { InfoPanelComponent } from "./components/info-panel/info-panel.component";
import { LanguagePipe } from "./pipes/language.pipe";
import { EditDialogComponent } from "./components/edit-dialog/edit-dialog.component";
import { EditUserContactsInformationComponent } from "./components/modal/edit-user-contacts-infomation/edit-user-contacts-information.component";
import { EditUserInfomationComponent } from "./components/modal/edit-user-infomation/edit-user-infomation.component";
import { EditUserLocationInfomationComponent } from "./components/modal/edit-user-location-infomation/edit-user-location-infomation.component";
import { ProfileAvatarComponent } from "./components/profile-avatar/profile-avatar.component";
import { EditOpentimerLanguagesComponent } from "./components/modal/edit-opentimer-languages/edit-opentimer-languages.component";
import { UpsertCityDialogComponent } from "./components/modal/upsert-city-dialog/upsert-city-dialog.component";
import { UpsertServiceCategoryComponent } from "./components/modal/upsert-service-category/upsert-service-category.component";
import { UpsertServiceSubCategoryComponent } from "./components/modal/upsert-service-sub-category/upsert-service-sub-category.component";
import { AccountEnablementButtonComponent } from "./components/account-enablement-button/account-enablement-button.component";
import { EditAgencyInformationComponent } from "./components/modal/edit-agency-information/edit-agency-information.component";
import { EditJobskillComponent } from "./components/modal/edit-jobskill/edit-jobskill.component";
import { AccountStatusTextColorDirective } from "./directives/account-status-text-color.directive";
import { ServiceSubcategoryDropdownComponent } from "./components/dropdown/service-subcategory-dropdown/service-subcategory-dropdown.component";
import { SelectButtonModule } from "primeng/selectbutton";
import { EmploymentTypePipe } from "./pipes/employment-type.pipe";
import { DaysChipComponent } from "./components/days-chip/days-chip.component";
import { DaysBGColorDirective } from "./directives/days-bg-color-directive";
import { GetContactByAccountIdComponent } from "./components/get-contact-by-account-id/get-contact-by-account-id.component";
import { CustomerItemComponent } from "./components/customer-item/customer-item.component";
import {StepsModule} from 'primeng/steps';
import {InputSwitchModule} from 'primeng/inputswitch';
import { JobLocationItemComponent } from "./components/job-location-item/job-location-item.component";
import { ServiceCategoryItemComponent } from "./components/service-category-item/service-category-item.component";
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { JobskillExperienceItemComponent } from "./components/jobskill-experience-item/jobskill-experience-item.component";
import { BookingShiftItemComponent } from "./components/booking-shift-item/booking-shift-item.component";
import { OpentimerSearchResultItemComponent } from "./components/opentimer-search-result-item/opentimer-search-result-item.component";
import {ProgressBarModule} from 'primeng/progressbar';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PersonalInfoFullNamePipe } from "./pipes/opentimer-full-name-pipe";
import { SubstractDatePipe } from "./pipes/substract-date-pipe";
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import { ShiftItemDetailComponent } from "./components/shift-item-detail/shift-item-detail.component";
import { TimeHhMmPipe } from "./time/time-hh-mm.pipe";
import { OpentimerHourlyRateComponent } from "./components/opentimer-hourly-rate/opentimer-hourly-rate.component";
import { OpentimerJobSkillsTableComponent } from "./components/opentimer-job-skills-table/opentimer-job-skills-table.component";
import { OpentimerTravelRestrictionsComponent } from "./components/opentimer-travel-restrictions/opentimer-travel-restrictions.component";
import { OpentimerMyAvailabilityComponent } from "./components/opentimer-my-availability/opentimer-my-availability.component";
import { OpentimerBankAccountComponent } from "./components/opentimer-bank-account/opentimer-bank-account.component";
import { OpentimerCoverLetterComponent } from "./components/opentimer-cover-letter/opentimer-cover-letter.component";
import { OpentimerEducationsComponent } from "./components/opentimer-educations/opentimer-educations.component";
import { OpentimerWorkExperienceComponent } from "./components/opentimer-work-experience/opentimer-work-experience.component";
import { OpentimerLicenseCertificatesComponent } from "./components/opentimer-license-certificates/opentimer-license-certificates.component";
import { OpentimerLocationComponent } from "./components/opentimer-location/opentimer-location.component";
import { OpentimerLanguageProficienciesComponent } from "./components/opentimer-language-proficiencies/opentimer-language-proficiencies.component";
import { JobProfileDetailItemComponent } from "./components/job-profile-detail-item/job-profile-detail-item.component";
import { OpentimerCalendarComponent } from "./components/opentimer-calendar/opentimer-calendar.component";
import { OpentimerServiceLengthComponent } from "./components/opentimer-service-length/opentimer-service-length.component";
import { OnlyIntegerDirective } from "./directives/only-integer.directive";
import { AccountActivateDeactivateButtonComponent } from "./components/account-activate-deactivate-button/account-activate-deactivate-button.component";
import { SkillsDropdownComponent } from "./components/dropdown/skills-dropdown/skills-dropdown.component";
import { ExperienceDropdownComponent } from "./components/dropdown/experience-dropdown/experience-dropdown.component";
import {InputTextareaModule} from 'primeng/inputtextarea';
import { DividerComponent } from "./components/divider/divider.component";
import { OpentimersDropdownComponent } from "./components/dropdown/opentimers-dropdown/opentimers-dropdown.component";
import { UpsertServiceFeeComponent } from "./components/modal/upsert-service-fee/upsert-service-fee.component";
import { UpsertTaxRateComponent } from "./components/modal/upsert-tax-rate/upsert-tax-rate.component";
import {RadioButtonModule} from 'primeng/radiobutton';
import { UpsertAgencyCommissionPercentageComponent } from "./components/modal/upsert-agency-commission-percentage/upsert-agency-commission-percentage.component";
import { UpsertHolidayDialogComponent } from "./components/modal/upsert-holiday-dialog/upsert-holiday-dialog.component";
import { StartEndDateFilterComponent } from "./components/start-end-date-filter/start-end-date-filter.component";
import { CustomerRatingsComponent } from "./components/customer-ratings/customer-ratings.component";
import { StarRatingModule } from "angular-star-rating";
import { IdVerificationComponent } from "./components/id-verification/id-verification.component";
import {ImageModule} from 'primeng/image';
import {SliderModule} from 'primeng/slider';
import { IdVerificationTypeTitlePipe } from "./pipes/id-verification-type-title.pipe";
import { IdVerificationItemTypeTitlePipe } from "./pipes/id-verification-item-type-title.pipe";
import { RecommendationLevelTitlePipePipe } from "./pipes/recommendation-level-title.pipe";
import { CustomerFeedBackCategoryTitlePipe } from "./pipes/customer-feedback-category-title.pipe";
import { ContactTerminationReasonTitlePipe } from "./pipes/contact-termination-title.pipe";
import { JobProfileActivateDeactivateComponent } from "./components/job-profile-activate-deactivate/job-profile-activate-deactivate.component";
import { FedbackCategoryTitlePipe } from "./pipes/feedback-category-title-map";
import { DateDurationDirective } from "./directives/date-duration-directive";
import { TimeDurationPipe } from "./time/time-duration.pipe";
import { WorkingTimeReasonPipe } from "./pipes/working-time-reason.pipe";
import { WorkingTimeStatusPipe } from "./pipes/working-time-status.pipe";
import { RemoveDecimalPipe } from "./pipes/remove-decimal.pipe";
import { ReportedProblemStatusTextColorDirective } from "./directives/reported-problem-status-text-color.directive";
import { BookingCancellationRequestStatusPipe } from "./pipes/booking-cancellation-request-status.pipe";
import { BookingCancellationRequestStatusDirective } from "./directives/booking-cancellation-status.directive";
import { BookingCancellationRequestReasonPipe } from "./pipes/booking-cancellation-reason.pipe";
import { ScrollToInvalidDirective } from "./directives/scroll-to-invalid.directive";
import { HasRoleDirective } from "./directives/has-role-directive";
import { EditAdminRolesComponent } from "./components/modal/edit-admin-roles/edit-admin-roles.component";
import { HasPermissionDirective } from "./directives/has-permission-directive";
import {CountryFlagPipe} from "./pipes/country-flag.pipe";
import localeJa from '@angular/common/locales/ja';
import {EditCustomerDetailsComponent} from "./components/modal/edit-customer-details/edit-customer-details.component";
import {EditProfileSkillsComponent} from "./components/modal/edit-profile-skills/edit-profile-skills.component";
import {EditHourlyRateComponent} from "./components/modal/edit-hourly-rate/edit-hourly-rate.component";
import {EditProfileLevelComponent} from "./components/modal/edit-profile-level/edit-profile-level.component";
import {
  EditProfileCoverLetterComponent
} from "./components/modal/edit-profile-cover-letter/edit-profile-cover-letter.component";
import {
  EditProfileEducationComponent
} from "./components/modal/edit-profile-education/edit-profile-education.component";
import {
  EditProfileExperienceComponent
} from "./components/modal/edit-profile-experience/edit-profile-experience.component";
import {
  EditProfileCertificationComponent
} from "./components/modal/edit-profile-certificate/edit-profile-certification.component";
import {EditProfileLocationComponent} from "./components/modal/edit-profile-location/edit-profile-location.component";
import {EditListDialogComponent} from "./components/edit-list-dialog/edit-list-dialog.component";
import {
  EditTravelRestrictionComponent
} from "./components/modal/edit-travel-restriction/edit-travel-restriction.component";
import {
  EditProfileAvailabilityComponent
} from "./components/modal/edit-profile-availability/edit-profile-availability.component";
import {EditMainPhoneComponent} from "./components/modal/edit-opentimer-phone/edit-main-phone.component";

registerLocaleData(localeJa, 'ja-JP')
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CheckboxModule,
        InputTextModule,
        ButtonModule,
        PanelMenuModule,
        DialogModule,
        BreadcrumbModule,
        CardModule,
        CalendarModule,
        DropdownModule,
        AutoCompleteModule,
        ListboxModule,
        ToastModule,
        ConfirmDialogModule,
        CustomPDropdownModule,
        NgxDropzoneModule,
        TableModule,
        MultiSelectModule,
        ChipsModule,
        ChipModule,
        VirtualScrollerModule,
        PaginatorModule,
        ToggleButtonModule,
        TabMenuModule,
        InputNumberModule,
        TooltipModule,
        SplitButtonModule,
        SelectButtonModule,
        TagModule,
        StepsModule,
        InputSwitchModule,
        OverlayPanelModule,
        ProgressBarModule,
        InfiniteScrollModule,
        DynamicDialogModule,
        InputTextareaModule,
        RadioButtonModule,
        StarRatingModule.forRoot(),
        ImageModule,
        SliderModule
    ],
    declarations: [
        ControlErrorsDirective,
        FormSubmitDirective,
        ControlErrorContainerDirective,
        RegStatusTextColorDirective,
        AccountStatusTextColorDirective,
        DaysBGColorDirective,
        OnlyIntegerDirective,
        DateDurationDirective,
        ReportedProblemStatusTextColorDirective,
        BookingCancellationRequestStatusDirective,
        ScrollToInvalidDirective,
        HasRoleDirective,
        HasPermissionDirective,
        CastPipe,
        IndexOfPipe,
        AvatarUriTransformPipe,
        CountryFlagPipe,
        TranslateBackendPipe,
        CurrencySymbolPipe,
        AccountTypeMapPipe,
        EllipsisPipe,
        RegistrationStatusFlagPipe,
        CreditStatusMapPipe,
        LanguagePipe,
        AccountStatusFlagPipe,
        EmploymentTypePipe,
        PersonalInfoFullNamePipe,
        SubstractDatePipe,
        TimeHhMmPipe,
        IdVerificationTypeTitlePipe,
        IdVerificationItemTypeTitlePipe,
        RecommendationLevelTitlePipePipe,
        CustomerFeedBackCategoryTitlePipe,
        ContactTerminationReasonTitlePipe,
        FedbackCategoryTitlePipe,
        TimeDurationPipe,
        WorkingTimeReasonPipe,
        WorkingTimeStatusPipe,
        RemoveDecimalPipe,
        BookingCancellationRequestStatusPipe,
        BookingCancellationRequestReasonPipe,
        ControlErrorComponent,
        OpentimerDialogComponent,
        OpentimerSplashComponent,
        OpentimePanelComponent,
        NotFoundComponent,
        LabelledInputComponent,
        LocationFormComponent,
        CountryDropdownComponent,
        PhoneNumberListComponent,
        EmailListComponent,
        GenderDropdownComponent,
        AgenciesDropdownComponent,
        RecuitersDropdownComponent,
        AccountImageUploadComponent,
        SearchBoxComponent,
        AdvanceSearchPanelComponent,
        SegmentComponent,
        ServiceCategoryDropdownComponent,
        ApproveAccountButtonsComponent,
        ApproveCreditButtonsComponent,
        InfoPanelComponent,
        EditDialogComponent,
        EditListDialogComponent,
        EditUserContactsInformationComponent,
        EditUserInfomationComponent,
        EditAdminRolesComponent,
        EditUserLocationInfomationComponent,
        EditMainPhoneComponent,
        ProfileAvatarComponent,
        EditOpentimerLanguagesComponent,
        UpsertCityDialogComponent,
        UpsertServiceCategoryComponent,
        UpsertServiceSubCategoryComponent,
        AccountEnablementButtonComponent,
        EditAgencyInformationComponent,
        EditCustomerDetailsComponent,
        EditProfileSkillsComponent,
        EditJobskillComponent,
        EditHourlyRateComponent,
        EditProfileLevelComponent,
        EditProfileCoverLetterComponent,
        EditProfileEducationComponent,
        EditProfileExperienceComponent,
        EditProfileCertificationComponent,
        EditProfileLocationComponent,
        EditTravelRestrictionComponent,
        EditProfileAvailabilityComponent,
        ServiceSubcategoryDropdownComponent,
        DaysChipComponent,
        GetContactByAccountIdComponent,
        CustomerItemComponent,
        JobLocationItemComponent,
        ServiceCategoryItemComponent,
        JobskillExperienceItemComponent,
        BookingShiftItemComponent,
        OpentimerSearchResultItemComponent,
        ShiftItemDetailComponent,
        OpentimerHourlyRateComponent,
        OpentimerJobSkillsTableComponent,
        OpentimerTravelRestrictionsComponent,
        OpentimerMyAvailabilityComponent,
        OpentimerBankAccountComponent,
        OpentimerCoverLetterComponent,
        OpentimerEducationsComponent,
        OpentimerWorkExperienceComponent,
        OpentimerLicenseCertificatesComponent,
        OpentimerLocationComponent,
        OpentimerLanguageProficienciesComponent,
        JobProfileDetailItemComponent,
        OpentimerCalendarComponent,
        OpentimerServiceLengthComponent,
        AccountActivateDeactivateButtonComponent,
        SkillsDropdownComponent,
        ExperienceDropdownComponent,
        DividerComponent,
        OpentimersDropdownComponent,
        UpsertServiceFeeComponent,
        UpsertTaxRateComponent,
        UpsertAgencyCommissionPercentageComponent,
        UpsertHolidayDialogComponent,
        StartEndDateFilterComponent,
        CustomerRatingsComponent,
        IdVerificationComponent,
        JobProfileActivateDeactivateComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        CheckboxModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        PanelMenuModule,
        DialogModule,
        BreadcrumbModule,
        CardModule,
        CalendarModule,
        DropdownModule,
        ListboxModule,
        AutoCompleteModule,
        ToastModule,
        ConfirmDialogModule,
        CustomPDropdownModule,
        ControlErrorComponent,
        NgxDropzoneModule,
        TableModule,
        MultiSelectModule,
        ChipsModule,
        ChipModule,
        VirtualScrollerModule,
        PaginatorModule,
        ToggleButtonModule,
        TabMenuModule,
        InputNumberModule,
        TooltipModule,
        SplitButtonModule,
        SelectButtonModule,
        TagModule,
        StepsModule,
        InputSwitchModule,
        OverlayPanelModule,
        ProgressBarModule,
        InfiniteScrollModule,
        DynamicDialogModule,
        InputTextareaModule,
        RadioButtonModule,
        StarRatingModule,
        ImageModule,
        SliderModule,

    ControlErrorContainerDirective,
    ControlErrorsDirective,
    FormSubmitDirective,
    RegStatusTextColorDirective,
    AccountStatusTextColorDirective,
    OnlyIntegerDirective,
    DaysBGColorDirective,
    DateDurationDirective,
    ReportedProblemStatusTextColorDirective,
    BookingCancellationRequestStatusDirective,
    ScrollToInvalidDirective,
    HasRoleDirective,
    HasPermissionDirective,
    CastPipe,
    IndexOfPipe,
    AvatarUriTransformPipe,
    CountryFlagPipe,
    TranslateBackendPipe,
    CurrencySymbolPipe,
    AccountTypeMapPipe,
    EllipsisPipe,
    RegistrationStatusFlagPipe,
    AccountStatusFlagPipe,
    CreditStatusMapPipe,
    LanguagePipe,
    EmploymentTypePipe,
    PersonalInfoFullNamePipe,
    SubstractDatePipe,
    TimeHhMmPipe,
    IdVerificationTypeTitlePipe,
    IdVerificationItemTypeTitlePipe,
    RecommendationLevelTitlePipePipe,
    CustomerFeedBackCategoryTitlePipe,
    ContactTerminationReasonTitlePipe,
    FedbackCategoryTitlePipe,
    TimeDurationPipe,
    WorkingTimeReasonPipe,
    WorkingTimeStatusPipe,
    RemoveDecimalPipe,
    BookingCancellationRequestStatusPipe,
    BookingCancellationRequestReasonPipe,

    OpentimerDialogComponent,
    OpentimerSplashComponent,
    OpentimePanelComponent,
    NotFoundComponent,
    LabelledInputComponent,
    LocationFormComponent,
    CountryDropdownComponent,
    PhoneNumberListComponent,
    EmailListComponent,
    GenderDropdownComponent,
    AgenciesDropdownComponent,
    RecuitersDropdownComponent,
    AccountImageUploadComponent,
    SearchBoxComponent,
    AdvanceSearchPanelComponent,
    SegmentComponent,
    ServiceCategoryDropdownComponent,
    ApproveAccountButtonsComponent,
    ApproveCreditButtonsComponent,
    InfoPanelComponent,
    EditDialogComponent,
    EditListDialogComponent,
    EditUserContactsInformationComponent,
    EditUserInfomationComponent,
    EditAdminRolesComponent,
    EditUserLocationInfomationComponent,
    ProfileAvatarComponent,
    EditOpentimerLanguagesComponent,
    UpsertCityDialogComponent,
    UpsertServiceCategoryComponent,
    UpsertServiceSubCategoryComponent,
    AccountEnablementButtonComponent,
    EditAgencyInformationComponent,
    EditCustomerDetailsComponent,
    EditProfileSkillsComponent,
    EditJobskillComponent,
    EditHourlyRateComponent,
    EditProfileLevelComponent,
    EditProfileCoverLetterComponent,
    EditProfileEducationComponent,
    EditProfileExperienceComponent,
    EditProfileCertificationComponent,
    EditProfileLocationComponent,
    EditTravelRestrictionComponent,
    EditProfileAvailabilityComponent,
    EditMainPhoneComponent,
    ServiceSubcategoryDropdownComponent,
    DaysChipComponent,
    GetContactByAccountIdComponent,
    CustomerItemComponent,
    JobLocationItemComponent,
    ServiceCategoryItemComponent,
    JobskillExperienceItemComponent,
    BookingShiftItemComponent,
    OpentimerSearchResultItemComponent,
    ShiftItemDetailComponent,
    OpentimerHourlyRateComponent,
    OpentimerJobSkillsTableComponent,
    OpentimerTravelRestrictionsComponent,
    OpentimerMyAvailabilityComponent,
    OpentimerBankAccountComponent,
    OpentimerCoverLetterComponent,
    OpentimerEducationsComponent,
    OpentimerWorkExperienceComponent,
    OpentimerLicenseCertificatesComponent,
    OpentimerLocationComponent,
    OpentimerLanguageProficienciesComponent,
    JobProfileDetailItemComponent,
    OpentimerCalendarComponent,
    OpentimerServiceLengthComponent,
    AccountActivateDeactivateButtonComponent,
    SkillsDropdownComponent,
    ExperienceDropdownComponent,
    DividerComponent,
    OpentimersDropdownComponent,
    UpsertServiceFeeComponent,
    UpsertTaxRateComponent,
    UpsertAgencyCommissionPercentageComponent,
    UpsertHolidayDialogComponent,
    StartEndDateFilterComponent,
    CustomerRatingsComponent,
    IdVerificationComponent,
    JobProfileActivateDeactivateComponent
  ],
    providers: [
        MessageService,
        ConfirmationService,
        DialogService
    ]
})
export class SharedModule { }
