import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {
  @Input() title: string;
  @Input() showEditButton: boolean = true;
  @Input() icon: string = 'pi-pencil';
  @Input() toolTip: string = 'Edit information'
  @Input() disableEditButton: boolean = false;

  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.icon = `pi ${this.icon}`;
  }

}
