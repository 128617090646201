import { Pipe, PipeTransform } from "@angular/core";
import { ReadonlyMapBuilder } from "../readyonly-map-builder";

@Pipe({
    name: 'accountStatusFlag'
})
export class AccountStatusFlagPipe implements PipeTransform {
  
    private readonly accountTypeMap = ReadonlyMapBuilder.create<boolean, string>()
    .set(true,'Active')
    .set(false,'Inactive')
    .build();

    transform(value: boolean): string {
      return this.accountTypeMap.get(value);
    }
  
} 