<app-edit-dialog [visible]="showHolidayDialog" [title]="'Add/Edit Holiday'" (onSubmit)="onSubmitHoliday()"
                 (onHide)="onHide.emit()" [disabled]="!holidayForm.valid" [dialogHeight]="'38'">

                 <ng-container [formGroup]="holidayForm">
                    <div  class="mt-3 grid">
                        <div class="col-12">
                            <app-labelled-input [ngControl]="holidayForm" [displayBlock]="true"
                                [placeholder]="'Holiday Name'" [label]="'Holiday Name'" [name]="'holidayName'">
                            </app-labelled-input>
                        </div>

                        <div class="col-12 lg:col-6">
                            <div class="grid grid-nogutter">
                                <label for="dateOfBirth" class="col-12 pr-2 text-rb" >Holiday Surcharge (%)</label>
                                <div class="col-12 mt-1">
                                    <input  id="phone" type="tel" formControlName="holidaySurcharge"
                                         appOnlyInteger
                                         pInputText  placeholder="Number" style="width: 100%"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6">
                            <div class="grid grid-nogutter">
                                <label for="dateOfBirth" class="col-12 pr-2 text-rb" >Holiday Repeat Mode</label>
                                <div class="col-12 mt-1">
                                    <p-dropdown
                                        [options]="holidayRepeatModeItems"
                                        formControlName="holidayRepeatMode"
                                        placeholder="Please Select"
                                        appendTo="body"
                                        [(ngModel)]="holidayForm.value.holidayRepeatMode"
                                        value="currency" [style]="{'width':'100%'}">
                                        <ng-template pTemplate="selectedItem" let-item  >
                                            <div class="country-item country-item-value" >
                                               <p class="text-ssb">{{item.name}}</p>
                                            </div>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div class="country-item">
                                                <p class="text-ssb">{{item.name}}</p>
                                            </div>
                                        </ng-template>
                                    </p-dropdown >
                                </div>
                            </div>
                        </div>

                        <div class="col-12 lg:col-6">
                            <div class="grid grid-nogutter block">
                                <label class="col-12 pr-2 text-rb">Start Date</label>
                                <div class="col-12 mt-1">
                                    <p-calendar formControlName="startDate"
                                                class="full-width-calendar-input"
                                                appendTo="body"
                                                [minDate]="minDate"
                                                [showButtonBar]="true"
                                                [dateFormat]="CONST.DATE_FORMAT"
                                                [showIcon] = "true"
                                                placeholder="Select Date">
                                    </p-calendar>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 lg:col-6">
                            <div class="grid grid-nogutter block">
                                <label class="col-12 pr-2 text-rb">End Date</label>
                                <div class="col-12 mt-1">
                                    <p-calendar formControlName="endDate"
                                                class="full-width-calendar-input"
                                                [minDate]="minDate"
                                                appendTo="body"
                                                [showButtonBar]="true"
                                                [dateFormat]="CONST.DATE_FORMAT"
                                                [showIcon] = "true"
                                                placeholder="Select Date">
                                    </p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6">
                            <p class="text-rm">Countries</p>
                            <p-multiSelect  [options]="countries"
                                            appendTo="body"
                                            placeholder="Please Select"
                                            formControlName="countries" display="chip"
                                            [disabled]="holidayForm.value.allCountries"
                                [style]="{'width':'100%'}" [filter]="false" placeholder="Select">
                                <ng-template let-value pTemplate="selectedItems">
                                    <div class="multiselct-item-selected flex">
                                        <p-chip  *ngFor="let option of holidayForm.value.countries"
                                                [label]="option | translateBackend"
                                                [removable]="false">
                                        </p-chip>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="country-item">
                                        <div><p>{{item | translateBackend}}</p></div>
                                    </div>
                                </ng-template>
                            </p-multiSelect >
                        </div>

                        <div class="col-12 lg:col-6">
                            <p class="text-rm">Holiday for All Countries</p>
                            <div class="grid grid-nogutter w-100 flex justify-content-start align-items-center p-1">
                                <p-checkbox  class="pr-2" formControlName="allCountries"
                                            [binary]="true">
                                </p-checkbox>
                                <p class="p-0 text-rb">Holiday is for all Countries</p>
                            </div>
                        </div>


                    </div>

                 </ng-container>

</app-edit-dialog>
