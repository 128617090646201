<app-edit-dialog [visible]="showServiceSubCategoryDialog" [title]="'Add/Edit Service Sub Category'"
                 (onSubmit)="onSubmitServiceSubCategory()"
                 [dialogHeight]="'32'"
                 (onHide)="onHide.emit()" >
                 <div  class="mt-3 grid">
                    <ng-container [formGroup]="serviceSubCategoryForm">
                        <div class="col-12">
                            <div class="grid grid-nogutter w-100 block">
                                <label class="col-3 pr-2 text-right">Service Category</label>
                                <div class="col-9" >
                                    <app-service-category-dropdown [ngControl]="serviceSubCategoryForm"
                                                        [name]="'serviceCategoryId'"
                                                        [readonly]="true"
                                                        [serviceCategory]="serviceSubCategory.serviceCategory">
                                    </app-service-category-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <app-labelled-input [ngControl]="serviceSubCategoryForm"
                                 [displayBlock] = "true"
                                 [placeholder]="'Service Sub Category Name'" [label]="'Service Sub Category Name'"
                                 [name]="'subCategoryName'">
                            </app-labelled-input>
                         </div>

                         <div class="col-12">
                            <app-labelled-input [ngControl]="serviceSubCategoryForm"
                                 [displayBlock] = "true"
                                 [placeholder]="'Service Sub Category Name (Native)'" [label]="'Service Sub Category Name (Native)'"
                                 [name]="'subCategoryNativeName'">
                            </app-labelled-input>
                         </div>
                         <div class="col-9">
                            <app-labelled-input [ngControl]="serviceSubCategoryForm"
                                 [displayBlock] = "true"
                                 [placeholder]="'CODE'" [label]="'CODE'"
                                 [name]="'code'">
                            </app-labelled-input>
                         </div>
                         <div class="col-3 p-0">
                           <div class="grid grid-nogutter">
                                <label class="col-12 text-rb">Status</label>
                                <div class="col-12">
                                    <p-toggleButton onLabel='Active' offLabel="Inactive"
                                    offIcon="pi pi-times" onIcon='pi pi-check' formControlName="active"></p-toggleButton>
                                </div>
                            </div>

                         </div>
                    </ng-container>
                </div>
</app-edit-dialog>
