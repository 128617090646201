<app-edit-dialog [visible]="showProfileLevelDialog" [title]="'Edit Profile level'"
                 (onSubmit)="onUpdateLevel()"
                 [dialogHeight]="'5'"
                 (onHide)="onHide.emit()" [disabled]="!experienceLevelForm.valid">
  <div class="grid grid-nogutter mt-5 justify-content-center">
    <ng-container [formGroup]="experienceLevelForm">
        <div class="grid grid-nogutter col-12">
          <div class="col-12 lg:col-3 flex lg:block">
            <p class="text-l-gray text-ssb">Job Category</p>
            <p class="text-rb">{{ serviceSubCategory.serviceCategory | translateBackend }}</p>
          </div>
          <div class="col-12 lg:col-3 flex lg:block">
            <p class="text-l-gray text-ssb">Sub Category</p>
            <p class="text-rb">{{ serviceSubCategory | translateBackend }}</p>
          </div>

          <div class="col-12 lg:col-6 flex lg:block">
            <p class="text-l-gray text-ssb">Level of Experience</p>
            <div class="grid grid-nogutter p-2 mt-2 block lg:flex pr-5">
              <div class="col-12 lg:col-10 mt-1 lg:mt-0">
                <p-dropdown
                  [options]="experienceLevels"
                  formControlName="experienceLevel"
                  placeholder="Please Select"
                  appendTo="body"
                  optionValue="experienceLevel" [style]="{'width':'100%'}">
                  <ng-template pTemplate="selectedItem" let-selectedItem>
                    <div class="country-item country-item-value">
                      <p-chip class="mb-1" [label]="selectedItem.title"
                              [class]="chipColor.get(selectedItem.experienceLevel)">
                      </p-chip>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="experience-item">
                      <p-chip class="mb-1" [label]="item.title" [class]="chipColor.get(item.experienceLevel)">
                      </p-chip>
                      <p>{{ item.description }}</p>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
    </ng-container>
  </div>
</app-edit-dialog>
