<app-edit-list-dialog [visible]="showEditDialog" [title]="'Edit Skills'"
                 (onClose)="onClose.emit()"
                 [dialogHeight]="'40'">
  <div  class="mt-3 grid">
    <ng-container [formGroup]="skillForm">
      <div class="col-12 lg:col-6">
        <div class="grid grid-nogutter w-100 block">
          <div class="col-12" >
            <p-dropdown  [options]="skills" formControlName="skillId" placeholder="Please select a skill"
                         optionValue="id" [style]="{'width':'100%'}" appendTo="body">
              <ng-template pTemplate="selectedItem" let-selectedItem  >
                <div class="country-item country-item-value" >
                  <div><p>{{selectedItem | translateBackend}}</p></div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="country-item">
                  <div><p>{{item | translateBackend}}</p></div>
                </div>
              </ng-template>
            </p-dropdown >
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="grid grid-nogutter w-100 block">
          <div class="col-12" >
              <p-dropdown
                [options]="experienceLevels"
                formControlName="experienceLevel"
                placeholder="Please select proficiency level"
                appendTo="body"
                optionValue="experienceLevel" [style]="{'width':'100%'}">
                <ng-template pTemplate="selectedItem" let-selectedItem  >
                  <div class="country-item country-item-value" >
                    <p-chip class="mb-1" [label]="selectedItem.title"
                            [class]="chipColor.get(selectedItem.experienceLevel)">
                    </p-chip>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="country-item">
                    <p-chip class="mb-1" [label]="item.title"
                            [class]="chipColor.get(item.experienceLevel)">
                    </p-chip>
                    <p>{{item.description}}</p>
                  </div>
                </ng-template>
              </p-dropdown >
          </div>
        </div>
      </div>
      <div class="col-12 p-0 text-right ">
        <button pButton type="button" label="Add Skill" icon="pi pi-plus-circle"
                class="p-button-text p-button-primary p-0 ml-2" iconPos="left"
                [disabled]="!skillForm.valid"
                (click)="addSkillLevel()">
        </button>
      </div>
      <div class="col-12" *ngIf="jobSkills.length > 0">
        <p-table [value]="jobSkills"
                 [paginator]="true"
                 [rows]="10"
                 [showCurrentPageReport]="true"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 editMode="row"
                 [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="header">
            <tr>
              <th>SKILL NAME</th>
              <th>LEVEL</th>
              <th>ACTIONS</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-jobSkill>
            <tr>
              <td>
                <p class="text-ssb pl-0">{{getSkill(jobSkill.skill) | translateBackend}}</p>
              </td>
              <td>
                <p class="text-ssb pl-0">
                  <p-chip class="mb-1" [label]="jobSkill.experienceLevel"
                          [class]="chipColor.get(jobSkill.experienceLevel)">
                  </p-chip>
                </p>
              </td>
              <td>
                <button pButton pRipple type="button" icon="pi pi-times-circle" (click)="removeSkillLevel(jobSkill.id)"
                        class="p-button-rounded p-button-danger p-button-outlined button-border-none">
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </div>
</app-edit-list-dialog>
