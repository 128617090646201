import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {HourlyRateDto, HourlyRateDtoSchema} from "../../../models/dto/opentimer-job-profile.dto";
import {BookingType} from "../../../models/dto/booking.dto";
import {CurrencyEnum} from "../../../models/dto/currency.dto";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-edit-hourly-rate',
  templateUrl: './edit-hourly-rate.component.html',
  styleUrls: ['./edit-hourly-rate.component.scss']
})
export class EditHourlyRateComponent implements OnInit {
  @Input() hourlyRates: HourlyRateDtoSchema;
  @Input() showHourlyDialog: boolean;
  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  hourlyRatesForm: FormArray;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.hourlyRatesForm = this.formBuilder.array([], Validators.required)
    this.setHourlyRateForm();
    if(this.hourlyRates) {
      for(const hourlyRate of this.hourlyRates.hourlyRates) {
        const findIndex = this.hourlyRatesForm.value.findIndex((v)=>{
          return v.employmentType === hourlyRate.employmentType;
        });

        this.hourlyRatesForm.at(findIndex).patchValue({
          id: hourlyRate.id,
          hourlyRate: hourlyRate.hourlyRate,
          employmentType: hourlyRate.employmentType,
          internationalHourlyRate: hourlyRate.internationalHourlyRate
        })
      }
    }
  }


  setHourlyRateForm() {
    const fullTimeControl = this.formBuilder.group({
      id: this.formBuilder.control(null),
      hourlyRate: this.formBuilder.control('', [Validators.required]),
      internationalHourlyRate: this.formBuilder.control(''),
      employmentType: this.formBuilder.control(BookingType.FULL_TIME, Validators.required)
    })
    this.hourlyRatesForm.push(fullTimeControl);

    const partTimeControl = this.formBuilder.group({
      id: this.formBuilder.control(null),
      hourlyRate: this.formBuilder.control('', [Validators.required]),
      internationalHourlyRate: this.formBuilder.control(''),
      employmentType: this.formBuilder.control(BookingType.PART_TIME, Validators.required)
    })
    this.hourlyRatesForm.push(partTimeControl);

    const oneTimeControl = this.formBuilder.group({
      id: this.formBuilder.control(null),
      hourlyRate: this.formBuilder.control('', [Validators.required]),
      internationalHourlyRate: this.formBuilder.control(''),
      employmentType: this.formBuilder.control(BookingType.ONE_TIME, Validators.required)
    })
    this.hourlyRatesForm.push(oneTimeControl);
  }

  onSaveHourlyRate() {
    const hourlyRates: Array<HourlyRateDto> = [];

    this.hourlyRatesForm.value.forEach((v: any)=>{
      hourlyRates.push({
        id: v.id,
        currency: CurrencyEnum.JPY,
        employmentType: v.employmentType,
        hourlyRate: v.hourlyRate,
        internationalCurrency: CurrencyEnum.USD,
        internationalHourlyRate: v.internationalHourlyRate
      })
    });

    const hourlyRateSchema: HourlyRateDtoSchema = {
      hourlyRates: hourlyRates
    }
    this.onSubmit.emit(hourlyRateSchema);
    this.showHourlyDialog = false;
  }
}
