<app-edit-dialog [visible]="showEditDialog" [title]="'Edit Commute preferences'"
                 (onSubmit)="saveTravelRestriction()"
                 [dialogHeight]="'30'"
                 (onHide)="onHide.emit()" [disabled]="!travelRestrictionForm.valid">
  <div class="grid grid-nogutter justify-content-center">
    <div class="col-10">
        <ng-container [formGroup]="travelRestrictionForm">
          <div class="grid grid-nogutter">
            <div class="col-12">
              <p class="text-ssb text-primary">Means of Transportation</p>
            </div>
          </div>

          <div class="grid grid-nogutter mt-1">
            <div class="col-12">
              <p class="text-ssb">Select One</p>
            </div>
            <div class="col-12 transport-type-col mt-1">
              <ng-container *ngFor="let transportationTypeItem of transportationTypeItems">
                <div class="flex justify-content-start align-items-center transport-type-item">
                  <p-radioButton name="transportationType" [value]="transportationTypeItem.transportationType"
                                 formControlName="transportationType"
                                 class="pr-2"
                                 [inputId]="transportationTypeItem.inputId">
                  </p-radioButton>
                  <div class="block justify-content-center text-left">
                    <img [src]="transportationTypeItem.iconUrl" alt="{{ transportationTypeItem.name }}"/>
                    <p class="text-ssb">{{ transportationTypeItem.name }}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="grid grid-nogutter mt-2">
            <div class="col-12">
              <p class="text-ssb text-primary">Remote work</p>
              <div class="grid grid-nogutter w-100 flex justify-content-start align-items-center p-1">
                <p-checkbox class="pr-2" formControlName="remoteWork"
                            [binary]="true">
                </p-checkbox>
                <p class="p-0 text-rb">Local companies</p>
              </div>
              <div class="grid grid-nogutter w-100 flex justify-content-start align-items-center p-1">
                <p-checkbox class="pr-2" formControlName="internationalWork"
                            [binary]="true">
                </p-checkbox>
                <p class="p-0 text-rb">International companies</p>
              </div>
            </div>
          </div>

          <div class="grid grid-nogutter mt-2">
            <div class="col-12">
              <p class="text-ssb text-primary">Maximum travel time</p>
            </div>
            <div class="col-6">
              <div class="grid grid-nogutter">
                <div class="col-12">
                  <p class="text-ssb">Travel (for work)</p>
                </div>
                <div class="col-12  p-1">
                  <p-dropdown
                    [options]="minuteSelectionItems"
                    formControlName="travelTime"
                    placeholder="Please Select"
                    appendTo="body"
                    optionValue="min" [style]="{'width':'100%'}">
                    <ng-template pTemplate="selectedItem" let-item>
                      <div class="country-item country-item-value">
                        <p class="text-ssb">{{ item.name }}</p>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="country-item">
                        <p class="text-ssb">{{ item.name }}</p>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="col-6 ">
              <div class="grid grid-nogutter">
                <div class="col-12">
                  <p class="text-ssb">Travel (for interview)</p>
                </div>
                <div class="col-12  p-1">
                  <p-dropdown
                    [options]="minuteSelectionItems"
                    formControlName="travelTimeInterview"
                    placeholder="Please Select"
                    appendTo="body"
                    optionValue="min" [style]="{'width':'100%'}">
                    <ng-template pTemplate="selectedItem" let-item>
                      <div class="country-item country-item-value">
                        <p class="text-ssb">{{ item.name }}</p>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="country-item">
                        <p class="text-ssb">{{ item.name }}</p>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>

          </div>

          <div class="grid grid-nogutter mt-2">
            <div class="col-12">
              <p class="text-ssb text-primary">Relocation</p>
              <div class="grid grid-nogutter w-100 flex justify-content-start align-items-center p-1">
                <p-checkbox class="pr-2" formControlName="relocate" [binary]="true">
                </p-checkbox>
                <p class="p-0 text-rb">I'm willing to relocate</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
  </div>
</app-edit-dialog>
