<ng-container *ngIf="creditStatus === CreditStatus.PENDING; else vettedTemplated">
    <div class="flex justify-content-evenly">
        <button pButton  label="Deny"  class="velvet  mr-3 w-100" pRipple (click)="deny()"> </button>
        <button pButton  label="Approve" class="primary w-100" pRipple (click)="approve()"> </button>
    </div>
</ng-container>

<ng-template #vettedTemplated>
    <button pButton  [label]="creditStatus | creditStatusMap"  
            class="gray  mr-3 w-100" pRipple disabled> </button>
</ng-template>

<p-dialog [(visible)]="display" [style]="{width: '25vw'}">
    <ng-template pTemplate="header">
        Credit Balance
    </ng-template>
    <div class="grid grid-nogutter">
        <div class="col-12">
            <p-inputNumber  [(ngModel)]="creditBalance" class="text-right" value=""
                            [min]="0"
                            [style]="{width: '100%'}" placeholder="Credit Balance">
            </p-inputNumber>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end">
            <button pButton  label="Cancel"  class="velvet  mr-3" pRipple (click)="display = false"> </button>
            <button pButton  label="Approve" class="primary" pRipple (click)="onApproveCredit()"> </button>
        </div>
    </ng-template>
</p-dialog>