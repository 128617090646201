<div class="grid grid-nogutter justify-content-center" >

    <app-info-panel class="col-12 info-panel-shadow p-2"
                    [title]="'REVIEWS'" [showEditButton]="false"
                    [toolTip]="'Reviews'" >
                    <div class="grid grid-nogutter review-container">
                        <div class="col-4 flex justify-content-center align-items-center review-col">
                            <div class="block text-center">
                                <p class="page-title-primary text-primary">{{averageRating | number:'1.2-2'}}</p>
                                <star-rating [starType]="'svg'" [rating]="averageRating"
                                            staticColor="positive" [readOnly]="true" [showHalfStars]="true">
                                </star-rating>
                                <p class="text-rm">{{customerRatings.length}} Reviews</p>
                            </div>
                        </div>
                        <div class="col-4 flex flex-column justify-content-center align-items-center p-2 review-col">
                            <ng-container *ngIf="ratingStarBreakdown">
                                <ng-container *ngFor="let recommendationLevelItem of ratingStarBreakdown.recommendationLevelItemsBreakDown">
                                    <div class="grid grid-nogutter w-100 flex align-items-center">
                                        <div class="col-7">
                                            <p class="text-rm">{{recommendationLevelItem.label}}:</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="text-rb text-primary">{{recommendationLevelItem.count}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-4 flex flex-column justify-content-center align-items-center p-2">
                            <ng-container *ngIf="ratingStarBreakdown">
                                <ng-container *ngFor="let ratingStar of ratingStarBreakdown.ratingStars">
                                    <div class="grid grid-nogutter w-100">
                                        <div class="col-3">
                                            <p class="text-rm">{{ratingStar.label}}</p>
                                        </div>
                                        <div class="col-7">
                                            <p-progressBar [value]="ratingStar.percentage"></p-progressBar>
                                        </div>
                                        <div class="col-2">
                                            <p class="text-rm">{{ratingStar.ratingCount}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
    </app-info-panel>



    <ng-container *ngIf="averageRating > 0">

        <div class="col-12 mt-3">
            <p class="text-rm">Rating: <span class="text-primary">{{averageRating | number:'1.2-2'}}</span></p>
        </div>

        <ng-container *ngFor="let customerRating of customerRatings">
            <div class="col-12 flex justify-content-start align-items-center customer-rating-item">
                <img [src]="customerRating.reviewedBy.contact.avatarUri | avatarUriTransform" class="account-avatar"
                onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>

                <div >
                    <p class="text-rb">{{customerRating.reviewedBy.contact.firstName}} {{customerRating.reviewedBy.contact.lastName}}</p>
                    <p class="text-ssb">{{customerRating.reviewedBy.serviceSubCategory | translateBackend}}</p>
                    <p class="text-rm">{{customerRating.reviewedBy.serviceSubCategory.serviceCategory | translateBackend}}</p>
                </div>
            </div>
            <div class="col-12 flex justify-content-start align-items-center mt-3">
                    <star-rating [starType]="'svg'" [rating]="customerRating.totalRating"
                                staticColor="positive" [readOnly]="true" [showHalfStars]="true">
                    </star-rating>
                    <p class="pl-5 text-rm"><i>"{{customerRating.message}}"</i></p>
            </div>

            <div class="col-12 flex justify-content-start align-items-center mt-2">
                <p class="text-rm">Feedback for Customer</p>
            </div>

            <div class="col-12 block mt-2">
                <ng-container *ngFor="let feedback of customerRating.feedbacks">
                    <div class="flex justify-content-start align-items-center">
                        <star-rating [starType]="'svg'" [rating]="feedback.categoryValue"
                            staticColor="ok" [readOnly]="true" [showHalfStars]="true">
                        </star-rating>
                        <p class="pl-5 text-rm">{{feedback.categoryName | fedbackCategoryTitlePipe}}</p>
                    </div>
                </ng-container>
            </div>

            <div class="col-12 flex justify-content-start align-items-center mt-1">
                <p class="text-rm">Recommendation: </p>
                <p class="pl-1 text-rb">{{customerRating.recommendationLevel | recommendationLevelTitlePipe}}</p>
            </div>

            <div class="col-12 flex justify-content-start align-items-center mt-1">
                <p class="text-rm">Contract Termination Reason: </p>
                <p class="pl-1 text-rb">{{customerRating.contractTerminationReason | contactTerminationReasonTitlePipe}}</p>
            </div>

            <div class="col-12 mt-2"></div>
        </ng-container>
    </ng-container>

</div>
