import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: '[appScrollToInvalid]'
})
export class ScrollToInvalidDirective {

  constructor(private el: ElementRef) { }

  @HostListener('submit', ['$event'])
  onFormSubmit(event: any): void {
    event.preventDefault();
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].scrollIntoView({ behavior: 'smooth' });
        // Adjust for fixed header, you might need to tweak the '100' value
    }
  }
}
