<div class="grid grid-nogutter flex align-items-center justify-content-center" >
    <div class="col-2">
        <button pButton  [label]="activaDeactivateteButtonLabelMap.get(status)"
                (click)="activateDeactivate()"
                class="light w-100 p-button-rounded" pRipple> 
        </button>
    </div>
</div>

<p-dialog [(visible)]="displayDeactivationDialog" [modal]="true" [closable]="true" [dismissableMask]="true"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '35vw'}"
          (onHide)="displayDeactivationDialog=false" class="activate-deactivate-modal">
    <ng-template pTemplate="header">
        <div class="grid grid-nogutter">
            <div class="col-12">
                <p class="text-rb page-title-primary">Deactivate Account</p>
                <p class="text-rm">Are you sure you want to deactivate this account?</p>
            </div>
        </div>
    </ng-template>
    <div class="grid grid-nogutter">
        <!-- <div class="col-12 flex justify-content-start align-items-center">
            <i class="pi pi-times text-velvet"></i>
            <p class="text-rm">Do you really want to deactivate this account?</p>
        </div> -->
        <div class="col-12 flex justify-content-start align-items-center" *ngIf="hasBookings || hasInterviews">
            <i class="pi pi-times text-velvet"></i>
            <p class="text-rm">To deactivate this account, please cancel all bookings associated with the account.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="grid grid-nogutter flex justify-content-center align-items-center">
            <div class="col-6 p-1">
                <button pButton  label="Keep the Account Active"
                        (click)="displayDeactivationDialog=false"
                        class="primary w-100" pRipple> 
                </button>
            </div>
            <div class="col-3 p-1">
                <button pButton  label="Deactivate"
                        (click)="accountDeactivation()"
                        [disabled]="hasBookings || hasInterviews"
                        class="light w-100" pRipple> 
                </button>
            </div>
        </div>
    </ng-template>
</p-dialog>
