import {Pipe, PipeTransform} from '@angular/core';
import { TranslationDto } from '../models/dto/translation.dto';


export interface TranslateObjectCode{
  code?: string;
  translations: Array<TranslationDto>
}

@Pipe({
  name: 'translateBackend',
})
export class TranslateBackendPipe implements PipeTransform {
  constructor() { }

  transform(translateObjectCode: TranslateObjectCode): string {
    const selectedLanguage = 'en';
    if (translateObjectCode) {
      // const selectedLanguage = this.languageService.selectedLanguage;
      if (translateObjectCode.translations && translateObjectCode.translations.length > 0) {
        return this.getLanguage(translateObjectCode.translations, selectedLanguage) ?
          this.getLanguage(translateObjectCode.translations, selectedLanguage).content :
          translateObjectCode.code || '';
      } else {
        return translateObjectCode.code || '';
      }
    }
    return '';
  }

  getLanguage(translateObject: Array<TranslationDto>, language: string): TranslationDto {
    return translateObject.find((obj: TranslationDto) => obj.language == language);
  }
}
