<div  class="grid grid-nogutter w-100 header-content align-items-center">
    <div class="col-1 flex justify-content-end align-items-center">
        <img [src]="opentimerJobProfile.avatarUri | avatarUriTransform" class="account-avatar" 
        onerror="this.src='/assets/media/img/profile-placeholder.png';" alt="Avatar"/>
    </div>
    <div class="col-9 pl-2">
        <p class="text-rb text-white">{{opentimerJobProfile.personalInfo | personalInfoDtoFullName}}</p>
        <p-chip  [label]="opentimerJobProfile.jobSubCategory.serviceSubCategory | translateBackend">
        </p-chip>
        <p class="text-white">{{opentimerJobProfile.jobSubCategory.serviceSubCategory.serviceCategory | translateBackend}}</p>
        <p-chip  [label]="opentimerJobProfile.jobSubCategory.experienceLevel"
            [class]="chipColor.get(opentimerJobProfile.jobSubCategory.experienceLevel)">
        </p-chip>
    </div>
    <div class="col-2 flex justify-content-center align-items-center">
        <p>
            <span class="text-rb text-white">
                {{hourlyRate.currency | currencySymbol}}
                {{hourlyRate.hourlyRate | number}}/ 
            </span>
            <span class="text-rm text-primary">
                hour
            </span>
        </p>
    </div>
</div>