import { AccountStatus } from "./dto/account.dto";

export enum AccountEnablementStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

export interface AccountApproveItem {
    accountId: number;
    status: AccountStatus;
}

export interface AccountEnablementItem {
    accountId: number;
    status: AccountEnablementStatus
}

export interface AccountEnabledStatusItem {
    value: boolean;
    name: string;
}