<div  class="grid grid-nogutter info-panel-shadow job-location-item p-2 align-items-center">
    <div class="col-2 lg:col-2 block">
        <p class="text-l-gray">Job Location Type:</p>
        <p class="text-rb">{{jobLocation.jobLocationType}}</p>
    </div>
    <div class="col-2  block">
        <p class="text-l-gray">Allow Remote Work:</p>
        <p class="text-rb">
            <ng-container *ngIf="jobLocation.communicationAvailability && 
                                 jobLocation.communicationAvailability.allowDifferentTimeZone; 
                                 else notAllowRemote">
                Yes
            </ng-container>
            <ng-template #notAllowRemote>
                No
            </ng-template>
        </p>
    </div>
    <div class="col-3 block">
        <p class="text-l-gray" *ngIf="jobLocation.address">Physical Address</p>
        <p class="text-rb"  *ngIf="jobLocation.address">
                {{jobLocation.address.formattedAddress}}
        </p>

    </div>
    <div class="col-2 block">
        <p class="text-l-gray" *ngIf="city">City</p>
        <p class="text-rb"  *ngIf="city">{{city | translateBackend}}</p>

    </div>
    <div class="col-3  flex justify-content-end align-items-start">
        <button pButton pRipple type="button" icon="pi pi-pencil" 
                (click)="onEditJobLocation.emit()"
                class="p-button-rounded p-button-text">
        </button>
    </div>
</div>