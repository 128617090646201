import { ContactBasicDto } from "./contact.dto";
import { CurrencyEnum } from "./currency.dto";

export enum AdminPriceItemType {
    INTERVIEW  = 'INTERVIEW'
}

export interface AdminPriceDtoSchema {
    priceDtos: Array<AdminPriceDto>;
}

export interface AdminPriceDto {
    id: number;
    item: AdminPriceItemType;
    currency: CurrencyEnum;
    version: number;
    price: number;
    executionDateTime: string;
    creationDateTime: string;
    admin: ContactBasicDto 
}

export interface PriceDto {
    id: number;
    item: AdminPriceItemType;
    currency: CurrencyEnum;
    version: number;
    price: number;
    taxRate: number;
    priceWithVat: number;
}

export interface PriceInputDto {
    item: AdminPriceItemType;
    currency: CurrencyEnum;
    executionDateTime: string;
    price: number;
}