import { CustomerBusinessProfileDto } from "./customer.dto";
import { RejectionReasonEnum } from "./rejection-reason.dto";

export interface CreditRequestSchema {
    creditRequests: Array<CreditRequestDisplayDto>;
    total: number;
}

export enum CreditStatus {
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
    PENDING  = 'PENDING'
}

export interface CreditRequestDisplayDto {
    id: number;
    companyName: string;
    country: number;
    city: number;
    representativeFirstName: string;
    representativeLastName: string;
    contactFirstName: string
    contactLastName: string
    email: string;
    amountAllowed: number;
    rejectionReason: RejectionReasonEnum;
    memo: string;
    creditStatus: CreditStatus;
    customer: CustomerBusinessProfileDto;   
}