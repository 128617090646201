import { AccountType } from "../account";
import { ContactsInfoDto, EmailDto, PersonalInfoDto, PhoneNumberDto } from "./contact.dto";
import { InviteDto, RoleEnum } from "./invite.dto";
import { LocationAddressDto } from "./location-address.dto";
import { DayOffDto } from "./opentimer-job-profile.dto";
import { ShiftEntryDto } from "./shift.dto";

export interface AccountContactDisplayDto {
    accountContactId: number;
    contactPersonalInfoDto: PersonalInfoDto;
    locationAddresses: LocationAddressDto;
    role: RoleEnum;
    shiftSchedulePending: boolean;
    phoneNumbers: Array<PhoneNumberDto>;
    emails: Array<EmailDto>;
    shiftEntries: Array<ShiftEntryDto>;
    dayOff: DayOffDto;
    invites: Array<InviteDto>;
    avatarUri: string;
}

export interface AccountContactDto {
    contactId: number;
    title: string;
    role: AccountRole;
}

export enum AccountRole {
    OWNER = 'OWNER',
    HR = 'HR'
}

export enum AccountStatus {
    PENDING = 'PENDING',
    IN_REVIEW = 'IN_REVIEW',
    VERIFIED = 'VERIFIED',
    NOT_VERIFIED  = 'NOT_VERIFIED',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface AccountStatusItem {
    value: AccountStatus;
    name: string;
}

export interface AccountDto {
   id: number;
   enabled: boolean;
   type:  AccountType;
   status: AccountStatus;
   countryId: number;
   avatarUri: string;
   createdDate: string;
   description: string;
   name: string;
   role: AccountRole;
   contactsInfo: ContactsInfoDto;
   contacts: Array<AccountContactDto>;
}