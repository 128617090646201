import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AccountApproveService } from '../../services/account-approve.service';

@Component({
  selector: 'app-account-enablement-button',
  templateUrl: './account-enablement-button.component.html',
  styleUrls: ['./account-enablement-button.component.scss']
})
export class AccountEnablementButtonComponent implements OnInit {
  @Input() accountId: number;

  items: MenuItem[] = [];

  constructor(private accountAppoveService: AccountApproveService) { }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Activate', 
        icon: 'pi pi-check', 
        command: () => { 
          this.enableAccount();
        }
      },
      {
        label: 'Deactivate', 
        icon: 'pi pi-times', 
        command: () => { 
          this.disAbleAccount();
        } 
      },
    ];
  }

  enableAccount() {
    this.accountAppoveService.accountEnablement(this.accountId)
  }

  disAbleAccount() {
    this.accountAppoveService.accountDisablement(this.accountId)
  }

}
