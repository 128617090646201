<section class="p-2">
    <div class="p-card content-panel">
        <div  class="grid grid-nogutter align-items-center pl-2">
            <div class="col-12 lg:col-5">
                <h2>Advanced Search</h2>
             </div>
        </div>
        <ng-content></ng-content>
        <div  class="grid grid-nogutter align-items-center p-2 justify-content-end">
            <div class="col-4 flex pr-5">
                <button pButton  label="Search" (click)="onAdvanceSearch.emit()"
                        class="primary mt-3 w-100 mr-3" pRipple> 
                </button>
                <button pButton  label="Reset"  (click)="onReset.emit()"
                        class="light mt-3 w-100" pRipple> 
                </button>
            </div>
        </div>
    </div>
</section>