<div class="grid grid-nogutter">
  <div class="col-6">
    <p class="text-ssb">Local</p>
  </div>
  <div class="col-6">
    <p class="text-ssb">International</p>
  </div>

  <div class="col-6">
    <div class="grid grid-nogutter align-items-center">
      <div class="col-4">
        <p>Full Time Booking</p>
      </div>
      <div class="col-8">
        <p class="text-ssb" *ngIf="getHourlyRate(BookingType.FULL_TIME); else notSet">
          {{ getHourlyRate(BookingType.FULL_TIME).currency | currencySymbol }}{{ getHourlyRate(BookingType.FULL_TIME).hourlyRate }}
        </p>
      </div>

      <div class="col-4">
        <p>Part Time Booking</p>

      </div>
      <div class="col-8">
        <p class="text-ssb" *ngIf="getHourlyRate(BookingType.PART_TIME); else notSet">
          {{ getHourlyRate(BookingType.PART_TIME).currency | currencySymbol }}{{ getHourlyRate(BookingType.PART_TIME).hourlyRate }}
        </p>
      </div>
      <div class="col-4">
        <p>One Time Booking</p>
      </div>
      <div class="col-8">
        <p class="text-ssb" *ngIf="getHourlyRate(BookingType.ONE_TIME); else notSet">
          {{ getHourlyRate(BookingType.ONE_TIME).currency | currencySymbol }}{{ getHourlyRate(BookingType.ONE_TIME).hourlyRate }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="grid grid-nogutter align-items-center">
      <div class="col-4">
        <p>Full Time Booking</p>
      </div>
      <div class="col-8">
        <p class="text-ssb"
           *ngIf="getHourlyRate(BookingType.FULL_TIME) && getHourlyRate(BookingType.FULL_TIME)?.internationalHourlyRate; else notAvailable">
          {{ getHourlyRate(BookingType.FULL_TIME).internationalCurrency | currencySymbol }}{{ getHourlyRate(BookingType.FULL_TIME).internationalHourlyRate }}
        </p>
      </div>

      <div class="col-4">
        <p>Part Time Booking</p>

      </div>
      <div class="col-8">
        <p class="text-ssb"
           *ngIf="getHourlyRate(BookingType.PART_TIME) && getHourlyRate(BookingType.PART_TIME).internationalHourlyRate; else notAvailable">
          {{ getHourlyRate(BookingType.PART_TIME).internationalCurrency | currencySymbol }}{{ getHourlyRate(BookingType.PART_TIME).internationalHourlyRate }}
        </p>
      </div>
      <div class="col-4">
        <p>One Time Booking</p>
      </div>
      <div class="col-8">
        <p class="text-ssb"
           *ngIf="getHourlyRate(BookingType.ONE_TIME) && getHourlyRate(BookingType.ONE_TIME).internationalHourlyRate; else notAvailable">
          {{ getHourlyRate(BookingType.ONE_TIME).internationalCurrency | currencySymbol }}{{ getHourlyRate(BookingType.ONE_TIME).internationalHourlyRate }}
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #notAvailable>
  <p class="text-ssb">N/A</p>
</ng-template>

<ng-template #notSet>
  <p class="text-ssb">-</p>
</ng-template>
