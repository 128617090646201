import { Component, EventEmitter, OnInit, Output, Input, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutoComplete } from 'primeng/autocomplete';
import { AddressLocation } from '../../models/dto/contact.dto';
import { AutoCompletePredictionDto } from '../../models/dto/google-places.dto';
import { LocationAddressDto, LocationMinimalAddressDto } from '../../models/dto/location-address.dto';
import { OpentimeAnimations } from '../../opentime-animation/opentime-animation';
import { GooglePlaceService } from '../../services/google-place.service';

@UntilDestroy()
@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  animations: [OpentimeAnimations.slideLeft]
})
export class LocationFormComponent implements OnInit, OnChanges {
  @Output() onLocationFormStatusChanges = new EventEmitter<string>();
  @Output() onLocationFormValueChanges = new EventEmitter<LocationMinimalAddressDto>();
  @Input() addressLocation: AddressLocation = null;
  @Input() displayBlock: boolean = false
  @Input() colFull: boolean = false;

  @ViewChild(AutoComplete) pAutoComplete: AutoComplete;

  results: Array<AutoCompletePredictionDto> = [];
  selectedPlace: LocationAddressDto = null;

  form = this.formBuilder.group({
    search: this.formBuilder.control(''),
    buildingName: this.formBuilder.control('', [Validators.required]),
    roomNumber: this.formBuilder.control('', [Validators.required]),
    name: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
  });

  constructor(private googlePlaceService: GooglePlaceService,
              private formBuilder: FormBuilder) { }



  ngOnChanges(changes: SimpleChanges): void {
    if(changes['addressLocation'] && changes['addressLocation'].currentValue) {
      this.form.enable();
      this.form.patchValue(this.addressLocation);
      this.selectedPlace = this.addressLocation;
      this.onLocationFormStatusChanges.emit(this.form.status);
    }
  }


  ngOnInit(): void {
    this.form.disable();
    if(this.addressLocation) {
      this.form.enable();
      this.form.patchValue(this.addressLocation);
      this.selectedPlace = this.addressLocation;
      this.onLocationFormStatusChanges.emit(this.form.status);
    }
    this.getSubcribers()
  }

  getSubcribers() {
    this.googlePlaceService.googlePredictionPlaces$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      this.results = response;
    })

    this.googlePlaceService.googlePlace$
    .pipe(untilDestroyed(this))
    .subscribe((response)=>{
      if(response) {
        this.selectedPlace = response;
        this.form.enable();
      }
    })

    this.form.statusChanges
    .pipe(untilDestroyed(this))
    .subscribe((status)=>{
      this.onLocationFormStatusChanges.emit(status);
    });

    this.form.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe((v)=>{
      if(v) {
        this.onLocationFormValueChanges.emit(v as LocationMinimalAddressDto);
      }
    });

  }

  search(ev: any) {
    this.googlePlaceService.googlePlaces(ev.query);
  }

  onDropdownClick(ev: any) {
    this.googlePlaceService.googlePlaces(ev.query);
  }

  onSelected(ev: AutoCompletePredictionDto) {
    this.googlePlaceService.googlePlaceByPlaceId(ev.placeId);
  }

  saveForm(): LocationAddressDto {
    console.log(this.selectedPlace);
    let place: LocationAddressDto = null;
    if (this.form.valid) {
      const placeForm = this.form.value as LocationAddressDto;
      place = Object.assign(this.selectedPlace, placeForm);
    }
    return place;
  }



}
